import React, { Component } from 'react'
import { Route, Redirect, withRouter, Switch } from 'react-router-dom'
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react'

import { Tabs, TabLink } from 'web/app/common/layout'
import EnergyProfile from './EnergyProfile'
import Info from './Info'
import Communication from './Communication'

@withRouter
@inject('store')
@observer
class CompanyShowView extends Component {
  static propTypes = {
    store: MobxPropTypes.observableObject.isRequired
  }

  render () {
    const { store, store: { company } } = this.props

    return (
      <div>
        <Tabs>
          <TabLink
            to={`/companies/${company.id}/info`}
            label="Info"
          />
          <TabLink
            to={`/companies/${company.id}/communication`}
            label="Communication"
          />
          <TabLink
            to={`/companies/${company.id}/energy_profile`}
            label="Energy profile"
          />
        </Tabs>

        <Switch>
          <Route
            exact
            path="/companies/:id/info"
          >
            <Info store={store} />
          </Route>
          <Route
            exact
            path="/companies/:id/communication"
          >
            <Communication store={store} />
          </Route>
          <Route
            exact
            path="/companies/:id/energy_profile"
          >
            <EnergyProfile store={store} />
          </Route>
          <Route
            exact
            path="/companies/:id/history"
          />
          <Redirect to={`/companies/${company.id}/info`} />
        </Switch>
      </div>
    )
  }
}

export default CompanyShowView
