import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Columns, Column } from 'web/app/common/layout'
import { SelectWithLabel } from 'web/app/common/html_forms'
import { t } from 'web/locale'

export default class SelectPostIndex extends Component {
  static propTypes = {
    cities: PropTypes.array.isRequired,
    city: PropTypes.string,
    columns: PropTypes.number.isRequired, // could be 2 or 3
    handleChange: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
    postalCode: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    postIndices: PropTypes.array.isRequired
  }

  static defaultProps = {
    columns:    2,
    isRequired: false
  }

  renderThirdColumn () {
    if (this.props.columns === 3) {
      return <Column />
    }
  }

  render () {
    const {
      cities,
      city,
      handleChange,
      isRequired,
      postalCode,
      postIndices
    } = this.props
    return (
      <Columns>
        <Column>
          <SelectWithLabel
            data={postIndices}
            handleChange={handleChange('postalCode')}
            isRequired={isRequired}
            label={t('attributes.postIndex.postalCode')}
            name="post_index[postal_code]"
            value={postalCode}
          />
        </Column>
        <Column>
          <SelectWithLabel
            data={cities}
            handleChange={handleChange('city')}
            isRequired={isRequired}
            label={t('attributes.postIndex.city')}
            name="post_index[city]"
            value={city}
          />
        </Column>
        {this.renderThirdColumn()}
      </Columns>
    )
  }
}
