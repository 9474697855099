import React from 'react'
import PropTypes from 'prop-types'

import Table from './components/Table'
import TableFilter from './components/TableFilter'
import columns from './components/Columns'
import { Panel, PanelBlock } from 'web/app/common/panel'

const SupplierPricesList = ({
  data, tableOptions, tableParams, isLoading
}) => {
  const { product } = tableParams
  return (
    <Panel>
      <PanelBlock>
        <TableFilter tableOptions={tableOptions} tableParams={tableParams} />
      </PanelBlock>

      <PanelBlock>
        <Table columns={columns(product)} data={data} isLoading={isLoading} />
      </PanelBlock>
    </Panel>
  )
}

SupplierPricesList.propTypes = {
  data:         PropTypes.array.isRequired,
  isLoading:    PropTypes.bool.isRequired,
  tableOptions: PropTypes.object.isRequired,
  tableParams:  PropTypes.object.isRequired
}

export default SupplierPricesList
