import buildValidation from 'web/utility/validations'

export default (values) => {
  const { filled } = buildValidation(values)

  return {
    contact: {
      email:      filled('contact.email'),
      firstName:  filled('contact.firstName'),
      jobTitle:   filled('contact.jobTitle'),
      kind:       filled('contact.kind'),
      languageId: filled('contact.languageId'),
      lastName:   filled('contact.lastName'),
      tel:        filled('contact.tel'),
      title:      filled('contact.title')
    },
    offer: {
      languageId: filled('offer.languageId')
    }
  }
}
