import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { fetchData } from './api'
import { IndexDatatable } from 'web/app/common/datatable'
import { papp } from 'web/utility/helpers'
import Notify from 'web/utility/Notify'
import { contactColumns } from './components/contactColumns'

export default class ContactsIndexTable extends Component {
  static propTypes = {
    canShow:   PropTypes.bool.isRequired,
    canUpdate: PropTypes.bool.isRequired
  }

  static defaultProps = {
    canShow:   false,
    canUpdate: false
  }

  _resolve = ({ success, data, error }) => {
    if (success) {
      return data
    } else {
      Notify.apiError(error)
      return []
    }
  }

  render () {
    const { canShow, canUpdate } = this.props
    return (
      <IndexDatatable
        withSearch
        columns={contactColumns(canShow, canUpdate)}
        fetchData={papp(fetchData, this._resolve)}
        onClickPath="/contacts"
        sorted={[{ id: 'contacts.id', desc: false }]}
      />
    )
  }
}
