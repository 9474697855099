import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { post } from 'web/utility/http'
import { t } from 'web/locale'

import ConsumptionDataPdf from './ConsumptionDataPdf'
import SignForm from './SignForm'

const RemoteDnoSignatureContainer = ({
  consumptionData: {
    publicDownloadPath,
    publicSignPath
  },
  contactName,
  salesName
}) => {
  const [success, setSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [numPages, setNumPages] = useState(null)
  const [signature, setSignature] = useState(null)

  // simple validation

  let canSubmit = !!signature && !isLoading

  // handlers

  const onDocumentLoad = ({ numPages }) => {
    setNumPages(numPages)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    const params = { signatureFile: signature }
    const { success } = await post(publicSignPath, params)
    setSuccess(success)
  }

  if (success) {
    const __html = t(`views.consumptionData.signature.success`, {
      contact_name: contactName, sales_name: salesName
    })
    const style = { fontSize: 20 }

    return <div className="notification" style={style} dangerouslySetInnerHTML={{ __html }} />
  }

  return (
    <>
      <ConsumptionDataPdf
        filePath={publicDownloadPath}
        onDocumentLoad={onDocumentLoad}
        numPages={numPages}
      />

      {
        numPages &&
          <SignForm
            canSubmit={canSubmit}
            onSignatureChange={setSignature}
            onSubmit={handleSubmit}
          />
      }
    </>
  )
}

RemoteDnoSignatureContainer.propTypes = {
  consumptionData: PropTypes.shape({
    publicDownloadPath: PropTypes.string.isRequired,
    publicSignPath: PropTypes.string.isRequired
  }).isRequired,
  contactName: PropTypes.string.isRequired,
  salesName: PropTypes.string.isRequired
}

export default RemoteDnoSignatureContainer
