import React  from 'react'
import PropTypes from 'prop-types'
import { Multiselect } from 'react-widgets'
import cn from 'classnames'
import { isObject } from 'lodash'

import useField from './useField'
import { Control, Label } from '../form-elements'
import { t } from 'web/locale'
import { toId, toInputName } from 'web/utility/helpers'
import Error from './Error'

// Please note that it is a bit different from DeprecatedMultiselectWithLabel.
// It always expects collection to be an array of objects with a
// "textField" and "valueField" and...
//
// IT WILL SET FORM FIELD STATE WITH ARRAY OF THESE OBJECTS
//
// So you may want to normalize them before submit ;)

const MultiselectWithLabel = ({
  field,

  allowCreate = 'onFilter',
  allowDuplicates = false,
  busy = false,
  data = undefined,
  defaultValue = undefined,
  disabled = false,
  error = undefined,
  fieldName = undefined,
  label = undefined,
  noLabel = false,
  placeholder = t('common.selectPrompt'),
  readOnly = false,
  required = false,
  textField = 'text',
  valueField = 'value',

  onSearch = undefined,
  onSelect = undefined,
  onChange = undefined,
  onCreateMultiple = undefined
}) => {
  const { getValue, getError, getTouched, handleChange } = useField(field, { defaultValue })

  const id = toId(field)
  label = label || (fieldName && t(`attributes.${fieldName}`)) || t(`attributes.${field}`)
  const value = getValue([])
  error = error || getError()
  const touched = getTouched()

  const inputClass = cn({
    'rw-required':         required && !!error,
    'rw-required-outline': required && touched && !!error
  })

  const iconClass = cn('icon is-small', {
    'icon-ok':                 !error,
    'icon-asterisk is-danger': !!error
  })

  const onCreate = onCreateBuilder({ value, handleChange, allowDuplicates, onCreateMultiple })
  const _defaultOnChange = (vals) => {
    const newVals = vals.map((v) => isObject(v) ? v[valueField] : v)
    handleChange(newVals)
  }

  const _onChangeFromProps = (vals) => {
    _defaultOnChange(vals)
    onChange(vals)
  }

  return (
    <Control>
      { !noLabel && <Label htmlFor={id}>{label}</Label> }
      <Control hasIcon={required} hasIconRight={required}>
        <Multiselect
          id={id}
          className={inputClass}
          value={value}
          name={toInputName(field)}
          placeholder={placeholder}
          data={data}
          defaultValue={defaultValue}
          textField={textField}
          valueField={valueField}
          allowCreate={allowCreate}
          readOnly={readOnly}
          disabled={disabled}
          busy={busy}
          required={required}
          onSearch={onSearch}
          onSelect={onSelect}
          onCreate={onCreate}
          onChange={onChange ? _onChangeFromProps : _defaultOnChange}
        />
        {required && <i className={iconClass} />}
      </Control>
      <Error touched={touched}>{error}</Error>
    </Control>
  )
}

const onCreateBuilder = ({
  value: arrayOfValues,
  handleChange,
  allowDuplicates,
  onCreateMultiple
}) => (createdValue) => {
  if (!createdValue) return

  let newValues
  createdValue = onCreateMultiple ? onCreateMultiple(createdValue) : createdValue
  arrayOfValues = arrayOfValues || []

  if (allowDuplicates && arrayOfValues.includes(createdValue)) return

  if (onCreateMultiple) {
    newValues = [...arrayOfValues, ...createdValue]
  } else {
    newValues = [...arrayOfValues, createdValue]
  }

  handleChange(newValues)
}

MultiselectWithLabel.propTypes = {
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,

  allowCreate: PropTypes.oneOf([true, false, 'onFilter']),
  allowDuplicates: PropTypes.bool,
  busy: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ])
  ), // select options
  defaultValue: PropTypes.array, // default selected options
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fieldName: PropTypes.string,
  label: PropTypes.string, // by default guessed from `field`
  noLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  textField: PropTypes.string,
  valueField: PropTypes.string,

  // callbacks
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  onCreateMultiple: PropTypes.func
}

export default MultiselectWithLabel
