import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { onCsvFormUpdate, onContractFormUpdate } from './api'
import SupplierMails from './component'
import Notify from 'web/utility/Notify'

export default class SupplierMailsContainer extends Component {
  static propTypes = {
    canUpdate:  PropTypes.bool.isRequired,
    days:       PropTypes.array.isRequired,
    mailStates: PropTypes.array.isRequired,
    supplier:   PropTypes.object.isRequired
  }

  static defaultProps = {
    days: []
  }

  _onContractFormUpdate = (values, { handleErrors, setSubmitting }) => {
    onContractFormUpdate(values.supplier)
      .then(({ success, data }) => {
        if (!success) {
          data.message ? Notify.apiError(data) : handleErrors(data)
        }

        setSubmitting(false)
      })
  }

  _onCsvFormUpdate = (values, { handleErrors, setSubmitting }) => {
    onCsvFormUpdate(values.supplier)
      .then(({ success, data }) => {
        if (!success) {
          data.message ? Notify.apiError(data) : handleErrors(data)
        }
        setSubmitting(false)
      })
  }

  _prepareTime = (time) => {
    return time
  }

  _parseToInt (array) {
    if (!array || isEmpty(array)) return
    return array.map((index) => parseInt(index))
  }

  _prepareSupplier = (supplier) => {
    return {
      ...supplier,
      contractsDays:      this._parseToInt(supplier.contractsDays),
      csvDays:            this._parseToInt(supplier.csvDays),
      deliverContractsAt: this._prepareTime(supplier.deliverContractsAt),
      deliverCsvAt:       this._prepareTime(supplier.deliverCsvAt)
    }
  }

  render () {
    const { supplier, days, mailStates, canUpdate } = this.props
    return (
      <SupplierMails
        days={days}
        onChangeState={this._onChangeState}
        onContractFormUpdate={this._onContractFormUpdate}
        onCsvFormUpdate={this._onCsvFormUpdate}
        states={mailStates}
        supplier={this._prepareSupplier(supplier)}
        canUpdate={canUpdate}
      />
    )
  }
}
