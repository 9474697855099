import React from 'react'
import PropTypes from 'prop-types'

import { Form } from 'web/app/common/forms'
import { Button }          from 'web/app/common/elements'
import { Columns, Column } from 'web/app/common/layout'
import { t }               from 'web/locale'
import { Signature }       from 'web/app/common/signature'
import { StampUpload }     from './'

const SignContractForm = ({
  disableSubmit,
  isStampShown,
  onSignatureChange,
  onStampUpload,
  onSubmit,
  pdfState,
  toggleSendResignationLetter
}) => {
  return (
    <div>
      <div>
        <label className="checkbox">
          <input
            type="checkbox"
            onClick={toggleSendResignationLetter}
            defaultChecked={pdfState.sendResignationLetter}
          />
          <u>{t('views.offers.contractSign.sendResLetter')}</u>
        </label>
        <br />
        <br />
      </div>

      {
        isStampShown &&
        <Form>
          <StampUpload onStampUpload={onStampUpload} />
        </Form>
      }

      <Columns>
        <Column classNames="is-4 is-offset-3 signature-field">
          <p>{t('views.offers.contractSign.signature')} *</p>
          <Signature onChange={onSignatureChange('contractSign')} />
        </Column>
        <Column classNames="is-2 paraph-field">
          <p>{t('views.offers.contractSign.paraph')} *</p>
          <Signature onChange={onSignatureChange('paraphSign')} />
        </Column>
      </Columns>
      <Button type="submit" onClick={onSubmit} disabled={disableSubmit}>
        {t('views.offers.contractSign.submit')}
      </Button>
    </div>
  )
}

SignContractForm.propTypes = {
  disableSubmit:               PropTypes.bool.isRequired,
  isStampShown:                PropTypes.bool.isRequired,
  onSignatureChange:           PropTypes.func.isRequired,
  onStampUpload:               PropTypes.func.isRequired,
  onSubmit:                    PropTypes.func.isRequired,
  pdfState:                    PropTypes.object.isRequired,
  toggleSendResignationLetter: PropTypes.func.isRequired
}

export default SignContractForm
