import React from 'react'
import PropTypes from 'prop-types'
import { chunk }            from 'lodash'
import { humanize }         from 'underscore.string'

import { FormRow }                      from 'web/app/common/layout'
import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import { isCountry }                    from 'web/utility/country'
import {
  ControlButtons,
  InputWithLabel as Input,
  SelectWithLabel as Select
} from 'web/app/common/forms'

const optionRules = {
  'Off':   { text: '-', value: '' },
  'true':  { text: 'Yes', value: 'true' },
  'false': { text: 'No', value: 'false' }
}

const prepareLabel = (name) => {
  if (isCountry('fr')) name = name.replace(/^.*N/, '')
  return humanize(name)
}

const renderElement = ({ name, _value, options }) => { // eslint-disable-line react/prop-types
  const label = prepareLabel(name)
  if (!options) {
    return <Input key={name} field={name} label={label} />
  } else {
    const data = options.map((opt) => optionRules[opt] || { text: humanize(opt), value: opt })
    return <Select key={name} field={name} data={data} label={label} />
  }
}

const ManualData = ({ manualData }) =>
  <Panel kind="light">
    <PanelHead>Manual data</PanelHead>
    <PanelBlock>
      <>
        {
          chunk(manualData, 3).map((chunk) =>
            <FormRow key={chunk.map((el) => el.name)}>
              {
                chunk.map((el) => renderElement(el))
              }
            </FormRow>
          )
        }
        <ControlButtons withoutReset />
      </>
    </PanelBlock>
  </Panel>

ManualData.propTypes = {
  manualData: PropTypes.array.isRequired
}

export default ManualData
