import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'web/app/common/elements'

const UserLoginLink = (props) => {
  const { id, canPretend, handleClick } = props
  if (!canPretend) return null

  return (
    <span href="#" onClick={(e) => handleClick(e, id)}>
      <Icon iconClass="icon-logout" />
    </span>
  )
}

UserLoginLink.propTypes = {
  id:          PropTypes.number.isRequired,
  canPretend:  PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default UserLoginLink
