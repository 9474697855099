import React from 'react'
import PropTypes from 'prop-types'
import { upperCase } from 'lodash'

import { Datatable } from 'web/app/common/datatable'
import OkIcon        from './OkIcon'
import CancelIcon    from './CancelIcon'

const generateColumns = (meters, createFilter, deleteFilter) =>
  meters.map(({ id: meterId, name }) => ({
    Header:   upperCase(name),
    accessor: name,
    sortable: false,
    style:    { textAlign: 'center' },
    Cell:   ({ value: id }) => { // eslint-disable-line react/prop-types
      if (id) {
        return <OkIcon func={deleteFilter} value={id} type="meter" />
      } else {
        return <CancelIcon func={createFilter} value={{ meterId }} type="meter" />
      }
    }
  }))

const prepareData = (data) =>
  [
    data.reduce((acc, filter) => {
      if (filter) acc[filter.meter.name] = filter.id
      return acc
    }, {})
  ]

const MeterFilters = ({ createFilter, deleteFilter, data, isLoading, meters }) =>
  <Datatable
    columns={generateColumns(meters, createFilter, deleteFilter)}
    data={prepareData(data)}
    loading={isLoading}
    showPagination={false}
  />

MeterFilters.propTypes = {
  createFilter: PropTypes.func.isRequired,
  data:         PropTypes.array.isRequired,
  deleteFilter: PropTypes.func.isRequired,
  isLoading:    PropTypes.bool.isRequired,
  meters:       PropTypes.array.isRequired
}

export default MeterFilters
