import React from 'react'
import PropTypes from 'prop-types'

import Table from './Table'

const TableWrapper = ({ children }) => (
  <div className="table-wrapper is-scrollable">
    {children}
  </div>
)

TableWrapper.propTypes = {
  children: PropTypes.objectOf(Table)
}

export default TableWrapper
