import React, { Component } from 'react'
import { PropTypes as MobxPropTypes, inject } from 'mobx-react'

import { fetchContracts, fetchEans, fetchOffers } from './api'
import EnergyProfile from './component'

@inject('store')
class EnergyProfileContainer extends Component {
  static propTypes = {
    store: MobxPropTypes.observableObject.isRequired
  }

  render () {
    const { company, permissions } = this.props.store

    return (
      <EnergyProfile
        onContractsTableChange={fetchContracts(company.id)}
        onEansTableChange={fetchEans(company.id)}
        onOffersTableChange={fetchOffers(company.id)}
        permissions={permissions}
      />
    )
  }
}

export default EnergyProfileContainer
