import React, { Component } from 'react'
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react'
import {
  ContractsTable,
  OffersTable
} from 'web/app/companies/CompanyShowView/EnergyProfile/components'
import PropTypes from 'prop-types'

import {
  fetchContacts,
  fetchCreditScores,
  fetchMeetings,
  fetchCalls,
  postCalls,
  postUnlockLeadList,
  fetchOffers,
  fetchContracts,
  fetchCallbacks
} from './api'
import {
  ContactsTable,
  ContactsHeading,
  CreditScoresTable,
  CreditScoresHeading,
  MeetingsTable,
  CallsHeading,
  CallsTable,
  CallbacksTable,
  GeneralInfo,
  GeneralInfoHeading
} from './components'
import { t } from 'web/locale'
import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import { validateCallsForm } from 'web/app/common/validations/validateCallsForm'
import AdditionalCallStates from './components/AdditionalCallStates'
import CallStates from './components/CallStates'
import Counters from './components/Counters'
import { Button } from 'web/app/common/elements'
import Notify from 'web/utility/Notify'

const phoneLink = {
  __html:
  '<svg ' +
  "viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'" +
  "xmlns:xlink='http://www.w3.org/1999/xlink'>" +
  "<foreignObject width='100%' height='100%'>" +
  "<a class='icon-phone'/>" +
  '</foreignObject>' +
  '</svg>'
}
const endCallLink = {
  __html:
  '<svg ' +
  "viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'" +
  "xmlns:xlink='http://www.w3.org/1999/xlink'>" +
  "<foreignObject width='100%' height='100%'>" +
  "<a class='icon-end-call'/>" +
  '</foreignObject>' +
  '</svg>'
}
const pauseLink = {
  __html:
  '<svg ' +
  "viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'" +
  "xmlns:xlink='http://www.w3.org/1999/xlink'>" +
  "<foreignObject width='100%' height='100%'>" +
  "<a class='icon-pause'/>" +
  '</foreignObject>' +
  '</svg>'
}

@inject('store')
@observer
class OverviewCompany extends Component {
  static propTypes = {
    store: MobxPropTypes.observableObject.isRequired,
    toogleCallStateChangeModal: PropTypes.func.isRequired
  }

  handleLeadListUnlock = () => {
    postUnlockLeadList().then(({ success, data }) => {
      if (success) {
        window.location = data.url
      } else {
        Notify.error('Something went wrong')
      }
    })
  }

  render () {
    const { store, toogleCallStateChangeModal } = this.props
    const {
      company,
      callsStates,
      callsSubStates,
      callsContacts,
      meetingsDurations,
      sales,
      callAgents,
      permissions,
      generalInfo,
      leadList
    } = store
    const afterAddContactRedirectUrl = encodeURI(`/lead_lists/${leadList.id}/company`)

    return (
      <Panel>
        <PanelHead>Simplelist details page</PanelHead>
        <PanelBlock>
          <div className="call-info">
            <div className="button1 phone-link" dangerouslySetInnerHTML={phoneLink} />
            <div className="button2 end-call-link" dangerouslySetInnerHTML={endCallLink} />
            <div className="button3 pause-link" dangerouslySetInnerHTML={pauseLink} />
            <Counters filteredCompanies={leadList.filteredCompanies} />
            <CallStates toogleModal={toogleCallStateChangeModal} />
            <div className="data-checked">
              {/* Change to common/forms/CheckBoxWithLabel */}
              <input type="checkbox" />
              {' '}
              {'Data checked'}
            </div>
          </div>
          <div>
            <Button onClick={() => this.handleLeadListUnlock()}> Finish work </Button>
          </div>
          <AdditionalCallStates toogleModal={toogleCallStateChangeModal} />
          <Panel kind={'primary'}>
            <PanelHead className="accordion active">
              <i className="icon-right-open" />
              <GeneralInfoHeading company={company} />
            </PanelHead>
            <PanelBlock className="accordion-body">
              <GeneralInfo company={company} generalInfo={generalInfo} />
            </PanelBlock>
          </Panel>
          <Panel>
            <PanelHead className="accordion active">
              <i className="icon-right-open" />
              <ContactsHeading
                title={t('common.panelHeaders.contacts')}
                buttonTitle={t('common.button.add')}
                companyId={company.id}
                afterAddContactRedirectUrl={afterAddContactRedirectUrl}
              />
            </PanelHead>
            <PanelBlock className="accordion-body">
              <ContactsTable onChange={fetchContacts(company.id)} />
            </PanelBlock>
          </Panel>
          {permissions.callbacks.includes('read_all') && (
            <Panel>
              <PanelHead className="accordion active">
                <i className="icon-right-open" />
                {t('common.panelHeaders.callbacks')}
              </PanelHead>
              <PanelBlock className="accordion-body">
                <CallbacksTable onChange={fetchCallbacks(company.id)} />
              </PanelBlock>
            </Panel>
          )}
          {permissions.creditScores.includes('read_all') && (
            <Panel>
              <PanelHead className="accordion active">
                <i className="icon-right-open" />
                <CreditScoresHeading
                  title={t('common.panelHeaders.creditScores')}
                  buttonTitle={t('common.button.add')}
                  companyId={company.id}
                />
              </PanelHead>
              <PanelBlock className="accordion-body">
                <CreditScoresTable onChange={fetchCreditScores(company.id)} />
              </PanelBlock>
            </Panel>
          )}
          {permissions.calls.includes('read_all') && (
            <Panel>
              <PanelHead className="accordion active">
                <i className="icon-right-open" />
                <CallsHeading
                  title={t('common.panelHeaders.calls')}
                  buttonTitle={t('common.button.add')}
                  companyId={company.id}
                  callsStates={callsStates}
                  callsSubStates={callsSubStates}
                  callsContacts={callsContacts}
                  meetingsDurations={meetingsDurations}
                  sales={sales}
                  callAgents={callAgents}
                  validateCallsForm={validateCallsForm}
                  postCalls={postCalls}
                  afterAddContactRedirectUrl={afterAddContactRedirectUrl}
                />
              </PanelHead>
              <PanelBlock className="accordion-body">
                <CallsTable onChange={fetchCalls(company.id)} />
              </PanelBlock>
            </Panel>
          )}
          {permissions.meetings.includes('read_all') && (
            <Panel>
              <PanelHead className="accordion active">
                Meetings
                <i className="icon-right-open" />
              </PanelHead>
              <PanelBlock classNames="accordion-body">
                <MeetingsTable onChange={fetchMeetings(company.id)} />
              </PanelBlock>
            </Panel>
          )}
          {permissions.contracts.includes('read_all') && (
            <Panel>
              <PanelHead>{t('views.companies.show.contracts.title')}</PanelHead>
              <PanelBlock>
                <ContractsTable onChange={fetchContracts(company.id)} />
              </PanelBlock>
            </Panel>
          )}
          {permissions.offers.includes('read_all') && (
            <Panel>
              <PanelHead>
                {t('views.companies.show.offers.title')}
              </PanelHead>
              <PanelBlock>
                <OffersTable onChange={fetchOffers(company.id)} />
              </PanelBlock>
            </Panel>
          )}
        </PanelBlock>
      </Panel>
    )
  }
}

export default OverviewCompany
