import React from 'react'
import PropTypes from 'prop-types'

const Level = ({ children }) => (
  <div className="level">{children}</div>
)

Level.propTypes = {
  children: PropTypes.any.isRequired
}

export default Level
