import React from 'react'
import PropTypes from 'prop-types'
import { isObject } from 'lodash'
import { humanize } from 'underscore.string'

import NativeSelectOption from './NativeSelectOption'

const buildSimpleOptions = (options, pretty) =>
  options.map((option) =>
    <NativeSelectOption
      key={option}
      value={option}
      name={pretty ? humanize(option) : option}
    />
  )

const buildOptions = (options, pretty) =>
  options.map(({ text, value, disabled }) =>
    <NativeSelectOption
      key={value}
      value={value}
      name={pretty ? humanize(text) : text}
      disabled={disabled || false}
    />
  )

const NativeSelect = ({ value, options, onChange, pretty, className, isLoading }) =>
  <span className="select is-fullwidth">
    <select className={className} onChange={onChange} value={value} disabled={isLoading}>
      {
        isObject(options[0])
          ? buildOptions(options, pretty)
          : buildSimpleOptions(options, pretty)
      }
    </select>
  </span>

NativeSelect.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  pretty: PropTypes.bool,
  value: PropTypes.any.isRequired
}

NativeSelect.defaultProps = {
  isLoading: false,
  pretty: true,
  value: ''
}

export default NativeSelect
