import { toNumber, isEmpty, isNumber, forEach, flatMap } from 'lodash'

import * as prep from 'web/utility/prepareData'

const isAnyEmptyInternal = (obj, requiredKeys) => {
  let emptyValues = []

  requiredKeys.forEach((key) => {
    if (isEmpty(obj[key]) && !isNumber(obj[key])) {
      emptyValues.push(obj[key])
    }
  })

  return !isEmpty(emptyValues)
}

export const calculatePrice = {
  electricity: {
    fix: (obj, getValue) => {
      const totalMargin = getValue('mandate.totalMargin')
      const isTotalMarginValid = !isEmpty(totalMargin) || isNumber(totalMargin)
      const isTariffPriceValid = !isEmpty(obj.tariffPrice) || isNumber(obj.tariffPrice)

      if (isTotalMarginValid && isTariffPriceValid) {
        return toNumber(totalMargin) + toNumber(obj.tariffPrice)
      }
    },
    flex: (obj, getValue) => {
      const totalMargin        = getValue('mandate.totalMargin')
      const isTotalMarginValid = !isEmpty(totalMargin) || isNumber(totalMargin)
      const requiredKeys       = ['coefficient', 'marketPrice', 'tariffPrice']

      if (isTotalMarginValid && !isAnyEmptyInternal(obj, requiredKeys)) {
        return (toNumber(obj.marketPrice) * toNumber(obj.coefficient)) +
          toNumber(obj.tariffPrice) +
          toNumber(totalMargin)
      }
    }
  },
  gas: {
    fix: (obj, getValue) => {
      const mandate               = getValue('mandate')
      const requiredKeysInMandate = ['totalMargin', 'productTypeAddon', 'gasTransportPrice']
      const requiredKeysInTariff  = ['tariffPrice']

      if (
        !isAnyEmptyInternal(mandate, requiredKeysInMandate) &&
        !isAnyEmptyInternal(obj, requiredKeysInTariff)
      ) {
        return toNumber(obj.tariffPrice) +
          toNumber(mandate.productTypeAddon) +
          toNumber(mandate.gasTransportPrice) +
          toNumber(mandate.totalMargin)
      }
    },
    flex: (obj, getValue) => {
      const mandate               = getValue('mandate')
      const requiredKeysInMandate = ['totalMargin', 'gasTransportPrice', 'productTypeAddon']
      const requiredKeysInTariff  = ['marketPrice', 'coefficient', 'tariffPrice']

      if (
        !isAnyEmptyInternal(mandate, requiredKeysInMandate) &&
        !isAnyEmptyInternal(obj, requiredKeysInTariff)
      ) {
        return (toNumber(obj.marketPrice) * toNumber(obj.coefficient)) +
          toNumber(obj.tariffPrice) +
          toNumber(mandate.gasTransportPrice) +
          toNumber(mandate.productTypeAddon) +
          toNumber(mandate.totalMargin)
      }
    }
  }
}

const filterByProduct = (objects, product) => {
  return objects.filter((obj) => obj.product === product)
}

const prepareTariffGroups = (tariffGroups, product) => {
  const filteredTariffGroups = filterByProduct(tariffGroups, product)
  let groupedTariffGroups = {}

  filteredTariffGroups.forEach((tariffGroup) => {
    tariffGroup.tariffs.forEach((tariff) => {
      groupedTariffGroups[tariff.id] = tariffGroup
    })
  })

  return groupedTariffGroups
}

const prepareTariffs = (tariffGroups, product) => {
  const filteredTariffGroups = filterByProduct(tariffGroups, product)
  return flatMap(filteredTariffGroups, (tariffGroup) => tariffGroup.tariffs)
}

export const preparedData = ({ store }, product) => ({
  marginLetters: store.marginLetters,
  marginTypes:   prep.preparePriceModels(store.priceModels),
  marketNames:   prep.prepareMarketNames(filterByProduct(store.marketNames, product)),
  meters:        prep.prepareMeters(store.meters),
  productTypes:  prep.prepareProductTypes(filterByProduct(store.productTypes, product)),
  suppliers:     prep.prepareSuppliers(store.suppliers),
  tariffGroups:  prepareTariffGroups(store.tariffGroups, product),
  tariffs:       prepareTariffs(store.tariffGroups, product)
})

export const prepareDefaultValues = (data) => ({
  mandate: {
    brokerFixedFee:   '0',
    fixedFee:         '0',
    supplierFixedFee: '0'
  },
  tariffs: data.tariffs.map((tariff) => {
    return { tariffId: tariff.id }
  })
})

export const isAnyEmpty = (obj) => {
  let empties = []

  forEach(obj, (value, key) => {
    if (isEmpty(value) && !isNumber(value)) {
      empties.push(key)
    }
  })

  return !isEmpty(empties)
}
