import React from 'react'
import PropTypes from 'prop-types'

import { Datatable } from 'web/app/common/datatable'

const Table = ({ columns, data, isLoading }) => {
  const options = { showPagination: false }

  return (
    <Datatable
      columns={columns}
      data={data}
      loading={isLoading}
      options={options}
    />
  )
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
}

Table.defaultProps = {
  isLoading: false
}

export default Table
