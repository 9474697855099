import React from 'react'
import PropTypes from 'prop-types'

import { ManualFieldsForm, MeetingForm } from './components'
import { ControlButtons } from 'web/app/common/forms'

const CreateDnoConsumption = ({
  manualFields,
  contacts,
  durations,
  sales,
  formApi: {
    getValue
  }
}) => (
  <>
    { manualFields.length > 0 && <ManualFieldsForm manualFields={manualFields} /> }
    <MeetingForm
      contacts={contacts}
      durations={durations}
      sales={sales}
      isNewAppointment={!!getValue('newAppointment')}
    />
    <ControlButtons />
  </>
)

CreateDnoConsumption.propTypes = {
  manualFields: PropTypes.array.isRequired,
  contacts: PropTypes.array.isRequired,
  durations: PropTypes.array.isRequired,
  sales: PropTypes.array.isRequired,
  formApi: PropTypes.shape({
    getValue: PropTypes.func.isRequired
  })
}

export default CreateDnoConsumption
