import React from 'react'
import PropTypes from 'prop-types'

const Panel = ({ kind, children, className = '' }) => {
  const classNames = `panel is-${kind} ${className}`

  return <div className={classNames}>{children}</div>
}

Panel.propTypes = {
  children:  PropTypes.any.isRequired,
  className: PropTypes.string,
  kind:      PropTypes.string.isRequired
}

Panel.defaultProps = {
  kind: 'primary'
}

export default Panel
