import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'
import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import { FormRow } from 'web/app/common/layout'
import { Mandate, Fees, Prices } from './components'

import {
  ControlButtons,
  SelectWithLabel as Select
} from 'web/app/common/forms'

const MandateForm = ({
  actions: { calculatePrice },
  data: {
    marginLetters, marginTypes, marketNames, tariffGroups,
    meters, productTypes, suppliers, tariffs
  },
  params,
  formApi: {
    getValue, handleChange: setValue, getErrors
  }
}) => {
  const { product, priceModel } = params

  const mandateProps      = { suppliers, marginTypes, marginLetters }
  const formActions       = { getValue, setValue }
  const pricesProps       = { tariffs, params, marketNames, productTypes, ...formActions, calculatePrice, tariffGroups } // eslint-disable-line max-len
  const isElectricityFlex = product === 'electricity' && priceModel === 'flex'
  const errors = getErrors('offerEanMeter')

  return (
    <Panel kind="primary">
      <PanelHead>{t('views.mandates.new.title')}</PanelHead>

      <>
        <Mandate {...mandateProps} />
        <PanelBlock>
          <FormRow columns={3}>
            <Select field="mandate.meterId" data={meters} required />
            { isElectricityFlex && <Select field="mandate.marketNameId" data={marketNames} /> }
          </FormRow>
          <Fees {...formActions} />
        </PanelBlock>

        <Prices {...pricesProps} />

        <PanelBlock>
          {
            errors.length > 0 &&
            <div className="notification is-danger">
              <ul>
                {errors.map((error) => <li key={error}>{error}</li>)}
              </ul>
            </div>
          }
          <ControlButtons />
        </PanelBlock>
      </>
    </Panel>
  )
}

MandateForm.propTypes = {
  actions: PropTypes.shape({
    calculatePrice:  PropTypes.object.isRequired
  }),
  data: PropTypes.shape({
    marginLetters: PropTypes.array.isRequired,
    marginTypes:   PropTypes.array.isRequired,
    meters:        PropTypes.array.isRequired,
    productTypes:  PropTypes.array.isRequired,
    suppliers:     PropTypes.array.isRequired,
    tariffGroups:  PropTypes.object.isRequired
  }),
  formApi: PropTypes.any,
  params:     PropTypes.object.isRequired
}

export default MandateForm
