import React from 'react'
import PropTypes from 'prop-types'

import { date, t } from 'web/locale'
import { IndexDatatable } from 'web/app/common/datatable'
import { countryEanName } from 'web/utility/country'

const mainTableColumns = [{
  Header: countryEanName(),
  accessor: 'ean.number',
  sortable: false
}, {
  Header: t('attributes.ean.product'),
  accessor: 'ean.product',
  sortable: false
}, {
  Header: t('attributes.ean.meterId'),
  accessor: 'ean.meter.name',
  sortable: false
}, {
  Header: t('attributes.eanStat.deliverFrom'),
  accessor: 'deliverFrom',
  render: ({ value }) => date(value),
  sortable: false
}, {
  Header: t('attributes.ean.state'),
  accessor: 'ean.state',
  sortable: false
}]

const EansTablePaginated = ({ onChange }) => (
  <IndexDatatable
    columns={mainTableColumns}
    fetchData={onChange}
    sorted={[{ id: 'eanStats.id', desc: false }]}
  />
)

EansTablePaginated.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default EansTablePaginated
