import React from 'react'
import PropTypes from 'prop-types'

import { Panel, PanelBlock } from 'web/app/common/panel'
import { t } from 'web/locale'
import { FormRow } from 'web/app/common/layout'
import {
  Button
} from 'web/app/common/elements'
import {
  ControlButtons,
  Form,
  DateTimePickerWithLabel as DatePicker,
  FileInputWithLabel as FileInput,
  SelectWithLabel as Select
} from 'web/app/common/forms'
import validation from './validation'

const SupplierPricesImport = ({
  minDate,
  supplierGasUnits,
  supplierElectricityUnits,
  onRollbackImport
}) => {
  return (
    <Panel>
      <PanelBlock>
        <>
          <FormRow columns={4}>
            <FileInput
              field="priceFile.file"
              label={t('common.button.file')}
              isWide
              required
            />
            <Select
              field="gasUnitId"
              label={t('views.suppliers.show.import.gasUnitId')}
              data={supplierGasUnits}
              required
            />
            <Select
              field="electricityUnitId"
              label={t('views.suppliers.show.import.electricityUnitId')}
              data={supplierElectricityUnits}
              required
            />
            <DatePicker
              field="validFrom"
              label={t('views.suppliers.show.import.validFrom')}
              time={false}
              min={minDate}
              required
            />
          </FormRow>

          <ControlButtons />
        </>
      </PanelBlock>
      <PanelBlock>
        <Button onClick={onRollbackImport}>
          {t('views.suppliers.show.import.rollback._')}
        </Button>
      </PanelBlock>
    </Panel>
  )
}

SupplierPricesImport.propTypes = {
  minDate:                  PropTypes.instanceOf(Date),
  supplierElectricityUnits: PropTypes.array.isRequired,
  supplierGasUnits:         PropTypes.array.isRequired,
  onRollbackImport: PropTypes.func.isRequired
}

export default (props) => {
  return (
    // eslint-disable-next-line
    <Form validation={validation} onSubmit={props.onSubmit}>
      <SupplierPricesImport {...props} />
    </Form>
  )
}
