import { post } from 'web/utility/http'
import { pricesPath } from '../../helpers'

const importPrices = (data) =>
  post(pricesPath('/'), data)

const rollbackImport = () =>
  post(pricesPath('/rollback'))

export { importPrices, rollbackImport }
