import React from 'react'
import PropTypes from 'prop-types'

import { IndexDatatable } from 'web/app/common/datatable'
import { t, dateTime } from 'web/locale'

/* eslint-disable react/prop-types */
const columns =
[
  {
    accessor: 'id',
    sortable: false,
    minWidth: 75,
    Header: t('attributes.call.id')
  }, {
    id: 'contact.first_name',
    accessor: 'contactName',
    minWidth: 180,
    Header: t('attributes.call.contact')
  }, {
    id: 'calls.created_at',
    accessor: ({ createdAt }) => dateTime(createdAt),
    minWidth: 180,
    Header: t('attributes.call.date')
  }, {
    id: 'calls.state',
    accessor: 'state',
    minWidth: 180,
    Header: t('attributes.call.status._')
  }, {
    accessor: 'note',
    sortable: false,
    minWidth: 180,
    Header: t('attributes.call.note'),
    Cell: ({ value }) => <div dangerouslySetInnerHTML={{ __html: value }} />
  }, {
    id: 'user.first_name',
    accessor: 'userName',
    minWidth: 180,
    Header: t('attributes.call.user')
  }
]
/* eslint-enable react/prop-types */

const CallsTable = ({ onChange }) => {
  return (
    <div id="calls-table">
      <IndexDatatable
        fetchData={onChange}
        columns={columns}
        sorted={[{ id: 'calls.created_at', desc: true }]}
      />
    </div>
  )
}

CallsTable.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default CallsTable
