import React from 'react'
import PropTypes from 'prop-types'
import Label from './Label'

const HorizontalLabel = ({ children }) => (
  <div className="control-label">
    <Label>
      {children}
    </Label>
  </div>
)

HorizontalLabel.propTypes = {
  children: PropTypes.children
}

export default HorizontalLabel
