import { get, apiPath } from 'web/utility/http'

const fetchData = (resolve, { page, pageSize, sorting, query }) =>
  get(apiPath('/contacts'), {
    params: {
      page,
      pageSize,
      sorting,
      query
    }
  }).then(resolve)

export { fetchData }
