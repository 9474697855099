import React, { Component } from 'react'
import { fetchMargins, deleteMargin, createMargin, updateRatio } from './api'
import Notify from 'web/utility/Notify'
import SupplierMargins from './component'
import { t } from 'web/locale'

export default class SupplierMarginsContainer extends Component {
  state = {
    creatingMargin: false,
    margins:        [],
    tableIsLoading: false,
    updatingRatio:  false,
    marginType:     null
  }

  componentDidMount () {
    this._fetchData()
  }

  _handleMarginType = (type) => {
    this.setState({ marginType: type })
  }

  _fetchData = () => {
    this.setState({ tableIsLoading: true, margins: [] })

    fetchMargins().then(({ success, error, data }) => {
      this.setState({ tableIsLoading: false })

      if (success) {
        this.setState({ margins: data })
      } else {
        Notify.apiError(error)
      }
    })
  }

  _onMarginCreate = (values, _state, _props, instance) => {
    this._protectedFuction((secretWord) => {
      this.setState({ creatingMargin: true, tableIsLoading: true })
      const params = { ...values, secretWord }

      createMargin(params)
        .then(({ success, data, error }) => {
          if (success) {
            this._addMargin(data)
          } else {
            this.setState({ creatingMargin: false, tableIsLoading: false })

            if (instance) {
              instance.setAllTouched() &&
              instance.setState({ errors: data })
            } else {
              Notify.apiError(error)
            }
          }
        })
    })
  }

  _addMargin = (data) => {
    const margins = data ? [...this.state.margins, data] : this.state.margins

    this.setState({
      margins,
      tableIsLoading: false,
      creatingMargin: false
    })
  }

  _protectedFuction (func) {
    const title = t('views.helpers.checkSecret')
    const secretWord = window.prompt(title)

    if (secretWord === null) {
      return
    }

    func(secretWord)
  }

  _onMarginDelete = (id) => {
    this._protectedFuction((secretWord) => {
      deleteMargin(id, { secretWord: secretWord }).then()
        .then(({ success, error }) => {
          if (success) {
            this._deleteMargin(id)
          } else {
            this.setState({ isTableLoading: false })
            Notify.apiError(error)
          }
        })
    })
  }

  _deleteMargin = (id) => {
    this.setState({
      margins: this.state.margins.filter((obj) => obj.id !== id),
      tableIsLoading: false
    })
  }

  _onRatioUpdate = (values, _state, _props, instance) => {
    this._protectedFuction((secretWord) => {
      this.setState({ updatingRatio: true, tableIsLoading: true })
      values.secretWord = secretWord

      updateRatio(values).then(({ success, data, error }) => {
        this.setState({ updatingRatio: false })
        if (success) {
          this._fetchData()
        } else {
          if (instance) {
            instance.setAllTouched()
            instance.setState({ errors: data })
          } else {
            Notify.apiError(error)
          }
        }
      })
    })
  }

  render () {
    const dataOptions = { ...this.props }

    return (
      <SupplierMargins
        margins={this.state.margins}
        onMarginDelete={this._onMarginDelete}
        onMarginCreate={this._onMarginCreate}
        onRatioUpdate={this._onRatioUpdate}
        updatingRatio={this.state.updatingRatio}
        creatingMargin={this.state.creatingMargin}
        dataOptions={dataOptions}
        tableIsLoading={this.state.tableIsLoading}
        handleMarginType={this._handleMarginType}
        marginType={this.state.marginType}
      />
    )
  }
}
