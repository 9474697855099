import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'

import { Icon } from 'web/app/common/elements'
import { t } from 'web/locale'
import { countryEanName } from 'web/utility/country'

import {
  Table,
  TableBody as Body,
  TableCell as Cell,
  TableCellHeader as HeadCell,
  TableHeader as Header,
  TableRow as Row,
  TableWrapper as Wrapper
} from 'web/app/common/table'

const EansRow = (ean, _i, finalize, deleteEanStat) => {
  const path = {
    pathname: `/offers/${ean.offerId}/parameters/${ean.id}/${ean.eanStatId}`,
    hash:     ean.product
  }

  return (
    <Row key={ean.id}>
      <Cell>{ean.supplier}</Cell>
      <Cell>{ean.volume}</Cell>
      <Cell>{ean.price}</Cell>
      <Cell>{ean.startOfDelivery}</Cell>
      <Cell>{ean.number}</Cell>
      <Cell>{ean.address}</Cell>
      <Cell>{ean.postalCode}</Cell>
      {
        finalize &&
        <Cell>
          <Link to={path}>
            <Icon iconClass="icon-pencil" link={false} />
          </Link>
          <Icon
            iconClass="icon-trash"
            onClick={deleteEanStat(ean.eanStatId)}
          />
        </Cell>
      }
    </Row>
  )
}

const EansTable = ({ unit, table, finalize, deleteEanStat }) => {
  if (isEmpty(table)) return null
  const volumeText = `${unit}/${t('common.year')}`

  return (
    <Wrapper>
      <Table>
        <Header>
          <HeadCell>{t('views.offers.eansTable.supplier')}</HeadCell>
          <HeadCell>{t('views.offers.eansTable.volume')} ({volumeText})</HeadCell>
          <HeadCell>{t('views.offers.eansTable.price')}</HeadCell>
          <HeadCell>{t('views.offers.eansTable.startDelivery')}</HeadCell>
          <HeadCell>{countryEanName()}</HeadCell>
          <HeadCell>{t('views.offers.eansTable.address')}</HeadCell>
          <HeadCell>{t('views.offers.eansTable.postalCode')}</HeadCell>
          { finalize && <HeadCell>{t('views.offers.eansTable.actions')}</HeadCell> }
        </Header>

        <Body>
          {table.map((row, i) => EansRow(row, i, finalize, deleteEanStat))}
        </Body>
      </Table>
    </Wrapper>
  )
}

EansTable.propTypes = {
  finalize:      PropTypes.bool.isRequired,
  unit:          PropTypes.string.isRequired,
  deleteEanStat: PropTypes.func.isRequired,
  table:         PropTypes.array
}

EansTable.defaultProps = {
  eans: []
}

export default EansTable
