import React, { Component }      from 'react'
import PropTypes                 from 'prop-types'
import Notify                    from 'web/utility/Notify'
import { MeetingsOverviewIndex } from './component'
import { prepareSorting }        from 'web/utility/prepareData'
import { fetchData }             from './api'

export default class MeetingsTableContainer extends Component {
  static propTypes = {
    tabs: PropTypes.array.isRequired,
    meetingsDurations: PropTypes.object.isRequired
  }

  state = {
    currentTab: this.props.tabs[0].value,
    isModalVisible: false,
    isMeetingModalVisible: false,
    newState: '',
    targetMeeting: null,
    tableData: {
      data:     [],
      loading:  true,
      page:     1,
      pages:    1,
      pageSize: 25,
      query:    '',
      sorted:  [{ id: 'id', desc: false }]
    }
  }

  _isCurrentTab = (tab) => {
    return this.state.currentTab === tab
  }

  _handleTableChange = (state = this.state.tableData) => {
    const { currentTab, tableData } = this.state
    const { page, pageSize, sorted } = state
    const { tableData: { query } }    = this.state

    this.setState({ tableData: { ...tableData, page, pageSize, sorted, loading: true } })
    fetchData({
      page: page + 1,
      pageSize,
      query,
      sorting: prepareSorting(sorted),
      state: currentTab
    }).then(({ success, response, error }) => {
      const { tableData } = this.state

      if (success) {
        const { data, pages } = response.data

        this.setState({ tableData: { ...tableData, data, pages, loading: false } })
      } else {
        this.setState({ tableData: { ...tableData, loading: false } })
        Notify.apiError(error)
      }
    })
  }

  _handleTableQueryChange = (query) => {
    const { tableData } = this.state

    this.setState({ tableData: { ...tableData, query } }, this._handleTableChange)
  }

  _handleTabChange = (tab) => {
    if (tab === this.state.currentTab) return

    this.setState({ currentTab: tab }, this._handleTableChange)
  }

  _handleCloseModal = () => {
    this.setState(
      {
        isModalVisible: false,
        isMeetingModalVisible: false,
        targetMeeting: null,
        newState: ''
      }
    )
  }

  _handleStateChange = (meeting, currentState) => {
    return (e) => {
      const newState = e.target.value

      if (newState === currentState) return

      this.setState({ isModalVisible: true, targetMeeting: meeting, newState })
    }
  }

  _handleRenderMeetingModal = (meeting) => {
    this.setState({ isMeetingModalVisible: true, targetMeeting: meeting })
  }

  render () {
    const {
      tableData,
      isModalVisible,
      isMeetingModalVisible,
      newState,
      targetMeeting
    } = this.state

    const actions = {
      handleTableChange:        this._handleTableChange,
      handleTableQueryChange:   this._handleTableQueryChange,
      handleTabChange:          this._handleTabChange,
      handleCloseModal:         this._handleCloseModal,
      handleStateChange:        this._handleStateChange,
      handleRenderMeetingModal: this._handleRenderMeetingModal
    }
    const { tabs, meetingsDurations } = this.props

    return (
      <div>
        <MeetingsOverviewIndex
          actions={actions}
          tableData={tableData}
          isCurrentTab={this._isCurrentTab}
          isModalVisible={isModalVisible}
          isMeetingModalVisible={isMeetingModalVisible}
          currentTab={this.state.currentTab}
          newState={newState}
          targetMeeting={targetMeeting}
          tabs={tabs}
          meetingsDurations={meetingsDurations}
        />
      </div>
    )
  }
}
