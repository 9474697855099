import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, Provider } from 'mobx-react'

import SupplierCees from './component'
import Store from './store'

@observer
class SupplierCeesContainer extends Component {
  render () {
    const store = new Store({ cees: [] })
    const { products, supplier } = this.props

    return (
      <Provider store={store}>
        <SupplierCees
          products={products}
          supplier={supplier}
        />
      </Provider>
    )
  }
}

SupplierCeesContainer.propTypes = {
  products: PropTypes.array.isRequired,
  supplier: PropTypes.object.isRequired
}

export default SupplierCeesContainer
