import React from 'react'
import PropTypes from 'prop-types'

const NativeSelectOption = ({ value, disabled, name }) => (
  <option value={value} disabled={disabled}>{name}</option>
)

NativeSelectOption.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  name: PropTypes.any.isRequired,
  disabled: PropTypes.bool.isRequired
}

NativeSelectOption.defaultProps = {
  disabled: false
}

export default NativeSelectOption
