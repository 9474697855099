import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextArea from '../../common/form-elements/TextArea'
import Button from '../../common/elements/Button'
import { updateDnoPostIndices } from './api'

import { trim } from 'lodash'

export default class DnoPostIndices extends Component {
  constructor (props) {
    super(props)
    this._onEditButtonClick = this._onEditButtonClick.bind(this)
    this._onPostIndicesChange = this._onPostIndicesChange.bind(this)
  }

  state = {
    editable:    false,
    saved:       false,
    error:       false,
    postalCodes: this.props.postalCodes
  }

  _onEditButtonClick () {
    const { editable } = this.state
    if (editable) this._sendUpdateRequest()
    this.setState({ editable: !editable, saved: false, error: false })
  }

  _onPostIndicesChange (e) {
    const postalCodes = e.target.value.split(',').map(trim)
    this.setState({ postalCodes })
  }

  _sendUpdateRequest () {
    const { postalCodes } = this.state
    const { id, product } = this.props

    updateDnoPostIndices({ id, postalCodes, product })
      .then((response) => {
        this.setState({ saved: true, postalCodes: response.data })
      })
      .catch(() => {
        this.setState({ error: true })
      })
  }

  _editButtonText () {
    return this.state.editable ? 'Save' : 'Edit'
  }

  _postalCodesToString () {
    if (this.state.postalCodes) {
      return this.state.postalCodes.join(', ')
    }

    return ''
  }

  _textArea () {
    if (this.state.editable) {
      return (
        <TextArea
          value={this._postalCodesToString()} rows={25}
          onChange={this._onPostIndicesChange}
        />
      )
    }

    return <p>{this._postalCodesToString()}</p>
  }

  _statusText () {
    let text
    if (this.state.saved) {
      text = 'Saved!'
    } else if (this.state.error) {
      text = 'Error..'
    }
    return <small className="status-text is-pulled-right">{text}</small>
  }

  render () {
    const { blockTitle } = this.props
    return (
      <div>
        <h4 className="subtitle is-4">{blockTitle}
          <Button className="is-pulled-right" onClick={this._onEditButtonClick}>
            {this._editButtonText()}
          </Button>
          {this._statusText()}
        </h4>
        <hr />
        <div>
          {this._textArea()}
        </div>
      </div>
    )
  }
}

DnoPostIndices.propTypes = {
  id: PropTypes.number.isRequired,
  product: PropTypes.string.isRequired,
  blockTitle: PropTypes.string.isRequired,
  postalCodes: PropTypes.array.isRequired
}
