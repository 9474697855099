import React from 'react'
import PropTypes from 'prop-types'

import { IndexDatatable } from 'web/app/common/datatable'
import { t, dateTime } from 'web/locale'
import { Column, Columns } from '../../../common/layout'
import { DateTimePicker as Picker } from 'react-widgets'

/* eslint-disable react/prop-types */
const columns = [
  {
    accessor: 'id',
    sortable: false,
    minWidth: 75,
    Header: t('attributes.call.id')
  },
  {
    id: 'callbacks.datetime',
    accessor: ({ datetime: d }) => dateTime(d),
    minWidth: 180,
    Header: t('attributes.callback.datetime')
  },
  {
    id: 'company.name',
    accessor: 'companyName',
    minWidth: 180,
    Header: t('attributes.callback.companyName'),
    Cell: ({ original: { companyId, companyName } }) => {
      return <a href={`/companies/${companyId}`} target="_blank">{companyName}</a>
    }
  },
  {
    id: 'contact_full_name',
    accessor: 'contactName',
    minWidth: 180,
    Header: t('attributes.callback.contactName'),
    Cell: ({ original: { contactId, contactName } }) => {
      if (contactId) {
        return <a href={`/contacts/${contactId}`} target="_blank">{contactName}</a>
      } else {
        return <span />
      }
    }
  },
  {
    accessor: 'contactPhone',
    sortable: false,
    minWidth: 180,
    Header: t('attributes.callback.contactPhone')
  },
  {
    id: 'callbacks.state',
    accessor: 'state',
    minWidth: 180,
    Header: t('attributes.callback.status._')
  },
  {
    accessor: 'note',
    minWidth: 180,
    sortable: false,
    Header: t('attributes.callback.note'),
    Cell: ({ value }) => <div dangerouslySetInnerHTML={{ __html: value }} />
  }
]
/* eslint-enable react/prop-types */

export default class TableWithSearch extends IndexDatatable {
  static propTypes = {
    fetchData: PropTypes.func.isRequired
  }
  static defaultProps = {
    columns,
    sorted: [{ id: 'callbacks.datetime', desc: false }]
  }

  apiQueryGetParams () {
    return { since: this.state.since }
  }

  headers () {
    return <Columns>
      <Column classNames="is-4 is-offset-4">
        <Picker
          format={'YYYY-MM-DD'}
          onChange={(since) => this.setState({ since }, this._handleChange)}
        />
      </Column>
      <Column classNames="is-4">
        { this.searchHeader() }
      </Column>
    </Columns>
  }
}
