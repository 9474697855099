export const tariffGroupsByProduct = (product, tariffGroups) =>
  tariffGroups.filter((tg) => tg.product === product)

export const eanStatsByProduct = (product, eanStats) =>
  eanStats.filter((stat) => stat.ean.product === product)

export const defaultProductValues = (products, defaultValue) =>
  products.reduce((acc, product) => {
    acc[product] = defaultValue
    return acc
  }, {})
