import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

import { t } from 'web/locale'
import Notify from 'web/utility/Notify'
import { fetchContracts, sendContract, sendSignatureLink, archiveSentRemoteLink } from '../api'
import { LoadingOverlay } from 'web/app/common/helpers'
import Contracts          from './component'

@inject('store')
@observer
class ContractContainer extends Component {
  static propTypes = {
    store:   PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  componentDidMount () {
    const { store } = this.props

    fetchContracts(store.offer.id).then(({ success, data }) => {
      if (success) {
        store.setContracts(data)
      } else {
        Notify.alert('Error while loading contracts')
      }
    })
  }

  _onSendContract (contract) {
    sendContract(contract).then(({ success }) => {
      if (success) {
        Notify.success(t('views.offers.show.contractSendNotification'))
      }
    })
  }

  _onSendSignatureLink = async (contract) => {
    const { store } = this.props
    const { success, data } = await sendSignatureLink(contract)

    if (success) {
      store.setSentRemoteLink(data.sentRemoteLink)
      Notify.success(t('views.offers.show.contractSendSignatureLinkNotification'))
    }
  }

  _onArchiveSentRemoteLink = async (sentRemoteLinkId) => {
    const { store } = this.props
    const { success } = await archiveSentRemoteLink(sentRemoteLinkId)

    if (success) {
      store.archiveSentRemoteLink()
      Notify.success(t('views.offers.show.sentRemoteLinkArchived'))
    }
  }

  render () {
    const {
      history: { push: goTo },
      store: { sentRemoteLink, contracts, hasContracts, offer }
    } = this.props

    if (!hasContracts) return <LoadingOverlay />

    const actions = {
      sendContract: this._onSendContract,
      sendSignatureLink: this._onSendSignatureLink,
      archiveSentRemoteLink: this._onArchiveSentRemoteLink
    }

    return (
      <Contracts
        contracts={contracts}
        offer={offer}
        goTo={goTo}
        sentRemoteLink={sentRemoteLink}
        actions={actions}
      />
    )
  }
}

export default ContractContainer
