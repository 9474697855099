import React from 'react'
import PropTypes from 'prop-types'

import {
  Attribute as Attr,
  AttributeRow as Row,
  Columns,
  Column
} from 'web/app/common/layout'
import { Panel, PanelBlock } from 'web/app/common/panel'
import { ButtonLink } from 'web/app/common/elements'
import { date, t } from '../../../locale'

const preparedAreaNames = (areas) =>
  areas.map((area) => area.name).join(', ')

const SupplierInfo = ({ canUpdate, supplier }) => {
  const postIndex = supplier.postIndex || {}

  return (
    <Panel>
      <PanelBlock>
        <Row>
          <Attr title="supplier.name" value={supplier.name} />
          <Attr title="supplier.aliasName" value={supplier.aliasName} />
          <Attr title="supplier.vat" value={supplier.vat} />
        </Row>

        <Row>
          <Attr title="supplier.contactName" value={supplier.contactName} />
          <Attr title="supplier.contactEmail" value={supplier.contactEmail} />
          <Attr title="supplier.contactPhone" value={supplier.contactPhone} />
        </Row>

        <Row>
          <Attr title="supplier.address" value={supplier.address} />
          <Attr title="postIndex.postalCode" value={postIndex.postalCode} />
          <Attr title="postIndex.city" value={postIndex.city} />
        </Row>

        <Row>
          <Attr
            title="supplier.electricityEomFixDate"
            value={date(supplier.electricityEomFixDate)}
          />
          <Attr
            title="supplier.electricityEomFlexDate"
            value={date(supplier.electricityEomFlexDate)}
          />
          <Attr
            title="supplier.areas"
            value={preparedAreaNames(supplier.areas)}
          />
        </Row>

        <Row>
          <Attr
            title="supplier.gasEomFixDate"
            value={date(supplier.gasEomFixDate)}
          />
          <Attr
            title="supplier.gasEomFlexDate"
            value={date(supplier.gasEomFlexDate)}
          />
        </Row>
        { canUpdate &&
          <Columns>
            <Column classNames="is-1 is-offset-11">
              <ButtonLink href={`/suppliers/${supplier.id}/edit`} kind="info" isWide>
                {t('common.button.edit')}
              </ButtonLink>
            </Column>
          </Columns>
        }
      </PanelBlock>
    </Panel>
  )
}

SupplierInfo.propTypes = {
  canUpdate: PropTypes.bool.isRequired,
  supplier:  PropTypes.object.isRequired
}

export default SupplierInfo
