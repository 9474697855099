import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'

import Table from './Table'
import Form from './Form'
import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import { createCalendarBlock, fetchData } from './api'

export default class CalendarBlocksContainer extends Component {
  static propTypes = {
    users: PropTypes.array.isRequired,
    permissions: PropTypes.shape({
      calendarBlocks: PropTypes.array.isRequired
    }).isRequired
  }

  tableRef = createRef()

  _handleSubmit = (values, { setSubmitting, handleErrors, resetForm }) => {
    createCalendarBlock(values).then(({ success, data }) => {
      if (success) {
        resetForm()
        this._refetchTable()
      } else {
        handleErrors(data)
      }

      setSubmitting(false)
    })
  }

  _refetchTable = () => {
    this.tableRef.current._handleChange()
  }

  render () {
    return (
      <Panel>
        <PanelHead>Calendar blocks</PanelHead>

        {this.props.permissions.calendarBlocks.includes('create') && (
          <PanelBlock>
            <Form users={this.props.users} onSubmit={this._handleSubmit} />
          </PanelBlock>
        )}

        <PanelBlock>
          <Table ref={this.tableRef} fetchData={fetchData} />
        </PanelBlock>
      </Panel>
    )
  }
}
