import React from 'react'
import PropTypes from 'prop-types'

import { Columns, Column, FormRow } from 'web/app/common/layout'
import {
  ControlButtons,
  Form,
  InputWithLabel as Input,
  SelectWithLabel as Select
} from 'web/app/common/forms'
import {
  preparePriceModels,
  prepareProducts
} from 'web/utility/prepareData'
import { t } from 'web/locale'
import { marginValidations } from '../validation'

const prepareMarginLetters = (letters) =>
  letters.map((letter) => { return { value: letter, text: letter } })

const MarginsForm = ({
  marginType,
  dataOptions
}) => {
  let totalMarginInput

  if (marginType === 'fix') {
    totalMarginInput = <Input
      fieldName="margin.total"
      field="totalMargin"
      type="number"
      required
    />
  }

  return (
    <>
      <FormRow columns={4}>
        <Select
          fieldName="margin.type"
          field="type"
          data={preparePriceModels(dataOptions.priceModels)}
          required
        />
        <Select
          fieldName="margin.letter"
          field="letter"
          data={prepareMarginLetters(dataOptions.marginLetters)}
          required
        />
        <Select
          fieldName="margin.product"
          field="product"
          data={prepareProducts(dataOptions.products)}
          required
        />
        <Select
          fieldName="margin.priceModel"
          field="priceModel"
          data={preparePriceModels(dataOptions.priceModels)}
          required
        />
      </FormRow>
      <FormRow columns={4}>
        <Input
          label={t('common.range.begin')}
          field="volume.begin"
          type="number"
          required
        />
        <Input
          label={t('common.range.end')}
          field="volume.end"
          type="number"
          required
        />
        <Input
          fieldName="margin.salesMargin"
          field="salesMargin"
          type="number"
          required
        />
        <Input
          fieldName="margin.passive"
          field="passive"
          type="number"
          required
        />
      </FormRow>
      <Columns>
        <Column classNames="is-one-quarter">
          {totalMarginInput}
        </Column>
      </Columns>
      <ControlButtons />
    </>
  )
}

MarginsForm.propTypes = {
  dataOptions: PropTypes.object.isRequired,
  marginType:  PropTypes.any
}

export default (props) => {
  // eslint-disable-next-line react/prop-types
  const { onMarginCreate, handleMarginType } = props

  const handleStateChange = ({ field, value }) => {
    if (field === 'type') {
      handleMarginType(value)
    }
  }

  return (
    <Form
      onSubmit={onMarginCreate}
      validation={marginValidations}
      onChange={handleStateChange}
    >
      <MarginsForm
        {...props}
      />
    </Form>
  )
}
