import buildValidation from 'web/utility/validations'

export default (values) => {
  const { filled } = buildValidation(values)
  const validation = {
    mail: {
      to: filled('mail.to'),
      body: filled('mail.body')
    }
  }

  return validation
}
