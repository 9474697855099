import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'
import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import EansTablePaginated          from './EansTablePaginated'
import PricesTable                 from './PricesTable'
import ContractButtons             from './ContractButtons'
import { fetchEanStatsCollection } from '../../api'

const Contract = ({
  contract, sentRemoteLink, goTo, offer,
  actions: { sendContract, sendSignatureLink, archiveSentRemoteLink }
}) => {
  return (
    <div>
      <Panel>
        <PanelHead>
          {t('attributes.contract._')} ({t('attributes.contract.id')} {contract.id})
        </PanelHead>

        <PanelBlock>
          <PricesTable prices={contract.prices} />

          <br />

          <EansTablePaginated onChange={fetchEanStatsCollection(offer.id, contract.id)} />

          <br />

          <ContractButtons
            contract={contract}
            sentRemoteLink={sentRemoteLink}
            sendContract={sendContract}
            goTo={goTo}
            sendSignatureLink={sendSignatureLink}
            archiveSentRemoteLink={archiveSentRemoteLink}
          />
        </PanelBlock>
      </Panel>

    </div>
  )
}

Contract.propTypes = {
  actions: PropTypes.shape({
    sendContract: PropTypes.func.isRequired,
    sendSignatureLink: PropTypes.func.isRequired,
    archiveSentRemoteLink: PropTypes.func.isRequired
  }),
  contract:       PropTypes.object.isRequired,
  offer:          PropTypes.object.isRequired,
  sentRemoteLink: PropTypes.object,
  goTo:           PropTypes.func.isRequired
}

export default Contract
