import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Link, Redirect, withRouter, Switch } from 'react-router-dom'
import { observer, inject } from 'mobx-react'

import { t }                 from 'web/locale'
import { Tabs, Tab }         from 'web/app/common/layout'
import { Panel, PanelBlock } from 'web/app/common/panel'
import { Level, LevelInfo }  from 'web/app/common/level'
import { isLinkActive }      from './helpers'

// Import of all routes for router
import { Parameters }           from './Parameters'
import { EanForm }              from './EanForm'
import { Proposal }             from './Proposal'
import { HiddenProposal }       from './HiddenProposal'
import { MandateForm }          from './MandateForm'
import { Contracts }            from './Contracts'
import { ManualData }           from './ManualData'
import { SignContract }         from './SignContract'
import { CreateDnoConsumption } from './CreateDnoConsumption'
import { SignDnoConsumption }   from './SignDnoConsumption'

@withRouter
@inject('store')
@observer
class ShowOfferView extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  _salesListing = () => {
    const { sales } = this.props.store

    return sales.map((salesman, index) => {
      const separator = (sales.length > 1) && (index < sales.length - 1) ? ' | ' : ''

      return (
        <a href={'/users/' + salesman.id} key={index}>
          {salesman.fullName + separator}
        </a>
      )
    })
  }

  render () {
    const { offer, contact, company, sales, hasContracts } = this.props.store
    const index = `/offers/${offer.id}` + (hasContracts ? '/contracts' : '/parameters')
    const size  = sales.length > 1 ? '4' : '3'

    return (
      <div>
        <Panel>
          <PanelBlock>
            <Level>
              <LevelInfo title="ID" size={size}>{offer.id}</LevelInfo>
              <LevelInfo title="Sales" size={size}>{this._salesListing()}</LevelInfo>
              <LevelInfo title="Company" size={size}>
                <a href={'/companies/' + company.id}>
                  {company.name}
                </a>
              </LevelInfo>
              <LevelInfo title="Contact" size={size}>
                <a href={'/contacts/' + contact.id}>
                  {contact.firstName} {contact.lastName}
                </a>
              </LevelInfo>
            </Level>
          </PanelBlock>
        </Panel>

        <Tabs>
          {
            hasContracts &&
            <Tab isActive={isLinkActive('contracts')}>
              <Link className="offer-contracts" to={`/offers/${offer.id}/contracts`}>
                {t('views.offers.show.links.contract')}
              </Link>
            </Tab>
          }
          <Tab isActive={isLinkActive('parameters')}>
            <Link className="offer-parameters" to={`/offers/${offer.id}/parameters`}>
              {t('views.offers.show.links.parameters')}
            </Link>
          </Tab>
          <Tab isActive={isLinkActive('proposals')}>
            <Link className="offer-proposals" to={`/offers/${offer.id}/proposals`}>
              {t('views.offers.show.links.proposal')}
            </Link>
          </Tab>
        </Tabs>

        <Switch>
          <Route
            exact
            path="/offers/:id/parameters"
            component={Parameters}
          />
          <Route
            exact
            path="/offers/:id/parameters/new"
            component={EanForm}
          />
          <Route
            exact
            path="/offers/:id/parameters/:eanId/:eanStatId"
            component={EanForm}
          />
          <Route
            exact
            path="/offers/:id/proposals"
            component={Proposal}
          />
          <Route
            exact
            path="/offers/:id/proposals/:product"
            component={HiddenProposal}
          />
          <Route
            exact
            path="/offers/:id/mandates/:product/:priceModel"
            component={MandateForm}
          />
          {
            hasContracts &&
            <Route
              exact
              path="/offers/:id/contracts"
              component={Contracts}
            />
          }
          {
            hasContracts &&
            <Route
              exact
              path="/offers/:id/contracts/:contractId/manual_data"
              component={ManualData}
            />
          }
          {
            hasContracts &&
            <Route
              exact
              path="/offers/:id/contracts/:contractId/sign"
              component={SignContract}
            />
          }
          <Route
            exact
            path="/offers/:id/consumption_data/:product"
            component={CreateDnoConsumption}
          />
          <Route
            exact
            path="/offers/:id/consumption_data/:consumptionDataId/sign"
            component={SignDnoConsumption}
          />
          <Redirect to={index} />
        </Switch>
      </div>
    )
  }
}

export default ShowOfferView
