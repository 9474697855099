import React from 'react'
import PropTypes from 'prop-types'

const LevelLeft = ({ children }) => (
  <div className="level-left">{children}</div>
)

LevelLeft.propTypes = {
  children: PropTypes.any.isRequired
}

export default LevelLeft
