import React, { Component } from 'react'
import { PropTypes as MobxPropTypes, inject } from 'mobx-react'

import Communication from './component'
import { fetchCalls, fetchCallbacks, fetchMeetings, fetchTickets, postCalls } from './api'
import { validateCallsForm } from 'web/app/common/validations/validateCallsForm'

@inject('store')
class CommunicationContainer extends Component {
  render () {
    const {
      company,
      callsStates,
      callsSubStates,
      callsContacts,
      spokeWithOpts,
      meetingsDurations,
      sales,
      callAgents,
      ticketCategories,
      companyContracts,
      permissions,
      users
    } = this.props.store
    const afterAddContactRedirectUrl = encodeURI(`/companies/${company.id}/communication`)

    return (
      <Communication
        company={company}
        onCallsTableChange={fetchCalls(company.id)}
        onCallbacksTableChange={fetchCallbacks(company.id)}
        onMeetingsTableChange={fetchMeetings(company.id)}
        onTicketsTableChange={fetchTickets(company.id)}
        callsStates={callsStates}
        callsSubStates={callsSubStates}
        callsContacts={callsContacts}
        spokeWithOpts={spokeWithOpts}
        meetingsDurations={meetingsDurations}
        sales={sales}
        callAgents={callAgents}
        ticketCategories={ticketCategories}
        companyContracts={companyContracts}
        permissions={permissions}
        users={users}
        validateCallsForm={validateCallsForm}
        postCalls={postCalls}
        afterAddContactRedirectUrl={afterAddContactRedirectUrl}
      />
    )
  }
}

CommunicationContainer.propTypes = {
  store: MobxPropTypes.observableObject.isRequired
}

export default CommunicationContainer
