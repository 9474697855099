import React from 'react'
import PropTypes from 'prop-types'

import { Modal } from 'web/app/common/modal'
import { Form, ControlButtons, TextWithLabel as Text } from 'web/app/common/forms'
import { t } from 'web/locale'

export const NoteModal = ({ onClose, onSubmit }) => (
  <Modal header="Note" onClose={onClose} isShown>
    <Form onSubmit={onSubmit}>
      <Text field="note" label={t('attributes.consumptionData.note')} />
      <ControlButtons />
    </Form>
  </Modal>
)

NoteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}
