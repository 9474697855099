import domready from 'domready'

const findUser = () => document.querySelector('a.menu-user-trigger')
const findMenu = () => document.querySelector('ul.menu-user')
const showMenu = () => {
  const menu   = findMenu()
  menu.classList.toggle('is-open')
  if (!menu.classList.contains('is-open')) document.body.removeEventListener('click', showMenu)
}

domready(() => {
  const link = findUser()
  if (link) {
    link.onclick = function () {
      document.body.addEventListener('click', showMenu)
    }
  }
})
