import React, { Component } from 'react'
import PropTypes from 'prop-types'

import SupplierFilters from './component'
import { fetchFilters, createFilter, deleteFilter, createVolumeFilters } from './api'
import { isCountry } from 'web/utility/country'
import Notify from 'web/utility/Notify'

const defaultData = {
  meterPriceFilters:    [],
  durationPriceFilters: [],
  volumePriceFilters:   []
}

export default class SupplierFiltersContainer extends Component {
  static propTypes = {
    durations:     PropTypes.array.isRequired,
    marginLetters: PropTypes.array.isRequired,
    meters:        PropTypes.array.isRequired,
    priceModels:   PropTypes.array.isRequired,
    products:      PropTypes.array.isRequired
  }

  state = {
    data:       defaultData,
    product:    this.props.products[0],
    priceModel: this.props.priceModels[0],
    isLoading:  true
  }

  componentDidMount () {
    this._fetchData()
  }

  _fetchData = () => {
    this.setState({ isLoading: true })

    const { product, priceModel } = this.state
    fetchFilters({ product, priceModel })
      .then(({ success, data, error }) => {
        if (success) {
          this.setState({ isLoading: false, data: data || defaultData })
        } else {
          this.setState({ isLoading: false })

          Notify.apiError(error)
        }
      })
  }

  _onFilterTypeChange = (type) => {
    return (e) => {
      this.setState({
        [type]: e.target.value
      }, this._fetchData)
    }
  }

  _onCreateFilter = (type, values) => {
    return () => {
      this.setState({ isLoading: true })
      const { product, priceModel } = this.state
      createFilter({ product, priceModel, type, values })
        .then(({ success, data, error }) => {
          if (success) {
            this._addFilter({ filter: data, type })
          } else {
            this.setState({ isLoading: false })

            Notify.apiError(error)
          }
        })
    }
  }

  _addFilter = ({ filter, type }) => {
    if (filter) {
      const { data } = this.state
      data[`${type}PriceFilters`].push(filter)
      this.setState({ data, isLoading: false })
    } else {
      this.setState({ isLoading: false })
    }
  }

  _onDeleteFilter = (type, value) => {
    return () => {
      this.setState({ isLoading: true })
      deleteFilter({ type, id: value })
        .then(({ success, error }) => {
          if (success) {
            this._deleteFilter({ id: value, type })
          } else {
            this.setState({ isLoading: false })

            Notify.apiError(error)
          }
        })
    }
  }

  _deleteFilter = ({ id, type }) => {
    if (id) {
      const { data } = this.state
      const fullType = `${type}PriceFilters`
      data[fullType] = data[fullType].filter((f) => id !== f.id)
      this.setState({ isLoading: false, data })
    } else {
      this.setState({ isLoading: false })
    }
  }

  _onCreateVolumeFilters = (
    { volume, marginLetters },
    { setSubmitting }
  ) => {
    this.setState({ isLoading: true })
    const { product, priceModel } = this.state
    createVolumeFilters({ product, priceModel, volume, marginLetters })
      .then(({ success, error, data }) => {
        if (success) {
          this._addVolumeFilters({ filters: data })
        } else {
          this.setState({ isLoading: false })
          Notify.apiError(error)
        }

        setSubmitting(false)
      })
  }

  _addVolumeFilters = ({ filters }) => {
    if (filters) {
      const type = 'volumePriceFilters'
      const { data } = this.state
      data[type] = filters[type]
      this.setState({ data, isLoading: false })
    } else {
      this.setState({ isLoading: false })
    }
  }

  render () {
    const { durations, marginLetters, meters, priceModels, products } = this.props
    const { product, priceModel, data, isLoading } = this.state
    const shouldApplyVolume = true

    return (
      <SupplierFilters
        data={data}
        durations={durations}
        isLoading={isLoading}
        marginLetters={marginLetters}
        meters={meters}
        onCreateFilter={this._onCreateFilter}
        onCreateVolumeFilters={this._onCreateVolumeFilters}
        onDeleteFilter={this._onDeleteFilter}
        onFilterTypeChange={this._onFilterTypeChange}
        priceModel={priceModel}
        priceModels={priceModels}
        product={product}
        products={products}
        shouldApplyVolume={shouldApplyVolume}
      />
    )
  }
}
