import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Notify from 'web/utility/Notify'
import { Form } from 'web/app/common/forms'
import { Modal } from 'web/app/common/modal'
import SupplierMailModal from './component'
import { t } from 'web/locale'
import { createSupplierMail } from './api'
import validations from './validations'

export default class SupplierMailModalContainer extends Component {
  static propTypes = {
    ticketId:             PropTypes.number.isRequired,
    onClose:              PropTypes.func.isRequired,
    supplierMailFormData: PropTypes.object.isRequired,
    isVisible:            PropTypes.bool.isRequired
  }

  _handleSubmit = (values, { setSubmitting, handleErrors, resetForm }) => {
    const { ticketId } = this.props
    const params = this._normalizeParams(values)

    createSupplierMail(ticketId, params).then(({ success, data }) => {
      if (success) {
        resetForm()
        this.props.onClose()
        Notify.success(t('views.tickets.show.supplierMail.successfullyScheduled'))
      } else {
        handleErrors(data)
        setSubmitting(false)
      }
    })
  }

  _getFormApi = (formApi) => {
    this.formApi = formApi
  }

  _normalizeParams = (values) => {
    const mail = Object.assign({}, values.mail)
    return Object.assign({}, values, { mail })
  }

  render () {
    const header = t(`views.tickets.index.supplierMail.title`)
    const { suppliersForMailForm } = this.props.supplierMailFormData

    return (
      <Modal header={header} onClose={this.props.onClose} isShown={this.props.isVisible}>
        <Form
          getApi={this._getFormApi}
          validation={validations}
          onSubmit={this._handleSubmit}
        >
          <SupplierMailModal suppliersToSelect={suppliersForMailForm} />
        </Form>
      </Modal>
    )
  }
}
