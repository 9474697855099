import buildValidation from 'web/utility/validations'

const validateStatusModalForm = (values = {}) => {
  const { filled } = buildValidation(values)

  return {
    deliveryStopAt: filled('deliveryStopAt')
  }
}

const validateTicketsForm = (values = {}) => {
  const { filled } = buildValidation(values)
  return ({
    ticket: {
      description: filled('ticket.description'),
      createdForId: filled('ticket.createdForId'),
      category: filled('ticket.category'),
      deadline: filled('ticket.deadline')
    }
  })
}

const validateEcaUploadForm = (values = {}) => {
  const { filled } = buildValidation(values)

  return ({
    contractsImportFile: filled('contractsImportFile'),
    contractsSignatureFile: filled('contractsSignatureFile'),
    contractsParaphFile: filled('contractsParaphFile')
  })
}

export { validateStatusModalForm, validateTicketsForm, validateEcaUploadForm }
