import { get, post, destroy, apiPath } from 'web/utility/http'
import Notify from 'web/utility/Notify'

export const fetchData = ({ page, pageSize, sorting, query }) =>
  get(apiPath(`/calendar_blocks`), {
    params: {
      page,
      pageSize,
      sorting,
      query
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

export const createCalendarBlock = (params) =>
  post(apiPath('/calendar_blocks'), params)

export const destroyCalendarBlock = (id) =>
  destroy(apiPath(`/calendar_blocks/${id}`))
