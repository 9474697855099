import React from 'react'
import PropTypes from 'prop-types'
import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import { Button, ButtonGroup } from 'web/app/common/elements'
import { camelCase } from 'lodash'
import { t } from 'web/locale'

const AdditionalCallStates = ({ toogleModal }) => {
  return (
    <Panel>
      <PanelHead className="accordion">
        Other qualifications
        <i className="icon-right-open" />
      </PanelHead>
      <PanelBlock classNames="accordion-body closed">
        <ButtonGroup classNames="states">
          <Button onClick={() => toogleModal({ callState: ['blacklist'] })}>
            {t(`attributes.call.status.${camelCase('blacklist')}`)}
          </Button>
          <Button onClick={() => toogleModal({ callState: ['incorrect_data'] })}>
            {t(`attributes.call.status.${camelCase('incorrect_data')}`)}
          </Button>
          <Button onClick={() => toogleModal({ callState: ['send_small_volume_email'] })}>
            {t(`attributes.call.status.${camelCase('send_small_volume_email')}`)}
          </Button>
          <Button onClick={() => toogleModal({ callState: ['lt_100'] })}>
            {t(`attributes.call.status.${camelCase('lt_100')}`)}
          </Button>
          <Button onClick={() => toogleModal({ callState: ['private_invoice'] })}>
            {t(`attributes.call.status.${camelCase('private_invoice')}`)}
          </Button>
          <Button onClick={() => toogleModal({ callState: ['customer'] })}>
            {t(`attributes.call.status.${camelCase('customer')}`)}
          </Button>
          <Button onClick={() => toogleModal({ callState: ['quality_call'] })}>
            {t(`attributes.call.status.${camelCase('quality_call')}`)}
          </Button>
        </ButtonGroup>
      </PanelBlock>
    </Panel>
  )
}

AdditionalCallStates.propTypes = {
  toogleModal: PropTypes.func.isRequired
}

export default AdditionalCallStates
