import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'
import { FormRow } from 'web/app/common/layout'
import { PanelBlock } from 'web/app/common/panel'
import { InfoWithLabel } from 'web/app/common/form-elements'

import {
  InputWithLabel as Input,
  SelectWithLabel as Select
} from 'web/app/common/forms'

const tariffGroupName = (tariffGroups, tariff) => {
  return `${tariff.name} (${tariffGroups[tariff.id].name})`
}

const flexInputBlock = (getValue, tariffGroups) => {
  return (tariff, i) => {
    return (
      <FormRow columns={5} key={[tariff.tariffGroupId, i]}>
        {tariffGroupName(tariffGroups, tariff)}
        <Input
          field={`tariffs[${i}].marketPrice`}
          fieldName="mandate.marketPrice"
          type="number"
        />
        <Input
          field={`tariffs[${i}].factor`}
          fieldName="mandate.factor"
          type="number"
        />
        <Input
          field={`tariffs[${i}].tariffPrice`}
          fieldName="mandate.tariffPrice"
          type="number"
          min="any"
        />
        <InfoWithLabel name={t('attributes.mandate.priceToDisplay')}>
          {getValue(`tariffs[${i}].priceToDisplay`)}
        </InfoWithLabel>
      </FormRow>
    )
  }
}

const fixInputBlock = (getValue, tariffGroups) => {
  return (tariff, i) => {
    return (
      <FormRow className="fix-input-block" columns={3} key={[tariff.tariffGroupId, i]}>
        {tariffGroupName(tariffGroups, tariff)}
        <Input
          field={`tariffs[${i}].tariffPrice`}
          fieldName="mandate.tariffPrice"
          type="number"
          min="any"
        />
        <InfoWithLabel name={t('attributes.mandate.priceToDisplay')}>
          {getValue(`tariffs[${i}].priceToDisplay`)}
        </InfoWithLabel>
      </FormRow>
    )
  }
}

export default class Prices extends Component {
  static propTypes = {
    calculatePrice: PropTypes.object.isRequired,
    getValue:       PropTypes.func.isRequired,
    marketNames:    PropTypes.array.isRequired,
    params:         PropTypes.object.isRequired,
    productTypes:   PropTypes.array.isRequired,
    setValue:       PropTypes.func.isRequired,
    tariffGroups:   PropTypes.object.isRequired,
    tariffs:        PropTypes.array.isRequired
  }

  constructor (props) {
    super(props)
    this._renderInputBlock = this._renderInputBlock.bind(this)
    this._calculatePrices  = this._calculatePrices.bind(this)
  }

  componentWillUpdate (_nextProps, _nextState) {
    this._calculatePrices()
  }

  _calculatePrices () {
    const { getValue, setValue, calculatePrice, params: { product, priceModel } } = this.props
    const tariffs = getValue('tariffs') || []

    tariffs.forEach((tariff, i) => {
      const pathToPrice  = `tariffs[${i}].priceToDisplay`
      const currentPrice = calculatePrice[product][priceModel](tariff, getValue)

      if (getValue(pathToPrice) !== currentPrice) {
        setValue(pathToPrice, currentPrice, true)
      }
    })
  }

  _renderInputBlock (tariff, i) {
    const { params: { priceModel }, getValue, tariffGroups } = this.props
    const fn = (priceModel === 'flex') ? flexInputBlock : fixInputBlock

    return fn(getValue, tariffGroups)(tariff, i)
  }

  render () {
    const { params: { priceModel, product } } = this.props
    const gas = product === 'gas'
    const gasFlex = gas && priceModel === 'flex'

    const productTypeAddon = (
      <Input
        field="mandate.productTypeAddon"
        type="number"
      />
    )

    const gasTransportPrice = (
      <Input
        field="mandate.gasTransportPrice"
        type="number"
      />
    )

    const marketNameSelect = (
      <Select
        field="mandate.marketNameId"
        data={this.props.marketNames}
      />
    )

    return (
      <PanelBlock>
        <FormRow columns={4}>
          <Select field="mandate.productTypeId" data={this.props.productTypes} />
          { gas && productTypeAddon }
          { gas && gasTransportPrice }
          { gasFlex && marketNameSelect }
        </FormRow>

        { this.props.tariffs.map(this._renderInputBlock) }
      </PanelBlock>
    )
  }
}
