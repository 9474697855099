import React            from 'react'
import PropTypes        from 'prop-types'
import { t }            from 'web/locale'
import { NativeSelect } from 'web/app/common/form-elements'
import { Icon }         from 'web/app/common/elements'
import { papp }         from 'web/utility/helpers'
import { camelCase }    from 'lodash'
import {
  DatatableWithSearch,
  RowLink
} from 'web/app/common/datatable'

const can = (ticket, action) =>
  ticket.permissions.includes(action)

const columns = (handleStateChange) => [
  {
    Header: t('attributes.ticket.id'),
    accessor: 'id',
    minWidth: 30,
    id: 'id'
  }, {
    Header:   t('attributes.ticket.company'),
    accessor: 'company',
    id: 'company.name'
  }, {
    Header:   t('attributes.ticket.category'),
    accessor: 'category',
    sortable: false,
    Cell: ({ row: { _original } }) => { // eslint-disable-line react/prop-types
      return (
        <span>
          {t(`attributes.ticket.categories.${camelCase(_original.category)}`)}
        </span>
      )
    }
  }, {
    Header:   t('attributes.ticket.description'),
    accessor: 'description',
    sortable: false
  }, {
    Header:   t('attributes.ticket.createdBy'),
    id: 'created_by',
    sortable: false,
    accessor: (t) => t.createdBy.fullName
  }, {
    Header:   t('attributes.ticket.createdForId'),
    id: 'created_for',
    sortable: false,
    accessor: (t) => t.createdFor.fullName
  }, {
    Header:   t('attributes.ticket.createdAt'),
    accessor: 'createdAt',
    sortable: false
  }, {
    Header:   t('attributes.ticket.deadline'),
    accessor: 'deadline',
    sortable: false
  }, {
    Header:   t('attributes.ticket.countdown'),
    accessor: 'countdown',
    sortable: false
  }, {
    Header:   t('attributes.ticket.state'),
    accessor: 'state',
    sortable: false,
    Cell: ({ row: { _original } }) => { // eslint-disable-line react/prop-types
      const { current, states } = _original.state

      return (
        can(_original, 'update_state') && <NativeSelect
          placeholder={current}
          options={[current, ...states]}
          value={current}
          onChange={handleStateChange(_original.id, current)}
        />
      )
    }
  }, {
    sortable: false,
    minWidth: 42,
    Cell: ({ row }) => { // eslint-disable-line react/prop-types
      return (
        <span>
          { <RowLink type="show" url={`/tickets/${row.id}`} /> }
        </span>
      )
    }
  }
]

const TableWithSearch = ({
  data,
  handleChange,
  handleStateChange,
  handleTableQueryChange,
  loading,
  pages,
  pageSize,
  query,
  sorted
}) => {
  return (
    <DatatableWithSearch
      input={{
        query,
        onChange: handleTableQueryChange
      }}
      manual
      columns={columns(handleStateChange)}
      data={data}
      loading={loading}
      pages={pages}
      defaultPageSize={pageSize}
      onFetchData={handleChange}
      defaultSorted={sorted}
    />
  )
}

TableWithSearch.propTypes = {
  data:                   PropTypes.array,
  handleChange:           PropTypes.func.isRequired,
  handleStateChange:      PropTypes.func.isRequired,
  handleTableQueryChange: PropTypes.func.isRequired,
  loading:                PropTypes.bool.isRequired,
  pages:                  PropTypes.number,
  pageSize:               PropTypes.number,
  query:                  PropTypes.string.isRequired,
  sorted:                 PropTypes.array.isRequired
}

export default TableWithSearch
