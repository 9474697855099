import React from 'react'
import PropTypes from 'prop-types'

import { t }                        from 'web/locale'
import { PanelBlock }               from 'web/app/common/panel'
import { SearchPostIndex }          from 'web/app/postIndices/SearchPostIndex'
import { SearchOrganisation }       from 'web/app/organisations/SearchOrganisation'
import { Columns, Column, FormRow } from 'web/app/common/layout'
import {
  InputWithLabel as Input,
  SelectWithLabel as Select,
  DateTimePickerWithLabel as DateTimePicker
} from 'web/app/common/forms'

const UserFields = ({
  data: {
    roles,
    statuses,
    countries,
    languages,
    references,
    offerCategories,
    cooperationForms,
    initialPostIndex,
    initialOrganisation
  },
  hide,
  handlers,
  permissions
}) => (
  <PanelBlock>
    <h3 className="title is-3">{t('views.users.new.userInfo')}</h3>
    <Columns>
      <Column classNames="is-two-thirds">
        <FormRow columns={2}>
          <Input field="user.firstName" required />
          <Input field="user.lastName" required />
        </FormRow>
        <FormRow columns={2}>
          <Input field="user.aliasName" />
          <Input field="user.email" required />
        </FormRow>
        <FormRow columns={2}>
          <DateTimePicker field="user.birthDate" time={false} required />
          <Select field="user.language" data={languages} required />
        </FormRow>
        <FormRow columns={2}>
          <Input field="user.nin" required />
          <Select field="user.citizenship" data={countries} required />
        </FormRow>
        <FormRow columns={2}>
          <Input field="user.tel" />
          <Input field="user.mobile" required />
        </FormRow>
        <FormRow columns={2}>
          <Input field="user.meetingsEmail" required />
        </FormRow>
      </Column>
      <Column>{' '}</Column>
    </Columns>

    <FormRow>
      <Input field="user.address" required />
      <SearchPostIndex
        field="user.postIndexId"
        initialData={initialPostIndex}
        required
      />
    </FormRow>
    <FormRow>
      <Select
        field="user.cooperationForm"
        data={cooperationForms}
        onChange={handlers.cooperationForm}
        required
      />
      {
        !hide.workingHours && <Input field="user.workingHours" required />
      }
      {
        permissions.includes('change_organisation') &&
        <SearchOrganisation
          field="user.organisationId"
          initialData={initialOrganisation}
          required
          preload
        />
      }
    </FormRow>
    <FormRow>
      <Select
        field="user.companyCategoryId"
        data={offerCategories}
        textField="name"
        valueField="id"
        required
      />
      <DateTimePicker field="user.enbroStart" time={false} required />
      <DateTimePicker field="user.enbroEnd" time={false} />
    </FormRow>
    <FormRow>
      <Select field="user.role" data={roles} required />
      <Select field="user.status" data={statuses} required />
      <Select field="user.referenceFromId" data={references} textField="fullName" valueField="id" />
    </FormRow>
  </PanelBlock>
)

UserFields.propTypes = {
  data: PropTypes.shape({
    cooperationForms: PropTypes.array.isRequired,
    countries: PropTypes.array.isRequired,
    languages: PropTypes.array.isRequired,
    offerCategories: PropTypes.array.isRequired,
    references: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    statuses: PropTypes.array.isRequired
  }),
  permissions: PropTypes.array.isRequired,
  hide: PropTypes.shape({
    workingHours: PropTypes.bool.isRequired
  }),
  handlers: PropTypes.shape({
    cooperationForm: PropTypes.func.isRequired
  }).isRequired
}

export default UserFields
