// All validation functions should return falsy values if value is valid
// (for example undefined) and error if value is not valid

import { get, isEmpty, isUndefined, isBoolean, isNumber } from 'lodash'

import { t } from '../locale'

const NO_ERROR = null

const exist = function (value) {
  if (isUndefined(value)) {
    return undefined
  } else if (isBoolean(value)) {
    return value || undefined
  } else if (isNumber(value)) {
    return true
  } else {
    return isEmpty(value) ? undefined : true
  }
}

const value = function (value) { return value }

const filledEach = function (values, ...keys) {
  const valuesError = filled(values)
  if (valuesError) return valuesError

  return values.map((value) => {
    return keys.reduce((acc, k) => {
      acc[k] = filled(value[k])
      return acc
    }, {})
  })
}

const filled = (value) => {
  if (isNumber(value)) {
    return NO_ERROR
  } else if (isBoolean(value)) { // eslint-disable-line no-undef
    return NO_ERROR
  } else if (value instanceof File) { // eslint-disable-line no-undef
    return NO_ERROR
  } else if (value instanceof Date) {
    return NO_ERROR
  } else {
    return isEmpty(value) ? t('errors.filled?') : NO_ERROR
  }
}

// TODO: Check if it work with images in editor.
const editorFilled = (value) => {
  const re = /^<p>(<br>|<br\/>|<br\s\/>|\s+|)<\/p>$/gm
  return (isEmpty(value) || re.test(value)) ? t('errors.filled?') : NO_ERROR
}

const number = function (value) {
  const num = exist(value) ? Number(value) : undefined
  return (num || num === 0) ? NO_ERROR : t('errors.number?')
}

const length = function (value, type, left, right = undefined) {
  const length = (value || '').length
  let path
  switch (type) {
    case 'eq':
      path = 'errors.size?.value.string.arg.default'
      return length === left ? NO_ERROR : t(path, { size: left })
    case 'lt':
      path = 'errors.max_size?'
      return length < left ? NO_ERROR : t(path, { size: left })
    case 'gt':
      path = 'errors.min_size?'
      return length < left ? NO_ERROR : t(path, { size: left })
    case 'range':
      path = 'errors.size?.value.string.arg.range'
      return length < left ? NO_ERROR : t(path, { size_left: left, size_right: right })
  }
}

const compare = function (value, type, key) {
  const left  = exist(value) ? Number(value) : undefined
  let right
  if (exist(key)) {
    right = isNumber(key) ? key : Number(this.value(key))
  } else {
    right = undefined
  }
  switch (type) {
    case 'lt':
      return left < right ? NO_ERROR : t('errors.lt?', { num: right })
    case 'gt':
      return left > right ? NO_ERROR : t('errors.gt?', { num: right })
    case 'lteq':
      return left <= right ? NO_ERROR : t('errors.lteq?', { num: right })
    case 'gteq':
      return left >= right ? NO_ERROR : t('errors.gteq?', { num: right })
  }
}

const eq = function (value, key) {
  const other = this.value(key)
  return value === other ? NO_ERROR : 'is wrong'
}

const validations = {
  exist, filled, value, filledEach, number, length, compare, eq, editorFilled
}

const buildValidation = (values) => {
  return Object.entries(validations).reduce((acc, [k, v]) => {
    acc[k] = function () {
      const args = [...arguments]
      const path = args.shift()
      return v.call(acc, get(values, path), ...args)
    }
    return acc
  }, {})
}

export default buildValidation
