import { http, pathWith } from '../../../utility/http'
import Notify from '../../../utility/Notify'

const fetchData = ({ page, pageSize, sorting, query }) =>
  http
    .get(pathWith('/events'), {
      params: {
        page,
        pageSize,
        sorting,
        query
      }
    })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

export { fetchData }
