import React from 'react'
import { t, dateTime } from 'web/locale'

const callsTableColumns =
[
  {
    id: 'calls.id',
    accessor: 'id',
    minWidth: 30,
    Header: t('attributes.call.id')
  },
  {
    id: 'calls.created_at',
    accessor: ({ createdAt }) => dateTime(createdAt),
    minWidth: 30,
    Header: t('attributes.call.date')
  },
  {
    id: 'calls.state',
    accessor: 'state',
    minWidth: 30,
    Header: t('attributes.call.status._')
  },
  {
    id: 'calls.note',
    accessor: 'note',
    minWidth: 100,
    Header: t('attributes.call.note'),
    Cell: ({ value }) => <div dangerouslySetInnerHTML={{ __html: value }} /> // eslint-disable-line
  },
  {
    id: 'user.first_name',
    accessor: 'userName',
    minWidth: 30,
    Header: t('attributes.call.userName')
  }
]

export { callsTableColumns }
