import React from 'react'
import PropTypes from 'prop-types'

import { papp } from 'web/utility/helpers'
import { t, date } from 'web/locale'
import { DatatableWithSearch, RowLink } from 'web/app/common/datatable'
import { Icon } from 'web/app/common/elements'
import { NativeSelect } from 'web/app/common/form-elements'

const can = (row, action) =>
  row.permissions.includes(action)

const columns = ({ onMailClick, onStateChange, isCurrentTab }) => ([
  {
    Header: t('attributes.consumptionData.id'),
    id: 'consumptionData.id',
    accessor: 'id'
  }, {
    Header: t('attributes.consumptionData.offerId'),
    id: 'consumptionData.offerId',
    accessor: 'offerId',
    Cell: ({ value }) => { // eslint-disable-line react/prop-types
      return (<a href={`/offers/${value}`}>{value}</a>)
    }
  }, {
    Header: t('attributes.company.name'),
    id: 'companies.name',
    Cell: ({ row: { _original } }) => { // eslint-disable-line react/prop-types
      return (<a href={`/companies/${_original.company.id}`}>{_original.company.name}</a>)
    }
  }, {
    Header: t('attributes.consumptionData.product'),
    id: 'consumptionData.product',
    accessor: ({ product }) => t(`common.product.${product}`)
  }, {
    Header: t('attributes.consumptionData.signedAt'),
    id: 'consumptionData.signedAt',
    accessor: ({ signedAt }) => date(signedAt)
  }, {
    Header: t('attributes.consumptionData.state'),
    id: 'consumptionData.state',
    Cell: ({ row: { _original } }) => { // eslint-disable-line react/prop-types
      if (!can(_original, 'update')) return _original.state
      return <NativeSelect options={[_original.state, ..._original.allowedStates]}
        value={_original.state}
        onChange={onStateChange({ id: _original.id, current: _original.state })}
      />
    }
  }, {
    Header: t('attributes.consumptionData.note'),
    id: 'consumptionData.note',
    accessor: 'note',
    minWidth: 180,
    sortable: false,
    show: isCurrentTab('denied'),
    Cell: ({ value }) => <div dangerouslySetInnerHTML={{ __html: value }} />
  }, {
    id: 'buttons',
    minWidth: 35,
    sortable: false,
    Cell: ({ row: { _original } }) => // eslint-disable-line react/prop-types
      <span>
        {
          can(_original, 'mail') &&
          <Icon iconClass="icon-mail-alt" onClick={papp(onMailClick, _original.id)} />
        }
        {' '}
        <RowLink type="download" url={_original.downloadPath} />
      </span>
  }
])

const TableWithSearch = ({
  onChange,
  onMailClick,
  onSearch,
  onStateChange,
  isCurrentTab,
  data,
  loading,
  pages,
  pageSize,
  query,
  sorted
}) => {
  const inputProps = {
    query,
    placeholder: t('views.consumptionData.index.searchPrompt'),
    onChange: onSearch
  }

  return (
    <DatatableWithSearch
      input={inputProps}
      columns={columns({ onMailClick, onStateChange, isCurrentTab })}
      data={data}
      defaultSorted={sorted}
      loading={loading}
      pages={pages}
      pageSize={pageSize}
      onFetchData={onChange}
      manual
    />
  )
}

TableWithSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  onMailClick: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onStateChange: PropTypes.func.isRequired,
  isCurrentTab: PropTypes.func.isRequired,
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  pages: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  query: PropTypes.string.isRequired,
  sorted: PropTypes.array.isRequired
}

export default TableWithSearch
