import React from 'react'
import PropTypes from 'prop-types'

const PanelHead = ({ children, className = '' }) => {
  const classNames = `panel-heading ${className}`
  return <div className={classNames}>{children}</div>
}

PanelHead.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string
}

export default PanelHead
