import { get, post, destroy } from 'web/utility/http'

import { pricesPath } from '../../helpers'

const fetchAddons = (type) =>
  get(pricesPath('/'), { params: { type } })

const deleteAddon = ({ type, id }) =>
  destroy(pricesPath(`/${id}`), { params: { type } })

const createAddon = ({ type, values }) =>
  post(pricesPath('/'), { type, ...values })

export { fetchAddons, createAddon, deleteAddon }
