import buildValidation from 'web/utility/validations'

const validateUser = (action, permissions = [], values = {}) => {
  const { eq, filled, exist } = buildValidation(values)

  const validation = {
    address:           filled('address'),
    birthDate:         filled('birthDate'),
    citizenship:       filled('citizenship'),
    companyCategoryId: filled('companyCategoryId'),
    cooperationForm:   filled('cooperationForm'),
    email:             filled('email'),
    enbroStart:        filled('enbroStart'),
    firstName:         filled('firstName'),
    language:          filled('language'),
    lastName:          filled('lastName'),
    mobile:            filled('mobile'),
    nin:               filled('nin'),
    postIndexId:       filled('postIndexId'),
    role:              filled('role'),
    status:            filled('status')
  }

  if (values.cooperationForm === 'payroll') {
    Object.assign(validation, {
      workingHours: filled('workingHours')
    })
  }

  if (action === 'new') {
    Object.assign(validation, {
      password: filled('password'),
      passwordConfirmation: eq('password', 'passwordConfirmation')
    })
  } else {
    Object.assign(validation, {
      passwordConfirmation: exist('password') && eq('password', 'passwordConfirmation')
    })
  }

  if (permissions.includes('change_organisation')) {
    validation.organisationId = filled('organisationId')
  }

  return validation
}

const validateCompany = (values = {}) => {
  const { filled } = buildValidation(values)

  return {
    uin: filled('uin'),
    name: filled('name'),
    address: filled('address'),
    tel: filled('tel'),
    email: filled('email'),
    iban: filled('iban'),
    companyCategoryId: filled('companyCategoryId'),
    companyTypeId: filled('companyTypeId'),
    postIndexId: filled('postIndexId')
  }
}

const validateContact = (values = {}) => {
  const { filled } = buildValidation(values)

  return {
    title: filled('title'),
    kind: filled('kind'),
    jobTitle: filled('jobTitle')
  }
}

export default (action, permissions, values) => {
  const base = {
    user: validateUser(action, permissions, values.user)
  }

  if (action === 'new') {
    return {
      ...base,
      company: validateCompany(values.company),
      contact: validateContact(values.contact)
    }
  } else {
    return base
  }
}
