import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MyTasks from './component'
export default class MyTasksContainer extends Component {
  static propTypes = {
    users: PropTypes.array.isRequired,
    meetingsDurations: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired
  }

  _selectedUserIds = () => {
    if (this.props.users.length === 1) {
      return this.props.users.map((u) => u.id)
    } else if (this.props.users.length > 0) {
      return []
    } else {
      return [this.props.currentUser.id]
    }
  }

  state = {
    selectedUserIds: this._selectedUserIds()
  }

  handleUserSelect = (values) => {
    this.setState({ selectedUserIds: values })
  }

  render () {
    const { users, meetingsDurations } = this.props
    const { selectedUserIds } = this.state

    return (
      <MyTasks
        users={users}
        selectedUserIds={selectedUserIds}
        handleUserSelect={this.handleUserSelect}
        meetingsDurations={meetingsDurations}
      />
    )
  }
}
