import React from 'react'
import PropTypes from 'prop-types'

import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import { Columns, Column } from 'web/app/common/layout'
import TariffGroups from './components/TariffGroups'
import { t } from 'web/locale'
import {
  ControlButtons,
  MultiselectWithLabel as MultiSelect
} from 'web/app/common/forms'

const SettingPricesForm = ({
  formApi: {
    handleChange,
    values: {
      electricityMarketNames,
      electricityProductTypes,
      gasMarketNames,
      gasProductTypes,
      tariffGroups,
      tariffs
    }
  },
  products,
  title
}) => {
  const tariffGroupProps = {
    handleChange,
    products,
    values: { tariffGroups, tariffs }
  }

  return (
    <Panel>
      <PanelHead>{title}</PanelHead>
      <>
        {/* Tarif Groups Block */}
        <PanelBlock>
          <TariffGroups {...tariffGroupProps} />
        </PanelBlock>
        <PanelBlock>
          {/* MultiTypes Block */}
          <h3 className="title is-3">{t('views.settings.prices.values')}</h3>

          <Columns>
            <Column>
              <MultiSelect
                field="electricityMarketNames"
                fieldName="settings.prices.electricityMarketNames"
                data={electricityMarketNames}
                required
              />

              <MultiSelect
                field="gasMarketNames"
                fieldName="settings.prices.gasMarketNames"
                data={gasMarketNames}
                required
              />
            </Column>

            <Column>
              <MultiSelect
                field="electricityProductTypes"
                fieldName="settings.prices.electricityProductTypes"
                data={electricityProductTypes}
                required
              />

              <MultiSelect
                field="gasProductTypes"
                fieldName="settings.prices.gasProductTypes"
                data={gasProductTypes}
                required
              />
            </Column>
          </Columns>
        </PanelBlock>

        {/* Submit Buttons Block */}
        <PanelBlock>
          <ControlButtons />
        </PanelBlock>
      </>
    </Panel>
  )
}

SettingPricesForm.propTypes = {
  errors:      PropTypes.object,
  formApi:     PropTypes.shape({
    handleChange: PropTypes.func,
    values: PropTypes.shape({
      electricityMarketNames: PropTypes.array,
      electricityProductTypes: PropTypes.array,
      gasMarketNames: PropTypes.array,
      gasProductTypes: PropTypes.array,
      meters: PropTypes.array,
      tariffGroups: PropTypes.array,
      tariffs: PropTypes.array
    })
  }),
  products:    PropTypes.array.isRequired,
  title:       PropTypes.string.isRequired
}

export default SettingPricesForm
