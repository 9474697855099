import React                            from 'react'
import PropTypes                        from 'prop-types'
import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import { t }                            from 'web/locale'
import { Tabs, Tab }                    from 'web/app/common/layout'
import { camelCase }                    from 'lodash'
import {
  TableWithSearch,
  StateChangeModal
} from './components'

const renderTab = (isCurrentTab, onTabChange) =>
  (tab) =>
    <Tab isActive={isCurrentTab(tab)} key={tab}>
      <a onClick={() => onTabChange(tab)}>{t(`views.tickets.index.tabs.${camelCase(tab)}`)}</a>
    </Tab>

const TicketsIndex = ({
  actions: {
    handleTableChange,
    handleTableQueryChange,
    handleTabChange,
    handleStateChange,
    handleCloseModal
  },
  tableData,
  tabs,
  isCurrentTab,
  isModalVisible,
  newState,
  targetTicketId,
  currentTab
}) => {
  const tableProps = {
    ...tableData,
    handleChange: handleTableChange,
    handleStateChange,
    handleTableQueryChange,
    currentTab
  }
  const stateModalProps = {
    handleTableChange,
    handleCloseModal,
    isModalVisible,
    targetTicketId,
    newState
  }

  return (
    <div>
      <StateChangeModal {...stateModalProps} />

      <Panel kind="primary">
        <PanelHead>{t('views.tickets.index.title')}</PanelHead>
      </Panel>

      <Tabs>
        { tabs.map(renderTab(isCurrentTab, handleTabChange)) }
      </Tabs>

      <Panel>
        <PanelBlock>
          <TableWithSearch {...tableProps} />
        </PanelBlock>
      </Panel>
    </div>
  )
}

TicketsIndex.propTypes = {
  actions: PropTypes.shape({
    handleTableChange:      PropTypes.func.isRequired,
    handleTableQueryChange: PropTypes.func.isRequired,
    handleTabChange:        PropTypes.func.isRequired,
    handleStateChange:      PropTypes.func.isRequired,
    handleCloseModal:       PropTypes.func.isRequired
  }),
  tableData:      PropTypes.object.isRequired,
  tabs:           PropTypes.array.isRequired,
  isCurrentTab:   PropTypes.func.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  newState:       PropTypes.string.isRequired,
  targetTicketId: PropTypes.number,
  currentTab:     PropTypes.string
}

export { TicketsIndex }
