import { get, destroy, apiPath } from '../../../utility/http'

export const fetchData = ({ page, pageSize, query, sorting, states }) =>
  get(apiPath('/eans'), {
    params: {
      page,
      pageSize,
      query,
      sorting,
      states
    }
  })

export const fetchEanDeleteInfo = (eanId) => get(apiPath(`/eans/${eanId}/confirm_delete`))

export const deleteEan = (eanId) => destroy(apiPath(`/eans/${eanId}`))
