import { isEmpty } from 'lodash'

import { t } from 'web/locale'
import { countryEanName } from 'web/utility/country'

const href = (url, text) =>
  `<a href="${url}" target="_blank">#${text}</a>`

const buildOffersLinks = (offerIds) => {
  if (isEmpty(offerIds)) return ''

  const text = t('views.eans.confirmDelete.offersLinks')
  const links =  offerIds.map((id) => href(`/offers/${id}`, id)).join(', ')

  return `<p>${text}: ${links}</p>`
}

const buildContractsLinks = (contractIds) => {
  if (isEmpty(contractIds)) return ''

  const text = t('views.eans.confirmDelete.contractsLinks')
  const links = contractIds
    .map(({ id, offerId }) => href(`/offers/${offerId}/contracts`, id))
    .join(', ')

  return `<p>${text}: ${links}</p>`
}

const confirmDeleteMessage = ({ eanNumber, offerIds, contractIds }) => {
  const params = { ean_name: countryEanName(), ean_number: eanNumber }
  const title = t('views.eans.confirmDelete.title', params)
  const offerLinks = buildOffersLinks(offerIds)
  const contractLinks = buildContractsLinks(contractIds)

  return `<div>
    <p>${title}</p>
    ${offerLinks}
    ${contractLinks}
  </div>`
}

export { confirmDeleteMessage }
