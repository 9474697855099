import React     from 'react'
import PropTypes from 'prop-types'

import { t }     from 'web/locale'
import {
  FileInputWithLabel as FileInput,
  ControlButtons
} from 'web/app/common/forms'

const FilesForm = ({ getFileName }) => {
  return (
    <>
      <FileInput
        getFileName={getFileName}
        label={t('attributes.document.file')}
        field="file"
        required
      />
      <ControlButtons withoutReset />
    </>
  )
}

FilesForm.propTypes = {
  getFileName: PropTypes.func.isRequired
}

export default FilesForm
