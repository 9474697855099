import React from 'react'
import PropTypes from 'prop-types'

const TableCellHeader = ({ children }) => (
  <th className="has-text-centered">
    {children}
  </th>
)

TableCellHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node
  ]).isRequired
}

export default TableCellHeader
