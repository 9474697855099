import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MultiSelectAreas from './component'

export default class MultiSelectAreasContainer extends Component {
  static propTypes = {
    areaIds: PropTypes.array,
    areas:   PropTypes.array
  }

  static defaultProps = {
    areaIds: [],
    areas:   []
  }

  constructor (props) {
    super(props)
    this._handleChange = this._handleChange.bind(this)
  }

  state = {
    areaIds: this.props.areaIds
  }

  _handleChange (values) {
    this.setState({ areaIds: values })
  }

  render () {
    const { areaIds } = this.state
    const { areas }   = this.props

    return (
      <MultiSelectAreas
        areas={areas}
        values={areaIds}
        handleChange={this._handleChange}
      />
    )
  }
}
