import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash'
import SelectPostIndex from './component'

const preparedPostalCodes = (postIndices) => {
  const objs = postIndices.map((pi) => {
    return { value: pi.postalCode, text: pi.postalCode }
  })

  return sortBy(objs, ['text'])
}

const preparedCities = (postIndices, postalCode) =>
  postIndices.filter((pi) =>
    pi.postalCode === postalCode
  ).map((pi) => {
    return { value: pi.city, text: pi.city }
  }).sort()

export default class SelectPostIndexContainer extends Component {
  static propTypes = {
    city:        PropTypes.string,
    isRequired:  PropTypes.bool,
    postalCode:  PropTypes.string.isRequired,
    postIndices: PropTypes.array.isRequired
  }

  static defaultProps = {
    city: '',
    isRequired: false,
    postalCode: ''
  }

  state = {
    city:       this.props.city,
    postalCode: this.props.postalCode
  }

  _handleChange = (attr) => {
    return (value) => {
      this.setState({ [attr]: value })

      if (attr === 'postalCode') this.setState({ city: '' })
    }
  }

  render () {
    const { postIndices, isRequired } = this.props
    const { postalCode }  = this.state

    return (
      <SelectPostIndex
        cities={preparedCities(postIndices, postalCode)}
        postIndices={preparedPostalCodes(postIndices)}
        handleChange={this._handleChange}
        isRequired={isRequired}
        {...this.state}
      />
    )
  }
}
