import React from 'react'
import PropTypes from 'prop-types'

import { Panel, PanelBlock } from 'web/app/common/panel'
import SelectAddonType from './components/SelectAddonType'
import AddonsForm from './components/AddonsForm'
import AddonsTable from './components/AddonsTable'

const SupplierAddons = ({
  addonType,
  addons,
  creatingAddon,
  dataOptions,
  onAddonCreate,
  onAddonDelete,
  onAddonTypeChange,
  tableIsLoading
}) => {
  return (
    <Panel>
      <PanelBlock>
        <SelectAddonType
          onAddonTypeChange={onAddonTypeChange}
          addonType={addonType}
          isLoading={tableIsLoading}
        />
      </PanelBlock>

      <PanelBlock>
        <AddonsForm
          addonType={addonType}
          creatingAddon={creatingAddon}
          dataOptions={dataOptions}
          onAddonCreate={onAddonCreate}
        />
      </PanelBlock>

      <PanelBlock>
        <AddonsTable
          data={addons}
          isLoading={tableIsLoading}
          onAddonDelete={onAddonDelete}
          type={addonType}
        />
      </PanelBlock>
    </Panel>
  )
}

SupplierAddons.propTypes = {
  addons:            PropTypes.array.isRequired,
  addonType:         PropTypes.string.isRequired,
  creatingAddon:     PropTypes.bool.isRequired,
  dataOptions:       PropTypes.object.isRequired,
  onAddonCreate:     PropTypes.func.isRequired,
  onAddonDelete:     PropTypes.func.isRequired,
  onAddonTypeChange: PropTypes.func.isRequired,
  tableIsLoading:    PropTypes.bool.isRequired
}

export default SupplierAddons
