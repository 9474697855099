import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'
import { FormRow } from 'web/app/common/layout'
import {
  ControlButtons,
  Form,
  InputWithLabel as Input,
  MultiselectWithLabel as Multiselect
} from 'web/app/common/forms'
import validation from '../validation'

const PlainForm = ({
  marginLetters
}) => {
  return (
    <>
      <FormRow columns={4}>
        <Input
          field="volume.begin"
          label={t('common.range.begin')}
          type="number"
          required
        />
        <Input
          field="volume.end"
          label={t('common.range.end')}
          type="number"
          required
        />
        <Multiselect
          field="marginLetters"
          label={t('common.marginLetter._')}
          data={marginLetters}
          required
        />
      </FormRow>
      <ControlButtons />
    </>
  )
}

PlainForm.propTypes = {
  marginLetters: PropTypes.array.isRequired
}

export default function VolumeFiltersForm (props) {
  // eslint-disable-next-line react/prop-types
  const { onCreateVolumeFilters, marginLetters } = props

  return (
    <Form onSubmit={onCreateVolumeFilters} validation={validation}>
      <PlainForm
        marginLetters={marginLetters}
      />
    </Form>
  )
}
