import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'
import Notify from 'web/utility/Notify'
import { papp } from 'web/utility/helpers'
import { ButtonGroup, Button, ButtonLink } from 'web/app/common/elements'
import { ContractUpload } from '../../ContractUpload'

const _onArchiveClick = ({ id }, archiveSentRemoteLink) => {
  Notify.confirm(t('common.areYouSure'), () => archiveSentRemoteLink(id))
}

const ContractButtons = ({
  contract, goTo, sentRemoteLink,
  sendContract, sendSignatureLink, archiveSentRemoteLink
}) =>
  <ButtonGroup>
    {
      contract.can('update') && contract.withManualFields &&
      <Button onClick={() => goTo(contract.manualDataPath)}>
        {t('views.contracts.show.buttons.manualData')}
      </Button>
    }

    {
      contract.fileMissed &&
      <Button disabled>
        {t('views.contracts.show.buttons.fileMissed')}
      </Button>
    }

    {
      !contract.fileMissed &&
      <ButtonLink
        href={contract.downloadPath}
        download={contract.filename}
        disabled={contract.cannot('download')}
      >
        {t('common.button.download')}
      </ButtonLink>
    }

    {
      !contract.fileMissed &&
      <Button
        onClick={papp(sendContract, contract)}
        disabled={contract.cannot('send')}
      >
        {t('views.contracts.show.buttons.sendContract')}
      </Button>
    }

    {
      contract.can('upload') &&
      <ContractUpload contract={contract} flag={'upload'} />
    }

    {
      contract.can('reupload') &&
      <ContractUpload contract={contract} flag={'reupload'} />
    }

    {
      contract.can('sign') &&
      <Button kind="success" onClick={() => goTo(`contracts/${contract.id}/sign`)}>
        {t('views.contracts.show.buttons.previewAndSign')}
      </Button>
    }

    {
      contract.can('sign') &&
      <Button onClick={papp(sendSignatureLink, contract)}>
        {t('views.contracts.show.buttons.sendSignatureLink')}
      </Button>
    }
    { sentRemoteLink && sentRemoteLink.can('destroy') &&
      <Button onClick={() => _onArchiveClick(sentRemoteLink, archiveSentRemoteLink)}>
        {t('views.contracts.show.buttons.archiveSentRemoteLink')}
      </Button>
    }
  </ButtonGroup>

ContractButtons.propTypes = {
  contract:              PropTypes.object.isRequired,
  goTo:                  PropTypes.func.isRequired,
  sendContract:          PropTypes.func.isRequired,
  sentRemoteLink:        PropTypes.object,
  sendSignatureLink:     PropTypes.func.isRequired,
  archiveSentRemoteLink: PropTypes.func.isRequired
}

export default ContractButtons
