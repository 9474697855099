import React from 'react'
import PropTypes from 'prop-types'

const Error = ({ children }) => {
  if (!children) return null

  return <span className="help is-danger">{children}</span>
}

Error.propTypes = {
  children: PropTypes.any
}

export default Error
