import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'web/app/common/elements'

const OkIcon = ({ func, type, value }) =>
  <Icon iconClass="icon-ok" size="" color="is-success" onClick={func(type, value)} />

OkIcon.propTypes = {
  func:  PropTypes.func.isRequired,
  type:  PropTypes.string.isRequired,
  value: PropTypes.any.isRequired
}

export default OkIcon
