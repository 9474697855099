import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const Control = ({
  children, classNames, isGrouped, isExpanded, isHorizontal, hasAddons, hasIcon, hasIconRight,
  height100
}) => {
  const finalClassNames = cn('control field', classNames, {
    'is-grouped': isGrouped,
    'is-expanded': isExpanded,
    'is-horizontal': isHorizontal,
    'has-addons': hasAddons,
    'has-icon': hasIcon,
    'has-icon-right': hasIconRight,
    'is-height-100': height100
  })
  return <div className={finalClassNames}>{children}</div>
}

Control.propTypes = {
  children: PropTypes.any.isRequired,
  classNames: PropTypes.string,
  isGrouped: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isHorizontal: PropTypes.bool.isRequired,
  hasAddons: PropTypes.bool.isRequired,
  hasIcon: PropTypes.bool.isRequired,
  hasIconRight: PropTypes.bool.isRequired,
  height100: PropTypes.bool.isRequired
}

Control.defaultProps = {
  isGrouped: false,
  isExpanded: false,
  isHorizontal: false,
  hasAddons: false,
  hasIcon: false,
  hasIconRight: false,
  height100: false
}

export default Control
