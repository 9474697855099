import React from 'react'
import PropTypes from 'prop-types'

import { Datatable } from 'web/app/common/datatable'
import { Icon }      from 'web/app/common/elements'
import { t }         from 'web/locale'

const columns = (deleteCee) => {
  return [
    { Header: t('attributes.cee.value'), accessor: 'value' },
    { Header: t('common.product._'), accessor: 'product' },
    { Header: t('attributes.cee.year'), accessor: 'year' },
    {
      Header: t('common.button.delete'),
      className: 'has-text-centered',
      sortable: false,
      Cell: ({ row }) => { // eslint-disable-line
        const { _original } = row // eslint-disable-line

        return (
          <Icon iconClass="icon-trash" onClick={() => deleteCee(_original.id)} /> // eslint-disable-line
        )
      }
    }
  ]
}

const CeesTable = ({ data, onCeeDelete, isLoading }) => {
  return (
    <Datatable
      loading={isLoading}
      columns={columns(onCeeDelete)}
      data={data}
      showPagination={false}
    />
  )
}

CeesTable.propTypes = {
  data:        PropTypes.array.isRequired,
  isLoading:   PropTypes.bool.isRequired,
  onCeeDelete: PropTypes.func.isRequired
}

export default CeesTable
