import React from 'react'
import PropTypes from 'prop-types'

const Columns = ({ children, classNames }) => (
  <div className={`columns ${classNames}`}>{children}</div>
)

Columns.propTypes = {
  children: PropTypes.any,
  classNames: PropTypes.string
}

Columns.defaultProps = {
  classNames: ''
}

export default Columns
