import React from 'react'
import PropTypes from 'prop-types'
import { capitalize } from 'lodash'

import { t } from 'web/locale'
import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import OldTariffsTable from './components/OldTariffsTable'
import ProposalTable from './components/ProposalTable'
import Controls from './components/Controls'

const Proposal = ({
  eanStats,
  finalize,
  goTo,
  offer,
  onFinalize,
  onSelectProposal,
  onSendOfferPdf,
  product,
  proposals,
  tariffGroups
}) => {
  return (
    <Panel className={`${product}-proposal`} key={product} kind="light">
      <PanelHead>{capitalize(t(`common.product.${product}`))}</PanelHead>
      <PanelBlock>
        <h5 className="old-tarrifs-title title is-5">{t('views.offers.show.oldTariffs')}</h5>
        <OldTariffsTable
          product={product}
          eanStats={eanStats}
          tariffGroups={tariffGroups}
        />
        <br />
      </PanelBlock>
      <PanelBlock>
        <h5 className="proposal-table-title title is-5">{t('views.offers.show.proposals')}</h5>
        <ProposalTable
          finalize={finalize}
          goTo={goTo}
          onSelectProposal={onSelectProposal}
          product={product}
          proposals={proposals}
          tariffGroups={tariffGroups}
        />
        <br />
      </PanelBlock>
      {
        finalize &&
        <Controls
          offer={offer}
          product={product}
          onFinalize={onFinalize}
          onSendOfferPdf={onSendOfferPdf}
          goTo={goTo}
        />
      }
    </Panel>
  )
}

Proposal.propTypes = {
  eanStats:         PropTypes.array.isRequired,
  finalize:         PropTypes.bool.isRequired,
  goTo:             PropTypes.func.isRequired,
  offer:            PropTypes.object.isRequired,
  onFinalize:       PropTypes.func.isRequired,
  onSelectProposal: PropTypes.func.isRequired,
  onSendOfferPdf:   PropTypes.func.isRequired,
  product:          PropTypes.string.isRequired,
  proposals:        PropTypes.array.isRequired,
  tariffGroups:     PropTypes.array.isRequired
}

export default Proposal
