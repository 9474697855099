import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'
import {
  ControlButtons,
  FileInputWithLabel as FileInput,
  SelectWithLabel,
  InputWithLabel
} from 'web/app/common/forms'
import { prepareUserFileTypes } from 'web/utility/prepareData'

class FilesForm extends Component {
  static propTypes = {
    getFileName: PropTypes.func.isRequired,
    getUserFileType: PropTypes.func.isRequired
  }

  state = {
    showUserFileTypeInput: false
  }

  handleUserFileTypeChange = ({ value }) => {
    const { getUserFileType } = this.props

    if (value === 'other') {
      this.setState({ showUserFileTypeInput: true })
    } else {
      this.setState({ showUserFileTypeInput: false })

      getUserFileType(value)
    }
  }

  render () {
    const { getFileName } = this.props
    const { showUserFileTypeInput } = this.state

    return (
      <>
        <FileInput
          getFileName={getFileName}
          label={t('attributes.company.document._')}
          field="file"
          required
        />
        <SelectWithLabel
          field="userFileType"
          label={t('attributes.company.document.userFileType')}
          data={prepareUserFileTypes()}
          onChange={this.handleUserFileTypeChange}
          required
        />
        { showUserFileTypeInput && (
          <InputWithLabel
            field="userFileOtherType"
            label={t('attributes.company.document.userFileType')}
          />
        )}
        <ControlButtons />
      </>
    )
  }
}

FilesForm.propTypes = {
  getFileName: PropTypes.func.isRequired
}

export default FilesForm
