import buildValidation from 'web/utility/validations'

const typeValidation = (addonType, { exist, filled, compare, number }) => {
  switch (addonType) {
    case 'tariff': return { 'tariffId': filled('tariffId') }
    case 'meter': return { 'meterId': filled('meterId') }
    case 'volume':
      return {
        'range': {
          begin: number('range.begin') && filled('range.begin'),
          end: (
            exist('range.begin') &&
            exist('range.end') &&
            number('range.end') &&
            compare('range.end', 'gt', 'range.begin')
          )
        }
      }
    default: return { [addonType]: filled(addonType) }
  }
}

const validation = (addonType) => {
  return (values) => {
    const { exist, filled, compare, number } = buildValidation(values)
    const addonTypeValidation = typeValidation(addonType, { exist, filled, compare, number })

    return {
      product: filled('product'),
      addon:   filled('addon'),
      ...addonTypeValidation
    }
  }
}

export default validation
