import buildValidation from 'web/utility/validations'

const validationCsvForm = (values) => {
  const { filled } = buildValidation(values)

  return {
    supplier: {
      csvEmails:    filled('supplier.csvEmails'),
      deliverCsvAt: filled('supplier.deliverCsvAt')
    }
  }
}

const validationContractForm = (values) => {
  const { filled } = buildValidation(values)

  return {
    supplier: {
      contractsEmails:    filled('supplier.contractsEmails'),
      deliverContractsAt: filled('supplier.deliverContractsAt')
    }
  }
}

export { validationCsvForm, validationContractForm }
