import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Form } from 'web/app/common/forms'
import { Columns, Column } from 'web/app/common/layout'
import { FilesForm } from './'
import { validateFilesForm } from '../validations'
import { Modal } from 'web/app/common/modal'
import { Button } from 'web/app/common/elements'
import { t } from 'web/locale'
import { postFiles } from '../api'

class FilesHeading extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    buttonTitle: PropTypes.string.isRequired,
    companyId: PropTypes.number.isRequired,
    onFilesTableChange: PropTypes.func.isRequired
  }

  state = {
    isOpen: false,
    fileName: '',
    userFileType: ''
  }

  _handleSubmit = (values, { setSubmitting, handleErrors, resetForm }) => {
    const { companyId } = this.props
    const { fileName, userFileType } = this.state

    postFiles({ id: companyId, file: values.file, fileName, userFileType })
      .then(({ success, data }) => {
        if (success) {
          resetForm()
          this._toogleModal()
          window.location.reload()
        } else {
          handleErrors(data)
          setSubmitting(false)
        }
      })
  }

  _toogleModal = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  _setFileName = (fileName) => {
    this.setState({ fileName: fileName })
  }

  _setUserFileType = (type) => {
    this.setState({ userFileType: type })
  }

  render () {
    const { buttonTitle, title } = this.props
    const { isOpen } = this.state
    const initialValues = { file: undefined }
    const modalProps = {
      header: t('views.companies.show.files.add'),
      isShown: isOpen,
      height: '55%',
      onClose: this._toogleModal
    }

    return (
      <Columns>
        <Column>
          { title }
        </Column>
        <Column>
          <Modal {...modalProps}>
            <Form
              onSubmit={this._handleSubmit}
              initialValues={initialValues}
              validation={validateFilesForm}
            >
              <FilesForm
                getFileName={this._setFileName}
                getUserFileType={this._setUserFileType}
              />
            </Form>
          </Modal>
          <div className="pull-right-table-buttons">
            <Button onClick={this._toogleModal}>
              { buttonTitle }
            </Button>
          </div>
        </Column>
      </Columns>
    )
  }
}

export default FilesHeading
