import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { capitalize } from 'lodash'

import { Columns, Column, AttributeRow } from 'web/app/common/layout'
import { Button } from 'web/app/common/elements'
import { t }      from 'web/locale'

const Filters = ({
  durations,
  filters,
  marginLetters,
  onFilterChange: onChange,
  priceModels,
  product,
  productTypes,
  offer
}) =>
  <div>
    <Columns classNames="proposal-duration-filters">
      <Column classNames="is-2">
        <h5 className="title is-5">{capitalize(t('common.duration._'))}</h5>
      </Column>
      <Column>
        <AttributeRow columns={durations.length}>
          {
            durations.map((d) => {
              const kind = cn({ 'primary': filters.durations.includes(d.value) })
              return (
                <Button key={d.value} kind={kind} onClick={onChange('durations', d.value)} isWide>
                  {d.text}
                </Button>
              )
            })
          }
        </AttributeRow>
      </Column>
    </Columns>

    {
      offer.canReadPriceModels && <Columns classNames="proposal-price-modal-filters">
        <Column classNames="is-2">
          <h5 className="title is-5">{capitalize(t('common.priceModel._'))}</h5>
        </Column>
        <Column>
          <AttributeRow columns={priceModels.length}>
            {
              priceModels.map((m) => {
                const kind = cn({ 'primary': filters.priceModels.includes(m.value) })
                return (
                  <Button
                    key={m.value}
                    kind={kind}
                    onClick={onChange('priceModels', m.value)}
                    isWide
                  >
                    {m.text}
                  </Button>
                )
              })
            }
          </AttributeRow>
        </Column>
      </Columns>
    }

    <Columns classNames="proposal-margin-filters">
      <Column classNames="is-2">
        <h5 className="title is-5">{t('attributes.margin._')}</h5>
      </Column>
      <Column>
        <AttributeRow columns={marginLetters.length}>
          {
            marginLetters.map((l) => {
              const kind = cn({ 'primary': filters.marginLetters.includes(l) })
              return (
                <Button key={l} kind={kind} onClick={onChange('marginLetters', l)} isWide>
                  {l}
                </Button>
              )
            })
          }
        </AttributeRow>
      </Column>
    </Columns>

    {
      product !== 'gas' && (
        <Columns>
          <Column classNames="is-2">
            <h5 className="title is-5">{t('attributes.productType._')}</h5>
          </Column>

          <Column>
            <AttributeRow columns={productTypes.length}>
              {
                productTypes.map((pt) => {
                  const kind = cn({ 'primary': filters.productTypes.includes(pt.id) })
                  return (
                    <Button
                      key={pt.id}
                      kind={kind}
                      onClick={onChange('productTypes', pt.id)} isWide
                    >
                      {pt.name}
                    </Button>
                  )
                })
              }
            </AttributeRow>
          </Column>
        </Columns>
      )
    }
  </div>

Filters.propTypes = {
  durations:      PropTypes.array.isRequired,
  filters:        PropTypes.object.isRequired,
  marginLetters:  PropTypes.array.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  priceModels:    PropTypes.array.isRequired,
  product:        PropTypes.string.isRequired,
  productTypes:   PropTypes.array.isRequired,
  offer:          PropTypes.object.isRequired
}

export default Filters
