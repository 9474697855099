import React                            from 'react'
import PropTypes                        from 'prop-types'
import { i18next, t }                   from 'web/locale'
import 'web/utility/javascriptTimeAgo'
import ReactTimeAgo                     from 'react-time-ago'
import { Button, Subtitle, ButtonLink } from 'web/app/common/elements'
import {
  CommentModal,
  FilesHeading,
  FilesTable,
  TicketForm
}                                       from './components'
import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import { Columns, Column }              from 'web/app/common/layout'
import { camelCase }                    from 'lodash'

const can = (ticket, action) =>
  ticket.permissions.includes(action)

const TicketShow = ({
  actions: {
    handleCommentsListChange,
    handleCloseModal,
    handleCloseEditModal,
    handleOpenCommentModal,
    handleOpenEditModal,
    handleOpenMailModal,
    handleFilesTableChange,
    handleClickResolveAppointment
  },
  filesTableData,
  isModalVisible,
  isEditModalVisible,
  isResolveAppointment,
  ticket,
  comments,
  ticketFormData
}) => {
  const ticketId = ticket.id
  const stateModalProps = {
    handleCommentsListChange,
    handleCloseModal,
    handleCloseEditModal,
    handleOpenEditModal,
    handleOpenCommentModal,
    isModalVisible,
    ticketId
  }
  const { contracts } = ticket

  const contractsLinks = ticket.contracts.map((contract) =>
    <div>
      <a href={contract.linkTo} key={contract.id}>
        {contract.linkText}
      </a>
    </div>
  )

  const commentsHTML = comments.map((comment) =>
    <article className="media" key={comment.id}>
      <div className="media-content">
        <div className="content">
          <p>
            <strong>
              {comment.createdBy.fullName}
            </strong>
            <small>
              {' '}
              {t('views.tickets.show.comments.madePost')}
            </small>
            <br />
            <small>
              <ReactTimeAgo date={new Date(comment.createdAt)} locale={language} />
            </small>
            <br />
            {comment.message}
          </p>
        </div>
      </div>
    </article>
  )
  const language = i18next.language

  return (
    <div>
      <CommentModal {...stateModalProps} />
      <Panel kind={'primary'}>
        <PanelHead className="is-clearfix">
          <Columns>
            <Column>
              <h1>
                {`${t('views.tickets.show.title')} #${ticket.id}`}
                <small>
                  {' '}
                  {`${t('views.tickets.show.createdBy')} ${ticket.createdBy.fullName}`}
                </small>
              </h1>
            </Column>
            <Column>
              <TicketForm
                ticket={ticket}
                isEditModalVisible={isEditModalVisible}
                ticketFormData={ticketFormData}
                handleCloseEditModal={handleCloseEditModal}
              />
              <div className="buttons pull-right-table-buttons">
                {
                  can(ticket, 'resolve_appointment') && !isResolveAppointment &&
                    <Button onClick={handleClickResolveAppointment}>
                      <i className="icon-eye" />
                      {t('views.tickets.show.resolveAppointment.button')}
                    </Button>
                }
                {
                  can(ticket, 'create_comments') && <Button onClick={handleOpenCommentModal}>
                    <i className="icon-plus" />
                    {t('views.tickets.show.comments.buttons.addComment')}
                  </Button>
                }
                {
                  can(ticket, 'send_supplier_mail') &&
                  <Button onClick={handleOpenMailModal}>
                    <i className="icon-pencil" />
                    {t('views.tickets.show.supplierMail.button')}
                  </Button>
                }
                {
                  can(ticket, 'update') && <Button onClick={handleOpenEditModal}>
                    <i className="icon-pencil" />
                    {t('common.button.edit')}
                  </Button>
                }
                <ButtonLink href={'/tickets'}>
                  <i className="icon-left-open" />
                  {t('common.button.back')}
                </ButtonLink>
              </div>
            </Column>
          </Columns>
        </PanelHead>
        <PanelBlock>
          <Subtitle size={4}>{t('views.tickets.show.ticketInfo')}</Subtitle>
          <Columns>
            <Column>
              {
                can(ticket, 'see_bcc') && <Columns className="is-mobile">
                  <Column className="is-one-third-desktop has-text-right">
                    {t('attributes.ticket.bccEmails')}:
                  </Column>
                  <Column className="is-two-thirds">
                    {ticket.bccEmails}
                  </Column>
                </Columns>
              }
              <Columns className="is-mobile">
                <Column className="is-one-third-desktop has-text-right">
                  {t('attributes.ticket.category')}:
                </Column>
                <Column className="is-two-thirds">
                  {t(`attributes.ticket.categories.${camelCase(ticket.category)}`)}
                </Column>
              </Columns>
              <Columns className="is-mobile">
                <Column className="is-one-third-desktop has-text-right">
                  {t('attributes.ticket.description')}:
                </Column>
                <Column className="is-two-thirds">
                  {ticket.description}
                </Column>
              </Columns>
            </Column>
            <Column>
              <Columns className="is-mobile">
                <Column className="is-one-third-desktop has-text-right">
                  {t('attributes.ticket.company')}:
                </Column>
                <Column className="is-two-thirds">
                  <a href={`/companies/${ticket.company.id}`}>{ticket.company.name}</a>
                </Column>
              </Columns>
              <Columns className="is-mobile">
                <Column className="is-one-third-desktop has-text-right">
                  {t('attributes.ticket.createdBy')}:
                </Column>
                <Column className="is-two-thirds">
                  <a href={`/users/${ticket.createdBy.id}`}>{ticket.createdBy.fullName}</a>
                </Column>
              </Columns>
              <Columns className="is-mobile">
                <Column className="is-one-third-desktop has-text-right">
                  {t('attributes.ticket.createdForId')}:
                </Column>
                <Column className="is-two-thirds">
                  <a href={`/users/${ticket.createdFor.id}`}>{ticket.createdFor.fullName}</a>
                </Column>
              </Columns>
            </Column>
            <Column>
              <Columns className="is-mobile">
                <Column className="is-one-third-desktop has-text-right">
                  {t('attributes.ticket.createdAt')}:
                </Column>
                <Column className="is-two-thirds">
                  {ticket.createdAt}
                </Column>
              </Columns>
              <Columns className="is-mobile">
                <Column className="is-one-third-desktop has-text-right">
                  {t('attributes.ticket.deadline')}:
                </Column>
                <Column className="is-two-thirds">
                  {ticket.deadline}
                </Column>
              </Columns>
              <Columns className="is-mobile">
                <Column className="is-one-third-desktop has-text-right">
                  {t('attributes.ticket.state')}:
                </Column>
                <Column className="is-two-thirds">
                  {ticket.state}
                </Column>
              </Columns>
            </Column>
            {
              contracts && contracts.length > 0 && <Column>
                <Columns className="is-mobile">
                  <Column className="is-one-third-desktop has-text-right">
                    {t('attributes.ticket.contracts')}:
                  </Column>
                  <Column className="is-two-thirds">
                    {contractsLinks}
                  </Column>
                </Columns>
              </Column>
            }
          </Columns>
          <Subtitle size={4}>{t('attributes.ticket.note')}</Subtitle>
          <p dangerouslySetInnerHTML={{ __html: ticket.note }} />
        </PanelBlock>
      </Panel>
      {
        comments && comments.length > 0 && <Panel>
          <PanelHead>{t('attributes.ticket.comments')}</PanelHead>
          <PanelBlock>
            {commentsHTML}
          </PanelBlock>
        </Panel>
      }
      <Panel>
        <PanelHead>
          <FilesHeading
            handleFilesTableChange={handleFilesTableChange}
            title={t('attributes.ticket.files')}
            buttonTitle={t('common.button.add')}
            ticket={ticket}
          />
        </PanelHead>
        <PanelBlock>
          <FilesTable
            tableData={filesTableData}
          />
        </PanelBlock>
      </Panel>
    </div>
  )
}

TicketShow.propTypes = {
  actions: PropTypes.shape({
    handleCommentsListChange: PropTypes.func.isRequired,
    handleCloseModal:         PropTypes.func.isRequired,
    handleFilesTableChange:   PropTypes.func.isRequired,
    handleOpenCommentModal:   PropTypes.func.isRequired,
    handleCloseEditModal:     PropTypes.func.isRequired,
    handleOpenEditModal:      PropTypes.func.isRequired,
    handleOpenMailModal:      PropTypes.func.isRequired
  }),
  isModalVisible:       PropTypes.bool.isRequired,
  isEditModalVisible:   PropTypes.bool.isRequired,
  isResolveAppointment: PropTypes.bool.isRequired,
  ticket:               PropTypes.object.isRequired,
  comments:             PropTypes.array.isRequired,
  filesTableData:       PropTypes.object.isRequired,
  ticketFormData:       PropTypes.object.isRequired
}

export default TicketShow
