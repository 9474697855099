const flatten = (object) => {
  return Object.assign(
    {},
    ...(function _flatten (objectBit, path = '') {
      // spread the result into our return object
      if (objectBit) {
        return [].concat(
          // concat everything into one level
          ...Object.keys(objectBit).map(
            // iterate over object
            (key) =>
              // check if there is a nested object
              typeof objectBit[key] === 'object' || typeof objectBit[key] === 'undefined'
                ? _flatten(objectBit[key], `${path}/${key}`) // call itself if there is
                : { [`${path}.${key}`]: objectBit[key] } // append object with it’s path as key
          )
        )
      }
    })(object)
  )
}

export { flatten }
