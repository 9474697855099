import { get, patch, apiPath } from 'web/utility/http'

export const fetchData = ({ page, pageSize, query, sorting, states }) =>
  get(apiPath('/consumption_data'), {
    params: {
      page,
      pageSize,
      query,
      sorting,
      states
    }
  })

export const updateConsumptionData = ({ id, params }) =>
  patch(apiPath(`/consumption_data/${id}`), params)
