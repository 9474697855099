import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Columns, Column } from 'web/app/common/layout'
import { Button, Icon } from 'web/app/common/elements'
import { Control } from 'web/app/common/form-elements'
import { t } from 'web/locale'

import {
  MultiselectWithLabel as MultiSelect,
  SelectWithLabel as Select,
  InputWithLabel as Input
} from 'web/app/common/forms'

export default class TariffGroups extends Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    values: PropTypes.shape({
      tariffGroups: PropTypes.array.isRequired
    })
  }

  render () {
    const {
      values: { tariffGroups },
      handleChange,
      products
    } = this.props
    const tariffGroupsAmount = tariffGroups.length

    return (
      <div>
        <h3 className="title is-3">{t('views.settings.prices.tariffGroups')}</h3>
        {tariffGroups.map((group, i) => ( // Loop over the values however you'd like
          <Columns key={i}>
            <Column classNames="is-2">
              <Input
                field={['tariffGroups', i, 'name']}
                fieldName="settings.prices.tariffGroups"
                required
              />
            </Column>

            <Column classNames="is-2">
              <Select
                field={['tariffGroups', i, 'product']}
                fieldName="settings.prices.product"
                data={products}
                required
              />
            </Column>

            <Column classNames="is-4">
              <MultiSelect
                field={['tariffGroups', i, 'tariffs']}
                fieldName="settings.prices.tariffInclude"
                value={group.tariffs}
                data={group.tariffs}
                required
                created
              />
            </Column>

            <Column classNames="is-3">
              <MultiSelect
                field={['tariffGroups', i, 'meters']}
                fieldName="settings.prices.meters"
                value={group.meters}
                data={group.meters}
                required
                created
              />
            </Column>

            <Column>
              <Control height100>
                <Button
                  type="button"
                  kind="white is-height-100 is-fullwidth"
                  onClick={(_e) => {
                    const newTariffGroups = tariffGroups.filter((_v, idx) => idx !== i)
                    handleChange(`tariffGroups`, newTariffGroups)
                  }}
                >
                  <Icon iconClass="icon-cancel" size="" />
                </Button>
              </Control>
            </Column>
          </Columns>
        ))}

        <Button
          type="button"
          onClick={() => handleChange(`tariffGroups.${tariffGroupsAmount}.tariffs`, [])}
        >
          {t('views.settings.prices.addTariffGroup')}
        </Button>
      </div>

    )
  }
}
