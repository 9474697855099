import { startCase } from 'lodash'

// This function receives server dara response as argument and checks if
// errors gets from fileds that can`t be shown throught setFormErrors.
const isParsePdfError = ({ errors }) => {
  if (errors && Object.keys(errors).includes('supplierContractTemplate')) {
    const errorsFields = Object.keys(errors.supplierContractTemplate)

    return (
      errorsFields.includes('fileMeta') ||
      errorsFields.includes('fileForms') ||
      errorsFields.includes('volume')
    )
  } else {
    return false
  }
}

// This function normalizes errors to be shown throught Notify.
const normalizeParsePdfError = ({ errors }) =>
  Object.entries(errors.supplierContractTemplate).map(
    (entry) => `${startCase(entry[0])}: ${entry[1]} \n`
  )

export { isParsePdfError, normalizeParsePdfError }
