import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'
import { validateTicketForm } from 'web/app/tickets/ProcessTicket/validations'
import { ProcessTicket } from 'web/app/tickets/ProcessTicket'
import { updateTicket } from '../api'

class TicketsForm extends Component {
  state = {
    fileName: '',
    isLoading: false
  }

  _handleSubmit = (values, { setSubmitting, handleErrors, resetForm }) => {
    if (values.ticket.contractsIds) {
      values.ticket.contractsIds = values.ticket.contractsIds.map((value) => value.id)
    }

    if (values.ticket.file) {
      values.ticket.fileName = this.state.fileName
    }

    updateTicket({ id: this.props.ticket.id, ...values })
      .then(({ success, data }) => {
        if (success) {
          resetForm()
          this.props.handleCloseEditModal()
          window.location = `/tickets/${this.props.ticket.id}`
        } else {
          handleErrors(data)
          setSubmitting(false)
        }
      })
  }

  _setFileName = (name) => {
    this.setState({ fileName: name })
  }

  render () {
    const modalProps = {
      header: t('views.tickets.show.editForm.title'),
      isShown: this.props.isEditModalVisible,
      onClose: this.props.handleCloseEditModal
    }
    const ticket = this.props.ticket
    const { deadlineDate, description, createdFor, note, bccEmails } = ticket
    const {
      categoriesToSelect,
      contractsToSelect,
      usersToSelect
    } = this.props.ticketFormData
    const initialValues = {
      ticket: {
        deadline: deadlineDate,
        description: description,
        createdForId: createdFor.id,
        note: note,
        category: ticket.category,
        bccEmails: bccEmails || [],
        contractsIds: ticket.contracts
      }
    }

    return (
      <ProcessTicket
        modalProps={modalProps}
        handleSubmit={this._handleSubmit}
        initialValues={initialValues}
        validateForm={validateTicketForm}
        ticketsUsers={usersToSelect}
        bccEmails={bccEmails}
        ticketsCategories={categoriesToSelect}
        companyContracts={contractsToSelect}
        getFileName={this._setFileName}
      />
    )
  }
}

TicketsForm.propTypes = {
  ticket:               PropTypes.object.isRequired,
  ticketFormData:       PropTypes.object.isRequired,
  handleCloseEditModal: PropTypes.func.isRequired,
  isEditModalVisible:   PropTypes.bool.isRequired
}

export default TicketsForm
