import React from 'react'
import PropTypes from 'prop-types'

import { Datatable }        from 'web/app/common/datatable'
import { t }                from 'web/locale'
import { downloadFilePath } from '../api'

/* eslint-disable react/prop-types */
const columns = [
  {
    Header: t('attributes.document.id'),
    sortable: false,
    minWidth: 50,
    Cell: ({ row: { _original } }) => <span>{_original.id}</span>
  },
  {
    Header: t('attributes.document.filename'),
    sortable: false,
    minWidth: 50,
    Cell: ({ row: { _original: { fileName, id, ticketId } } }) =>
      <a
        target="_blank"
        download={fileName}
        href={downloadFilePath({ ticketId, id })}
      >
        {fileName}
      </a>
  },
  {
    Header: t('attributes.document.uploadedBy'),
    sortable: false,
    minWidth: 50,
    Cell: ({ row: { _original } }) => <span>{_original.uploadedBy}</span>
  },
  {
    Header: t('attributes.document.uploadedOn'),
    sortable: false,
    minWidth: 50,
    Cell: ({ row: { _original } }) => <span>{_original.uploadedOn}</span>
  }
]
/* eslint-enable react/prop-types */

const TicketFilesTable = ({
  tableData: {
    pages,
    loading,
    data,
    defaultSorted
  }
}) => {
  return (
    <Datatable
      manual
      columns={columns}
      data={data}
      loading={loading}
      defaultSorted={defaultSorted}
      pages={pages}
    />
  )
}

TicketFilesTable.propTypes = {
  tableData: PropTypes.object.isRequired
}

export default TicketFilesTable
