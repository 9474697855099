import React from 'react'
import PropTypes from 'prop-types'

import {
  SignContractModal,
  SignContractPdf,
  SignContractForm
} from './components'
import { t } from 'web/locale'

const SignContract = ({
  advModal,
  onModalClose,
  directModalShown,
  disableSubmit,
  filePath,
  isSignatureShown,
  isStampShown,
  onDocumentLoad,
  onPageChange,
  onSignatureChange,
  onStampUpload,
  onSubmit,
  pdfState,
  toggleSendResignationLetter
}) => {
  const modalProps = {
    advModal:          advModal,
    directModalShown:  directModalShown,
    onModalClose:      onModalClose,
    onSignatureChange: onSignatureChange
  }

  const pdfProps = {
    filePath:       filePath,
    onDocumentLoad: onDocumentLoad,
    onPageChange:   onPageChange,
    pdfState:       pdfState
  }

  const formProps = {
    disableSubmit:               disableSubmit,
    isStampShown:                isStampShown,
    onSignatureChange:           onSignatureChange,
    onStampUpload:               onStampUpload,
    onSubmit:                    onSubmit,
    pdfState:                    pdfState,
    toggleSendResignationLetter: toggleSendResignationLetter
  }

  return (
    <div className="has-text-centered">
      <SignContractModal {...modalProps} />
      <SignContractPdf {...pdfProps} />

      {
        (isSignatureShown && <SignContractForm {...formProps} />) ||
        <p>{t('views.offers.contractSign.checkPages')}</p>
      }
    </div>
  )
}

SignContract.propTypes = {
  advModal: PropTypes.shape({
    advModalClosable: PropTypes.bool.isRequired,
    advModalPath:     PropTypes.string.isRequired,
    advModalShown:    PropTypes.bool.isRequired
  }),
  directModalShown:            PropTypes.bool.isRequired,
  disableSubmit:               PropTypes.bool.isRequired,
  filePath:                    PropTypes.string.isRequired,
  isSignatureShown:            PropTypes.bool.isRequired,
  isStampShown:                PropTypes.bool.isRequired,
  onDocumentLoad:              PropTypes.func.isRequired,
  onModalClose:                PropTypes.func.isRequired,
  onPageChange:                PropTypes.func.isRequired,
  onSignatureChange:           PropTypes.func.isRequired,
  onStampUpload:               PropTypes.func.isRequired,
  onSubmit:                    PropTypes.func.isRequired,
  pdfState:                    PropTypes.object.isRequired,
  toggleSendResignationLetter: PropTypes.func.isRequired
}

export default SignContract
