import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DevTools from 'mobx-react-devtools'
import CallModal from './components/CallModal'
import OverviewCompany from './component'
import Store from './store'
import { initAccordion } from 'web/utility/accordion'
import { BrowserRouter as Router } from 'react-router-dom'
import { observer, Provider } from 'mobx-react'
import { t } from 'web/locale'
import { postCall } from './api'
import { prepareCompanyCall } from 'web/utility/prepareData'
import { env } from 'web/utility/helpers'

@observer
class OverviewCompanyContainer extends Component {
  static propTypes = {
    leadList: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    companyKeys: PropTypes.object.isRequired,
    callsStates: PropTypes.array.isRequired,
    callsSubStates: PropTypes.object.isRequired,
    callsContacts: PropTypes.array.isRequired,
    spokeWithOpts: PropTypes.array.isRequired,
    meetingsDurations: PropTypes.object.isRequired,
    sales: PropTypes.array.isRequired,
    callAgents: PropTypes.array.isRequired,
    permissions: PropTypes.shape({
      callbacks: PropTypes.array.isRequired,
      meetings: PropTypes.array.isRequired,
      calls: PropTypes.array.isRequired,
      creditScores: PropTypes.array.isRequired
    }).isRequired
  }

  state = {
    callModalIsOpen: false,
    contact: null,
    note: '',
    selectedCallsStates: []
  }

  componentDidMount () {
    initAccordion()
  }

  _toogleCallStateChangeModal = ({ callState }) => {
    const callStates = callState || []

    this.setState({
      callModalIsOpen: !this.state.callModalIsOpen,
      selectedCallsStates: callStates
    })
  }

  _handleCallSubmit = (values, { setSubmitting, handleErrors, resetForm }) => {
    const {
      company: { id: companyId },
      leadList: { id: leadListId }
    } = this.props
    const { call, callback, meeting } = prepareCompanyCall({ values, companyId })

    postCall({ leadListId: leadListId, companyId: companyId, call, callback, meeting })
      .then(({ success, data }) => {
        if (success) {
          resetForm()
          this._toogleCallStateChangeModal({ callState: [] })
          window.location.reload()
        } else {
          handleErrors(data)
          setSubmitting(false)
        }
      })
  }

  render () {
    const {
      company,
      companyKeys,
      callsStates,
      callsSubStates,
      callsContacts,
      spokeWithOpts,
      meetingsDurations,
      sales,
      callAgents,
      permissions,
      leadList
    } = this.props
    const { callModalIsOpen, contact, note, selectedCallsStates } = this.state
    const initialValues = {
      contact,
      state: selectedCallsStates[0],
      note
    }
    const modalProps = {
      header: t('views.companies.show.calls.add'),
      isShown: callModalIsOpen,
      onClose: this._toogleCallStateChangeModal
    }

    const store = new Store({
      company,
      companyKeys,
      callsStates,
      callsSubStates,
      callsContacts,
      spokeWithOpts,
      meetingsDurations,
      sales,
      callAgents,
      permissions,
      leadList
    })

    return (
      <div>
        <Provider store={store}>
          <Router>
            <>
              <OverviewCompany toogleCallStateChangeModal={this._toogleCallStateChangeModal} />
              <CallModal
                modalProps={modalProps}
                initialValues={initialValues}
                handleCallSubmit={this._handleCallSubmit}
                callsStates={selectedCallsStates}
                callsSubStates={callsSubStates}
                callsContacts={callsContacts}
                spokeWithOpts={spokeWithOpts}
                meetingsDurations={meetingsDurations}
                sales={sales}
                callAgents={callAgents}
                companyId={company.id}
                leadListId={leadList.id}
              />
            </>
          </Router>
        </Provider>
        {env.dev && <DevTools />}
      </div>
    )
  }
}

export default OverviewCompanyContainer
