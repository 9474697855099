import React from 'react'
import PropTypes from 'prop-types'
import {
  ControlButtons,
  Form
} from 'web/app/common/forms'
import { Modal } from 'web/app/common/modal'

const ChangeLeadListStatus = ({
  status,
  modalProps,
  handleSubmit,
  validateStatusChangeForm,
  initialValues
}) => (
  <div>
    <Modal {...modalProps} >
      <Form
        initialValues={initialValues || {}}
        onSubmit={handleSubmit}
        validation={validateStatusChangeForm}
      >
        {() =>
          <>
            {
              ['active', 'inactive'].includes(status)
            }
            <input name="status" field="status" type="hidden" />
            <ControlButtons withoutReset />
          </>
        }
      </Form>
    </Modal>
  </div>
)

ChangeLeadListStatus.propTypes = {
  status: PropTypes.string.isRequired,
  modalProps: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  validateStatusChangeForm: PropTypes.func.isRequired,
  initialValues: PropTypes.object
}

export default ChangeLeadListStatus
