import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Modal } from 'web/app/common/modal'
import { t } from 'web/locale'
import { ProcessMeeting } from 'web/app/meetings/ProcessMeeting'

export default class MeetingModal extends Component {
  static propTypes = {
    handleTableChange: PropTypes.func.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    isMeetingModalVisible: PropTypes.bool.isRequired,
    targetMeeting: PropTypes.object,
    meetingsDurations: PropTypes.object.isRequired
  }

  _handleCloseModal = () => {
    this.props.handleCloseModal()
  }

  _handleMeetingUpdate = () => {
    this.props.handleTableChange()
    this.props.handleCloseModal()
  }

  render () {
    const { isMeetingModalVisible, handleCloseModal, targetMeeting, meetingsDurations } = this.props

    const modalProps = {
      header: t(`views.meetings.index.processMeeting`),
      isShown: isMeetingModalVisible,
      onClose: handleCloseModal
    }

    return (
      <div>
        <Modal {...modalProps}>
          <ProcessMeeting
            meetingsDurations={meetingsDurations}
            meeting={targetMeeting}
            onMeetingUpdate={this._handleMeetingUpdate}
          />
        </Modal>
      </div>
    )
  }
}
