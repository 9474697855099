import React from 'react'

import {
  ControlButtons,
  CheckBoxWithLabel as Checkbox,
  InputWithLabel as Input,
  MultiselectWithLabel as MultiSelect,
  TextWithLabel as TextInput
} from 'web/app/common/forms'
import { t } from 'web/locale'

const MailModal = () => (
  <>
    <Input
      field="mail.to"
      required
    />
    <MultiSelect
      field="mail.cc"
    />
    <MultiSelect
      field="mail.bcc"
    />
    <TextInput
      field="mail.body"
    />
    <Checkbox
      field="attachFile"
      label={t('views.consumptionData.index.mail.attachFile')}
    />
    <ControlButtons />
  </>
)

export default MailModal
