import React, { Component } from 'react'
import LeadListShowView from './component'
import PropTypes from 'prop-types'
import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import { Columns, Column } from 'web/app/common/layout'

export default class LeadListFormContainer extends Component {
  static propTypes = {
    leadList: PropTypes.object.isRequired,
    companyLink: PropTypes.string.isRequired,
    permissions: PropTypes.object.isRequired
  }

  _handleSubmit () { return null }

  render () {
    const { leadList, companyLink, permissions } = this.props

    return (
      <Panel>
        <PanelHead>
          Create new lead list
        </PanelHead>
        <PanelBlock>
          <Columns>
            <Column>
              <div className="counters">
                <div className="counter1">
                  Total filtered companies:
                  <div className="counter">
                    { leadList.filteredCompanies }
                  </div>
                </div>
                <div className="counter2">
                  Total called companies:
                  <div className="counter">112</div>
                </div>
                <div className="counter3">
                  Total left to call companies:
                  <div className="counter">112</div>
                </div>
                <div className="counter4">
                  Total meetings booked:
                  <div className="counter">112</div>
                </div>
              </div>
              <LeadListShowView
                leadList={leadList}
                handleSubmit={this._handleSubmit}
                companyLink={companyLink}
                permissions={permissions}
              />
            </Column>
          </Columns>
        </PanelBlock>
      </Panel>
    )
  }
}
