import buildValidation from 'web/utility/validations'

const validation = (values) => {
  const { filled } = buildValidation(values)

  return {
    unit: {
      coefficient:   filled('unit.coefficient'),
      name:          filled('unit.name'),
      productTypeId: filled('unit.productTypeId')
    }
  }
}

export default validation
