import React from 'react'
import PropTypes from 'prop-types'

import { IndexDatatable, RowLink } from 'web/app/common/datatable'
import { t, dateTime } from 'web/locale'

const salesListing = (users) => {
  return users.map((user, index, users) => {
    const separator = (users.length > 1) && (index < users.length - 1) ? ', ' : null

    return (
      <span key={index}>
        <a href={`/users/${user.id}`}>
          {user.fullName}
        </a>
        {separator}
      </span>
    )
  })
}

const columns =
[
  {
    id: 'offers.id',
    accessor: 'id',
    minWidth: 55,
    Header: t('attributes.offer.id')
  }, {
    id: 'offers.createdAt',
    accessor: ({ createdAt }) => dateTime(createdAt),
    minWidth: 180,
    Header: t('attributes.offer.createdAt')
  }, {
    id: 'offers.sales',
    accessor: ({ users }) => salesListing(users),
    sortable: false,
    minWidth: 180,
    Header: t('attributes.offer.sales')
  }, {
    accessor: 'language.name',
    sortable: false,
    minWidth: 180,
    Header: t('attributes.offer.languageId')
  }, {
    sortable: false,
    minWidth: 55,
    Header: t('common.actions'),
    Cell: ({ row: { _original } }) => // eslint-disable-line react/prop-types
      <RowLink type="show" url={`/offers/${_original.id}`} />
  }
]

const OffersTable = ({ onChange }) => {
  return (
    <div id="offers-table">
      <IndexDatatable
        fetchData={onChange}
        columns={columns}
        sorted={[{ id: 'offers.id', desc: false }]}
      />
    </div>
  )
}

OffersTable.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default OffersTable
