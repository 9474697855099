import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { papp }       from 'web/utility/helpers'
import { t }          from 'web/locale'
import { PanelBlock } from 'web/app/common/panel'
import { ButtonGroup, Button, ButtonLink } from 'web/app/common/elements'

const FixMandateBtn = ({ offer, product }) => // eslint-disable-line react/prop-types
  <Link to={`/offers/${offer.id}/mandates/${product}/fix`}>
    <Button>{t('views.offers.show.links.fixMandate')}</Button>
  </Link>

const FlexMandateBtn = ({ offer, product }) => // eslint-disable-line react/prop-types
  <Link to={`/offers/${offer.id}/mandates/${product}/flex`}>
    <Button>{t('views.offers.show.links.flexMandate')}</Button>
  </Link>

const AnalyzeBtn = ({ product, onAnalyzeClick }) => // eslint-disable-line react/prop-types
  <ButtonLink onClick={papp(onAnalyzeClick, product)}>
    {t('views.offers.show.links.analyze')}
  </ButtonLink>

const DeleteMandateBtn = ({ product, onDeleteMandateClick }) => // eslint-disable-line react/prop-types, max-len
  <ButtonLink onClick={papp(onDeleteMandateClick, product)}>
    {t('views.offers.show.links.deleteMandates')}
  </ButtonLink>

const Buttons = ({ offer, product, onAnalyzeClick, onDeleteMandateClick }) => {
  const canCreateMandates = offer.can(`create_${product}_mandates`)
  const canDeleteMandates = offer.can(`destroy_${product}_mandates`)
  const canAnalyze = offer.can('analyze_proposals')
  if (!(canCreateMandates || canAnalyze || canDeleteMandates)) return null

  const btnProps = { offer: offer, product: product }
  const deleteBtnProps = { ...btnProps, onDeleteMandateClick }
  const analyzeBtnProps = { ...btnProps, onAnalyzeClick }

  return (
    <PanelBlock>
      <ButtonGroup>
        { canCreateMandates && <FixMandateBtn {...btnProps} /> }
        { canCreateMandates && <FlexMandateBtn {...btnProps} /> }
        { canDeleteMandates && <DeleteMandateBtn {...deleteBtnProps} /> }
        { canAnalyze && <AnalyzeBtn {...analyzeBtnProps} /> }
      </ButtonGroup>
    </PanelBlock>
  )
}

Buttons.propTypes = {
  offer: PropTypes.object.isRequired,
  product: PropTypes.string.isRequired,
  onAnalyzeClick: PropTypes.func.isRequired,
  onDeleteMandateClick: PropTypes.func.isRequired
}

export default Buttons
