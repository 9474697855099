import React from 'react'
import PropTypes from 'prop-types'
import { Document, Page } from 'react-pdf'

import { t } from 'web/locale'
import { FormRow, Column } from 'web/app/common/layout'
import { Signature } from 'web/app/common/signature'
import { FileInputWithLabel } from 'web/app/common/forms'

const SignDnoConsumption = ({
  downloadPath,
  onSignatureChange,
  withStamp,
  formApi: { handleSubmit }
}) =>
  <div>
    <div className="pdf-preview has-text-centered">
      <Document file={downloadPath} noData={t('views.offers.contractSign.noPdf')}>
        <Page pageNumber={1} />
      </Document>
    </div>
    <br />
    {
      withStamp &&
      <>
        <FormRow>
          <Column />
          <Column classNames="stamp-columns">
            <FileInputWithLabel
              name="consumptionData[stampFile]"
              field="stamp"
              isWide
              required
            />
          </Column>
          <Column />
        </FormRow>
        <br />
      </>
    }
    <Signature onSave={handleSubmit} onChange={onSignatureChange} />
  </div>

SignDnoConsumption.propTypes = {
  downloadPath:      PropTypes.string.isRequired,
  onSignatureChange: PropTypes.func.isRequired,
  withStamp:         PropTypes.bool.isRequired,
  formApi:           PropTypes.shape({
    handleSubmit:    PropTypes.func.isRequired
  })
}

export default SignDnoConsumption
