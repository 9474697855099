import React from 'react'
import PropTypes from 'prop-types'

import TableRow from './TableRow'

const TableBody = ({ children }) => (
  <tbody>
    {children}
  </tbody>
)

TableBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.objectOf(TableRow)),
    PropTypes.objectOf(TableRow)
  ])
}

export default TableBody
