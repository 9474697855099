import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { DropdownList } from 'react-widgets'
import { isObject, startsWith, sortBy, lowerCase, get } from 'lodash'

import useField from './useField'
import { t } from 'web/locale'
import { toId, toInputName } from 'web/utility/helpers'
import { Control, Label } from 'web/app/common/form-elements'
import Error              from './Error'

const SelectWithLabel = ({
  busy = false,
  disabled = false,
  fieldName = undefined,
  handleSearch = undefined,
  label = undefined,
  placeholder = undefined,
  limit = 50,
  name = undefined,
  required = false,
  onChange = () => {},
  search = false,
  sort = false,
  textField = 'text',
  valueField = 'value',
  wrapperClassNames = '',
  withDefaultValue = false,

  data,
  field
}) => {
  const [searchValue, setSearchValue] = useState('')
  const {
    getValue, getError, getTouched, handleTouched, handleChange
  } = useField(field)

  const onSearch = buildSearch({ handleSearch, handleChange, getValue, setSearchValue })

  const id = toId(field)
  label = label || (fieldName && t(`attributes.${fieldName}`)) || t(`attributes.${field}`)
  const value = withDefaultValue
    ? getValueWithDefault({ data, valueField, getValue })
    : getValue('')
  const error = getError()
  const touched = getTouched()

  const classNames = cn({
    'rw-required': required && !!error,
    'rw-required-outline': required && !!error && !!touched
  })

  // providing this.props.handleSearch means that filtering is done serverSide
  const dropdownData = handleSearch ? data : dataToShow({
    data,
    handleSearch,
    limit,
    searchValue,
    sort,
    textField,
    value,
    valueField
  })
  const filter = handleSearch ? () => true : search && 'startsWith'

  return (
    <Control classNames={wrapperClassNames}>
      <Label htmlFor={id}>{label}</Label>
      <Control>
        <DropdownList
          id={id}
          name={name || toInputName(field)}
          placeholder={placeholder || `${label}...`}
          className={classNames}
          disabled={disabled}
          value={value}

          data={dropdownData}
          textField={textField}
          valueField={valueField}

          onChange={onChangeBuilder({ valueField, onChange, handleChange, setSearchValue })}
          onBlur={() => handleTouched()}

          filter={filter}
          onSearch={onSearch}
          busy={busy}

          messages={{
            filterPlaceholder: 'Type to search',
            emptyList: filter ? 'Nothing was found' : 'There are no items in this list'
          }}
        />
      </Control>
      <Error touched={touched}>{error}</Error>
    </Control>
  )
}

const buildSearch = ({ handleSearch, handleChange, getValue, setSearchValue }) => (search) => {
  const value = getValue()

  if (value !== '' && !handleSearch) {
    handleChange('')
    setSearchValue(search)
  } else {
    setSearchValue(search)
  }

  if (handleSearch) handleSearch(search, value)
}

const filterData = ({ data, textField, searchValue }) => {
  if (searchValue && searchValue !== '') {
    const search = lowerCase(searchValue)
    return data.filter((el) => {
      const elVal = lowerCase(el[textField])
      return startsWith(elVal, search)
    })
  } else {
    return data
  }
}

const sortData = ({ data, sort, textField }) => {
  return sort ? sortBy(data, [textField]) : data
}

const limitData = ({ data, limit }) => {
  return limit > 0 ? data.slice(0, limit) : data
}

const dataToShow = ({
  data,
  handleSearch,
  limit,
  searchValue,
  sort,
  textField,
  value,
  valueField
}) => {
  if (handleSearch) return data

  let preparedData = data

  preparedData = filterData({ data: preparedData, textField, searchValue })
  preparedData = sortData({ data: preparedData, sort, textField })
  preparedData = limitData({ data: preparedData, limit })

  // Hack to be able to show preselected (initial) value, if its filtered out of preparedData list
  const findValue = (collection) => collection.find((el) => el[valueField] === value)
  const valueInData = findValue(data)
  const valueInPreparedData = findValue(preparedData)
  if (valueInData && !valueInPreparedData) preparedData = [valueInData, ...preparedData]

  return preparedData
}

const onChangeBuilder = ({ valueField, onChange, handleChange, setSearchValue }) => (object) => {
  const value = isObject(object) ? object[valueField] : object
  handleChange(value)
  onChange(object, handleChange)
  setSearchValue('')
}

const getValueWithDefault = ({ data, valueField, getValue }) => {
  const defaultFallbackValue = get(data, `[0][${valueField}]`, '')

  return getValue(defaultFallbackValue)
}

SelectWithLabel.propTypes = {
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,
  data:              PropTypes.array.isRequired,
  busy:              PropTypes.bool,
  disabled:          PropTypes.bool,
  fieldName:         PropTypes.string,
  handleSearch:      PropTypes.func,
  label:             PropTypes.string,
  placeholder:       PropTypes.string,
  limit:             PropTypes.number,
  name:              PropTypes.string,
  onChange:          PropTypes.func,
  required:          PropTypes.bool,
  search:            PropTypes.bool,
  sort:              PropTypes.bool,
  textField:         PropTypes.string,
  valueField:        PropTypes.string,
  withDefaultValue:  PropTypes.bool,
  wrapperClassNames: PropTypes.string
}

export default SelectWithLabel
