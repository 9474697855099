import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'
import {
  ControlButtons,
  InputWithLabel as Input,
  DateTimePickerWithLabel as DateTime,
  MultiselectWithLabel as MultiSelect,
  SelectWithLabel as Select,
  FileInputWithLabel as FileInput,
  TextWithLabel as TextInput
} from 'web/app/common/forms'

const TicketForm = ({ ticketsCategories, ticketsUsers }) => {
  return (
    <>
      <Select
        field="ticket.createdForId"
        label={t('attributes.ticket.createdForId')}
        textField="name"
        valueField="id"
        search
        data={ticketsUsers}
        required
        forceUpdate
      />
      <MultiSelect
        field="ticket.bccEmails"
        label={t('attributes.ticket.bccEmails')}
      />
      <Select
        field="ticket.category"
        label={t('attributes.ticket.category')}
        data={ticketsCategories}
        textField="humanized"
        valueField="default"
        required
      />
      <DateTime
        field="ticket.deadline"
        label={t('attributes.ticket.deadline')}
        required
      />
      <Input
        field="ticket.description"
        label={t('attributes.ticket.description')}
        required
      />
      <TextInput
        field={'ticket.note'}
        label={t('attributes.ticket.note')}
      />
      <FileInput
        field="ticket.attachment"
        label={t('attributes.ticket.attachment')}
      />
      <ControlButtons />
    </>
  )
}

TicketForm.propTypes = {
  ticketsCategories: PropTypes.array.isRequired,
  ticketsUsers:      PropTypes.array.isRequired
}

export default TicketForm
