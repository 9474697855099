import React, { Component } from 'react'
import { snakeCase } from 'lodash'

import { fetchAddons, createAddon, deleteAddon } from './api'
import SupplierAddons from './component'
import Notify from 'web/utility/Notify'

export default class SupplierAddonsContainer extends Component {
  state = {
    addons:         [],
    addonType:      'priceModel',
    tableIsLoading: false,
    creatingAddon:  false
  }

  componentDidMount () {
    this._fetchData()
  }

  _fetchData () {
    this.setState({ tableIsLoading: true, addons: [] })

    fetchAddons(snakeCase(this.state.addonType)).then(({ success, error, data }) => {
      if (success) {
        this.setState({ addons: data, tableIsLoading: false })
      } else {
        this.setState({ tableIsLoading: false })

        Notify.apiError(error)
      }
    })
  }

  _onAddonCreate = (values, { resetForm, setSubmitting }) => {
    this.setState({ creatingAddon: true, tableIsLoading: true })

    createAddon({ type: snakeCase(this.state.addonType), values })
      .then(({ success, error, data }) => {
        if (success) {
          this._addAddon(data)
          resetForm()
        } else {
          this.setState({ tableIsLoading: false })
          Notify.apiError(error)
        }
        setSubmitting(false)
      })
  }

  _addAddon = (data) => {
    const addons = data ? [...this.state.addons, data] : this.state.addons

    this.setState({
      addons,
      tableIsLoading: false,
      creatingAddon: false
    })
  }

  _onAddonDelete = (id) => {
    this.setState({ tableIsLoading: true })

    deleteAddon({ type: snakeCase(this.state.addonType), id })
      .then(({ success, error }) => {
        if (success) {
          this._deleteAddon(id)
        } else {
          this.setState({ tableIsLoading: false })

          Notify.apiError(error)
        }
      })
  }

  _deleteAddon = (id) => {
    this.setState({
      addons: this.state.addons.filter((obj) => obj.id !== id),
      tableIsLoading: false
    })
  }

  _onAddonTypeChange = (e) => {
    this.setState({ addonType: e.target.value }, this._fetchData)
  }

  render () {
    const dataOptions = { ...this.props }

    return (
      <SupplierAddons
        addons={this.state.addons}
        addonType={this.state.addonType}
        dataOptions={dataOptions}
        onAddonCreate={this._onAddonCreate}
        onAddonDelete={this._onAddonDelete}
        onAddonTypeChange={this._onAddonTypeChange}
        tableIsLoading={this.state.tableIsLoading}
        creatingAddon={this.state.creatingAddon}
      />
    )
  }
}
