import React, { Component }        from 'react'
import PropTypes                   from 'prop-types'
import { Modal }                   from 'web/app/common/modal'
import { t }                       from 'web/locale'
import { updateMeeting }           from '../api'
import { prepareMeetingsDurations } from 'web/utility/prepareData'
import {
  Form,
  ControlButtons,
  TextAreaWithLabel as TextArea,
  SelectWithLabel as Select,
  DateTimePickerWithLabel as DateTime
} from 'web/app/common/forms'
import Notify from 'web/utility/Notify'
import {
  validateMeetingUpdateStateForm as validateMeeting,
  validateCallbackUpdateStateForm as validateCallback
} from '../validation'

export default class StateChangeModal extends Component {
  constructor (props) {
    super(props)
    this._handleCloseModal = this._handleCloseModal.bind(this)
    this._handleSubmit = this._handleSubmit.bind(this)
  }

  _handleCloseModal () {
    this.setState({}, this.props.handleCloseModal)
  }

  _handleSubmit (values) {
    const { targetMeeting, newState } = this.props

    updateMeeting({
      id: targetMeeting.id,
      state: newState,
      values
    }).then(({ success, error }) => {
      if (success) {
        this._handleCloseModal()
        Notify.success(t('views.meetings.flash.updateState'))
        this.props.handleTableChange()
      } else {
        Notify.apiError(error)
      }
    })
  }

  render () {
    const { isModalVisible, meetingsDurations, newState } = this.props

    const modalProps = {
      header:  t(`views.meetings.index.updateStateModal.addNote`),
      isShown: isModalVisible,
      onClose: this._handleCloseModal
    }
    const meetingsDurationsOptions = prepareMeetingsDurations(meetingsDurations)
    const target = newState === 'callback' ? 'newCallback' : 'newMeeting'
    const validation = newState === 'callback' ? validateCallback : validateMeeting

    return (
      <div>
        <Modal {...modalProps} >
          <Form
            onSubmit={this._handleSubmit}
            validation={validation}
          >
            <>
              <TextArea
                field="note"
                label={t(`attributes.meeting.comment`)}
                required
              />
              <br />
              <DateTime
                field={`${target}.datetime`}
                label={t(`attributes.meeting.datetime`)}
                time
                required
              />
              <Select
                field={`${target}.duration`}
                label={t(`attributes.meeting.duration`)}
                data={meetingsDurationsOptions}
                required
                textField="name"
                valueField="value"
              />
              <ControlButtons withoutReset />
            </>
          </Form>
        </Modal>
      </div>
    )
  }
}

StateChangeModal.propTypes = {
  handleTableChange: PropTypes.func.isRequired,
  handleCloseModal:  PropTypes.func.isRequired,
  isModalVisible:    PropTypes.bool.isRequired,
  targetMeeting:     PropTypes.object,
  newState:          PropTypes.string.isRequired,
  meetingsDurations:  PropTypes.object.isRequired
}
