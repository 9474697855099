import React, { Component }  from 'react'
import TicketShow            from './component'
import Notify                from 'web/utility/Notify'
import PropTypes             from 'prop-types'
import { SupplierMailModal } from './SupplierMailModal'
import {
  fetchComments,
  fetchTicketDocuments,
  postResolveAppointment
} from './api'
import { t } from 'web/locale'

export default class TicketShowContainer extends Component {
  static propTypes = {
    ticket:               PropTypes.object.isRequired,
    ticketFormData:       PropTypes.object.isRequired,
    supplierMailFormData: PropTypes.object.isRequired
  }

  state = {
    isModalVisible:     false,
    isEditModalVisible: false,
    isMailModalVisible: false,
    comments:           this.props.ticket.comments,
    files:              this.props.ticket.documents,
    filesTableState:    false,
    resolveAppointment: this.props.ticket.resolveAppointment
  }

  _handleCommentsListChange = () => {
    fetchComments({ ticketId: this.props.ticket.id })
      .then(({ success, response, error }) => {
        if (success) {
          this.setState({ comments: response.data })
        } else {
          Notify.apiError(error)
        }
      })
  }

  _handleCloseModal = () => {
    this.setState({ isModalVisible: false, targetTicketId: '', newState: '' })
  }

  _handleOpenCommentModal = () => {
    this.setState({ isModalVisible: true })
  }

  _handleOpenEditModal = () => {
    this.setState({ isEditModalVisible: true })
  }

  _handleOpenMailModal = () => {
    this.setState({ isMailModalVisible: true })
  }

  _handleCloseEditModal = () => {
    this.setState({ isEditModalVisible: false })
  }

  _handleFilesTableChange = () => {
    fetchTicketDocuments({ ticketId: this.props.ticket.id })
      .then(({ success, response, error }) => {
        if (success) {
          this.setState({ files: response.data })
        } else {
          Notify.apiError(error)
        }
      })
  }

  _handleSupplierMailModalClose = () => {
    this.setState({ isMailModalVisible: false })
  }

  _handleClickResolveAppointment = () => {
    const confirm = window.confirm(t('views.tickets.show.resolveAppointment.confirmMessage'))

    postResolveAppointment({ ticketId: this.props.ticket.id, confirm: confirm })
      .then(({ success, error }) => {
        if (success) {
          this.setState({ resolveAppointment: true })
        } else {
          Notify.apiError(error)
        }
      })
  }

  render () {
    const actions = {
      handleCommentsListChange:      this._handleCommentsListChange,
      handleCloseModal:              this._handleCloseModal,
      handleCloseEditModal:          this._handleCloseEditModal,
      handleOpenCommentModal:        this._handleOpenCommentModal,
      handleOpenEditModal:           this._handleOpenEditModal,
      handleOpenMailModal:           this._handleOpenMailModal,
      handleFilesTableChange:        this._handleFilesTableChange,
      handleClickResolveAppointment: this._handleClickResolveAppointment
    }
    const { ticket, supplierMailFormData } = this.props
    const comments = this.state.comments
    const filesTableData = {
      pages:          null,
      loading:        false,
      data:           this.state.files,
      defaultSorted: [{ id: 'files.id', desc: false }]
    }

    return (
      <div>
        <TicketShow
          actions={actions}
          isModalVisible={this.state.isModalVisible}
          isEditModalVisible={this.state.isEditModalVisible}
          isResolveAppointment={this.state.resolveAppointment}
          ticket={ticket}
          comments={comments}
          filesTableData={filesTableData}
          ticketFormData={this.props.ticketFormData}
        />
        {
          <SupplierMailModal
            supplierMailFormData={supplierMailFormData}
            ticketId={ticket.id}
            onClose={this._handleSupplierMailModalClose}
            isVisible={this.state.isMailModalVisible}
          />
        }
      </div>
    )
  }
}
