import React from 'react'
import PropTypes from 'prop-types'

import { ContractsTable, EansTable, OffersTable } from './components'
import { Panel, PanelBlock, PanelHead } from 'web/app/common/panel'
import { IconLink } from 'web/app/common/elements'
import { t } from 'web/locale'

const offerNewUrl = () => {
  const locArr = window.location.pathname.split('/')
  locArr[locArr.length - 1] = 'offers'
  locArr.push('new')
  return locArr.join('/')
}

const EnergyProfile = ({
  onContractsTableChange,
  onEansTableChange,
  onOffersTableChange,
  permissions
}) => (
  <>
    {permissions.contracts.includes('read_all') && (
      <Panel>
        <PanelHead>{t('views.companies.show.contracts.title')}</PanelHead>
        <PanelBlock>
          <ContractsTable onChange={onContractsTableChange} />
        </PanelBlock>
      </Panel>
    )}

    {permissions.eans.includes('read_all') && (
      <Panel>
        <PanelHead>{t('views.companies.show.eans.title')}</PanelHead>
        <PanelBlock>
          <EansTable onChange={onEansTableChange} />
        </PanelBlock>
      </Panel>
    )}

    {permissions.offers.includes('read_all') && (
      <Panel>
        <PanelHead>
          {t('views.companies.show.offers.title')}
          <div className="is-pulled-right">
            <IconLink iconClass="icon-plus" color="white" size="default" url={offerNewUrl()} />
          </div>
        </PanelHead>
        <PanelBlock>
          <OffersTable onChange={onOffersTableChange} />
        </PanelBlock>
      </Panel>
    )}
  </>
)

EnergyProfile.propTypes = {
  onContractsTableChange: PropTypes.func.isRequired,
  onEansTableChange: PropTypes.func.isRequired,
  onOffersTableChange: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired
}

export default EnergyProfile
