import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { each } from 'lodash'

import { Button } from 'web/app/common/elements'
import { Modal } from 'web/app/common/modal'
import { t } from 'web/locale'

export default class OfferConfirmationModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    offer: PropTypes.object.isRequired,
    confirmationLinks: PropTypes.array.isRequired,
    externalOfferConfirmLoading: PropTypes.bool.isRequired
  }

  modalBody (offer) {
    if (!offer.marginInfo) return (<p> { t('common.empty') } </p>)

    const { externalOfferConfirmLoading, confirmationLinks } = this.props

    return (
      <div>
        <table className="table table-striped">
          <tbody>
            <tr>
              <th> { t('attributes.mandate.totalMargin') } </th>
              <th> { t('attributes.mandate.marginLetter') } </th>
              <th> { t('attributes.mandate.salesMargin') } </th>
            </tr>
            <tr>
              <td>
                {offer.marginInfo.totalMargin}
              </td>
              <td>
                {offer.marginInfo.letter}
              </td>
              <td>
                {offer.marginInfo.salesMargin}
              </td>
            </tr>
          </tbody>
        </table>

        { externalOfferConfirmLoading && (<p> Loading .. </p>) }
        { confirmationLinks && (each(confirmationLinks, (link) => (<p> { link } </p>))) }
      </div>
    )
  }

  render () {
    const { isOpen, offer, onSubmit } = this.props
    const modalProps = {
      header:  t('views.contracts.index.label.confirmExternalOffer', { offer_id: offer.id }),
      isShown: isOpen,
      onClose: this.props.onCloseModal
    }

    return (
      <div className="TicketModal">
        <Modal {...modalProps}>
          { this.modalBody(offer) }
          <Button onClick={() => { onSubmit(offer.id) }}>
            { t('common.confirm') }
          </Button>
        </Modal>
      </div>
    )
  }
}
