import React from 'react'
import PropTypes from 'prop-types'
import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import { TableWithSearch } from './components'

const CallbacksTable = ({ fetchData }) => {
  return (
    <Panel>
      <PanelHead>Callbacks</PanelHead>
      <PanelBlock>
        <TableWithSearch fetchData={fetchData} />
      </PanelBlock>
    </Panel>
  )
}

CallbacksTable.propTypes = {
  fetchData: PropTypes.func.isRequired
}
export { CallbacksTable }
