import React from 'react'

import { t }   from 'web/locale'
import { RowLink } from 'web/app/common/datatable'

const editColumn = (canShow, canUpdate) => ({
  id:       'buttons',
  minWidth: 40,
  sortable: false,
  Cell: ({ row: { _original } }) => { // eslint-disable-line react/prop-types
    return (
      <span>
        { canShow &&
          <RowLink type="show" url={`/contacts/${_original.id}`} />
        }
        {
          canUpdate &&
          <RowLink type="edit" url={`/contacts/${_original.id}/edit`} />
        }
      </span>
    )
  }
})

const contactColumns = (canShow, canUpdate) => {
  let array = [{
    Header: t('attributes.contact.id'),
    accessor: 'id',
    id: 'contacts.id',
    minWidth: 50
  }, {
    Header: t('attributes.contact.name'),
    accessor: 'name',
    id: 'contacts.first_name',
    minWidth: 160
  }, {
    Header: t('attributes.contact.tel'),
    accessor: 'tel',
    id: 'contacts.tel',
    minWidth: 140
  }, {
    Header: t('attributes.contact.mobile'),
    accessor: 'mobile',
    id: 'contacts.mobile',
    minWidth: 140
  }, {
    Header: t('attributes.contact.email'),
    accessor: 'email',
    id: 'contacts.email',
    minWidth: 140
  }]

  if (canShow || canUpdate) array.push(editColumn(canShow, canUpdate))

  return array
}

export { contactColumns }
