import React from 'react'
import PropTypes from 'prop-types'

import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import MarginsTable from './components/MarginsTable'
import MarginsForm from './components/MarginsForm'
import MarginRatiosForm from './components/MarginRatiosForm'

const SupplierMargins = ({
  margins,
  dataOptions,
  onMarginDelete,
  onMarginCreate,
  onRatioUpdate,
  updatingRatio,
  creatingMargin,
  tableIsLoading,
  handleMarginType,
  marginType
}) => {
  return (
    <div>
      <Panel kind="light">
        <PanelHead>Coefficients for all margins</PanelHead>
        <PanelBlock>
          <MarginRatiosForm
            onRatioUpdate={onRatioUpdate}
            updatingRatio={updatingRatio}
            dataOptions={dataOptions}
          />
        </PanelBlock>
      </Panel>

      <Panel kind="light">
        <PanelHead>Margins</PanelHead>
        <PanelBlock>
          <MarginsForm
            onMarginCreate={onMarginCreate}
            creatingMargin={creatingMargin}
            dataOptions={dataOptions}
            handleMarginType={handleMarginType}
            marginType={marginType}
          />
        </PanelBlock>
      </Panel>

      <Panel>
        <PanelBlock>
          <MarginsTable
            data={margins}
            onMarginDelete={onMarginDelete}
            isLoading={tableIsLoading}
          />
        </PanelBlock>
      </Panel>
    </div>
  )
}

SupplierMargins.propTypes = {
  margins:           PropTypes.array.isRequired,
  handleMarginType:  PropTypes.func.isRequired,
  dataOptions:       PropTypes.object.isRequired,
  tableIsLoading:    PropTypes.bool.isRequired,
  onMarginDelete:    PropTypes.func.isRequired,
  onMarginCreate:    PropTypes.func.isRequired,
  onRatioUpdate:     PropTypes.func.isRequired,
  updatingRatio:     PropTypes.bool.isRequired,
  creatingMargin:    PropTypes.bool.isRequired,
  marginType:        PropTypes.any
}

export default SupplierMargins
