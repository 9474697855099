import { get, post, destroy, apiPath } from 'web/utility/http'
import Notify from 'web/utility/Notify'

const fetchContacts = (id) => ({ page, pageSize, sorting }) =>
  get(apiPath(`/contacts`), {
    params: {
      query: '',
      companyId: id,
      page,
      pageSize,
      sorting
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

const fetchUserAssignments = (id) => ({ page, pageSize, sorting }) =>
  get(apiPath(`/companies/${id}/user_assignments`), {
    params: {
      query: '',
      page,
      pageSize,
      sorting
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

const fetchCreditScores = (id) => ({ page, pageSize, sorting }) =>
  get(apiPath(`/companies/${id}/credit_scores`), {
    params: {
      query: '',
      page,
      pageSize,
      sorting
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

const fetchFiles = (id) => ({ page, pageSize, sorting }) =>
  get(apiPath(`/companies/${id}/documents`), {
    params: {
      query: '',
      page,
      pageSize,
      sorting
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

const postFiles = ({ id, file, fileName, userFileType }) =>
  post(apiPath(`/companies/${id}/documents`), { file, fileName, userFileType })

const downloadFilePath = ({ companyId, id }) =>
  apiPath(`/companies/${companyId}/documents/${id}/download`)

const destroyCompanyDocument = ({ companyId, id }) =>
  destroy(apiPath(`/companies/${companyId}/documents/${id}`))

const deleteCompanyUserAssignment = ({ companyId, id }) =>
  destroy(`/companies/${companyId}/user_assignments/${id}/invalidate`)

export {
  fetchContacts,
  fetchUserAssignments,
  fetchCreditScores,
  fetchFiles,
  postFiles,
  downloadFilePath,
  destroyCompanyDocument,
  deleteCompanyUserAssignment
}
