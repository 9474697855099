import React from 'react'
import PropTypes from 'prop-types'

const Column = ({ children, classNames }) => (
  <div className={`column ${classNames}`}>{children}</div>
)

Column.propTypes = {
  children: PropTypes.any,
  classNames: PropTypes.string
}

Column.defaultProps = {
  classNames: ''
}

export default Column
