import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Multiselect } from 'react-widgets'

import { Control, Label } from '../form-elements'
import { reactFormName } from '../../../utility/forms'

export default class MultiselectWithLabel extends Component {
  static propTypes = {
    data:         PropTypes.array.isRequired,
    handleChange: PropTypes.func,
    label:        PropTypes.string.isRequired,
    name:         PropTypes.string.isRequired,
    values:       PropTypes.array,
    textField:    PropTypes.string,
    valueField:   PropTypes.string
  }

  static defaultProps = {
    values: [],
    textField: 'text',
    valueField: 'value'
  }

  constructor (props) {
    super(props)
    this._hiddenInputs = this._hiddenInputs.bind(this)
  }

  state = {
    selectedValues: this.props.values
  }

  _handleChange = (objs) => {
    const values = objs.map((obj) => obj[this.props.valueField])
    this.setState({ selectedValues: values })
    if (this.props.handleChange) {
      this.props.handleChange(values)
    }
  }

  _hiddenInputs () {
    const { name } = this.props
    const { selectedValues } = this.state

    return selectedValues.map((value) =>
      <input
        key={`${name}[${value}]`}
        name={`${name}[]`}
        type="hidden"
        value={value}
      />
    )
  }

  render () {
    const { data, label, name, textField, valueField } = this.props

    return (
      <Control>
        <Label htmlFor={'field'}>{label}</Label>
        <Control>
          {this._hiddenInputs()}

          <Multiselect
            data={data}
            name={reactFormName(name)}
            onChange={this._handleChange}
            placeholder={label}
            textField={textField}
            value={this.state.selectedValues}
            valueField={valueField}
          />
        </Control>
      </Control>
    )
  }
}
