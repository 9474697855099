import { get, apiPath } from 'web/utility/http'

const fetchPrices = ({ product, priceModel, meterId, duration, supplierId }) =>
  get(apiPath('/naked_prices'), {
    params: {
      product,
      priceModel,
      meterId,
      duration,
      supplierId
    }
  })

export { fetchPrices }
