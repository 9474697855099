import React, { Component }    from 'react'
import PropTypes               from 'prop-types'
import Notify                  from 'web/utility/Notify'
import { Form }                from 'web/app/common/forms'
import { Modal }               from 'web/app/common/modal'
import { t }                   from 'web/locale'
import { createTicketComment } from '../api'
import { validateCommentForm } from '../../validations'
import CommentForm             from './CommentForm'

export default class CommentModal extends Component {
  constructor (props) {
    super(props)
    this._handleCloseModal = this._handleCloseModal.bind(this)
    this._handleSubmit = this._handleSubmit.bind(this)
  }

  _handleCloseModal () {
    this.setState({}, this.props.handleCloseModal)
  }

  _handleSubmit (values, { setSubmitting, handleErrors, resetForm }) {
    const { ticketId } = this.props

    createTicketComment({
      ticketId,
      values
    }).then(({ success, data }) => {
      if (success) {
        resetForm()
        this._handleCloseModal()
        this.props.handleCommentsListChange()
        // TODO: fix of immediately hiding of alertify message
        Notify.success(t('views.tickets.show.commentForm.fleshMessage'))
      } else {
        handleErrors(data)
        setSubmitting(false)
      }
    })
  }

  render () {
    const { isModalVisible } = this.props

    const modalProps = {
      header:  t('views.tickets.show.commentForm.title'),
      isShown: isModalVisible,
      onClose: this._handleCloseModal
    }

    return (
      <div>
        <Modal {...modalProps} >
          <Form
            onSubmit={this._handleSubmit}
            validation={validateCommentForm}
          >
            <CommentForm />
          </Form>
        </Modal>
      </div>
    )
  }
}

CommentModal.propTypes = {
  handleCommentsListChange: PropTypes.func.isRequired,
  handleCloseModal:  PropTypes.func.isRequired,
  isModalVisible:    PropTypes.bool.isRequired,
  ticketId:          PropTypes.number.isRequired
}
