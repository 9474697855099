import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { shouldApplyKVA, countryEanName, isCountry } from 'web/utility/country'
import { Panel, PanelHead, PanelBlock }              from 'web/app/common/panel'
import { Ean, EanStats, EanStatsFr, Prices }         from './components'
import { Button, ButtonGroup }                       from 'web/app/common/elements'
import { Control }                                   from 'web/app/common/form-elements'
import { t }                                         from 'web/locale'

export default function EanForm ({
  actionName,
  eanDisabled,
  isIdemLoading,
  isSearching,
  loadedPostIndex,
  onSearchEan,
  onSearchIdem,
  onTariffGroupChange,
  onChangeMeter,
  prefillStartDate,
  product,
  data: {
    canEditEanStatField, canSetFlex, fixedFeePeriods, tariffs, tariffGroups,
    priceModels, reasons, units, suppliers, availableKva
  },
  formApi: {
    getValue, handleChanges
  }
}) {
  const hasPriceRate = !!getValue('helper.priceRate')
  const formTitle = t(`views.eans.${actionName}.title`, {
    ean_name: countryEanName(),
    product: t(`attributes.importedPrice.${product}`)
  })
  const changeAllKvaValues = (kvaValue) => {
    const eanTariffs = getValue('eanStat').eanTariffs.map((tariff) => {
      tariff.kva = kvaValue
      return tariff
    })

    handleChanges({ eanStat: { eanTariffs } })
  }

  return (
    <Panel kind="primary">
      <PanelHead>{formTitle}</PanelHead>

      <Ean
        searchEan={onSearchEan}
        searchIdem={onSearchIdem}
        isSearching={isSearching}
        isIdemLoading={isIdemLoading}
        initialPostIndex={loadedPostIndex}
        disabled={eanDisabled}
        tariffGroups={tariffGroups}
        product={product}
        onChangeMeter={onChangeMeter}
      />

      {isCountry('fr') ? (
        <EanStatsFr
          hasPriceRate={hasPriceRate}
          canEditEanStatField={canEditEanStatField}
          canSetFlex={canSetFlex}
          fixedFeePeriods={fixedFeePeriods}
          prefillStartDate={prefillStartDate}
          priceModels={priceModels}
          reasons={reasons}
          suppliers={suppliers}
          tariffGroups={tariffGroups}
          units={units}
          getValue={getValue}
          onTariffGroupChange={onTariffGroupChange}
        />
      ) : (
        <EanStats
          hasPriceRate={hasPriceRate}
          canEditEanStatField={canEditEanStatField}
          canSetFlex={canSetFlex}
          fixedFeePeriods={fixedFeePeriods}
          prefillStartDate={prefillStartDate}
          priceModels={priceModels}
          reasons={reasons}
          suppliers={suppliers}
          tariffGroups={tariffGroups}
          units={units}
          getValue={getValue}
          onTariffGroupChange={onTariffGroupChange}
        />
      )}

      {
        hasPriceRate &&
        tariffs &&
        tariffs.length > 0 &&
        <Prices
          shouldApplyKVA={shouldApplyKVA(product)}
          availableKva={availableKva}
          onKvaValueChange={changeAllKvaValues}
          tariffs={tariffs}
        />
      }

      <PanelBlock>
        <Control>
          <ButtonGroup>
            <Button kind="primary" type="submit">
              {t('common.button.save')}
            </Button>
            <Link className="button" to="/parameters">
              {t('common.button.cancel')}
            </Link>
          </ButtonGroup>
        </Control>
      </PanelBlock>
    </Panel>
  )
}

EanForm.propTypes = {
  data: PropTypes.shape({
    canEditEanStatField: PropTypes.bool.isRequired,
    canSetFlex: PropTypes.bool.isRequired,
    fixedFeePeriods: PropTypes.array.isRequired,
    priceModels: PropTypes.array.isRequired,
    suppliers: PropTypes.array.isRequired,
    reasons: PropTypes.array.isRequired,
    tariffs: PropTypes.array,
    meters: PropTypes.array.isRequired,
    units: PropTypes.array.isRequired,
    tariffGroups: PropTypes.array.isRequired,
    availableKva: PropTypes.array.isRequired
  }),
  formApi: PropTypes.any,
  loadedPostIndex: PropTypes.object,
  prefillStartDate: PropTypes.func.isRequired,
  isIdemLoading: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool.isRequired,
  eanDisabled: PropTypes.bool.isRequired,
  onSearchEan: PropTypes.func.isRequired,
  onSearchIdem: PropTypes.func.isRequired,
  onTariffGroupChange: PropTypes.func.isRequired,
  onChangeMeter: PropTypes.func.isRequired,
  actionName: PropTypes.string.isRequired,
  product: PropTypes.string.isRequired
}
