import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject } from 'mobx-react'

import { Panel, PanelBlock } from 'web/app/common/panel'
import { FormRow } from 'web/app/common/layout'
import {
  SelectWithLabel,
  Form,
  ControlButtons,
  InputWithLabel,
  DateTimePickerWithLabel
} from 'web/app/common/forms'
import validation from '../validation'
import Notify from 'web/utility/Notify'

@inject('store')
class CeeForm extends Component {
  render () {
    const { products, supplier, store } = this.props
    const initialValues = { cee: { supplierId: supplier.id } }
    const onSubmit = (values, { resetForm, setSubmitting }) => {
      store.createCee({ values }).then((response) => {
        if (response.success) return resetForm()

        Notify.apiError(response)
      }).finally(() => (setSubmitting(false)))
    }
    const formatInput = (value) => (value.replace(',', '.'))

    return (
      <Panel>
        <PanelBlock>
          <Form
            validation={validation}
            onSubmit={onSubmit}
            initialValues={initialValues}
          >
            <FormRow columns={3}>
              <InputWithLabel
                field="cee.value"
                button={
                  <span className="padded-control"> €/MWh </span>
                }
                formatInput={formatInput}
                required
              />
              <SelectWithLabel
                field="cee.product"
                data={products}
                required
              />
              <DateTimePickerWithLabel
                field="cee.year"
                time={false}
                views={['decade']}
                format={'YYYY'}
                defaultValue={new Date()}
                withDefaultValue
                required
              />
              <div className="display-none">
                <InputWithLabel
                  field="cee.supplierId"
                />
              </div>
            </FormRow>

            <ControlButtons />
          </Form>
        </PanelBlock>
      </Panel>
    )
  }
}

CeeForm.propTypes = {
  products: PropTypes.array.isRequired,
  supplier: PropTypes.object.isRequired,
  store: PropTypes.object
}

export default CeeForm
