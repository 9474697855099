import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { isFunction } from 'lodash'
import { humanize } from 'underscore.string'

import useField from './useField'
import { toId, toInputName } from 'web/utility/helpers'
import { Control, Label } from '../form-elements'
import { Button } from '../elements'
import Error from './Error'

import { readAsDataURL } from 'web/utility/file'
import { t } from 'web/locale'

const FileInputWithLabel = ({
  field,

  button,
  fieldName = undefined,
  getFileName = undefined,
  isWide = false,
  label = undefined,
  name = undefined,
  required = false,
  onChange = undefined
}) => {
  const fileInputEl = useRef()
  const { getError, getTouched, handleChange } = useField(field)

  const id = toId(field)
  label = label || (fieldName && t(`attributes.${fieldName}`)) || t(`attributes.${field}`)
  const error = getError()
  const touched = getTouched()
  const fileEl = fileInputEl.current ? fileInputEl.current.files[0] : null
  const fileName = fileEl ? fileEl.name : null
  const buttonText = fileName === null ? humanize(t('common.button.chooseFile')) : fileName

  const inputAttributes = {
    id,
    style: { display: 'none' },
    onChange: onChange || ((e) => {
      const file = e.target.files[0]
      const fileName = file ? file.name : null

      readAsDataURL(e.target.files[0], (encoded) => handleChange(encoded))
      if (isFunction(getFileName)) getFileName(fileName)
    }),
    ref: fileInputEl,
    type: 'file',
    name: name || toInputName(field)
  }

  const buttonAttributes = {
    onClick: (e) => {
      e.stopPropagation()
      fileInputEl.current.click()
    },
    isWide: isWide
  }

  if (required && error) buttonAttributes.kind = 'danger is-outlined'

  return (
    <Control>
      <Label htmlFor={'field'}>{label}</Label>
      <Control
        hasIcon={required}
        hasIconLeft={button && required}
        hasIconRight={required && !button}
      >
        <Button {...buttonAttributes}>
          {buttonText}
          {button}
        </Button>
        <input
          {...inputAttributes}
        />
      </Control>
      <Error touched={touched}>{error}</Error>
    </Control>
  )
}

FileInputWithLabel.propTypes = {
  field: PropTypes.string.isRequired,

  button: PropTypes.element,
  fieldName: PropTypes.string,
  getFileName: PropTypes.func,
  isWide: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func
}

export default FileInputWithLabel
