import React, { Component } from 'react'

import { t, dateTime } from '../../../locale'
import { fetchData } from './actions'
import { IndexDatatable } from '../../common/datatable'

const columns = [{
  Header: t('attributes.event.id'),
  accessor: 'id',
  minWidth: 50
}, {
  Header: t('attributes.event.resourceName'),
  accessor: 'sourceableType'
}, {
  Header: t('attributes.event.resourceId'),
  accessor: 'sourceableId'
}, {
  Header: t('attributes.event.resourceState'),
  accessor: 'sourceableState',
  sortable: false
}, {
  Header: t('attributes.event.resourceMail'),
  accessor: 'sourceableEmail',
  sortable: false
}, {
  Header: t('attributes.event.action'),
  accessor: 'type'
}, {
  Header: t('attributes.event.createdBy'),
  accessor: 'userFullName',
  sortable: false
}, {
  Header: t('attributes.event.createdAt'),
  accessor: 'createdAt',
  minWidth: 110,
  Cell: ({ value }) => { // eslint-disable-line react/prop-types
    return <span>{dateTime(value)}</span>
  }
}]

export default class UserEventsTable extends Component {
  render () {
    return (
      <IndexDatatable
        columns={columns}
        fetchData={fetchData}
        onClickPath="/events"
      />
    )
  }
}
