import React from 'react'
import PropTypes from 'prop-types'

import { IndexDatatable } from 'web/app/common/datatable'
import { dateTime, t } from 'web/locale'

const columns = [
  {
    accessor: 'id',
    sortable: false,
    minWidth: 10,
    Header: t('attributes.creditScore.id')
  },
  {
    id: 'creditAgency.name',
    accessor: 'creditAgency',
    minWidth: 50,
    Header: t('attributes.creditScore.creditAgencyId')
  },
  {
    accessor: 'suppliers',
    sortable: false,
    minWidth: 50,
    Header: t('attributes.creditScore.suppliers')
  },
  {
    accessor: 'score',
    sortable: false,
    minWidth: 50,
    Header: t('attributes.creditScore.score')
  },
  {
    accessor: 'remark',
    sortable: false,
    Header: t('attributes.creditScore.remark')
  },
  {
    id: 'creditScores.createdAt',
    accessor: ({ createdAt }) => dateTime(createdAt),
    minWidth: 50,
    Header: t('attributes.creditScore.createdAt')
  },
  {
    id: 'user.firstName',
    accessor: 'createdBy',
    minWidth: 50,
    Header: t('attributes.creditScore.createdBy')
  }
]

const CreditScoresTable = ({ onChange }) => {
  return (
    <IndexDatatable
      fetchData={onChange}
      columns={columns}
      sorted={[{ id: 'user.first_name', desc: false }]}
    />
  )
}

CreditScoresTable.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default CreditScoresTable
