import React from 'react'

import { Form } from 'web/app/common/forms'
import SettingPricesForm from './component'
import { onSubmit } from './actions'
import validate from './validation'

const prepareArray = (array) => {
  if (array === undefined || !array.length) return []
  return array.map((element) => element.name)
}

const prepareTariffGroups = (array) => {
  if (!array.length) return []
  return array.map((obj) => ({
    name: obj.name,
    product: obj.product,
    tariffs: prepareArray(obj.tariffs),
    meters: prepareArray(obj.meters)
  }))
}

const prepareProducts = (array, product) =>
  prepareArray(
    array.filter((obj) => obj.product === product)
  )

const defaultValues = {
  tariffGroups: []
}

const loadState = (props) => {
  const { prices } = props

  let values = {
    electricityMarketNames: prepareProducts(prices.marketNames, 'electricity'),
    electricityProductTypes: prepareProducts(prices.productTypes, 'electricity'),
    gasMarketNames: prepareProducts(prices.marketNames, 'gas'),
    gasProductTypes: prepareProducts(prices.productTypes, 'gas'),
    meters: prepareArray(prices.meters),
    tariffGroups: prepareTariffGroups(prices.tariffGroups)
  }

  values = { ...defaultValues, ...values }

  return {
    values: values,
    touched: {},
    errors: validate(values),
    nestedErrors: {}
  }
}

export default (props) => (
  <Form
    onSubmit={onSubmit}
    initialValues={loadState(props).values}
    validation={validate}
  >
    <SettingPricesForm
      {...props}
    />
  </Form>
)
