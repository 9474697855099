import { isArray, isObject, isString, cloneDeep } from 'lodash'

import { post, apiPath } from 'web/utility/http'
import Notify from 'web/utility/Notify'
import { t } from 'web/locale'

const prepareStrings = (array) =>
  array.map((st) => {
    return { name: st }
  })

const prepareArrayHashs = (array) =>
  array.map((obj) => prepareHash(obj))

const prepareHash = (hash) => {
  const keys = Object.keys(hash)
  keys.map((key) => {
    if (isArray(hash[key]) && isString(hash[key][0])) {
      hash[key] = prepareStrings(hash[key])
      return
    }

    if (isArray(hash[key]) && isObject(hash[key][0])) {
      hash[key] = prepareArrayHashs(hash[key])
    }
  })
  return hash
}

const prepareMarketNames = (values) => {
  let marketNames = values.electricityMarketNames.map((marketElectricity) => {
    marketElectricity.product = 'electricity'
    return marketElectricity
  })
  return marketNames.concat(
    values.gasMarketNames.map((marketGas) => {
      marketGas.product = 'gas'
      return marketGas
    })
  )
}

const prepareProductTypes = (values) => {
  let productTypes = values.electricityProductTypes.map((productElectricity) => {
    productElectricity.product = 'electricity'
    return productElectricity
  })

  return productTypes.concat(
    values.gasProductTypes.map((productGas) => {
      productGas.product = 'gas'
      return productGas
    })
  )
}

const prepareValues = (values) => {
  values = prepareHash(values)

  return {
    marketNames:  prepareMarketNames(values),
    meters:       values.meters,
    productTypes: prepareProductTypes(values),
    tariffGroups: values.tariffGroups
  }
}

const onSubmit = (values, { setSubmitting }) => {
  const pathname = window.location.pathname
  return (
    post(
      apiPath('/settings/prices'),
      prepareValues(cloneDeep(values))
    ).then(() => {
      window.location = pathname
    }).catch((error) => {
      setSubmitting(false)
      if (error.response.status === 422) {
        const e = Object.values(error.response.data).join('<br />')
        Notify.alert(
          t('errors.forms.form_fields') + `<br /><br />${e}`
        )
      } else {
        Notify.alert(t('errors.forms.unexpected'))
      }
    })
  )
}

export { onSubmit }
