import React from 'react'
import PropTypes from 'prop-types'
import { Columns, Column } from 'web/app/common/layout'
import {
  MultiselectWithLabel as MultiSelect,
  SelectWithLabel as Select,
  InputWithLabel as Input,
  DateTimePickerWithLabel as DateTime,
  ControlButtons
} from 'web/app/common/forms'
import { prepareCompanyStates } from 'web/utility/prepareData'
import { t } from 'web/locale'

export default function LeadListForm ({
  formData: {
    states,
    areas,
    organisations
  },
  actions: {
    handleSearchCities,
    handleSearchZips,
    handleSearchCounties,
    handleChangeArea,
    handleChangeCounties,
    handleChangeZips,
    handleChangeOrganisation
  },
  selectsOptions: {
    citiesOptions,
    zipOptions,
    countyOptions,
    callAgentsOptions,
    nacesOptions,
    salesOptions,
    categoryOptions,
    callAgentsSelectingDisabled,
    salesSelectingDisabled,
    postIndexFieldsDisabled
  },
  formApi: { getValue, setValues }
}) {
  return (
    <div>
      <Columns>
        <Column classNames="is-one-quarter">
          <Input
            field="leadList.name"
            label={t('views.leadLists.form.name')}
            required
          />
        </Column>
      </Columns>
      <Columns>
        <Column classNames="is-one-quarter">
          <Select
            field="leadList.areaId"
            label={t('views.leadLists.form.area')}
            placeholder={t('common.placeholder.search')}
            data={areas}
            textField="name"
            valueField="id"
            onChange={(vals) => handleChangeArea({ setValues, vals })}
          />
        </Column>
        <Column classNames="is-one-quarter">
          <MultiSelect
            field="leadList.counties"
            label={t('views.leadLists.form.county')}
            placeholder={t('common.placeholder.search')}
            data={countyOptions}
            onSearch={(searchTerm) => handleSearchCounties({ searchTerm, getValue })}
            onChange={(vals) => handleChangeCounties({ getValue, vals })}
            disabled={postIndexFieldsDisabled}
          />
        </Column>
        <Column classNames="is-one-quarter">
          <MultiSelect
            field="leadList.postalCodes"
            label={t('views.leadLists.form.zip')}
            placeholder={t('common.placeholder.search')}
            data={zipOptions}
            onSearch={(searchTerm) => handleSearchZips({ searchTerm, getValue })}
            onChange={(vals) => handleChangeZips({ getValue, vals })}
            disabled={postIndexFieldsDisabled}
          />
        </Column>
        <Column classNames="is-one-quarter">
          <MultiSelect
            field="leadList.cities"
            placeholder={t('common.placeholder.search')}
            label={t('views.leadLists.form.city')}
            data={citiesOptions}
            onSearch={(searchTerm) => handleSearchCities({ searchTerm, getValue })}
            disabled={postIndexFieldsDisabled}
          />
        </Column>
      </Columns>
      <Columns>
        <Column classNames="is-one-quarter">
          <MultiSelect
            field="leadList.naces"
            label={t('views.leadLists.form.nace')}
            placeholder={t('common.placeholder.search')}
            data={nacesOptions}
            onCreateMultiple={(value) => value.split(' ')}
          />
        </Column>
        <Column classNames="is-one-quarter">
          <MultiSelect
            field="leadList.categories"
            label={t('views.leadLists.form.category')}
            data={categoryOptions}
          />
        </Column>
        <Column classNames="is-one-quarter">
          <MultiSelect
            field="leadList.states"
            label={t('views.leadLists.form.state')}
            data={prepareCompanyStates(states)}
            textField="text"
            valueField="value"
          />
        </Column>
        <Column classNames="is-one-quarter">
          <Columns>
            <Column classNames="is-half">
              <DateTime
                field="leadList.stateFrom"
                label={t('views.leadLists.form.stateFrom')}
                required
              />
            </Column>
            <Column classNames="is-half">
              <DateTime
                field="leadList.stateTill"
                label={t('views.leadLists.form.stateTill')}
                required
              />
            </Column>
          </Columns>
        </Column>
      </Columns>
      <Columns>
        <Column classNames="is-one-quarter">
          <DateTime
            field="leadList.stateChangedAt"
            label={t('views.leadLists.form.lastCallState')}
            required
          />
        </Column>
      </Columns>
      <Columns>
        <Column classNames="is-one-quarter">
          <Select
            field="leadList.organisationId"
            label={t('views.leadLists.form.organisation')}
            data={organisations}
            textField="name"
            valueField="id"
            onChange={handleChangeOrganisation}
            // required
          />
        </Column>
        <Column classNames="is-one-quarter">
          <MultiSelect
            field="leadList.callAgentsIds"
            label={t('views.leadLists.form.callAgents')}
            textField="fullName"
            valueField="id"
            data={callAgentsOptions}
            disabled={callAgentsSelectingDisabled}
          />
        </Column>
        <Column classNames="is-one-quarter">
          <MultiSelect
            field="leadList.salesIds"
            label={t('views.leadLists.form.sales')}
            textField="fullName"
            valueField="id"
            data={salesOptions}
            disabled={salesSelectingDisabled}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <ControlButtons />
        </Column>
      </Columns>
    </div>
  )
}

LeadListForm.propTypes = {
  formData: PropTypes.shape({
    states: PropTypes.array.isRequired,
    areas: PropTypes.array.isRequired,
    organisations: PropTypes.array.isRequired
  }),
  actions: PropTypes.shape({
    handleSearchCities: PropTypes.func.isRequired,
    handleSearchZips: PropTypes.func.isRequired,
    handleSearchCounties: PropTypes.func.isRequired,
    handleSearchNaces: PropTypes.func.isRequired,
    handleChangeArea: PropTypes.func.isRequired,
    handleChangeNaces: PropTypes.func.isRequired,
    handleChangeCategories: PropTypes.func.isRequired,
    handleChangeCounties: PropTypes.func.isRequired,
    handleChangeZips: PropTypes.func.isRequired,
    handleChangeOrganisation: PropTypes.func.isRequired
  }),
  selectsOptions: PropTypes.shape({
    citiesOptions: PropTypes.array.isRequired,
    zipOptions: PropTypes.array.isRequired,
    countyOptions: PropTypes.array.isRequired,
    callAgentsOptions: PropTypes.array.isRequired,
    nacesOptions: PropTypes.array.isRequired,
    salesOptions: PropTypes.array.isRequired,
    categoryOptions: PropTypes.array.isRequired,
    callAgentsSelectingDisabled: PropTypes.bool.isRequired,
    salesSelectingDisabled: PropTypes.bool.isRequired,
    postIndexFieldsDisabled: PropTypes.bool.isRequired
  }),
  formApi: PropTypes.shape({
    getValue: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired
  })
}
