import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

import { Form }             from 'web/app/common/forms'
import { createMandate, findMargin } from '../api'
import validation         from './validation'
import MandateForm        from './component'
import {
  calculatePrice,
  preparedData,
  prepareDefaultValues,
  isAnyEmpty
} from './helpers'

@inject('store')
@observer
class MandateFormContainer extends Component {
  static propTypes = {
    store: PropTypes.shape({
      offer:         PropTypes.object.isRequired,
      marginLetters: PropTypes.array.isRequired,
      marketNames:   PropTypes.array.isRequired,
      meters:        PropTypes.array.isRequired,
      priceModels:   PropTypes.array.isRequired,
      productTypes:  PropTypes.array.isRequired,
      suppliers:     PropTypes.array.isRequired,
      tariffGroups:  PropTypes.array.isRequired
    }).isRequired,

    history: PropTypes.object.isRequired,
    match:   PropTypes.object.isRequired
  }

  state = {
    marginLetter: undefined,
    marginType: undefined,
    supplierId: undefined
  }

  _onMandateCreate = (values, { handleErrors, setSubmitting }) => {
    const { offer } = this.props.store
    const tariffs = values.tariffs.filter((tf) => tf.tariffPrice)
    values.tariffs = tariffs

    createMandate({ values, id: offer.id }).then(({ success, data }) => {
      if (success) {
        offer.permissions = data.offer.permissions
        this._redirectToProposal()
      } else {
        setSubmitting(false)
        handleErrors(data)
      }
    })
  }

  // try to prefill margin & passive data
  _onMandateUpdate = ({ field, value }) => {
    const fieldsToCheck = ['mandate.marginLetter', 'mandate.marginType', 'mandate.supplierId']
    const { product, priceModel }  = this.props.match.params
    const { store: { offer: { id: offerId } } } = this.props

    if (value && fieldsToCheck.includes(field)) {
      const stateVar = field.split('.')[1]

      this.setState({ [stateVar]: value }, () => {
        if (!isAnyEmpty(this.state)) {
          findMargin({ ...this.state, product, priceModel, offerId }).then(this._prefillMargin)
        }
      })
    }
  }

  _prefillMargin = ({ success, data }) => {
    const { handleChange: setValue } = this.formApi

    if (success) {
      setValue('mandate.brokerMargin', data.bruttoBroker)
      setValue('mandate.totalMargin', data.totalMargin)
      setValue('mandate.salesMargin', data.salesMargin)
      setValue('mandate.passive', data.passive)
    } else {
      setValue('mandate.brokerMargin', undefined)
      setValue('mandate.totalMargin', undefined)
      setValue('mandate.salesMargin', undefined)
      setValue('mandate.passive', undefined)
    }
  }

  _redirectToProposal = () => {
    const { store: { offer: { id } }, history, match: { params: { product } } } = this.props
    history.push(`/offers/${id}/proposals/${product}`)
  }

  _prepareToSubmit = (values) => {
    const { product, priceModel } = this.props.match.params

    values.mandate.product    = product
    values.mandate.priceModel = priceModel

    return values
  }

  _getFormApi = (formApi) => {
    this.formApi = formApi
  }

  render () {
    const { params } = this.props.match
    const { product } = params
    const actions = { calculatePrice }
    const data    = preparedData(this.props, product)

    return (
      <Form
        initialValues={prepareDefaultValues(data)}
        onSubmit={this._onMandateCreate}
        preSubmit={this._prepareToSubmit}
        validation={validation(params)}
        getApi={this._getFormApi}
        onChange={this._onMandateUpdate}
      >
        <MandateForm
          data={data}
          params={params}
          actions={actions}
        />
      </Form>
    )
  }
}

export default MandateFormContainer
