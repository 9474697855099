import { isUinAndVatRequired } from 'web/utility/country'
import buildValidation from 'web/utility/validations'

export default (values) => {
  const { filled } = buildValidation(values.company)

  let validation = {
    address:           filled('address'),
    companyCategoryId: filled('companyCategoryId'),
    languageId:        filled('languageId'),
    name:              filled('name'),
    postIndexId:       filled('postIndexId'),
    uin:               filled('uin'),
    tel:               filled('tel')
  }

  if (isUinAndVatRequired()) {
    Object.assign(validation, { vat: filled('vat') })
  }

  return { company: validation }
}
