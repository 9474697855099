import React from 'react'
import PropTypes from 'prop-types'
import { range } from 'lodash'

import { t } from 'web/locale'
import {
  CheckBoxWithLabel as CheckBox,
  SelectWithLabel as Select,
  TextWithLabel as TextInput,
  DateTimePickerWithLabel as DateTime,
  ControlButtons
} from 'web/app/common/forms'
import { ButtonLink } from 'web/app/common/elements'
import {
  prepareCallsStates,
  prepareCallsSubStates,
  prepareMeetingsDurations,
  prepareSpokeWith,
  prepareDefaultValueMeetingsDurations
} from 'web/utility/prepareData'
import { isCountry } from 'web/utility/country'
import { MeetingsCalendar } from 'web/app/calendars/Meetings'

const availableTimes = {
  // evaluates to [8..20], 21 excluded
  hours: range(8, 21),
  includeLastStep: false
}

const notificationEnabled = isCountry('fr')
const CallsForm = ({
  formApi: { values = {}, getValue } = {},
  callsContacts,
  callsStates,
  callsSubStates,
  spokeWithOpts,
  sales,
  callAgents,
  meetingsDurations,
  companyId,
  afterAddContactRedirectUrl
}) => {
  const url = `/companies/${companyId}/contacts/new?redirect_to=${afterAddContactRedirectUrl}`
  const defaultMeetingsDuration = {
    name: '1:00',
    value: 1
  }
  const preparedMeetingsDuration =
    prepareDefaultValueMeetingsDurations(
      prepareMeetingsDurations(meetingsDurations), defaultMeetingsDuration
    )
  const calendarForUser = getValue('meeting.userId')

  return (
    <div>
      <Select
        field="contactId"
        label={t('attributes.call.contact')}
        textField="name"
        valueField="id"
        search
        data={callsContacts}
        required
      />
      <div className="pull-right-table-buttons">
        <ButtonLink href={url}>
          {t('attributes.call.addContact')}
        </ButtonLink>
      </div>
      <Select
        field="state"
        label={t('attributes.call.status._')}
        textField="name"
        valueField="value"
        data={prepareCallsStates(callsStates)}
        required
      />
      { prepareCallsSubStates(values.state, callsSubStates) !== undefined &&
        <Select
          field="subState"
          label={t('attributes.call.subStatus._')}
          textField="name"
          valueField="value"
          data={prepareCallsSubStates(values.state, callsSubStates)}
          required
        />
      }
      <TextInput
        className="calls-form__text-input"
        field="note"
        label={t('attributes.call.note')}
      />
      { values.state === 'not_interested' &&
        <Select
          field="extra.spokeWith"
          label={t('attributes.call.extra.spokeWith')}
          data={prepareSpokeWith(spokeWithOpts)}
          required
        />
      }
      {
        values.state === 'callback' &&
        <div>
          <DateTime
            field="callback.datetime"
            label={t('attributes.callback.datetime')}
            required
            time
          />
          <Select
            field="callback.userId"
            label={`${t('attributes.callback._')} ${t('attributes.callback.userId')}`}
            textField="fullName"
            valueField="id"
            data={callAgents}
            required
            search
          />
          <div className="calls-form">
            <TextInput
              className="calls-form__text-input"
              field="callback.note"
              label={`${t('attributes.callback._')} ${t('attributes.callback.note')}`}
            />
          </div>
        </div>
      }
      {
        values.state === 'meeting' &&
        <div>
          <Select
            field="meeting.userId"
            label={`${t('attributes.meeting._')} ${t('attributes.meeting.userId')}`}
            textField="fullName"
            valueField="id"
            data={sales}
            required
            search
          />
          { calendarForUser &&
            <MeetingsCalendar
              meetingsDurations={meetingsDurations}
              userIds={[calendarForUser]}
              eventClickable
            />
          }
          <DateTime
            field="meeting.datetime"
            label={`${t('attributes.meeting._')} ${t('attributes.meeting.datetime')}`}
            availableTimes={availableTimes}
            required
            time
          />
          <Select
            field="meeting.duration"
            label={`${t('attributes.meeting._')} ${t('attributes.meeting.duration')}`}
            textField="name"
            valueField="value"
            data={preparedMeetingsDuration}
            withDefaultValue
            required
          />
          <div className="display-none">
            <CheckBox
              field={'meeting.notification'}
              label={t('attributes.meeting.notification')}
              defaultValue={notificationEnabled}
            />
          </div>
          <div className="calls-form">
            <TextInput
              className="calls-form__text-input"
              field="meeting.note"
              label={`${t('attributes.meeting._')} ${t('attributes.meeting.note')}`}
            />
          </div>
        </div>
      }
      <ControlButtons />
    </div>
  )
}

CallsForm.propTypes = {
  formApi: PropTypes.shape({
    values: PropTypes.object.isRequired
  }),
  callsContacts: PropTypes.array.isRequired,
  callsStates: PropTypes.array.isRequired,
  callsSubStates: PropTypes.object.isRequired,
  spokeWithOpts: PropTypes.array.isRequired,
  sales: PropTypes.array.isRequired,
  callAgents: PropTypes.array.isRequired,
  meetingsDurations: PropTypes.object.isRequired,
  companyId: PropTypes.number.isRequired,
  afterAddContactRedirectUrl: PropTypes.string.isRequired
}

export default CallsForm
