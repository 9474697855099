import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { NativeSelect }        from 'web/app/common/form-elements'
import { IndexDatatable }      from 'web/app/common/datatable'
import { Icon, IconLink }      from 'web/app/common/elements'
import { papp }                from 'web/utility/helpers'
import { t, dateTime }         from 'web/locale'
import TicketsChangeStateModal from './TicketsChangeStateModal'
import { updateTicket }        from '../api'
import { ProcessTicket as TicketFormModal } from 'web/app/tickets/ProcessTicket'
import { validateTicketForm } from 'web/app/tickets/ProcessTicket/validations'

const can = (ticket, action) =>
  ticket.permissions.includes(action)

/* eslint-disable react/prop-types */
const columns = (handleStateChange, handleOpenEditModal) => [
  {
    accessor: 'id',
    sortable: false,
    minWidth: 55,
    Header: t('attributes.ticket.id')
  }, {
    id: 'tickets.category',
    accessor: 'category',
    minWidth: 140,
    Header: t('attributes.ticket.category')
  }, {
    id: 'tickets.description',
    accessor: 'description',
    minWidth: 200,
    Header: t('attributes.ticket.description')
  }, {
    id: 'created_by.first_name',
    accessor: ({ createdBy }) => createdBy && createdBy.fullName,
    minWidth: 180,
    Header: t('attributes.ticket.createdBy')
  }, {
    id: 'created_for.first_name',
    accessor: ({ createdFor }) => createdFor && createdFor.fullName,
    minWidth: 180,
    Header: t('attributes.ticket.createdForId')
  }, {
    id: 'tickets.deadline',
    accessor: ({ deadline }) => dateTime(deadline),
    minWidth: 180,
    Header: t('attributes.ticket.deadline')
  }, {
    accessor: 'countdown',
    sortable: false,
    minWidth: 180,
    Header: t('attributes.ticket.countdown')
  }, {
    Header:   t('attributes.ticket.state'),
    accessor: 'state',
    sortable: false,
    minWidth: 140,
    Cell: ({ original }) => { // eslint-disable-line react/prop-types
      const { current, states } = original.state

      return (
        can(original, 'update_state') && <NativeSelect
          placeholder={current}
          options={[current, ...states]}
          value={current}
          onChange={handleStateChange(original, current)}
        />
      )
    }
  }, {
    sortable: false,
    minWidth: 42,
    Cell: ({ original }) => { // eslint-disable-line react/prop-types
      return (
        <span>
          {
            can(original, 'update') &&
              <Icon iconClass="icon-pencil" onClick={papp(handleOpenEditModal, original)} />
          }
          {
            can(original, 'read') &&
              <IconLink iconClass="icon-eye" url={`/tickets/${original.id}`} />
          }
        </span>
      )
    }
  }
]
/* eslint-enable react/prop-types */

const TicketsTable = ({
  companyId,
  onChange,
  users,
  ticketCategories,
  companyContracts
}) => {
  const [newState, setNewState] = useState('')
  const [targetTicket, setTargetTicket] = useState(null)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [editStateModalVisible, setEditStateModalVisible] = useState(false)
  const [ticketFileName, setTicketFileName] = useState('')

  /*
    functions for Edit Ticket Form
  */

  const handleEditFormSubmit = (values, { setSubmitting, handleErrors, resetForm }) => {
    if (values.ticket.contractsIds) {
      values.ticket.contractsIds = values.ticket.contractsIds.map((value) => value.id)
    }

    if (values.ticket.file) {
      values.ticket.fileName = ticketFileName
    }

    updateTicket({ id: targetTicket.id, ...values })
      .then(({ success, data }) => {
        if (success) {
          resetForm()
          handleCloseEditModal()
          window.location = `/companies/${companyId}/communication`
        } else {
          handleErrors(data)
          setSubmitting(false)
        }
      })
  }

  const bccEmails = (targetTicket && targetTicket.bccEmails) || []
  const initialValues = targetTicket && {
    ticket: {
      deadline: targetTicket.deadline,
      description: targetTicket.description,
      createdForId: targetTicket.createdFor.id,
      note: targetTicket.note,
      category: targetTicket.category,
      bccEmails: bccEmails || [],
      contractsIds: targetTicket.contracts
    }
  }

  const handleCloseEditModal = () => {
    setEditModalVisible(false)
  }

  const editModalProps = {
    header:  t(`views.tickets.index.updateState.title`),
    isShown: editModalVisible,
    onClose: handleCloseEditModal
  }

  const handleOpenEditModal = (ticket) => {
    setEditModalVisible(true)
    setTargetTicket(ticket)
  }

  /*
    functions for Update Ticket State
  */

  const handleStateChange = (ticket, currentState) => {
    return (e) => {
      const newState = e.target.value

      if (newState === currentState) return

      handleOpenEditStateModal()
      setTargetTicket(ticket)
      setNewState(newState)
    }
  }

  const handleOpenEditStateModal = () => {
    setEditStateModalVisible(true)
  }
  const handleCloseEditStateModal = () => {
    setEditStateModalVisible(false)
  }

  const editStateModalProps = {
    handleCloseModal: handleCloseEditStateModal,
    isModalVisible: editStateModalVisible,
    targetTicketId: targetTicket && targetTicket.id,
    newState
  }

  return (
    <div>
      <TicketsChangeStateModal {...editStateModalProps} />
      <TicketFormModal
        modalProps={editModalProps}
        handleSubmit={handleEditFormSubmit}
        initialValues={targetTicket && initialValues}
        validateForm={validateTicketForm}
        ticketsUsers={users}
        bccEmails={bccEmails}
        ticketsCategories={ticketCategories}
        companyContracts={companyContracts}
        getFileName={(name) => { setTicketFileName(name) }}
      />
      <div id="calls-table">
        <IndexDatatable
          fetchData={onChange}
          columns={columns(handleStateChange, handleOpenEditModal)}
          sorted={[{ id: 'tickets.created_at', desc: true }]}
        />
      </div>
    </div>
  )
}

TicketsTable.propTypes = {
  companyId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  ticketCategories: PropTypes.array.isRequired,
  companyContracts: PropTypes.array.isRequired
}

export default TicketsTable
