import React from 'react'
import PropTypes from 'prop-types'

import { SearchPostIndex } from 'web/app/postIndices/SearchPostIndex'
import { PanelBlock }      from 'web/app/common/panel'
import { FormRow }         from 'web/app/common/layout'
import { Button }          from 'web/app/common/elements'
import { t }               from 'web/locale'
import {
  InputWithLabel as Input,
  SelectWithLabel as Select
} from 'web/app/common/forms'

const CompanyFields = ({
  handleCompanySearch,
  searchingCompany,
  companyPostIndex,
  data: {
    categories,
    types
  }
}) => (
  <PanelBlock>
    <h3 className="title is-3">{t('views.users.new.companyInfo')}</h3>
    <FormRow>
      <Input
        field="company.uin"
        button={
          <Button onClick={handleCompanySearch} isLoading={searchingCompany}>
            {t('views.users.new.searchCompanyButton')}
          </Button>
        }
        required
      />
    </FormRow>
    <FormRow>
      <Input field="company.name" required />
    </FormRow>
    <FormRow>
      <Input field="company.address" required />
      <SearchPostIndex
        field="company.postIndexId"
        initialData={companyPostIndex}
        required
      />
    </FormRow>
    <FormRow>
      <Input field="company.tel" required />
      <Input field="company.mobile" />
      <Input field="company.fax" />
    </FormRow>
    <FormRow>
      <Input field="company.iban" required />
      <Select
        field="company.companyCategoryId"
        data={categories}
        textField="name"
        valueField="id"
        required
      />
      <Select
        field="company.companyTypeId"
        data={types}
        textField="name"
        valueField="id"
        required
      />
    </FormRow>
    <FormRow>
      <Input field="company.email" required />
      <Input field="company.website" />
    </FormRow>
  </PanelBlock>
)

CompanyFields.propTypes = {
  handleCompanySearch: PropTypes.func.isRequired,
  searchingCompany:    PropTypes.bool.isRequired,
  companyPostIndex:    PropTypes.object,

  data: PropTypes.shape({
    types:      PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired
  })
}

export default CompanyFields
