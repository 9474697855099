import React from 'react'
import PropTypes from 'prop-types'

import { IndexDatatable, RowLink } from 'web/app/common/datatable'
import { t } from 'web/locale'
import { deleteCompanyUserAssignment } from '../api'
import Notify from 'web/utility/Notify'

const _deleteUserAssignment = ({ companyId, id }) => {
  deleteCompanyUserAssignment({ companyId, id }).then(({ success, error }) => {
    if (success) {
      window.location.reload()
    } else {
      Notify.apiError(error)
    }
  })
}

const ActionsIcons = ({ id, companyId, valid }) => { // eslint-disable-line react/prop-types
  if (id && valid) {
    return (<div>
      <RowLink type="delete" onClick={() => _deleteUserAssignment({ companyId, id })} />
    </div>
    )
  } else {
    return null
  }
}

const columns = (companyId) => (
  [
    {
      id: 'user.firstName',
      accessor: 'user.fullName',
      minWidth: 50,
      Header: t('attributes.company.userAssignment.userName')
    },
    {
      accessor: 'assignedTo',
      sortable: false,
      minWidth: 50,
      Header: t('attributes.company.userAssignment.sourceableId')
    },
    {
      id: 'validTo',
      accessor: 'hValidTo',
      minWidth: 50,
      Header: t('attributes.company.userAssignment.validTo')
    },
    {
      sortable: false,
      minWidth: 20,
      Header: t('common.actions'),
      Cell: ({ original: { id, valid } }) => // eslint-disable-line react/prop-types
        <ActionsIcons id={id} companyId={companyId} valid={valid} />
    }
  ]
)

const UserAssignmentTable = ({ onChange, companyId }) => {
  return (
    <IndexDatatable
      fetchData={onChange}
      columns={columns(companyId)}
      sorted={[{ id: 'validTo', desc: true }]}
    />
  )
}

UserAssignmentTable.propTypes = {
  onChange: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired
}

export default UserAssignmentTable
