import { get, post, patch, apiPath } from 'web/utility/http'

export const searchCompany = (uin) =>
  get(apiPath(`/companies/search`), { params: { uin } })

export const saveUser = (params) =>
  post(apiPath('/users'), params)

export const updateUser = (id, params) =>
  patch(apiPath(`/users/${id}`), params)
