import { round } from 'lodash'

export const calcSavings = (contracts) =>
  contracts.reduce((acc, c) => {
    Object.entries(c.savings).map(([p, s]) => {
      acc[p] = acc[p] || 0
      acc[p]    += s || 0
      acc.total += s || 0
      acc.total = round(acc.total, 4)
    })
    return acc
  }, { total: 0 })
