import buildValidation from 'web/utility/validations'

const validation = (values) => {
  const { filled, exist, compare } = buildValidation(values)

  return {
    productTypeIds: filled('productTypeIds'),
    file:           filled('file'),
    languageId:     filled('languageId'),
    meterIds:       filled('meterIds'),
    volumeBegin:    compare('volumeBegin', 'gteq', 0),
    volumeEnd:
      exist('volumeEnd') && compare('volumeEnd', 'gt', 'volumeBegin')
  }
}

export default validation
