import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, BrowserRouter as Router } from 'react-router-dom'

import ShowSupplier from './component'
import { SupplierContractTemplates } from '../SupplierContractTemplates'
import { SupplierInfo } from '../SupplierInfo'
import { SupplierMails } from '../SupplierMails'
import { SupplierSettings } from '../SupplierSettings'
import {
  SupplierPrices,
  SupplierPricesImport,
  SupplierMargins,
  SupplierAddons,
  SupplierFilters,
  SupplierPricesList,
  SupplierCees
} from '../SupplierPrices'

export default class ShowSupplierContainer extends Component {
  static propTypes = {
    baseGasUnit:     PropTypes.object.isRequired,
    canUpdate:       PropTypes.bool.isRequired,
    days:            PropTypes.array.isRequired,
    durations:       PropTypes.array.isRequired,
    gasProductTypes: PropTypes.array.isRequired,
    globalGasUnits:  PropTypes.array.isRequired,
    languages:       PropTypes.array.isRequired,
    lastImport:      PropTypes.string.isRequired,
    mailStates:      PropTypes.array.isRequired,
    marginLetters:   PropTypes.array.isRequired,
    marginRatio:     PropTypes.object.isRequired,
    meters:          PropTypes.array.isRequired,
    priceModels:     PropTypes.array.isRequired,
    products:        PropTypes.array.isRequired,
    productTypes:    PropTypes.array.isRequired,
    supplier:        PropTypes.object.isRequired,
    tariffGroups:    PropTypes.array.isRequired,
    tariffs:         PropTypes.array.isRequired,
    supplierGasUnits:         PropTypes.array.isRequired,
    supplierElectricityUnits: PropTypes.array.isRequired
  }

  render () {
    const {
      meters, supplier, tariffGroups, durations, products,
      languages, tariffs, baseGasUnit, gasProductTypes, priceModels, days,
      globalGasUnits, productTypes, marginLetters, marginRatio, mailStates,
      lastImport, supplierGasUnits, supplierElectricityUnits, canUpdate
    } = this.props

    const ShowSupplierPage = (props) =>
      <ShowSupplier
        {...props}
        canUpdate={canUpdate}
        supplier={supplier}
      />

    const SupplierInfoPage = (props) =>
      <SupplierInfo
        {...props}
        supplier={supplier}
        canUpdate={canUpdate}
      />

    const SupplierMailsPage = (props) =>
      <SupplierMails
        {...props}
        {...{ days, mailStates }}
        supplier={supplier}
        canUpdate={canUpdate}
      />

    const SupplierSettingsPage = (props) =>
      <SupplierSettings
        {...props}
        {...{ baseGasUnit, gasProductTypes, globalGasUnits }}
      />

    const SupplierContractTemplatesPage = (props) =>
      <SupplierContractTemplates
        {...props}
        {...{ products, priceModels, meters, supplier, languages, productTypes }}
      />

    const SupplierPricesPage = (props) =>
      <SupplierPrices
        {...props}
        {...{ supplier }}
      />

    // SupplierPrices Children Components

    const SupplierPricesListPage = (props) =>
      <SupplierPricesList
        {...props}
        {...{ meters, durations, tariffGroups, products, priceModels, supplier }}
      />

    const SupplierAddonsPage = (props) =>
      <SupplierAddons
        {...props}
        {...{ meters, durations, tariffs, products, priceModels }}
      />

    const SupplierFiltersPage = (props) =>
      <SupplierFilters
        {...props}
        {...{ meters, durations, marginLetters, products, priceModels }}
      />

    const SupplierMarginsPage = (props) =>
      <SupplierMargins
        {...props}
        {...{ priceModels, products, marginLetters, marginRatio }}
      />

    const SupplierPricesImportPage = (props) =>
      <SupplierPricesImport
        {...props}
        {...{ lastImport, supplierGasUnits, supplierElectricityUnits }}
      />

    const SupplierCeesPage = (props) =>
      <SupplierCees
        {...props}
        {...{ products, supplier }}
      />

    return (
      <Router>
        <div>
          <Route
            path={`/suppliers/${supplier.id}`}
            component={ShowSupplierPage}
          />
          <Route
            exact path={`/suppliers/${supplier.id}`}
            component={SupplierInfoPage}
          />
          { canUpdate &&
            <Route
              path={`/suppliers/${supplier.id}/prices`}
              component={SupplierPricesPage}
            />
          }
          { canUpdate &&
            <Route
              exact path={`/suppliers/${supplier.id}/prices`}
              component={SupplierPricesListPage}
            />
          }
          { canUpdate &&
            <Route
              path={`/suppliers/${supplier.id}/prices/addons`}
              component={SupplierAddonsPage}
            />
          }
          { canUpdate &&
            <Route
              path={`/suppliers/${supplier.id}/prices/price_filters`}
              component={SupplierFiltersPage}
            />
          }
          { canUpdate &&
            <Route
              path={`/suppliers/${supplier.id}/prices/margins`}
              component={SupplierMarginsPage}
            />
          }
          { canUpdate &&
            <Route
              path={`/suppliers/${supplier.id}/prices/import_prices`}
              component={SupplierPricesImportPage}
            />
          }
          { canUpdate &&
            <Route
              path={`/suppliers/${supplier.id}/prices/cees`}
              component={SupplierCeesPage}
            />
          }
          <Route
            path={`/suppliers/${supplier.id}/mails`}
            component={SupplierMailsPage}
          />
          { canUpdate &&
            <Route
              path={`/suppliers/${supplier.id}/contract_templates`}
              component={SupplierContractTemplatesPage}
            />
          }
          { canUpdate &&
            <Route
              path={`/suppliers/${supplier.id}/settings`}
              component={SupplierSettingsPage}
            />
          }
        </div>
      </Router>
    )
  }
}
