import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'
import { Button, ButtonGroup } from '../elements'
import { useFormContext } from './Form'

const ControlButtons = ({ withoutReset = false, onCancel = undefined, onReset = undefined }) => {
  const { submitting, dirty, invalid, resetForm } = useFormContext()
  const withReset = !withoutReset

  return (
    <ButtonGroup>
      <Button type="submit" isLoading={submitting}>
        {
          (dirty && invalid)
            ? t('common.button.invalid')
            : t('common.button.save')
        }
      </Button>

      {
        withReset &&
        <Button
          onClick={() => {
            resetForm()
            if (onReset) onReset()
          }}
        >
          {t('common.button.reset')}
        </Button>
      }

      {onCancel && <Button onClick={onCancel}>{t('common.button.cancel')}</Button>}
    </ButtonGroup>
  )
}

ControlButtons.propTypes = {
  withoutReset: PropTypes.bool,
  onCancel: PropTypes.func,
  onReset: PropTypes.func
}

export default ControlButtons
