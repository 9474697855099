import React from 'react'
import ReactTable from 'react-table'

import { LoadingOverlay } from '../../common/helpers/index'

// eslint-disable-next-line react/prop-types
const LoadingComponent = ({ loading }) => (loading ? <LoadingOverlay /> : null)

const defaultOptions = {
  LoadingComponent: LoadingComponent,
  minRows: 1,
  pageSizeOptions: [5, 10, 25, 50],
  defaultPageSize: 25
}

const Datatable = (props) => {
  return (
    <ReactTable
      {...defaultOptions}
      {...props}
    />
  )
}

export default Datatable
