import React from 'react'

import { t } from 'web/locale'
import { RowLink } from 'web/app/common/datatable'
import UserLoginLink from './UserLoginLink'

const UserColumns = ({ canPretendAll, handleLinkClick }) => {
  return ([
    {
      Header: t('attributes.user.id'),
      accessor: 'id',
      maxWidth: 50
    },
    {
      Header: t('attributes.user.name'),
      id:     'firstName',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { _original } }) => <span>{_original.firstName} {_original.lastName}</span>
    },
    { Header: t('attributes.user.email'), accessor: 'email' },
    { Header: t('attributes.user.organisation'), accessor: 'organisation.name' },
    { Header: t('attributes.user.role'), accessor: 'role' },
    {
      id: 'buttons',
      minWidth: 30,
      sortable: false,
      Cell: ({ row: { _original } }) => { // eslint-disable-line react/prop-types
        return (
          <span>
            <RowLink type="show" url={`/users/${_original.id}`} />
            <RowLink type="edit" url={`/users/${_original.id}/edit`} />
            <UserLoginLink
              id={_original.id}
              canPretend={canPretendAll}
              handleClick={handleLinkClick}
            />
          </span>
        )
      }
    }
  ])
}

export default UserColumns
