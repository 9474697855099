import { get, post, apiPath } from 'web/utility/http'
import Notify from 'web/utility/Notify'

const fetchContacts = (id) => ({ page, pageSize, sorting }) =>
  get(apiPath(`/contacts`), {
    params: {
      query: '',
      companyId: id,
      page,
      pageSize,
      sorting
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

const fetchCreditScores = (id) => ({ page, pageSize, sorting }) =>
  get(apiPath(`/companies/${id}/credit_scores`), {
    params: {
      query: '',
      page,
      pageSize,
      sorting
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

const fetchMeetings = (id) => ({ page, pageSize, sorting }) =>
  get(apiPath(`/companies/${id}/meetings`), {
    params: {
      page,
      pageSize,
      sorting
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

const fetchCalls = (id) => ({ page, pageSize, sorting }) =>
  get(apiPath(`/companies/${id}/calls`), {
    params: {
      page,
      pageSize,
      sorting
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

const postCalls = ({ id, call, meeting, callback }) =>
  post(apiPath(`/companies/${id}/calls`), { call, meeting, callback })

const fetchCallbacks = (id) => ({ page, pageSize, sorting }) =>
  get(apiPath(`/companies/${id}/callbacks`), {
    params: {
      page,
      pageSize,
      sorting
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

const postCall = ({ leadListId, companyId, call, meeting, callback }) =>
  post(
    apiPath(`/lead_lists/${leadListId}/companies/${companyId}/calls`),
    { call, meeting, callback }
  )

const postUnlockLeadList = () =>
  post(
    apiPath('/lead_lists/unlock')
  )

const fetchOffers = (id) => ({ page, pageSize, sorting }) =>
  get(apiPath(`/companies/${id}/offers`), {
    params: {
      page,
      pageSize,
      sorting
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

const fetchContracts = (id) => ({ page, pageSize, sorting }) =>
  get(apiPath(`/companies/${id}/contracts`), {
    params: {
      page,
      pageSize,
      sorting
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

export {
  fetchContacts,
  fetchCreditScores,
  fetchMeetings,
  fetchCalls,
  postCalls,
  fetchCallbacks,
  postUnlockLeadList,
  fetchOffers,
  fetchContracts,
  postCall
}
