import React from 'react'
import PropTypes from 'prop-types'
import { Route, Link } from 'react-router-dom'
import cn from 'classnames'

const TabLink = ({ label, to, exact }) => (
  <Route
    path={to}
    exact={exact}
    children={({ match }) => {
      return (
        <li className={cn({ 'is-active': match })}>
          <Link to={to}>{label}</Link>
        </li>
      )
    }}
  />
)

TabLink.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool
}

TabLink.defaultProps = {
  exact: false
}

export default TabLink
