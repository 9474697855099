import React from 'react'
import PropTypes from 'prop-types'

import { Datatable } from 'web/app/common/datatable'
import OkIcon        from './OkIcon'
import CancelIcon    from './CancelIcon'
import { t }         from 'web/locale'

const generateColumns = (durations, createFilter, deleteFilter) =>
  durations.map((duration) => ({
    Header:   t(`common.duration.${duration}`),
    accessor: `${duration}`,
    sortable: false,
    style:    { textAlign: 'center' },
    Cell:   ({ value: id }) => { // eslint-disable-line react/prop-types
      if (id) {
        return <OkIcon func={deleteFilter} value={id} type="duration" />
      } else {
        return <CancelIcon func={createFilter} value={{ duration }} type="duration" />
      }
    }
  }))

const prepareData = (data) =>
  [
    data.reduce((acc, filter) => {
      if (filter) acc[filter.duration] = filter.id
      return acc
    }, {})
  ]

const DurationFilters = ({ createFilter, data, deleteFilter, durations, isLoading }) =>
  <Datatable
    columns={generateColumns(durations, createFilter, deleteFilter)}
    data={prepareData(data)}
    loading={isLoading}
    showPagination={false}
  />

DurationFilters.propTypes = {
  createFilter: PropTypes.func.isRequired,
  data:         PropTypes.array.isRequired,
  deleteFilter: PropTypes.func.isRequired,
  durations:    PropTypes.array.isRequired,
  isLoading:    PropTypes.bool.isRequired
}

export default DurationFilters
