import React from 'react'
import PropTypes from 'prop-types'
import { FormRow } from 'web/app/common/layout'
import { NativeSelect } from 'web/app/common/form-elements'

const TableFilter = ({ tableOptions, tableParams }) => {
  const { onChange } = tableOptions

  return (
    <div>
      <FormRow columns={4}>
        <NativeSelect
          value={tableParams.product}
          options={tableOptions.products}
          onChange={onChange('product')}
        />
        <NativeSelect
          value={tableParams.priceModel}
          options={tableOptions.priceModels}
          onChange={onChange('priceModel')}
        />
        <NativeSelect
          value={tableParams.meterId}
          options={tableOptions.meters}
          onChange={onChange('meterId')}
          pretty={false}
        />
        <NativeSelect
          value={tableParams.duration}
          options={tableOptions.durations}
          onChange={onChange('duration')}
          pretty={false}
        />
      </FormRow>
    </div>
  )
}

TableFilter.propTypes = {
  tableParams: PropTypes.shape({
    product:    PropTypes.string.isRequired,
    priceModel: PropTypes.string.isRequired,
    meterId:    PropTypes.number.isRequired,
    duration:   PropTypes.number.isRequired
  }).isRequired,
  tableOptions: PropTypes.shape({
    durations:   PropTypes.array.isRequired,
    meters:      PropTypes.array.isRequired,
    onChange:    PropTypes.func.isRequired,
    priceModels: PropTypes.array.isRequired,
    products:    PropTypes.array.isRequired
  }).isRequired
}

export default TableFilter
