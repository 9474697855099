import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { toId } from 'web/utility/helpers'

const Button = ({
  type, kind, children, disabled, onClick, inForm, isWide, isLoading, isOutlined, className
}) => {
  const id = toId(children)
  const finalClassNames = cn('button', className, {
    [`is-${kind}`]:   kind,
    [`is-in-form`]:   inForm,
    [`is-fullwidth`]: isWide,
    [`is-loading`]:   isLoading,
    [`is-outlined`]:  isOutlined
  })

  return (
    <button type={type} id={id} className={finalClassNames} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  )
}

Button.propTypes = {
  children:   PropTypes.any.isRequired,
  className:  PropTypes.string,
  disabled:   PropTypes.bool.isRequired,
  inForm:     PropTypes.bool,
  isLoading:  PropTypes.bool,
  isOutlined: PropTypes.bool,
  isWide:     PropTypes.bool,
  kind:       PropTypes.string,
  onClick:    PropTypes.func,
  type:       PropTypes.string.isRequired
}

Button.defaultProps = {
  disabled:   false,
  inForm:     false,
  isLoading:  false,
  isOutlined: false,
  isWide:     false,
  type:       'button'
}

export default Button
