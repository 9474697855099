import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { SearchPostIndex } from 'web/app/postIndices/SearchPostIndex'
import { countryEanName }  from 'web/utility/country'
import { PanelBlock }      from 'web/app/common/panel'
import { FormRow }         from 'web/app/common/layout'
import { Button }          from 'web/app/common/elements'
import { t }               from 'web/locale'
import {
  InputWithLabel as Input,
  SelectWithLabel as Select
} from 'web/app/common/forms'

export default class Ean extends Component {
  static propTypes = {
    initialPostIndex: PropTypes.object,
    isIdemLoading:    PropTypes.bool.isRequired,
    isSearching:      PropTypes.bool.isRequired,
    disabled:         PropTypes.bool.isRequired,
    tariffGroups:     PropTypes.array.isRequired,
    product:          PropTypes.string.isRequired,
    searchEan:        PropTypes.func.isRequired,
    searchIdem:       PropTypes.func.isRequired,
    onChangeMeter:    PropTypes.func.isRequired
  }

  renderSearch (searchEan, isSearching, disabled) {
    return (
      <Button onClick={searchEan} isLoading={isSearching} disabled={disabled}>
        {t('views.eans.new.search')}
      </Button>
    )
  }

  renderIdem (searchIdem, isIdemLoading, disabled) {
    return (
      <Button onClick={searchIdem} isLoading={isIdemLoading} disabled={disabled}>
        {t('views.eans.new.idem')}
      </Button>
    )
  }

  metersByProduct (tariffGroups, product) {
    let groupsByProduct = tariffGroups.filter((group) => group.product === product)
    let groupMeters = groupsByProduct.flatMap((group) => group.meters)
    let meters = [...new Map(groupMeters.map((item) => [item['id'], item])).values()]

    return (meters)
  }

  render () {
    const {
      searchEan, searchIdem,
      isSearching, isIdemLoading,
      initialPostIndex, disabled,
      tariffGroups, product,
      onChangeMeter
    } = this.props

    return (
      <PanelBlock>
        <h5 className="title is-5">{countryEanName()}</h5>

        <FormRow>
          <Input
            field="ean.number"
            button={this.renderSearch(searchEan, isSearching, disabled)}
            disabled={disabled}
            required
          />
        </FormRow>

        <FormRow>
          <Input field="ean.meterNumber" disabled={disabled} required forceUpdate />
          <Select
            field="ean.meterId"
            data={this.metersByProduct(tariffGroups, product)}
            textField="name"
            valueField="id"
            disabled={disabled}
            required
            onChange={onChangeMeter}
          />
        </FormRow>

        <FormRow>
          <Input
            field="ean.address"
            button={this.renderIdem(searchIdem, isIdemLoading, disabled)}
            disabled={disabled}
            required
          />
          <SearchPostIndex
            field="ean.postIndexId"
            initialData={initialPostIndex}
            disabled={disabled}
            required
          />
        </FormRow>
      </PanelBlock>
    )
  }
}
