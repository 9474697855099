import {
  difference,
  fromPairs,
  isEmpty,
  isNil,
  pick,
  sortBy
} from 'lodash'

import * as prep from 'web/utility/prepareData'
import { moment } from 'web/locale'

export const filterCities = (postIndices) =>
  (postalCode) =>
    postIndices
      .filter((pi) => pi.postalCode === postalCode)
      .map((pi) => ({ value: pi.city, text: pi.city }))

export const prepareUnits = (units, product) =>
  units
    .filter((unit) => unit.product === product)
    .sort()

export const prepareTariffGroups = (tariffGroups, product) =>
  tariffGroups
    .filter((group) => group.product === product)
    .sort()

export const prepareTariffGroupsByMeter = (tariffGroups, product, meterId) =>
  tariffGroups
    .filter((group) =>
      (group.product === product) && group.meters.some((meter) => meter.id === meterId)
    )
    .sort()

export const prepareGroupedTariffs = (tariffGroups) =>
  tariffGroups.reduce((acc, group) => {
    acc[group.id] = sortBy(group.tariffs, ['id'])
    return acc
  }, {})

export const prepareData = (store, state) => ({
  canEditEanStatField: store.offer.can(`edit_${state.product}_ean_stat`),
  canSetFlex:          store.offer.can('create_flex_ean_stat'),
  fixedFeePeriods:     prep.prepareFixedFeePeriods(store.fixedFeePeriods),
  postalCodes:         prep.preparePostalCodes(store.postIndices),
  priceModels:         prep.preparePriceModels(store.priceModels),
  reasons:             prep.prepareReasons(store.reasons),
  suppliers:           prep.prepareSuppliers(store.suppliers, { withEmpty: true }),
  units:               prepareUnits(store.units, state.product),
  tariffs:             state.tariffs,
  tariffGroups:        state.tariffGroups,
  availableKva:        store.availableKva,
  meters:              store.meters
})

export const prepareActions = (props) => ({
  filterCities:       filterCities(props.store.postIndices)
})

export const prepareDefaultValues = (state) => {
  const { ean, eanStat } = state

  // BUG: Force price rates temporarily because there are no ability on backend to propose prices
  // without volumes
  // const hasTariffs = !!eanStat.eanTariffs
  // const helper = { priceRate: hasTariffs }
  const helper = { priceRate: true }

  return { ean, eanStat, helper }
}

export const prepareEanStat = (data) => {
  const eanStatKeys = [
    'deliverFrom', 'fixedFee', 'gasTransportPrice', 'greenEnergy',
    'invoicingPeriod', 'priceModel', 'reason', 'solarPanels',
    'tariffGroupId', 'unitId', 'invoicingFrom', 'supplierId'
  ]
  let eanStat = pick(data, eanStatKeys)

  if (eanStat.invoicingFrom) {
    eanStat.invoicingTo = moment(eanStat.invoicingFrom)
      .add(eanStat.invoicingPeriod, 'days')
      .format()
  }

  if (!isNil(data.eanTariffs) && !isEmpty(data.eanTariffs)) {
    const collection = data.eanTariffs.map((eanTariff) => ({
      tariffId: eanTariff.tariffId,
      volume: eanTariff.volume,
      price: eanTariff.price,
      kva: eanTariff.kva
    }))
    eanStat.eanTariffs = sortBy(collection, ['tariffId'])
  }

  if (!isNil(data.fixedFee)) {
    eanStat.fixedFeePeriod = 12
  }

  return eanStat
}

export const prepareStartDate = () => {
  const day = moment().date()
  const addMonths = day > 20 ? 3 : 2
  const startDate = moment()
    .add(addMonths, 'months')
    .startOf('month')

  return startDate
    .add(startDate.utcOffset(), 'minutes')
    .toDate()
}

export const prepareEanStatToSubmit = (eanStat, priceRateSelected) => {
  if (priceRateSelected) return eanStat

  const priceRateAttributes = [
    'fixedFee', 'fixedFeePeriod', 'gasTransportPrice', 'priceModel',
    'tariffGroupId', 'unitId', 'eanTariffs'
  ]

  const attrsToKeep  = difference(Object.keys(eanStat), priceRateAttributes)
  const eanStatArray = attrsToKeep.map((attr) => [attr, eanStat[attr]])

  return fromPairs(eanStatArray)
}
