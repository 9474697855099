import React from 'react'
import PropTypes from 'prop-types'

const TextArea = ({ rows, value, name, onChange }) => (
  <textarea rows={rows} className="textarea" value={value} name={name} onChange={onChange} />
)

TextArea.propTypes = {
  rows:     PropTypes.number.isRequired,
  value:    PropTypes.string,
  name:     PropTypes.string,
  onChange: PropTypes.func.isRequired
}

TextArea.defaultProps = {
  rows: 5
}

export default TextArea
