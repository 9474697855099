import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Form } from 'web/app/common/forms'
import { Columns, Column } from 'web/app/common/layout'
import CallsForm from './CallsForm'
import { Modal } from 'web/app/common/modal'
import { Button } from 'web/app/common/elements'
import { t } from 'web/locale'
import { prepareCompanyCall } from 'web/utility/prepareData'

class CallsHeading extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    buttonTitle: PropTypes.string.isRequired,
    companyId: PropTypes.number.isRequired,
    callsStates: PropTypes.array.isRequired,
    callsSubStates: PropTypes.object.isRequired,
    callsContacts: PropTypes.array.isRequired,
    spokeWithOpts: PropTypes.array.isRequired,
    meetingsDurations: PropTypes.object.isRequired,
    sales: PropTypes.array.isRequired,
    callAgents: PropTypes.array.isRequired,
    postCalls: PropTypes.func.isRequired,
    validateCallsForm: PropTypes.func.isRequired,
    afterAddContactRedirectUrl: PropTypes.string.isRequired
  }

  state = {
    contactId: null,
    state: '',
    note: '',
    isOpen: false
  }

  _handleSubmit = (values, { setSubmitting, handleErrors, resetForm }) => {
    const { companyId, postCalls } = this.props
    const { call, callback, meeting } = prepareCompanyCall({ values, companyId })

    postCalls({ id: companyId, call, callback, meeting })
      .then(({ success, data }) => {
        if (success) {
          resetForm()
          this._toogleModal()
          window.location.reload()
        } else {
          handleErrors(data)
          setSubmitting(false)
        }
      })
  }

  _toogleModal = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render () {
    const {
      buttonTitle,
      title,
      callsStates,
      callsSubStates,
      callsContacts,
      spokeWithOpts,
      meetingsDurations,
      sales,
      callAgents,
      companyId,
      validateCallsForm,
      afterAddContactRedirectUrl
    } = this.props
    const { contact, state, note, isOpen } = this.state
    const initialValues = { contact, state, note }
    const modalProps = {
      header: t('views.companies.show.calls.add'),
      isShown: isOpen,
      onClose: this._toogleModal,
      isWide: true
    }
    return (
      <Columns>
        <Column>
          {title}
        </Column>
        <Column>
          <Modal {...modalProps}>
            <Form
              onSubmit={this._handleSubmit}
              initialValues={initialValues}
              validation={validateCallsForm}
            >
              <CallsForm
                callsStates={callsStates}
                callsSubStates={callsSubStates}
                callsContacts={callsContacts}
                spokeWithOpts={spokeWithOpts}
                meetingsDurations={meetingsDurations}
                sales={sales}
                callAgents={callAgents}
                companyId={companyId}
                afterAddContactRedirectUrl={afterAddContactRedirectUrl}
              />
            </Form>
          </Modal>
          <div className="pull-right-table-buttons">
            <Button onClick={this._toogleModal}>
              {buttonTitle}
            </Button>
          </div>
        </Column>
      </Columns>
    )
  }
}

export default CallsHeading
