import React, { Component } from 'react'

import { t } from '../../../locale'
import { fetchData } from './actions'
import { IndexDatatable, RowLink } from '../../common/datatable'

const can = (row, action) =>
  row.permissions.includes(action)

const columns = [
  {
    Header: t('attributes.organisation.id'),
    id: 'organisations.id',
    accessor: 'id',
    minWidth: 55
  },
  { Header: t('attributes.organisation.name'), id: 'organisations.name', accessor: 'name' },
  {
    Header: t('attributes.organisation.admin'),
    id: 'admin.firstName',
    accessor: ({ admin: { fullName } = {} }) => fullName || t('views.organisations.index.noAdmin')
  },
  {
    id:       'buttons',
    minWidth: 25,
    sortable: false,
    Cell: ({ row: { _original } }) => { // eslint-disable-line react/prop-types
      return (
        <span>
          {
            can(_original, 'read') &&
              <RowLink type="show" url={`/organisations/${_original.id}`} />
          }
          {
            can(_original, 'update') &&
              <RowLink type="edit" url={`/organisations/${_original.id}/edit`} />
          }
        </span>
      )
    }
  }
]

export default class OrganisationsTable extends Component {
  render () {
    return (
      <IndexDatatable
        withSearch
        columns={columns}
        fetchData={fetchData}
        sorted={[{ id: 'organisations.id', desc: false }]}
      />
    )
  }
}
