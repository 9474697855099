import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { Button } from 'web/app/common/elements'
import { Columns, Column } from 'web/app/common/layout'
import { t } from 'web/locale'
import { Signature } from 'web/app/common/signature'

const SignContractForm = ({
  canSubmit,
  isStampShown,
  isDirectDebitShown,
  onSignatureChange,
  onParaphSignatureChange,
  onStampChange,
  onDirectDebitSignature,
  onSubmit,
  stampFilename
}) => {
  const fileInputEl = useRef(null)

  return (
    <div style={{ marginTop: '4rem' }}>
      {
        isStampShown &&
        <Columns>
          <Column classNames="is-4 is-offset-3">
            <Button onClick={() => fileInputEl.current.click()} isWide>
              {t('attributes.contract.stampFile')}
            </Button>
            <input
              ref={fileInputEl}
              type="file"
              style={{ display: 'none' }}
              onChange={onStampChange}
            />
          </Column>

          <Column>
            <p style={{ marginTop: '6px', fontStyle: 'italic' }}>
              {stampFilename}
            </p>
          </Column>
        </Columns>
      }

      <Columns>
        <Column classNames="is-4 is-offset-3 signature-field">
          <p>{t('views.offers.contractSign.signature')} *</p>
          <Signature onChange={onSignatureChange} />
        </Column>

        <Column classNames="is-2 paraph-field">
          <p>{t('views.offers.contractSign.paraph')} *</p>
          <Signature onChange={onParaphSignatureChange} />
        </Column>
      </Columns>

      {
        isDirectDebitShown && <Columns>
          <Column classNames="is-6 is-offset-3 signature-field">
            <p>{t('views.companies.offers.show.directDebitWarning')} *</p>
            <Signature onChange={onDirectDebitSignature} />
          </Column>
        </Columns>
      }

      <Columns>
        <Column classNames="is-6 is-offset-3">
          <Button
            type="submit"
            kind="primary"
            isWide
            disabled={!canSubmit}
            onClick={onSubmit}
          >
            {t('views.offers.contractSign.submit')}
          </Button>
        </Column>
      </Columns>
    </div>
  )
}

SignContractForm.propTypes = {
  canSubmit: PropTypes.bool.isRequired,
  isStampShown: PropTypes.bool.isRequired,
  isDirectDebitShown: PropTypes.bool.isRequired,
  onSignatureChange: PropTypes.func.isRequired,
  onParaphSignatureChange: PropTypes.func.isRequired,
  onDirectDebitSignature: PropTypes.func.isRequired,
  onStampChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  stampFilename: PropTypes.string
}

export default SignContractForm
