import React from 'react'

import { shouldApplyKVA } from 'web/utility/country'
import { date } from 'web/locale'

const prepareColumns = (product) => {
  let columns = [
    {
      Header: 'Delivery',
      columns: [{
        Header: 'From',
        id: 'from',
        accessor: (obj) => date(obj.delivery.begin)
      }, {
        Header: 'To',
        id: 'to',
        accessor: (obj) => date(obj.delivery.end)
      }]
    }, {
      columns: [{
        Header: 'Type',
        accessor: 'productType.name'
      }, {
        Header: 'Tariff (Group)',
        accessor: 'tariff.name',
        Cell: ({ value, row: { _original } }) => // eslint-disable-line react/prop-types
          <span>{value} ({_original.tariff.tariffGroup.name})</span>
      }, {
        Header: 'Price',
        accessor: 'tariffPrice'
      }]
    }
  ]

  if (shouldApplyKVA(product)) {
    columns.unshift(
      {
        Header: 'KVA',
        columns: [{
          Header: 'From',
          accessor: 'kva.begin'
        }, {
          Header: 'To',
          accessor: 'kva.end'
        }]
      }
    )
  } else {
    columns.unshift(
      {
        Header: 'Volume',
        columns: [{
          Header: 'From',
          accessor: 'volume.begin'
        }, {
          Header: 'To',
          accessor: 'volume.end'
        }]
      }
    )
  }

  return columns
}

export default prepareColumns
