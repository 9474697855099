import React from 'react'
import ReactDOM from 'react-dom'
import domready from 'domready'

class ReactAutoMount {
  constructor () {
    this.components = {}
  }

  setup () {
    domready(() => this.mountComponents())
  }

  exposeGlobal (globalName = 'ReactAutoMount') {
    window[globalName] = this
  }

  removeGlobal (globalName = 'ReactAutoMount') {
    delete window[globalName]
  }

  registerComponents (components) {
    Object.keys(components).forEach((name) => {
      this.components[name] = components[name]
    })
  }

  findComponentNodes () {
    return document.querySelectorAll('[data-component]')
  }

  mountComponents () {
    const nodes = this.findComponentNodes()
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      const { component, props } = node.dataset
      const reactComponent = this.components[component]
      const reactProps = props && JSON.parse(props)

      if (!reactComponent) {
        throw new Error(`React component with name ${component} is not registered`)
      }

      const element = React.createElement(reactComponent, reactProps)
      ReactDOM.render(element, node)
    }
  }

  unmountComponents () {
    const nodes = this.findComponentNodes()
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]

      ReactDOM.unmountComponentAtNode(node)
    }
  }
}

export default new ReactAutoMount()
