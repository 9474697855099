import buildValidation from 'web/utility/validations'

const validation = (values) => {
  const { filled, number } = buildValidation(values)

  return {
    validFrom:         filled('validFrom'),
    electricityUnitId: number('electricityUnitId'),
    gasUnitId:         number('gasUnitId'),
    priceFile: {
      file: filled('priceFile.file')
    }
  }
}

export default validation
