import React, { Component } from 'react'
import { fetchData } from './api'
import { IndexDatatable } from '../../common/datatable'
import { dnoColumns } from './components/DnoTableColumns'

export default class DnosTable extends Component {
  render () {
    return (
      <IndexDatatable
        columns={dnoColumns}
        fetchData={fetchData}
        onClickPath="/dnos"
        sorted={[{ id: 'dnos.id', desc: false }]}
      />
    )
  }
}
