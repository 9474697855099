import React from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup } from 'web/app/common/elements'
import { camelCase } from 'lodash'
import { t } from 'web/locale'

const CallStates = ({ toogleModal }) => {
  return (
    <ButtonGroup classNames="states">
      <Button onClick={() => toogleModal({ callState: ['callback'] })}>
        {t(`attributes.call.status.${camelCase('callback')}`)}
      </Button>
      <Button onClick={() => toogleModal({ callState: ['no_answer'] })}>
        {t(`attributes.call.status.${camelCase('no_answer')}`)}
      </Button>
      <Button onClick={() => toogleModal({ callState: ['not_interested'] })}>
        {t(`attributes.call.status.${camelCase('not_interested')}`)}
      </Button>
      <Button onClick={() => toogleModal({ callState: ['request_email'] })}>
        {t(`attributes.call.status.${camelCase('request_email')}`)}
      </Button>
      <Button onClick={() => toogleModal({ callState: ['meeting'] })}>
        {t(`attributes.call.status.${camelCase('meeting')}`)}
      </Button>
    </ButtonGroup>
  )
}

CallStates.propTypes = {
  toogleModal: PropTypes.func.isRequired
}

export default CallStates
