import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'

import { i18next, moment } from 'web/locale'

const plugins = [ dayGridPlugin, timeGridPlugin ]

const Calendar = forwardRef(({
  events = [],
  eventSources = undefined,
  eventDataTransform = undefined,
  eventRender = undefined,
  eventClick = undefined
}, ref) => {
  return (
    <FullCalendar
      ref={ref}
      events={events}
      eventSources={eventSources}
      eventDataTransform={eventDataTransform}

      defaultView="timeGridWeek"
      plugins={plugins}
      header={{
        left: 'prev,next today',
        center: 'title',
        right: 'timeGridDay timeGridWeek dayGridMonth'
      }}
      height="auto"
      minTime="07:00:00"
      maxTime="20:00:00"
      slotLabelFormat={{
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      }}

      eventRender={eventRender}
      eventClick={eventClick}
      locale={i18next.language}
      weekNumberCalculation="ISO"
      columnHeaderText={(date) => moment(date).format('DD/MM/YYYY')}
    />
  )
})

Calendar.propTypes = {
  events: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.func
  ]),
  eventSources: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.func
  ]),

  eventDataTransform: PropTypes.func,
  eventRender: PropTypes.func,
  eventClick: PropTypes.func
}

export default Calendar
