import React from 'react'
import PropTypes from 'prop-types'

import UnitTable from './components/UnitTable'
import UnitForm from './components/UnitForm'
import { Panel, PanelBlock } from 'web/app/common/panel'

const SupplierSettings = ({
  onUnitCreate,
  onUnitDelete,
  productTypes,
  tableIsLoading,
  unitsForm,
  unitsTable,
  viewConversion
}) => {
  return (
    <div>
      <Panel>
        <PanelBlock>
          <UnitForm
            onUnitCreate={onUnitCreate}
            productTypes={productTypes}
            units={unitsForm}
            viewConversion={viewConversion}
          />
        </PanelBlock>
      </Panel>

      <Panel>
        <PanelBlock>
          <UnitTable
            data={unitsTable}
            onUnitDelete={onUnitDelete}
            tableIsLoading={tableIsLoading}
            viewConversion={viewConversion}
          />
        </PanelBlock>
      </Panel>
    </div>
  )
}

SupplierSettings.propTypes = {
  onUnitCreate:   PropTypes.func.isRequired,
  onUnitDelete:   PropTypes.func.isRequired,
  productTypes:   PropTypes.array.isRequired,
  tableIsLoading: PropTypes.bool.isRequired,
  unitsForm:      PropTypes.array.isRequired,
  unitsTable:     PropTypes.array.isRequired,
  viewConversion: PropTypes.func.isRequired
}

export default SupplierSettings
