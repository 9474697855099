import { moment } from 'web/locale'

export const formatMeeting = (eventData) => {
  const momentStart = moment(eventData.datetime)
  const postIndex = (eventData.company || {}).postIndex || {}

  return {
    id: eventData.id,
    title: `(${eventData.state}) ${postIndex.city}`,
    start: momentStart.toDate(),
    end: momentStart.add(eventData.duration, 'hours').toDate(),
    extendedProps: eventData
  }
}

export const formatCalendarBlock = (eventData) => {
  const start = moment(eventData.startAt)
  const end = moment(eventData.endAt)
  let event = {
    id: eventData.id,
    title: eventData.note,
    start: start.toDate(),
    end: end.toDate(),
    extendedProps: eventData,
    rendering: 'background'
  }
  if (eventData.isRecurring) {
    const recurring = {
      startTime: start.format('HH:mm'),
      endTime: end.format('HH:mm'),
      startRecur: start.toDate(),
      daysOfWeek: eventData.days.split('').map((c) => Number(c))
    }
    event = { ...event, ...recurring }
  }
  return event
}
