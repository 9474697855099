import React     from 'react'
import {
  ControlButtons,
  TextAreaWithLabel as TextArea
} from 'web/app/common/forms'
import { t } from 'web/locale'

const CommentForm = () => {
  return (
    <div>
      <TextArea
        label={t('attributes.document.file')}
        placeholder={t(`views.tickets.index.updateState.title`)}
        field="comment"
        withLabel={false}
        required
      />
      <ControlButtons withoutReset />
    </div>
  )
}

export default CommentForm
