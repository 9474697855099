import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isUndefined, includes, merge } from 'lodash'

import Notify from 'web/utility/Notify'
import { t } from 'web/locale'
import { prepareSorting } from 'web/utility/prepareData'
import { ContractsIndex } from './component'
import {
  fetchData,
  updateContractStatus,
  createTicket,
  importEcaContract,
  confirmOffer
} from './api'
import { prepareStatusToSend, prepareStatuses, prepareTicketsUsers } from './helpers'
import { setFormErrors } from 'web/utility/forms'

const transitionsRequiredModal = {
  rework:        [],
  renewed:       ['left'],
  accepted:      ['left'],
  consideration: [],
  verification:  [],
  future:        [],
  left:          [],
  expiring:      ['left']
}

export default class ContractsTableContainer extends Component {
  static propTypes = {
    allowedActions:    PropTypes.array.isRequired,
    stateAggregators:  PropTypes.object.isRequired,
    ticketsCategories: PropTypes.array.isRequired,
    ticketsUsers:      PropTypes.array.isRequired
  }

  state = {
    id:              null,
    companyId:       null,
    ticketSalesId:   null,
    state:           null,
    statuses:        prepareStatuses(this.props),
    currentStatus:   prepareStatuses(this.props)[0],
    isStatusModalOpen: false,
    isTicketModalOpen: false,
    isOfferConfirmationModalOpen: false,
    isEcaUploadModalOpen: false,
    ecaUploadErrors: [],
    offer: {},
    externalOfferConfirmLoading: false,
    confirmationLinks: [],
    tableData: {
      data:     [],
      loading:  true,
      page:     1,
      pages:    1,
      pageSize: 25,
      query:    '',
      sorted:  [{ id: 'id', desc: false }]
    }
  }

  _changeStatus = (status) => {
    return () => {
      this.setState({ currentStatus: status }, this._handleTableChange)
    }
  }

  _isCurrentStatus = (status) => {
    return this.state.currentStatus === status
  }

  _handleTableQueryChange = (query) => {
    const { tableData } = this.state

    this.setState({ tableData: { ...tableData, query } }, this._handleTableChange)
  }

  _handleTableChange = (state = this.state.tableData) => {
    const { stateAggregators }         = this.props
    const { currentStatus, tableData } = this.state
    const { page, pageSize, sorted }   = state
    const { tableData: { query } }     = this.state

    this.setState({ tableData: { ...tableData, page, pageSize, sorted, loading: true } })

    fetchData({
      page: page + 1,
      pageSize,
      query,
      sorting: prepareSorting(sorted),
      states: prepareStatusToSend({ currentStatus, stateAggregators })
    }).then(({ success, response, error }) => {
      const { tableData } = this.state

      if (success) {
        const { data, pages } = response.data

        this.setState({ tableData: { ...tableData, data, pages, loading: false } })
      } else {
        this.setState({ tableData: { ...tableData, loading: false } })
        Notify.apiError(error)
      }
    })
  }

  _onStatusChange = ({ id, currentState }) => {
    return (e) => {
      const state    = e.target.value
      const stateArr = transitionsRequiredModal[currentState]

      if (state === currentState) return

      if (!isUndefined(stateArr) && includes(stateArr, state)) {
        this.setState({ id, state, isStatusModalOpen: true })
      } else {
        this._updateContractStatus({ id, state })
      }
    }
  }

  _onTicketSubmit = ({ ticket, formApi }) => {
    const { companyId } = this.state

    createTicket(merge({ ticket }, { companyId }))
      .then(({ success, data }) => {
        if (success) {
          this._onCloseModal({ modal: 'ticket' })
        } else {
          setFormErrors({ formApi, errors: data })
        }
      })
  }

  _onOfferConfirmation = (offerId) => {
    this.setState({ externalOfferConfirmLoading: true })

    confirmOffer(offerId).then(({ success, data }) => {
      if (success) {
        this.setState({
          externalOfferConfirmLoading: false,
          confirmationLinks: data.links
        })
      } else {
        this.setState({ externalOfferConfirmLoading: false })

        Notify.alert('Error occured during offer confirmation')
      }
    })
  }

  _openOfferConfirmationModal = (offer) => {
    this.setState({ isOfferConfirmationModalOpen: true, offer })
  }

  _onStatusModalSubmit = ({ bcc, note, deliveryStopAt }, formApi) => {
    const { id, state } = this.state

    const callback = () => {
      this._updateContractStatus({ note, bcc, id, state, deliveryStopAt }, formApi)
    }

    this._onCloseModal({ callback, modal: 'status' })
  }

  _onEcaUploadSubmit = (data, { setSubmitting }) => {
    importEcaContract(data).then(({ success, data }) => {
      if (success) {
        Notify.success(t('views.contracts.index.ecaUpload.resultNotification'))
        this._onCloseModal({ modal: 'ecaUpload' })
      } else {
        setSubmitting(false)
        this.setState({ ecaUploadErrors: data })
      }
    })
  }

  _onEcaUploadReset = () => {
    let newState = { ecaUploadErrors: [] }
    this.setState(newState)
  }

  _openTicketModal = ({ row }) => {
    const companyId = row.company.id
    const sales = row.sales[0]
    const ticketSalesId = (sales && sales.id) || null

    const newState = {
      companyId,
      ticketSalesId,
      isTicketModalOpen: true
    }

    this.setState(newState)
  }

  _openEcaUploadModal = () => {
    this.setState({ isEcaUploadModalOpen: true })
  }

  _onCloseModal = ({ callback, modal }) => {
    if (modal === 'status') {
      let newState = { state: null, id: null, isStatusModalOpen: false }

      this.setState(merge(newState), callback)
    } else if (modal === 'ticket') {
      let newState = { id: null, companyId: null, ticketSalesId: null, isTicketModalOpen: false }

      this.setState(merge(newState), callback)
    } else if (modal === 'offerConfirmation') {
      let newState = { offer: {}, isOfferConfirmationModalOpen: false }

      this.setState(merge(newState), callback)
    } else if (modal === 'ecaUpload') {
      let newState = { isEcaUploadModalOpen: false, ecaUploadErrors: [] }

      this.setState(merge(newState), callback)
    }
  }

  _updateContractStatus = (
    { id, state, note, bcc, deliveryStopAt },
    { resetForm, setSubmitting } = { resetForm: () => {}, setSubmitting: () => {} }
  ) => {
    const { tableData } = this.state

    const params = { id, state, note, bcc, deliveryStopAt }

    this.setState({ tableData: { ...tableData, loading: true } })

    updateContractStatus(params).then(({ success, error }) => {
      if (success) {
        this._handleTableChange()
        resetForm()
      } else {
        this.setState({ tableData: { ...tableData, loading: false } })
        Notify.errorAlert(error)
      }
      setSubmitting(false)
    })
  }

  render () {
    const newState = this.state.state
    const { allowedActions, ticketsCategories, ticketsUsers } = this.props

    const {
      statuses,
      tableData,
      currentStatus,
      isStatusModalOpen,
      isTicketModalOpen,
      isOfferConfirmationModalOpen,
      isEcaUploadModalOpen,
      ecaUploadErrors,
      externalOfferConfirmLoading,
      confirmationLinks,
      offer,
      ticketSalesId
    } = this.state

    const actions = {
      onOfferConfirmation:        this._onOfferConfirmation,
      openOfferConfirmationModal: this._openOfferConfirmationModal,
      onTicketSubmit:             this._onTicketSubmit,
      openTicketModal:            this._openTicketModal,
      onEcaUploadSubmit:          this._onEcaUploadSubmit,
      onEcaUploadReset:           this._onEcaUploadReset,
      openEcaUploadModal:         this._openEcaUploadModal,
      onCloseModal:               this._onCloseModal,
      changeStatus:               this._changeStatus,
      onStatusChange:             this._onStatusChange,
      isCurrentStatus:            this._isCurrentStatus,
      handleTableChange:          this._handleTableChange,
      handleTableQueryChange:     this._handleTableQueryChange,
      onStatusModalSubmit:        this._onStatusModalSubmit
    }

    return (
      <ContractsIndex
        isStatusModalOpen={isStatusModalOpen}
        isTicketModalOpen={isTicketModalOpen}
        isOfferConfirmationModalOpen={isOfferConfirmationModalOpen}
        isEcaUploadModalOpen={isEcaUploadModalOpen}
        actions={actions}
        newState={newState}
        statuses={statuses}
        status={currentStatus}
        tableData={tableData}
        allowedActions={allowedActions}
        ticketsCategories={ticketsCategories}
        ticketsUsers={prepareTicketsUsers({ ticketsUsers })}
        ticketSalesId={ticketSalesId}
        offer={offer}
        externalOfferConfirmLoading={externalOfferConfirmLoading}
        confirmationLinks={confirmationLinks}
        ecaUploadErrors={ecaUploadErrors}
      />
    )
  }
}
