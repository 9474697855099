import { get, apiPath, post, patch } from 'web/utility/http'
import Notify from 'web/utility/Notify'

const fetchCalls = (id) => ({ page, pageSize, sorting }) =>
  get(apiPath(`/companies/${id}/calls`), {
    params: {
      page,
      pageSize,
      sorting
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

const fetchCallbacks = (id) => ({ page, pageSize, sorting }) =>
  get(apiPath(`/companies/${id}/callbacks`), {
    params: {
      page,
      pageSize,
      sorting
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

const fetchMeetings = (id) => ({ page, pageSize, sorting }) =>
  get(apiPath(`/companies/${id}/meetings`), {
    params: {
      page,
      pageSize,
      sorting
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

const fetchTickets = (id) => ({ page, pageSize, sorting }) =>
  get(apiPath(`/companies/${id}/tickets`), {
    params: {
      page,
      pageSize,
      sorting
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

const postCalls = ({ id, call, meeting, callback }) =>
  post(apiPath(`/companies/${id}/calls`), { call, meeting, callback })

const postTickets = ({ id, ticket }) =>
  post(apiPath(`/companies/${id}/tickets`), { ticket })

const updateTicketState = ({ id, values }) =>
  patch(apiPath(`/tickets/${id}/update_state`), {
    ticket: { state: values.state },
    comment: values.comment
  })

const updateTicket = ({ id, ticket }) =>
  patch(apiPath(`/tickets/${id}`), { ticket })

export {
  fetchCalls,
  postCalls,
  fetchCallbacks,
  fetchMeetings,
  fetchTickets,
  postTickets,
  updateTicketState,
  updateTicket
}
