import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import { findKey, isNull } from 'lodash'

import Notify           from 'web/utility/Notify'
import signImageFr      from 'web/../images/sign-notification-fr.png'
import signImageNl      from 'web/../images/sign-notification-nl.png'
import { signContract } from '../api'
import SignContract     from './component'
import { isCountry }    from 'web/utility/country'
import validate         from './validation'

const prepareModalPath = ({ name: language }) => {
  switch (language) {
    case 'nl':
      return signImageNl
    case 'fr':
      return signImageFr
    default:
      return signImageNl
  }
}

@inject('store')
@observer
class SignContractContainer extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired
  }

  state = {
    index:                 0,
    number:                0,
    total:                 0,
    advModalClosable:      false,
    advModalShown:         false,
    isSignatureShown:      false,
    isStampShown:          false,
    sendResignationLetter: true,
    contractSign:          undefined,
    stampFile:             undefined,
    directDebitSign:       undefined,
    paraphSign:            undefined
  }

  _onDocumentLoad = ({ numPages }) => {
    this.setState({ total: numPages, number: 1 })
  }

  _onPageChange = (num) => {
    const { total, number, index } = this.state

    if (total === 0) return

    const isSignatureShown = this.state.isSignatureShown || this.state.total === number + num
    const isStampShown     = isCountry('fr') && isSignatureShown

    this.setState({
      number: number + num,
      index:  index + num,
      isSignatureShown,
      isStampShown
    })
  }

  _handleSignatureChange = (attr) => {
    return (value, isSavable) => {
      const obj = {}
      obj[attr] = value
      if (isSavable) this.setState(obj)
    }
  }

  _onStampUpload = (stampFile) => {
    const reader = new FileReader() // eslint-disable-line
    const self   = this

    reader.onload = (readerEvt) => {
      const binaryString  = readerEvt.target.result
      const base64Encoded = `data:${stampFile.type};base64,${window.btoa(binaryString)}`

      self.setState({ stampFile: base64Encoded })
    }

    reader.readAsBinaryString(stampFile)
  }

  _handleSubmit = () => {
    const { companyId, hex } = this._contract()
    const {
      sendResignationLetter, contractSign, paraphSign, directDebitSign, stampFile
    } = this.state
    const params = {
      companyId, hex, sendResignationLetter, contractSign, directDebitSign, paraphSign, stampFile
    }

    signContract(params)
      .then(({ success, error }) => {
        if (success) {
          isCountry('be') ? this._openAdvModal() : this._redirectToOffer()
        } else {
          Notify.apiError(error)
        }
      })
  }

  _openAdvModal = () => {
    const enableClose = () => {
      this.setState({ advModalClosable: true })
    }

    this.setState(
      { advModalShown: true, advModalClosable: false },
      () => setTimeout(enableClose, 10000)
    )
  }

  _handleModalClose = () => {
    this._redirectToOffer()
  }

  _redirectToOffer = () => {
    const { match: { params } } = this.props
    window.location = `/offers/${params.id}`
  }

  _isSignatureShown = () => {
    const { total, number } = this.state

    return total > 0 && number === total
  }

  _toggleSendResignationLetter = (e) => {
    const sendResignationLetter = e.target.checked
    this.setState({ sendResignationLetter })
  }

  _isSubmitDisabled = () => {
    const { contractSign, paraphSign, sendResignationLetter, stampFile } = this.state
    const paramsToValidate = { contractSign, paraphSign, sendResignationLetter, stampFile }
    const validation = validate(paramsToValidate)

    return !!findKey(validation, (o) => !isNull(o))
  }

  _contract = () => {
    const { match: { params }, store } = this.props
    return store.contractById(params.contractId)
  }

  _directModalShow = () => {
    const { withDirectDebit } = this._contract()
    const { directDebitSign } = this.state

    return !!(withDirectDebit && !directDebitSign)
  }

  render () {
    const { match: { params } }               = this.props
    const { advModalShown, advModalClosable } = this.state
    const contract     = this._contract()
    const advModalPath = prepareModalPath(contract.language)
    const advModal     = { advModalShown, advModalClosable, advModalPath }

    if (contract.cannot('sign')) return <Redirect to={`/offers/${params.id}`} />

    return (
      <SignContract
        onDocumentLoad={this._onDocumentLoad}
        onPageChange={this._onPageChange}
        onSignatureChange={this._handleSignatureChange}
        onStampUpload={this._onStampUpload}
        onSubmit={this._handleSubmit}
        pdfState={this.state}
        isSignatureShown={this.state.isSignatureShown}
        isStampShown={this.state.isStampShown}
        advModal={advModal}
        directModalShown={this._directModalShow()}
        onModalClose={this._handleModalClose}
        filePath={contract.downloadPath}
        toggleSendResignationLetter={this._toggleSendResignationLetter}
        disableSubmit={this._isSubmitDisabled()}
      />
    )
  }
}

export default SignContractContainer
