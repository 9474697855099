const pageMatchPattern = (page) => new RegExp(`/offers/\\d+/${page}$`, 'i')

export const isLinkActive = (page) => {
  const loc = window.location.pathname

  switch (page) {
    case 'contracts':
      return pageMatchPattern('contracts').test(loc)
    case 'parameters':
      return pageMatchPattern('parameters').test(loc)
    case 'proposals':
      return pageMatchPattern('proposals').test(loc)
  }

  return false
}
