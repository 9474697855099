import React, { Component }        from 'react'
import PropTypes                   from 'prop-types'
import Notify                      from 'web/utility/Notify'
import { t }                       from 'web/locale'
import { updateTicketState }       from '../api'
import { validateStateChangeForm }     from '../../validations'
import { ChangeTicketState }       from 'web/app/tickets/ChangeTicketState'

export default class StateChangeModal extends Component {
  constructor (props) {
    super(props)
    this._handleCloseModal = this._handleCloseModal.bind(this)
    this._handleSubmit = this._handleSubmit.bind(this)
  }

  _handleCloseModal () {
    this.setState({}, this.props.handleCloseModal)
  }

  _handleSubmit (values, { setSubmitting, handleErrors, resetForm }) {
    const { targetTicketId } = this.props

    updateTicketState({
      id: targetTicketId,
      values
    }).then(({ success, data }) => {
      if (success) {
        resetForm()
        this._handleCloseModal()
        this.props.handleTableChange()
        Notify.success(t('views.tickets.updateState.flashMessage'))
      } else {
        handleErrors(data)
        setSubmitting(false)
      }
    })
  }

  render () {
    const { isModalVisible, newState } = this.props

    const modalProps = {
      header:  t(`views.tickets.index.updateState.title`),
      isShown: isModalVisible,
      onClose: this._handleCloseModal
    }
    const initialValues = { state: newState }

    return (
      <div>
        <ChangeTicketState
          state={newState}
          modalProps={modalProps}
          handleSubmit={this._handleSubmit}
          validateStateChangeForm={validateStateChangeForm}
          initialValues={initialValues}
        />
      </div>
    )
  }
}

StateChangeModal.propTypes = {
  handleTableChange: PropTypes.func.isRequired,
  handleCloseModal:  PropTypes.func.isRequired,
  isModalVisible:    PropTypes.bool.isRequired,
  targetTicketId:    PropTypes.number,
  newState:          PropTypes.string.isRequired
}
