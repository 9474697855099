import { patch, apiPath, get, post } from 'web/utility/http'

const fetchData = ({ page, pageSize, query, sorting, states }) =>
  get(apiPath('/contracts'), {
    params: {
      page,
      pageSize,
      sorting,
      query,
      states
    }
  })

const updateContractStatus = ({ id, state, deliveryStopAt }) =>
  patch(apiPath(`/contracts/${id}/state`), { contract: { id, state, deliveryStopAt } })

const createTicket = ({ companyId, ticket }) =>
  post(apiPath(`/companies/${companyId}/tickets`), { ticket })

const confirmOffer = (offerId) =>
  post(apiPath(`/offers/${offerId}/confirm`))

const importEcaContract = ({ contractsImportFile, contractsParaphFile, contractsSignatureFile }) =>
  post(apiPath(`/contracts/import_eca`),
    { contractsImportFile, contractsSignatureFile, contractsParaphFile })

export { fetchData, updateContractStatus, createTicket, confirmOffer, importEcaContract }
