import React from 'react'
import PropTypes from 'prop-types'

import { Columns, Column }        from '../layout'
import { Datatable, SearchInput } from './'

const DatatableWithSearch = ({ input, ...props }) => (
  <div>
    <Columns>
      <Column classNames="is-4 is-offset-8">
        <SearchInput {...input} />
      </Column>
    </Columns>
    <Datatable {...props} />
  </div>
)

DatatableWithSearch.propTypes = {
  input: PropTypes.object.isRequired
}

export default DatatableWithSearch
