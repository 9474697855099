import React from 'react'
import PropTypes from 'prop-types'

import { Panel, PanelHead, PanelBlock } from '../../common/panel'
import { t } from '../../../locale'
import { TableWithSearch } from './components'
import { Tabs, Tab } from '../../common/layout'

const renderTab = (isCurrentStatus, changeStatus) =>
  (status) =>
    <Tab isActive={isCurrentStatus(status)} key={status}>
      <a onClick={changeStatus(status)}>{t(`views.eans.index.states.${status}.name`)}</a>
    </Tab>

const EansTable = ({
  actions: {
    handleEanDelete,
    changeStatus,
    handleTableChange,
    isCurrentStatus,
    handleTableQueryChange
  },
  statuses,
  tableData
}) => {
  const tableProps = {
    ...tableData,
    handleEanDelete,
    handleTableQueryChange,
    handleChange: handleTableChange
  }

  return (
    <div>
      <Panel kind="primary">
        <PanelHead>{t('views.eans.index.title')}</PanelHead>
      </Panel>

      <Tabs>
        { statuses.map(renderTab(isCurrentStatus, changeStatus)) }
      </Tabs>

      <Panel>
        <PanelBlock>
          <TableWithSearch {...tableProps} />
        </PanelBlock>
      </Panel>
    </div>
  )
}

EansTable.propTypes = {
  actions: PropTypes.shape({
    handleEanDelete:   PropTypes.func.isRequired,
    changeStatus:      PropTypes.func.isRequired,
    handleTableChange: PropTypes.func.isRequired,
    isCurrentStatus:   PropTypes.func.isRequired
  }),
  statuses:       PropTypes.array.isRequired,
  tableData:      PropTypes.object.isRequired
}

export { EansTable }
