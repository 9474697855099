import React from 'react'
import PropTypes from 'prop-types'

import { Panel, PanelBlock } from 'web/app/common/panel'
import TemplatesForm from './components/TemplatesForm'
import TemplatesTable from './components/TemplatesTable'

const SupplierContractTemplates = ({
  dataOptions,
  downloadTemplatePath,
  filteredProductTypes,
  onTemplateCreate,
  onTemplateDelete,
  tableIsLoading,
  templates
}) => {
  return (
    <div>
      <Panel>
        <PanelBlock>
          <TemplatesForm
            filteredProductTypes={filteredProductTypes}
            dataOptions={dataOptions}
            onTemplateCreate={onTemplateCreate}
          />
        </PanelBlock>
      </Panel>

      <Panel>
        <PanelBlock>
          <TemplatesTable
            data={templates}
            isLoading={tableIsLoading}
            onTemplateDelete={onTemplateDelete}
            downloadTemplatePath={downloadTemplatePath}
          />
        </PanelBlock>
      </Panel>
    </div>
  )
}

SupplierContractTemplates.propTypes = {
  dataOptions:          PropTypes.object.isRequired,
  downloadTemplatePath: PropTypes.func.isRequired,
  filteredProductTypes: PropTypes.func.isRequired,
  onTemplateCreate:     PropTypes.func.isRequired,
  onTemplateDelete:     PropTypes.func.isRequired,
  templates:            PropTypes.array.isRequired,
  tableIsLoading:       PropTypes.bool.isRequired
}

export default SupplierContractTemplates
