import buildValidation from 'web/utility/validations'

const validateMeetingUpdateStateForm = (values = {}) => {
  const { filled } = buildValidation(values)
  return ({
    note: filled('note'),
    newMeeting: {
      datetime: filled('newMeeting.datetime'),
      duration: filled('newMeeting.duration')
    }
  })
}

const validateCallbackUpdateStateForm = (values = {}) => {
  const { filled } = buildValidation(values)
  return ({
    note: filled('note'),
    newCallback: {
      datetime: filled('newCallback.datetime'),
      duration: filled('newCallback.duration')
    }
  })
}

export { validateMeetingUpdateStateForm, validateCallbackUpdateStateForm }
