import React from 'react'
import PropTypes from 'prop-types'

import { prepareDays } from 'web/utility/prepareData'
import { FormRow } from 'web/app/common/layout'
import { validationContractForm } from '../validation'
import {
  ControlButtons,
  Form,
  MultiselectWithLabel as MultiSelect,
  DateTimePickerWithLabel as DateTime
} from 'web/app/common/forms'

const ContractMailerForm = ({
  canUpdate,
  days,
  supplier
}) => {
  return (
    <>
      <FormRow>
        <MultiSelect
          field="supplier.contractsEmails"
          data={supplier.contractsEmails}
          disabled={!canUpdate}
          created
          required
        />

        <MultiSelect
          field="supplier.contractsDays"
          data={prepareDays(days)}
          disabled={!canUpdate}
        />

        <DateTime
          field="supplier.deliverContractsAt"
          calendar={false}
          time
          disabled={!canUpdate}
          required
        />
      </FormRow>
      { canUpdate && <ControlButtons withoutReset /> }
    </>
  )
}

ContractMailerForm.propTypes = {
  canUpdate: PropTypes.bool.isRequired,
  days:      PropTypes.array.isRequired,
  supplier:  PropTypes.object.isRequired
}

export default (props) => {
  // eslint-disable-next-line react/prop-types
  const { onContractFormUpdate, supplier } = props

  return (
    <Form
      initialValues={{ supplier }}
      validation={validationContractForm}
      onSubmit={onContractFormUpdate}
    >
      <ContractMailerForm {...props} />
    </Form>
  )
}
