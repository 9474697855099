import React, { Component } from 'react'
import { Columns, Column } from 'web/app/common/layout'
import {
  MultiselectWithLabel as MultiSelect,
  SelectWithLabel as Select,
  InputWithLabel as Input,
  DateTimePickerWithLabel as DateTime,
  ControlButtons,
  Form
} from 'web/app/common/forms'
import PropTypes from 'prop-types'
import { t } from 'web/locale'
import { ButtonGroup, ButtonLink } from 'web/app/common/elements'

class LeadListSettings extends Component {
  getFormApi = (formApi) => {
    this.formApi = formApi
  }

  handleChangeAssignedUsers = (vals, field) => {
    this.formApi.handleChange(field, vals)
  }

  render () {
    const {
      leadList, handleSubmit, companyLink,
      callAgentsOptions, salesOptions,
      permissions: {
        openList
      }
    } = this.props

    return (
      <Form
        initialValues={{ leadList: leadList }}
        getApi={this.getFormApi}
        onSubmit={handleSubmit}
      >
        <Columns>
          <Column classNames="is-one-quarter">
            <Input
              field="leadList.name"
              label="List name"
              required
            />
          </Column>
        </Columns>
        <Columns>
          <Column classNames="is-one-quarter">
            <Select
              field="leadList.area"
              label="Area"
              data={[]}
              textField="name"
              valueField="id"
              disabled
            />
          </Column>
          <Column classNames="is-one-quarter">
            <MultiSelect
              field="leadList.cities"
              label="City"
              disabled
            />
          </Column>
          <Column classNames="is-one-quarter">
            <MultiSelect
              field="leadList.postalCodes"
              label="ZIP"
              disabled
            />
          </Column>
          <Column classNames="is-one-quarter">
            <MultiSelect
              field="leadList.counties"
              label="County"
              disabled
            />
          </Column>
        </Columns>
        <Columns>
          <Column classNames="is-one-quarter">
            <MultiSelect
              field="leadList.naces"
              label="NACE"
              disabled
            />
          </Column>
          <Column classNames="is-one-quarter">
            <MultiSelect
              field="leadList.categories"
              label="Category"
              disabled
            />
          </Column>
          <Column classNames="is-one-quarter">
            <MultiSelect
              field="leadList.states"
              label="State"
              disabled
            />
          </Column>
          <Column classNames="is-one-quarter">
            <Columns>
              <Column classNames="is-half">
                <DateTime
                  field="leadList.stateFrom"
                  label="State from"
                  required
                  disabled
                />
              </Column>
              <Column classNames="is-half">
                <DateTime
                  field="leadList.stateTill"
                  label="State till"
                  required
                  disabled
                />
              </Column>
            </Columns>
          </Column>
        </Columns>
        <Columns>
          <Column classNames="is-one-quarter">
            <DateTime
              field="leadList.stateChangedAt"
              label="Last call state"
              required
              disabled
            />
          </Column>
        </Columns>
        <Columns>
          <Column classNames="is-one-quarter">
            <Select
              field="leadList.organisation"
              label="Organization"
              data={[]}
              textField="name"
              valueField="id"
              disabled
            />
          </Column>
          <Column classNames="is-one-quarter">
            <MultiSelect
              field="leadList.callAgents"
              label={t('views.leadLists.form.callAgents')}
              textField="fullName"
              valueField="id"
              onChange={(vals) => (this.handleChangeAssignedUsers(vals, 'leadList.callAgents'))}
              data={callAgentsOptions}
            />
          </Column>
          <Column classNames="is-one-quarter">
            <MultiSelect
              field="leadList.sales"
              label={t('views.leadLists.form.sales')}
              textField="fullName"
              valueField="id"
              onChange={(vals) => (this.handleChangeAssignedUsers(vals, 'leadList.sales'))}
              data={salesOptions}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <ButtonGroup>
              <ControlButtons />
              <ButtonLink href="/lead_lists"> { t('views.leadLists.form.toOverview') }</ButtonLink>
              { openList &&
              <ButtonLink href={companyLink}> { t('views.leadLists.form.openList') } </ButtonLink> }
            </ButtonGroup>
          </Column>
        </Columns>
      </Form>
    )
  }
}

LeadListSettings.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  leadList: PropTypes.object.isRequired,
  companyLink: PropTypes.string.isRequired,
  callAgentsOptions: PropTypes.array.isRequired,
  salesOptions: PropTypes.array.isRequired,
  permissions: PropTypes.shape({
    openList: PropTypes.bool.isRequired
  })
}

export default LeadListSettings
