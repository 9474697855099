import { get, post, patch, apiPath } from 'web/utility/http'

export const createCompany = (params) =>
  post(apiPath('/companies'), params)

export const updateCompany = (id, params) =>
  patch(apiPath(`/companies/${id}`), params)

export const searchNaceCodes = ({ query }) =>
  get(apiPath('/nace_codes/search'), {
    params: {
      query: query
    }
  })
