import { observable, computed } from 'mobx'
import { camelCase, upperCase } from 'lodash'

import { t } from 'web/locale'

export default class CompanyPage {
  constructor ({
    company,
    companyKeys,
    callsStates,
    callsSubStates,
    callsContacts,
    spokeWithOpts,
    meetingsDurations,
    sales,
    callAgents,
    ticketCategories,
    companyContracts,
    permissions,
    users
  }) {
    this.company = company
    this.companyKeys = companyKeys
    this.callsStates = callsStates
    this.callsSubStates = callsSubStates
    this.callsContacts = callsContacts
    this.spokeWithOpts = spokeWithOpts
    this.meetingsDurations = meetingsDurations
    this.sales = sales
    this.callAgents = callAgents
    this.ticketCategories = ticketCategories
    this.companyContracts = companyContracts
    this.permissions = permissions
    this.users = users
  }

  @observable company = {}
  @observable companyKeys = {}
  @observable callsStates = []
  @observable callsSubStates = {}
  @observable callsContacts = []
  @observable sales = []
  @observable callAgents = []
  @observable ticketCategories = []
  @observable companyContracts = []
  @observable permissions = {}
  @observable users = []

  @computed
  get generalInfo () {
    const { company, postIndex } = this.companyKeys
    let value

    const companyArray = company.map(
      (column) => {
        switch (column) {
          case 'company_category':
            value = this.company[camelCase(column)]['name']
            break
          case 'uin':
            const country = ServerConfig.country
            const uin = this.company[camelCase(column)]

            value = (country === 'fr') ? `${upperCase(country)}${uin}` : uin
            break
          default:
            value = this.company[camelCase(column)]
            break
        }

        return ({
          key: t(`attributes.company.${camelCase(column)}`),
          value: value
        })
      }
    )

    const postIndexArray = postIndex.map(
      (column) => {
        return ({
          key: t(`attributes.postIndex.${camelCase(column)}`),
          value: this.company.postIndex ? this.company.postIndex[camelCase(column)] : null
        })
      }
    )

    return companyArray.concat(postIndexArray)
  }
}
