import buildValidation from 'web/utility/validations'

const contactCanBeOmit = (state) => {
  const statusesOptionalContact = [
    'no_answer',
    'incorrect_data',
    'not_interested',
    'blacklist',
    'private_invoice',
    'lt_100'
  ]

  return statusesOptionalContact.indexOf(state) > -1
}

const validateCallsForm = (values = {}) => {
  const { filled } = buildValidation(values)

  const validation = {
    state: filled('state'),
    contactId: filled('contactId')
  }

  if (values.state === 'callback') {
    validation.callback = {
      datetime: filled('callback.datetime')
    }
  }

  if (values.state === 'meeting') {
    validation.meeting = {
      datetime: filled('meeting.datetime'),
      duration: filled('meeting.duration'),
      userId: filled('meeting.userId')
    }
  }

  if (values.state === 'not_interested') {
    validation.note = filled('note')

    validation.extra = {
      spokeWith: filled('extra.spokeWith')
    }
  }

  if (values.state === 'lt_100') {
    validation.subState = filled('subState')
  }

  if (contactCanBeOmit(values.state)) {
    delete validation.contactId
  }

  return validation
}

export { validateCallsForm }
