import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { cloneDeep } from 'lodash'

import { t }              from 'web/locale'
import Notify             from 'web/utility/Notify'
import { LoadingOverlay } from 'web/app/common/helpers'
import Proposal           from './component'
import UniversalButtons   from './components/UniversalButtons'
import {
  selectProposal,
  fetchProposals,
  finalizeOffer,
  fetchEanStats,
  sendOfferPdf,
  sendResignationLetter
} from '../api'
import {
  tariffGroupsByProduct,
  eanStatsByProduct,
  defaultProductValues
} from './helpers'

@inject('store')
@observer
class ProposalContainer extends Component {
  static propTypes = {
    store:   PropTypes.object.isRequired,
    history: PropTypes.shape({
      push:    PropTypes.func.isRequired
    }).isRequired
  }

  state = {
    finalizing: false,
    loading:    defaultProductValues(this.props.store.products, false),
    proposals:  defaultProductValues(this.props.store.products, []),
    eanStats:   []
  }

  componentDidMount () {
    const { store: { offer } } = this.props

    // load ean stats only if offer can be finalized
    if (offer.canFinalize) {
      fetchEanStats(offer.id).then(({ success, data }) => {
        if (success) this.setState({ eanStats: data })
      })
    }

    // load proposals only if specific product can be finalized
    if (offer.canFinalizeGas) this._fetchProposals('gas')
    if (offer.canFinalizeElectricity) this._fetchProposals('electricity')
  }

  _isLoading () {
    return Object.values(this.state.loading).includes(true) || this.state.finalizing
  }

  _setLoading (product, state) {
    const { loading } = this.state
    loading[product] = state
    this.setState({ loading })
  }

  _onSendOfferPdf = () => {
    sendOfferPdf(this.props.store.offer.id).then(({ success, data }) => {
      if (success) {
        Notify.success(t('views.offers.show.offerSendNotification'))
      } else {
        Notify.error(data)
      }
    })
  }

  _onSendResignationLetter = () => {
    sendResignationLetter(this.props.store.offer.id).then(({ success }) => {
      if (success) {
        Notify.success(t('views.offers.show.resignationLetterSendNotification'))
      }
    })
  }

  _fetchProposals = (product) => {
    this._setLoading(product, true)
    const { id } = this.props.store.offer

    fetchProposals({ id, product }).then(({ success, data }) => {
      if (success) {
        const { proposals, loading } = this.state

        proposals[product] = data
        loading[product]   = false

        this.setState({ proposals, loading })
      } else {
        Notify.alert(data)
        this._setLoading(product, false)
      }
    })
  }

  _onSelectProposal = ({ id, product }) => {
    const { id: offerId } = this.props.store.offer

    return () => {
      this._setLoading(product, true)

      selectProposal({ offerId, proposalId: id }).then(({ success, data }) => {
        if (success) {
          const { proposals } = this.state
          const productProposals = cloneDeep(proposals[product])
          productProposals.map((p) => {
            if (p.id === id) {
              p.chosen = true
            } else {
              p.chosen = false
            }
          })
          proposals[product] = productProposals
          this.setState({ proposals })
        } else {
          Notify.alert(data)
        }
        this._setLoading(product, false)
      })
    }
  }

  _onFinalizeOffer = (product) => {
    const { id } = this.props.store.offer

    return () => {
      this.setState({ finalizing: true })
      finalizeOffer({ id, product }).then(({ success, data, error }) => {
        if (success) {
          const manualDataPath = `/offers/${id}/contracts/${data.id}/manual_data`
          const offerPath      = `/offers/${id}`
          const path = data.withManualFields ? manualDataPath : offerPath

          window.location = path
        } else {
          this.setState({ finalizing: false })
          Notify.apiError(error)
        }
      })
    }
  }

  _renderProducts = () => {
    const { store: { offer, products, tariffGroups }, history: { push: goTo } } = this.props
    const { proposals, eanStats } = this.state

    return products.map((product) => {
      const productProposals    = proposals[product]
      const productTariffGroups = tariffGroupsByProduct(product, tariffGroups)
      const productEanStats     = eanStatsByProduct(product, eanStats)
      const finalize            = offer.finalize[product]

      if (!finalize) return null

      return (
        <Proposal
          key={product}
          eanStats={productEanStats}
          finalize={finalize}
          goTo={goTo}
          offer={offer}
          product={product}
          proposals={productProposals}
          tariffGroups={productTariffGroups}
          onFinalize={this._onFinalizeOffer}
          onSelectProposal={this._onSelectProposal}
          onSendOfferPdf={this._onSendOfferPdf}
        />
      )
    })
  }

  render () {
    const universal = this.props.store.offer.finalize.universal
    const isLoading = this._isLoading()

    return (
      <div>
        { isLoading && <LoadingOverlay /> }
        { this._renderProducts() }
        <hr />
        <UniversalButtons
          universal={universal}
          onFinalize={this._onFinalizeOffer}
          onSendResignationLetter={this._onSendResignationLetter}
        />
      </div>
    )
  }
}

export default ProposalContainer
