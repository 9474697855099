import { get, apiPath, patch } from 'web/utility/http'
import Notify from '../../../utility/Notify'

const fetchData = ({ page, pageSize, query, status, sorting }) =>
  get(apiPath('/lead_lists'), {
    params: {
      page,
      pageSize,
      query,
      status,
      sorting
    }
  }).then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

const updateLeadListStatus = ({ id, values }) =>
  patch(apiPath(`/lead_lists/${id}/update_status`), {
    leadList: { status: values.status }
  })

export { fetchData, updateLeadListStatus }
