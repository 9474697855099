import React from 'react'
import PropTypes from 'prop-types'

const Label = ({ children, htmlFor, onClick }) => (
  <label htmlFor={htmlFor} className="label" onClick={onClick}>{children}</label>
)

Label.propTypes = {
  children: PropTypes.any,
  htmlFor: PropTypes.string,
  onClick: PropTypes.func
}

Label.defaultProps = {
  htmlFor: ''
}

export default Label
