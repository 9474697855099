import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { withRouter }       from 'react-router-dom'
import {
  isNil,
  isEmpty,
  cloneDeep
} from 'lodash'

import { Form }             from 'web/app/common/forms'
import Notify               from 'web/utility/Notify'
import EanForm              from './component'
import validate             from './validation'
import { daysDiff, t }      from 'web/locale'
import {
  createEanStat,
  fetchEanStat,
  searchIdem,
  searchEan
} from '../api'
import {
  prepareDefaultValues,
  prepareEanStat,
  prepareEanStatToSubmit,
  prepareGroupedTariffs,
  prepareStartDate,
  prepareTariffGroups,
  prepareTariffGroupsByMeter,
  prepareData
} from './helpers'

@withRouter
@inject('store')
@observer
class EanFormContainer extends Component {
  static propTypes = {
    store:       PropTypes.object.isRequired,
    match:       PropTypes.object.isRequired,
    history:     PropTypes.object.isRequired
  }

  constructor (props) {
    super(props)

    const { history, store } = this.props
    const product = history.location.hash.substr(1)
    const groupedTariffs = prepareGroupedTariffs(store.tariffGroups)
    const tariffGroups = prepareTariffGroups(store.tariffGroups, product)

    this.state = {
      currentAction:   '',
      ean:             {},
      eanStat:         {},
      product:         product,
      tariffGroups:    tariffGroups, // tariff groupes filtered by current product
      groupedTariffs:  groupedTariffs, // all tariffs grouped by tariff group id
      tariffGroupId:   null, // currently selected group
      tariffs:         [], // tariffs of the current group
      loadedPostIndex: null,
      eanDisabled:     false,
      isIdemLoading:   false,
      isLoading:       false,
      isSearching:     false
    }
  }

  componentWillMount () {
    !this._isActionEdit()
      ? this.setState({ currentAction: 'new' })
      : this.setState({ currentAction: 'edit', isLoading: true }, () => { this._onEditFormLoad() })
  }

  _onCreateEanStat = (values, { handleErrors, setSubmitting }) => {
    const { id } = this.props.match.params
    const params = { ...values, offer_id: id }

    createEanStat(params)
      .then(({ success, data }) => {
        if (success) {
          this.props.history.push(`/offers/${id}`)
        } else {
          setSubmitting(false)
          handleErrors(data)
        }
      })
  }

  _onEditFormLoad = () => {
    const { store, match: { params } } = this.props
    const { eanId, eanStatId }         = params
    const statsInStore  = store[`${this.state.product}Stats`]
    const dataFromStore =
      statsInStore
        ? statsInStore.find((eanStat) => eanStat.id === parseInt(eanStatId))
        : undefined

    !isEmpty(dataFromStore)
      ? this._setForm({ data: dataFromStore, setEanValue: false })
      : fetchEanStat(eanId, eanStatId).then(({ success, data, error }) => {
        success
          ? this._setForm({ data, setEanValue: false })
          : Notify.apiError(error)
      })
  }

  _onSearchEan = () => {
    const { product }   = this.state
    const eanNumber     = this.formApi.getValue('ean.number')
    const statsInStore  = this.props.store[`${product}Stats`]
    const dataFromStore =
      statsInStore
        ? statsInStore.find((es) => es.ean.number === eanNumber)
        : undefined

    this.setState({ isSearching: true })

    if (!isEmpty(dataFromStore)) {
      this._setForm({ data: dataFromStore, setEanValue: true })
      this._onChangeMeter(dataFromStore.ean.meter)
    } else {
      searchEan(eanNumber, product).then(({ success, data, error }) => {
        if (success) {
          this._setForm({ data, setEanValue: true })
          this._onChangeMeter(data.ean.meter)
        } else {
          Notify.apiError(error)
        }
      }).then(() => this.setState({ isSearching: false }))
    }
  }

  _onSearchIdem = () => {
    const { companyId } = this.props.store.offer
    const dataFromStore = this.props.store.company

    this.setState({ isIdemLoading: true })

    !isEmpty(dataFromStore)
      ? this._setIdem(dataFromStore)
      : searchIdem(companyId).then(({ success, data, error }) => {
        success
          ? this._setIdem(data)
          : Notify.apiError(error)
      }).then(() => this.setState({ isIdemLoading: false }))
  }

  _onTariffGroupChange = ({ id }) => {
    const tariffs = id ? this.state.groupedTariffs[id] : []
    this.setState({ tariffs, tariffGroupId: id }, () => {
      const ids = tariffs.map((t) => ({ tariffId: t.id }))
      this.formApi.handleChange('eanStat.eanTariffs', ids)
    })
  }

  _onChangeMeter = ({ id: meterId }) => (this._filterTariffGroups(meterId))

  _filterTariffGroups = (meterId) => {
    const { store } = this.props
    const { product } = this.state
    const tariffGroups = store.tariffGroups
    const filteredTariffGroups = prepareTariffGroupsByMeter(tariffGroups, product, meterId)

    this.setState({ tariffGroups: filteredTariffGroups })
  }

  _setForm = ({ data, setEanValue }) => {
    if (setEanValue) {
      const { handleChange, handleChanges } = this.formApi
      this.setState(this._eanStatSettings(data), () => {
        if (!isNil(data.fixedFee)) handleChange('helper.priceRate', true)
        handleChanges({ ean: data.ean })
        handleChanges({ eanStat: prepareEanStat(data) })
      })
    } else {
      this._filterTariffGroups(data.ean.meterId)
      this.setState(this._eanStatSettings(data))
    }
  }

  _eanStatSettings = (data) => {
    const { ean, tariffGroupId } = data
    const tariffs = this.state.groupedTariffs[tariffGroupId]

    const settings = {
      ean:             ean,
      eanStat:         prepareEanStat(data),
      loadedPostIndex: ean.postIndex,
      tariffGroupId:   tariffGroupId,
      tariffs:         tariffs,
      eanDisabled:     true,
      isLoading:       false,
      isSearching:     false
    }

    return settings
  }

  _prefillStartDate = () => {
    this.formApi.handleChange('eanStat.deliverFrom', prepareStartDate(), true)
  }

  _setIdem = (data) => {
    const settings = {
      isIdemLoading:   false,
      loadedPostIndex: data.postIndex
    }

    if (data.postIndex) {
      this.setState(settings, () => {
        this.formApi.handleChange('ean.address', data.address, true)
        this.formApi.handleChange('ean.postIndexId', data.postIndex.id, true)
      })
    }
  }

  _prepareToSubmit = (values) => {
    const { invoicingFrom, invoicingTo } = values.eanStat

    values.eanStat.invoicingPeriod = daysDiff(invoicingFrom, invoicingTo)
    values.eanStat = prepareEanStatToSubmit(values.eanStat, values.helper.priceRate)

    return values
  }

  _isActionEdit = () => {
    return !!this.props.match.params.eanStatId
  }

  _initialValues = () => {
    const { ean, eanStat } = this.state
    let values

    if (this._isActionEdit()) {
      values = { ean: cloneDeep(ean), eanStat: cloneDeep(eanStat) }
    } else {
      values = {
        ean:     { product: this.state.product },
        eanStat: { reason: 'switching', priceModel: 'fix' }
      }
    }

    return prepareDefaultValues(values)
  }

  _getFormApi = (formApi) => {
    this.formApi = formApi
  }

  _renderForm = () => {
    const initialValues = this._initialValues()
    const preparedData  = prepareData(this.props.store, this.state)
    const validation = (values) => validate(values, this.state.tariffs)

    const {
      loadedPostIndex,
      currentAction,
      isIdemLoading,
      isSearching,
      eanDisabled,
      product
    } = this.state

    return (
      <Form
        initialValues={initialValues}
        preSubmit={this._prepareToSubmit}
        onSubmit={this._onCreateEanStat}
        getApi={this._getFormApi}
        validation={validation}
      >
        <EanForm
          onSearchEan={this._onSearchEan}
          onSearchIdem={this._onSearchIdem}
          onTariffGroupChange={this._onTariffGroupChange}
          onChangeMeter={this._onChangeMeter}
          isSearching={isSearching}
          isIdemLoading={isIdemLoading}
          loadedPostIndex={loadedPostIndex}
          eanDisabled={eanDisabled}
          actionName={currentAction}
          product={product}
          data={preparedData}
          prefillStartDate={this._prefillStartDate}
        />
      </Form>
    )
  }

  render () {
    const { isLoading } = this.state

    if (this._isActionEdit() && isLoading) {
      return <h2>{t('views.eans.common.formIsLoading')}</h2>
    } else {
      return this._renderForm()
    }
  }
}

export default EanFormContainer
