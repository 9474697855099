import React from 'react'
import PropTypes from 'prop-types'
import Hammer from 'hammerjs'
import { capitalize } from 'lodash'
import { withRouter } from 'react-router-dom'

import { Datatable } from 'web/app/common/datatable'
import { Icon }      from 'web/app/common/elements'
import { t }         from 'web/locale'
import ProposalDetailsTable from './ProposalDetailsTable'

const generateColumns = ({ product, goTo, onSelectProposal, finalize, params }) => [{
  Header:   t('attributes.price.supplier'),
  accessor: 'supplier.name'
}, {
  Header:   t('attributes.price.priceModel'),
  accessor: 'priceModel'
}, {
  Header:   capitalize(t('common.duration._')),
  id: 'duration',
  accessor: ({ months, duration }) => {
    return duration === 0
      ? `${months} ${t('common.month', { count: months })}`
      : `${duration} ${t('common.year', { count: duration })}`
  }
}, {
  Header:   t('views.offers.show.annualSavingsPercent'),
  accessor: 'annualSavingsPercent',
  style:    { textAlign: 'right' }
}, {
  Header:   t('views.offers.show.annualSavings'),
  accessor: 'annualSavings',
  style:    { textAlign: 'right' }
}, {
  Header:   t('views.offers.show.totalSavings'),
  accessor: 'totalSavings',
  style:    { textAlign: 'right' },
  headerStyle: { fontWeight: 600 }
}, {
  accessor: 'chosen',
  sortable: false,
  style:    { textAlign: 'center' },
  Cell: ({ original: { id }, value: chosen }) => { // eslint-disable-line react/prop-types
    if (chosen) {
      return (
        <Icon iconClass="icon-dot-circled" size="" />
      )
    } else {
      if (finalize) {
        return (
          <Icon iconClass="icon-circle-empty" onClick={onSelectProposal({ id, product })} size="" />
        )
      } else {
        return <Icon iconClass="icon-circle-empty" size="" />
      }
    }
  },
  Header: () => {
    const addEvent = (div) => {
      if (!div) return
      const parent = div.parentElement
      if (parent.classList.contains('hammerjs')) return

      parent.classList.add('hammerjs')
      const hammer = new Hammer.Manager(parent)
      hammer.add(new Hammer.Tap({ event: 'openHidden', taps: 2 }))
      hammer.on('openHidden', () => { goTo(`/offers/${params.id}/proposals/${product}`) })
    }

    return <div style={{ cursor: 'default' }} ref={addEvent}>{ 'Select' }</div>
  }
}]

const ProposalTable = ({
  product, tariffGroups, proposals, goTo, onSelectProposal, finalize, match: { params }
}) => {
  return (
    <Datatable
      columns={generateColumns({ product, goTo, onSelectProposal, finalize, params })}
      data={proposals}
      showPagination={false}
      SubComponent={({ original: { margin, meters } }) => { // eslint-disable-line react/prop-types
        return (
          <div style={{ padding: 20 }}>
            <ProposalDetailsTable margin={margin} meters={meters} tariffGroups={tariffGroups} />
          </div>
        )
      }}
    />
  )
}

ProposalTable.propTypes = {
  finalize:         PropTypes.bool.isRequired,
  goTo:             PropTypes.func.isRequired,
  match:            PropTypes.object.isRequired,
  onSelectProposal: PropTypes.func.isRequired,
  product:          PropTypes.string.isRequired,
  proposals:        PropTypes.array.isRequired,
  tariffGroups:     PropTypes.array.isRequired
}

export default withRouter(ProposalTable)
