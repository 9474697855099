import React from 'react'
import PropTypes from 'prop-types'

import { DatatableWithSearch, RowLink } from '../../../common/datatable'
import { Icon } from 'web/app/common/elements'
import { papp } from 'web/utility/helpers'
import { t }    from 'web/locale'

const can = (row, action) =>
  row.permissions.includes(action)

const columns = (handleEanDelete) =>
  [
    {
      Header: t('attributes.ean.id'),
      id: 'eans.id',
      accessor: 'id',
      minWidth: 55
    }, {
      Header: t('attributes.ean.number'),
      id: 'eans.number',
      accessor: 'number',
      minWidth: 180
    }, {
      Header: t('attributes.company._'),
      accessor: 'company.name',
      minWidth: 180
    }, {
      Header: t('attributes.ean.state'),
      id: 'eans.state',
      accessor: 'state',
      minWidth: 100
    }, {
      Header: t('attributes.ean.product'),
      id: 'eans.product',
      accessor: 'product',
      minWidth: 100,
      Cell: ({ value }) => t(`common.product.${value}`)
    }, {
      Header: t('attributes.ean.address'),
      id: 'eans.address',
      accessor: 'address',
      minWidth: 200
    }, {
      Header: t('attributes.postIndex.postalCode'),
      id: 'postIndex.postalCode',
      accessor: 'postIndex.postalCode',
      minWidth: 55
    }, {
      id: 'buttons',
      minWidth: 35,
      sortable: false,
      Cell: ({ row: { _original } }) => // eslint-disable-line react/prop-types
        <span>
          { can(_original, 'update') && <RowLink type="edit" url={`/eans/${_original.id}/edit`} /> }
          {
            can(_original, 'delete') &&
              <Icon iconClass="icon-trash" onClick={papp(handleEanDelete, _original.id)} />
          }
        </span>
    }
  ]

const TableWithSearch = ({
  data,
  handleChange,
  handleEanDelete,
  handleTableQueryChange,
  loading,
  pages,
  pageSize,
  query,
  sorted
}) => {
  return (
    <DatatableWithSearch
      input={{
        query,
        onChange: handleTableQueryChange
      }}
      manual
      columns={columns(handleEanDelete)}
      data={data}
      loading={loading}
      pages={pages}
      pageSize={pageSize}
      onFetchData={handleChange}
      defaultSorted={sorted}
    />
  )
}

TableWithSearch.propTypes = {
  data:                   PropTypes.array,
  handleChange:           PropTypes.func.isRequired,
  handleEanDelete:        PropTypes.func.isRequired,
  loading:                PropTypes.bool.isRequired,
  pages:                  PropTypes.number,
  pageSize:               PropTypes.number.isRequired,
  query:                  PropTypes.string.isRequired,
  sorted:                 PropTypes.array.isRequired,
  handleTableQueryChange: PropTypes.func.isRequired
}

export default TableWithSearch
