import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'web/app/common/elements'

const BooleanRow = ({ value }) => {
  if (!value) {
    return (
      <Icon iconClass="icon-cancel" size="" color="is-danger" />
    )
  } else if (value) {
    return (
      <Icon iconClass="icon-ok" size="" color="is-success" />
    )
  }
}

BooleanRow.propTypes = {
  value: PropTypes.bool.isRequired
}

export default BooleanRow
