import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { difference, isEmpty } from 'lodash'

import { t }      from 'web/locale'
import { Button } from 'web/app/common/elements'
import Notify     from 'web/utility/Notify'

import Parameters                       from './component'
import buildValidation from 'web/utility/validations'
import { generateTable, generateStats } from './helpers'
import {
  copyOffer,
  deleteEanStat as deleteEanStatAction,
  fetchEanStats,
  importEans,
  fetchExternalPrices
} from '../api'
import {
  ExternalPriceRequestModal,
  NAME as EXTERNAL_PRICE_REQUEST_MODAL_NAME
} from './components/ExternalPriceRequestModal'

@inject('store')
@observer
class ParametersContainer extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  state = {
    currentModal: null
  }

  componentDidMount () {
    const { store } = this.props
    const { offer } = store

    fetchEanStats(offer.id).then((response) => {
      if (response.success) {
        store.eanStats = response.data
        store.eanStatsLoaded = true
      }
    })
  }

  _copyOffer = () => {
    const { store: { offer: { id } } } = this.props

    copyOffer({ id }).then(({ success, data, error }) => {
      if (success) {
        window.location = `/offers/${data.id}/edit`
      } else {
        Notify.apiError(error)
      }
    })
  }

  _deleteEanStat = (id) => {
    const { store: { deleteEanStat, offer } } = this.props
    return () => {
      if (confirm(t('views.offers.show.statDeleteConfirm'))) { // eslint-disable-line
        deleteEanStatAction({ eanStatId: id, offerId: offer.id }).then(({ success }) => {
          if (success) deleteEanStat(id)
        })
      }
    }
  }

  _handleButtonClick = (e) => {
    e.preventDefault()
    this.fileInputElement.click()
  }

  _showModalExternalPrices = (e) => {
    e.preventDefault()
    this._toggleModal(EXTERNAL_PRICE_REQUEST_MODAL_NAME)
  }

  _toggleModal = (name) => {
    const { currentModal } = this.state

    currentModal === name
      ? this.setState({ currentModal: null }) : this.setState({ currentModal: name })
  }

  _handleSubmit = (values) => {
    const selectedYears = values.years
    const { store: { offer, externalRequestContractDurations } } = this.props
    const afterSubmitAvailableDurations =
      difference(externalRequestContractDurations, selectedYears)

    this.props.store.updateExternalRequestContractDurations(afterSubmitAvailableDurations)

    fetchExternalPrices({ years: selectedYears, offerId: offer.id }).then((response) => {
      if (response.success) {
        Notify.success(`Request success. pending for years ${selectedYears}`)
      } else {
        Notify.apiError(response)
      }

      this._toggleModal(EXTERNAL_PRICE_REQUEST_MODAL_NAME)
    })
  }

  _validate = (values) => {
    const { filled } = buildValidation(values)

    const validation = {
      years: filled('years')
    }

    return validation
  }

  _importEANs = (e) => {
    const { store: { offer } } = this.props
    let file = e.target.files[0]
    let fileName = file.name
    let reader = new FileReader() // eslint-disable-line no-undef

    reader.readAsDataURL(file)
    reader.onload = (e) => {
      importEans(offer.id, fileName, e.target.result)
        .then(({ data }) => {
          if (data.success) {
            Notify.success(data.message)
            window.location.reload()
          } else {
            Notify.apiError(data)
          }
        })
    }
  }

  render () {
    const { store: { offer, eanStats, units, externalRequestContractDurations } } = this.props

    const electricityStats = eanStats.filter(({ ean }) => ean.product === 'electricity')
    const gasStats         = eanStats.filter(({ ean }) => ean.product === 'gas')

    const baseUnits       = units.filter(({ coefficient }) => coefficient === 1)
    const electricityUnit = baseUnits.find(({ product }) => product === 'electricity')
    const gasUnit         = baseUnits.find(({ product }) => product === 'gas')
    const { currentModal } = this.state

    const props = {
      deleteEanStat: this._deleteEanStat,
      electricity: {
        stats: generateStats(electricityStats, 'electricity', offer.id),
        table: generateTable(electricityStats, offer.id),
        finalize: offer.canFinalizeElectricity,
        unit: electricityUnit.name
      },
      gas: {
        stats: generateStats(gasStats, 'gas', offer.id),
        table: generateTable(gasStats, offer.id),
        finalize: offer.canFinalizeGas,
        unit: gasUnit.name
      }
    }
    const fileInputAttributes = {
      id: 'import-eans',
      className: 'display-none',
      type: 'file',
      onChange: this._importEANs
    }

    const externalRequestModalProps = {
      modalProps: {
        header: t('views.offers.show.requestExternalPrices'),
        isShown: currentModal === EXTERNAL_PRICE_REQUEST_MODAL_NAME,
        onClose: () => this._toggleModal(EXTERNAL_PRICE_REQUEST_MODAL_NAME),
        height: '40%'
      },
      externalRequestContractDurations: externalRequestContractDurations,
      onSubmit: this._handleSubmit,
      validation: this._validate
    }

    return (
      <div>
        <Parameters {...props} />
        <ExternalPriceRequestModal {...externalRequestModalProps} />
        <br />
        <div className="control field is-grouped">
          <div className="control field">
            <Button onClick={this._copyOffer}>Copy offer</Button>
          </div>
          <div className="control field">
            <input {...fileInputAttributes} ref={(input) => (this.fileInputElement = input)} />
            {
              offer.permissions.includes('import_eans') &&
                <Button onClick={this._handleButtonClick}>Import EANs</Button>
            }
          </div>
          <div className="control field">
            {
              offer.canRequestExternalPrices && !isEmpty(externalRequestContractDurations) &&
                <Button onClick={this._showModalExternalPrices}>
                  { t('views.offers.show.requestExternalPrices') }
                </Button>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default ParametersContainer
