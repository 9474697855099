import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject } from 'mobx-react'

import { Form } from 'web/app/common/forms'
import { LoadingOverlay } from 'web/app/common/helpers'
import CreateDnoConsumption from './component'

import Notify from 'web/utility/Notify'
import { validation } from './validation'
import {
  createDnoConsumption,
  fetchConsumptionDataManualFields,
  fetchCompanyContacts,
  fetchCompanySales,
  fetchMeetingDurations
} from '../api'
import {
  prepareFieldsToRender,
  prepareFieldsToSubmit,
  prepareMeetingDurations
} from './helpers'

@inject('store')
class CreateDnoConsumptionContainer extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired
  }

  state = {
    isLoading: true,
    manualFields: [],
    contacts: [],
    durations: [],
    sales: []
  }

  // Handlers
  // ---------------------------------------------------------------
  _fetchCompanyContacts = () => { // eslint-disable-line react/sort-comp
    const { companyId } = this.props.store.offer

    return fetchCompanyContacts(companyId).then(({ success, data, error }) => {
      success ? this.setState({ contacts: data }) : Notify.apiError(error)
    })
  }

  _fetchCompanySales = () => {
    const { companyId } = this.props.store.offer

    return fetchCompanySales(companyId).then(({ success, data, error }) => {
      success ? this.setState({ sales: data }) : Notify.apiError(error)
    })
  }

  _fetchConsumptionDataManualFields = () => {
    return fetchConsumptionDataManualFields().then(({ success, data, error }) => {
      if (success) {
        this.setState({ manualFields: prepareFieldsToRender(data) })
      } else {
        Notify.apiError(error)
      }
    })
  }

  _fetchMeetinDurations = () => {
    return fetchMeetingDurations().then(({ success, data, error }) => {
      success ? this.setState({ durations: prepareMeetingDurations(data) }) : Notify.apiError(error)
    })
  }

  _handleSubmit = (values, { handleErrors, setSubmitting }) => {
    const { id, product } = this.props.match.params
    const params = {
      consumptionData: { product: product },
      manualFields: prepareFieldsToSubmit(values.manualFields)
    }

    if (values.isNewAppointment) params.meeting = values.meeting

    this.setState({ isLoading: true }, () => {
      createDnoConsumption({ id, params }).then(({ success, data }) => {
        if (success) {
          this.props.store.offer.addConsumptionData(data)
          this.props.store.offer.setAbilityRequestExternalPrices()
          this.props.history.push(`/offers/${id}/consumption_data/${data.id}/sign`)
        } else {
          this.setState({ isLoading: false }, () => {
            setSubmitting(false)
            handleErrors(data)
          })
        }
      })
    })
  }

  // React API
  // ---------------------------------------------------------------
  componentDidMount () {
    this.setState({ isLoading: true }, () => {
      this._fetchCompanyContacts()
        .then(this._fetchCompanySales)
        .then(this._fetchConsumptionDataManualFields)
        .then(this._fetchMeetinDurations)
        .finally(() => this.setState({ isLoading: false }))
    })
  }

  // NOTE: we use conditional <Form> rendering because it's memoizes validateError function
  // in order for it to work properly we have to make sure manualFields aren't empty
  render () {
    const {
      isLoading, manualFields, contacts, durations, sales
    } = this.state
    const validate = validation(manualFields)

    return (
      <div>
        { isLoading && <LoadingOverlay /> }
        {
          !isLoading &&
          <Form validation={validate} onSubmit={this._handleSubmit}>
            <CreateDnoConsumption
              manualFields={manualFields}
              contacts={contacts}
              durations={durations}
              sales={sales}
            />
          </Form>
        }
      </div>
    )
  }
}

export default CreateDnoConsumptionContainer
