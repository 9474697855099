import { forEach, isUndefined, isEmpty } from 'lodash'
import buildValidation from 'web/utility/validations'

const isAnyValid = (tariffs) => {
  const invalidTariffs = []

  forEach(tariffs, (tariff) => {
    let invalidKeys = []

    forEach(tariff, (value, key) => {
      if (!isUndefined(value)) invalidKeys.push(key)
    })

    if (!isEmpty(invalidKeys)) invalidTariffs.push(tariff)
  })

  return invalidTariffs.length < tariffs.length
}

const validateTariff = (filled) => ({
  flex: (_tariff, i) => ({
    marketPrice: filled(`tariffs.${i}.marketPrice`),
    factor:      filled(`tariffs.${i}.factor`),
    tariffPrice: filled(`tariffs.${i}.tariffPrice`)
  }),
  fix: (_tariff, i) => ({
    tariffPrice: filled(`tariffs.${i}.tariffPrice`)
  })
})

export default ({ product, priceModel }) => {
  return (values) => {
    const { filled, number } = buildValidation(values)
    const tariffsValidation = values.tariffs.map(validateTariff(filled)[priceModel])

    let validation = {
      mandate: {
        brokerFixedFee:   filled('mandate.brokerFixedFee') || number('mandate.brokerFixedFee'),
        brokerMargin:     filled('mandate.brokerMargin') || number('mandate.brokerMargin'),
        marginLetter:     filled('mandate.marginLetter'),
        marginType:       filled('mandate.marginType'),
        meterId:          filled('mandate.meterId'),
        passive:          filled('mandate.passive') || number('mandate.passive'),
        salesMargin:      filled('mandate.salesMargin') || number('mandate.salesMargin'),
        supplierFixedFee: filled('mandate.supplierFixedFee') || number('mandate.supplierFixedFee'),
        supplierId:       filled('mandate.supplierId'),
        totalMargin:      filled('mandate.totalMargin') || number('mandate.totalMargin'),
        validFrom:        filled('mandate.validFrom'),
        validTo:          filled('mandate.validTo')
      }
    }

    if (product === 'electricity' && priceModel === 'fix') {
      validation.mandate.productTypeId = filled('mandate.productTypeId')
    }

    if (!isAnyValid(tariffsValidation)) {
      validation.tariffs = tariffsValidation
    }

    return validation
  }
}
