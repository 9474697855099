import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { PanelBlock } from 'web/app/common/panel'
import { FormRow } from 'web/app/common/layout'
import { moment } from 'web/locale'

import {
  SelectWithLabel as Select,
  DateTimePickerWithLabel as DateTime,
  InputWithLabel as Input
} from 'web/app/common/forms'

export default class Mandate extends Component {
  static propTypes = {
    marginLetters:   PropTypes.array.isRequired,
    marginTypes:     PropTypes.array.isRequired,
    suppliers:       PropTypes.array.isRequired
  }

  render () {
    const { suppliers, marginTypes, marginLetters } = this.props

    const today    = moment()
    const tomorrow = moment(today).add(1, 'day')

    return (
      <div>
        <PanelBlock>
          <FormRow columns={2}>
            <Select field="mandate.supplierId" data={suppliers} required search sort />
            <Select field="mandate.marginType" data={marginTypes} required />
          </FormRow>
          <FormRow columns={2}>
            <Input field="mandate.brokerMargin" type="number" required />
            <Select field="mandate.marginLetter" data={marginLetters} required />
          </FormRow>
          <FormRow columns={2}>
            <Input field="mandate.totalMargin" type="number" required />
            <Input field="mandate.salesMargin" type="number" required />
          </FormRow>
        </PanelBlock>
        <PanelBlock>
          <FormRow columns={2}>
            <Input field="mandate.passive" type="number" required />
          </FormRow>
        </PanelBlock>
        <PanelBlock>
          <FormRow columns={2}>
            <DateTime field="mandate.validFrom" time={false} min={today.toDate()} required />
            <DateTime field="mandate.validTo" time={false} min={tomorrow.toDate()} required />
          </FormRow>
        </PanelBlock>
      </div>
    )
  }
}
