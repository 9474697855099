import React, { Component }  from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { withRouter, Redirect } from 'react-router-dom'
import { reduce, map } from 'lodash'

import { Form } from 'web/app/common/forms'
import Notify                             from 'web/utility/Notify'
import { LoadingOverlay }                 from 'web/app/common/helpers'
import { loadManualData, saveManualData } from '../api'
import ManualData                         from './component'

const preparedManualData = (data) => {
  return map(data, (field) => {
    field.name = field.name.split('.').join('-')
    return field
  })
}

@withRouter
@inject('store')
@observer
class ManualDataContainer extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props)
    this._onFormSave = this._onFormSave.bind(this)
  }

  state = {
    manualData: [],
    loading: true
  }

  componentDidMount () {
    const { params: { contractId: id } } = this.props.match
    loadManualData({ id }).then(({ success, data: { data } }) => {
      if (success) {
        this.setState({ manualData: preparedManualData(data), loading: false })
      }
    })
  }

  _formDefaultValues () {
    const { manualData } = this.state
    return manualData.reduce((acc, el) => {
      acc[el.name] = el.value || ''
      return acc
    }, {})
  }

  _onFormSave (values, { setSubmitting }) {
    const { params: { id, contractId } } = this.props.match

    const data = reduce(values, (acc, value, name) => {
      const nname = name.split('-').join('.')
      acc.push({ name: nname, value })
      return acc
    }, [])

    this.setState({ loading: true })
    saveManualData({ id: contractId, data }).then(({ success, error }) => {
      if (success) {
        window.location = `/offers/${id}/contracts`
      } else {
        // TOOD: mark the field as invalid
        Notify.apiError(error)
        this.setState({ loading: false })
        setSubmitting(false)
      }
    })
  }

  _contract () {
    const { store, match: { params } } = this.props
    const { contractId } = params
    return store.contractById(contractId)
  }

  render () {
    const contract = this._contract()
    const { match: { params } } = this.props
    const { manualData, loading } = this.state

    if (contract.cannot('update')) return <Redirect to={`/offers/${params.id}`} />

    return (
      <div>
        {loading && <LoadingOverlay />}
        {
          manualData.length > 0 &&
          <Form
            initialValues={this._formDefaultValues()}
            onSubmit={this._onFormSave}
          >
            <ManualData
              manualData={this.state.manualData}
            />
          </Form>
        }
      </div>
    )
  }
}

export default ManualDataContainer
