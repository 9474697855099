import React from 'react'
import PropTypes from 'prop-types'
import { Columns, Column } from 'web/app/common/layout'
import { Button } from 'web/app/common/elements'

const handleClickFactory = (companyId) => {
  return (
    () => window.open(`/companies/${companyId}/credit_scores/new`, '_blank')
  )
}

const CreditScoreHeading = ({ title, buttonTitle, companyId }) => {
  return (
    <Columns>
      <Column>
        { title }
      </Column>
      <Column>
        <div className="pull-right-table-buttons">
          <Button type="submit" onClick={handleClickFactory(companyId)}>
            { buttonTitle }
          </Button>
        </div>
      </Column>
    </Columns>
  )
}

CreditScoreHeading.propTypes = {
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  companyId: PropTypes.number.isRequired
}

export default CreditScoreHeading
