import { get, post, destroy } from 'web/utility/http'
import { pricesPath } from '../../helpers'

const fetchFilters = ({ product, priceModel }) =>
  get(pricesPath('/'), { params: { product, priceModel } })

const createFilter = ({ product, priceModel, type, values }) =>
  post(pricesPath('/'), {
    type,
    product,
    priceModel,
    ...values
  })

const createVolumeFilters = ({ product, priceModel, volume, marginLetters }) =>
  post(pricesPath('/volumes'), {
    marginLetters,
    volume: { product, priceModel, volume }
  })

const deleteFilter = ({ type, id }) =>
  destroy(pricesPath(`/${id}`), { params: { type } })

export { fetchFilters, createFilter, createVolumeFilters, deleteFilter }
