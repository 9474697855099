import React from 'react'
import PropTypes from 'prop-types'
import { chunk } from 'lodash'

import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import { FormRow }                      from 'web/app/common/layout'
import {
  CheckBoxWithLabel as CheckBox,
  InputWithLabel as Input,
  SelectWithLabel as Select
} from 'web/app/common/forms'
import { t } from 'web/locale'

const optionsMap = [
  ['Ja', true],
  ['Off', false]
]

const onCheckboxChange = (options) => {
  const optValues = options.map((o) => o.value)

  return (isChecked, { handleChange }) => {
    const val = optionsMap.find((tuple) => tuple[1] === isChecked && optValues.includes(tuple[0]))
    handleChange((val && val[0]) || isChecked)
  }
}

const renderInput = (field, i) => {
  const { name, label, options } = field
  const formName = `manualFields.${name}`

  if (options && options.length === 2) {
    return (
      <CheckBox
        key={i}
        field={formName}
        label={label}
        onManualChange={onCheckboxChange(options)}
        defaultValue="Off"
        checkedValue="Ja"
        uncheckedValue="Off"
      />
    )
  } else if (options && options.length > 0) {
    return <Select key={i} field={formName} label={label} data={options} required />
  } else {
    return <Input key={i} field={formName} label={label} required />
  }
}

const ManualFieldsForm = ({ manualFields }) => (
  <Panel kind="light">
    <PanelHead>{t('views.offers.show.manualFields')}</PanelHead>
    <PanelBlock>
      <div id="sign-dno-consumption">
        {
          chunk(manualFields, 3).map((chunk, i) =>
            <FormRow key={i}>
              { chunk.map((field, i) => renderInput(field, i)) }
            </FormRow>
          )
        }
      </div>
    </PanelBlock>
  </Panel>
)

ManualFieldsForm.propTypes = {
  manualFields: PropTypes.array.isRequired
}

export default ManualFieldsForm
