import { get, post, toFormData, apiPathWith } from 'web/utility/http'

export const fetchUserFiles = (resolve, { page, pageSize, sorting, query }) =>
  get(apiPathWith('/files'), {
    params: {
      page,
      pageSize,
      sorting,
      query
    }
  }).then(resolve)

export const uploadUserFile = ({ values }) =>
  post(apiPathWith('/files/upload'), toFormData(values))
