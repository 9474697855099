import React from 'react'
import PropTypes from 'prop-types'
import { Columns, Column } from 'web/app/common/layout'
import { Button } from 'web/app/common/elements'

const handleClickFactory = (companyId, afterAddContactRedirectUrl) => {
  const url = `/companies/${companyId}/contacts/new`

  if (afterAddContactRedirectUrl) {
    return () => { window.location.href = url + `?redirect_to=${afterAddContactRedirectUrl}` }
  } else {
    return () => window.open(url, '_blank')
  }
}

const ContactsHeading = ({ title, buttonTitle, companyId, afterAddContactRedirectUrl }) => {
  return (
    <Columns>
      <Column>
        { title }
      </Column>
      <Column>
        <div className="pull-right-table-buttons">
          <Button type="submit" onClick={handleClickFactory(companyId, afterAddContactRedirectUrl)}>
            { buttonTitle }
          </Button>
        </div>
      </Column>
    </Columns>
  )
}

ContactsHeading.propTypes = {
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  companyId: PropTypes.number.isRequired,
  afterAddContactRedirectUrl: PropTypes.string
}

export default ContactsHeading
