import buildValidation from 'web/utility/validations'

export default (values) => {
  const { filled } = buildValidation(values)

  return {
    contractFile: filled('contract.contractFile'),
    signedAt:     filled('contract.signedAt')
  }
}
