import React from 'react'
import PropTypes from 'prop-types'

import { Datatable } from 'web/app/common/datatable'
import { Icon }      from 'web/app/common/elements'
import { t }         from 'web/locale'

const headers = {
  priceModel: { Header: t('common.priceModel._'), accessor: 'priceModel' },
  meter:      { Header: t('attributes.meter._'), accessor: 'meter.name' },
  duration:   { Header: t('common.duration._'), accessor: 'duration' },
  tariff: {
    Header: t('attributes.tariff._'),
    id: 'tariff',
    accessor: (obj) => `${obj.tariff.name} (${obj.tariffGroup.name})`
  },
  volume: {
    Header: t('common.volume._'),
    id: 'volume',
    accessor: (obj) => {
      if (obj.range) return `${obj.range.begin || 0} - ${obj.range.end || ''}`
    }
  }
}

const columns = (type, deleteAddon) => {
  return [
    { Header: t('common.product._'), accessor: 'product' },
    { ...headers[type] },
    { Header: t('attributes.addon._'), accessor: 'addon' },
    {
      Header: t('common.button.delete'),
      className: 'has-text-centered',
      sortable: false,
      Cell: ({ row }) => { // eslint-disable-line
        const { _original } = row

        return (
          <Icon iconClass="icon-trash" onClick={() => deleteAddon(_original.id)} />
        )
      }
    }
  ]
}

const AddonsTable = ({ type, data, onAddonDelete, isLoading }) => {
  return (
    <Datatable
      loading={isLoading}
      columns={columns(type, onAddonDelete)}
      data={data}
      showPagination={false}
    />
  )
}

AddonsTable.propTypes = {
  data:          PropTypes.array.isRequired,
  isLoading:     PropTypes.bool.isRequired,
  onAddonDelete: PropTypes.func.isRequired,
  type:          PropTypes.string.isRequired
}

export default AddonsTable
