import { compact } from 'lodash'

import { t } from 'web/locale'
import { currentPath } from 'web/utility/http'
import { prepareTitles, prepareLanguages } from 'web/utility/prepareData'

const prepareData = (props) => ({
  contacts:       prepareContacts(props.action, props.contacts),
  jobTitles:      prepareJobTitles(),
  kinds:          prepareKinds(),
  languages:      prepareLanguages(props.languages),
  offerLanguages: prepareLanguages(props.offerLanguages),
  title:          prepareTitle(props.action),
  titles:         prepareTitles()
})

const contactName = (contact) => {
  const names = compact([contact.firstName, contact.lastName])

  if (names.length > 0) {
    return names.join(' ')
  } else {
    return t('common.noName')
  }
}

const prepareContacts = (action, contacts) => {
  let array = contacts.map((contact) => ({
    text:  contactName(contact),
    value: contact.id
  }))

  if (action === 'new') {
    array.unshift({ text: 'Select a contact', value: '' })
  }

  return array
}

const prepareKinds = () => {
  const translations = t(`views.contacts.form.type`, { returnObjects: true })

  return Object.values(translations).map((title) => ({ text: title, value: title }))
}

const prepareJobTitles = () => {
  const translations = t(`views.contacts.form.jobTitle`, { returnObjects: true })

  return Object.values(translations).map((title) => ({ text: title, value: title }))
}

const prepareTitle = (action) => t(`views.offers.${action}.title`)

const prepareBackPath = () => currentPath().split('/').slice(0, 3).join('/')

export { prepareData, prepareBackPath }
