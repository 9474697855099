import React from 'react'
import PropTypes from 'prop-types'

import TableRow from './TableRow'
import TableCellHeader from './TableCellHeader'

const TableHeader = ({ children }) => (
  <thead>
    <TableRow>
      {children}
    </TableRow>
  </thead>
)

TableHeader.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.objectOf(TableCellHeader),
      PropTypes.bool
    ]).isRequired
  )
}

export default TableHeader
