import http     from 'axios'
import qs       from 'qs'
import {
  isArray, isPlainObject, isString, isUndefined, reduce, camelCase, snakeCase
} from 'lodash'

import Notify from '../utility/Notify'
import { t }  from '../locale'

const setContentTypeIfUnset = (headers, value) => {
  if (!isUndefined(headers) && isUndefined(headers['Content-Type'])) {
    headers['Content-Type'] = value
  }
}

const isArrayOfObjects = (o) => isArray(o) && isPlainObject(o[0])

const transformObj = (fn) =>
  (acc, v, k) => {
    acc[fn(k)] = isPlainObject(v) || isArray(v) ? deepMapKeys(v, fn) : v
    return acc
  }

const mapObject = (fn) =>
  (o) => {
    if (isPlainObject(o) || isArrayOfObjects(o)) {
      return reduce(o, transformObj(fn), {})
    } else {
      return o
    }
  }

const deepMapKeys = (obj, fn) => {
  if (isArray(obj)) {
    return obj.map(mapObject(fn))
  } else {
    return reduce(obj, transformObj(fn), {})
  }
}

const snakeCasedFormData = (data) => {
  let keys = []

  for (var key of data.keys()) keys.push(key)
  keys.forEach((key) => {
    if (snakeCase(key) !== key) {
      data.append(snakeCase(key), data.get(key))
      data.delete(key)
    }
  })

  return data
}

const isFormData = (val) =>
  (typeof FormData !== 'undefined') && (val instanceof FormData) // eslint-disable-line no-undef

http.defaults.transformRequest = (data, headers) => {
  if (isPlainObject(data) || isArray(data)) {
    setContentTypeIfUnset(headers, 'application/json;charset=utf-8')
    const transformedData = deepMapKeys(data, snakeCase)
    return JSON.stringify(transformedData)
  } else if (isFormData(data)) {
    return snakeCasedFormData(data)
  } else {
    return data
  }
}

http.defaults.transformResponse = (data) => {
  if (isString(data)) {
    data = data.replace(/^\)]\}',?\n/, '')
    try {
      data = deepMapKeys(JSON.parse(data), camelCase)
    } catch (e) {}
  }
  return data
}

http.defaults.paramsSerializer = (params) => {
  const snakeCaseParams = deepMapKeys(params, snakeCase)
  return qs.stringify(snakeCaseParams, { arrayFormat: 'brackets' })
}

const getCSRFToken = () => {
  const csrfEl = document.querySelector('meta[name="_csrf_token"]')
  if (csrfEl) {
    return csrfEl.getAttribute('content')
  } else {
    return null
  }
}

http.interceptors.request.use((config) => {
  config.headers['X-CSRF-Token'] = getCSRFToken()
  Notify.log(t('common.loading'))
  return config
}, (error) => {
  return Promise.reject(error)
})

http.interceptors.response.use((response) => {
  Notify.clearLogs()
  return response
}, (error) => {
  Notify.clearLogs()

  if (error.response.status === 401) {
    window.location.reload(true)
  }
  return Promise.reject(error)
})

http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
