import { t } from 'web/locale'
import { camelCase, sortBy, reduce } from 'lodash'

const optionsMap = {
  'Ja': t('common.yes'),
  'Off': t('common.no')
}

const prepareOptions = (opts) => {
  return opts ? opts.map((o) => ({ text: optionsMap[o], value: o })) : opts
}

const decorateLabel = (label) => {
  const name = camelCase(label.match(/N(\w+)/)[1])
  return t(`views.offers.show.manualFieldLabels.${name}`)
}

const escapeFieldName = (name) => {
  return name.replace(/\./g, '-')
}

const unescapeFieldName = (name) => {
  return name.replace(/-/g, '.')
}

const prepareFieldsToRender = (fields) => {
  const collection = fields.map(({ name, options }) => ({
    label: decorateLabel(name),
    name: escapeFieldName(name),
    options: prepareOptions(options)
  }))

  return sortBy(collection, ['label'])
}

// we send them as an array because we configured axios
// to transorm params keys to snakeCase by default
const prepareFieldsToSubmit = (fields) => {
  return reduce(fields, (memo, value, key) => {
    memo.push({
      name: unescapeFieldName(key),
      value: value
    })
    return memo
  }, [])
}

const prepareMeetingDurations = (data) => {
  return data.reduce((acc, entry) => {
    acc.push({ text: entry[0], value: entry[1] })
    return acc
  }, [])
}

export {
  decorateLabel,
  escapeFieldName,
  unescapeFieldName,
  prepareFieldsToRender,
  prepareFieldsToSubmit,
  prepareMeetingDurations,
  prepareOptions
}
