import React from 'react'
import PropTypes from 'prop-types'

import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import { t } from 'web/locale'
import {
  StatusChangeModal,
  StatusTabs,
  TableWithSearch,
  TicketModal,
  EcaUploadModal,
  OfferConfirmationModal
} from './components'
import { Button } from 'web/app/common/elements'

const ContractsIndex = ({
  actions: {
    onTicketSubmit,
    openTicketModal,
    onOfferConfirmation,
    onEcaUploadSubmit,
    onEcaUploadReset,
    openOfferConfirmationModal,
    openEcaUploadModal,
    changeStatus,
    handleTableChange,
    handleTableQueryChange,
    isCurrentStatus,
    onCloseModal,
    onStatusChange,
    onStatusModalSubmit
  },
  allowedActions,
  ticketSalesId,
  ticketsCategories,
  ticketsUsers,
  newState,
  status,
  statuses,
  offer,
  externalOfferConfirmLoading,
  confirmationLinks,
  isStatusModalOpen,
  isTicketModalOpen,
  isOfferConfirmationModalOpen,
  isEcaUploadModalOpen,
  ecaUploadErrors,
  tableData
}) => {
  const tableProps = {
    ...tableData,
    openTicketModal,
    openOfferConfirmationModal,
    allowedActions,
    handleChange: handleTableChange,
    handleTableQueryChange,
    onStatusChange
  }

  const statusModalProps = {
    isOpen:       isStatusModalOpen,
    newState:     newState,
    onCloseModal: () => onCloseModal({ modal: 'status', callback: () => {} }),
    onSubmit:     onStatusModalSubmit
  }

  const ticketModalProps = {
    onTicketSubmit,
    isOpen:       isTicketModalOpen,
    onCloseModal: () => onCloseModal({ modal: 'ticket', callback: () => {} }),
    onSubmit: onTicketSubmit,
    ticketSalesId,
    ticketsCategories,
    ticketsUsers
  }

  const offerConfirmatioModalProps = {
    onSubmit: onOfferConfirmation,
    isOpen: isOfferConfirmationModalOpen,
    onCloseModal: () => onCloseModal({ modal: 'offerConfirmation', callback: () => {} }),
    offer,
    externalOfferConfirmLoading,
    confirmationLinks
  }

  const ecaUploadModalProps = {
    onSubmit: onEcaUploadSubmit,
    onReset: onEcaUploadReset,
    isOpen: isEcaUploadModalOpen,
    ecaUploadErrors,
    onCloseModal: () => onCloseModal({ modal: 'ecaUpload', callback: () => {} })
  }

  return (
    <div>
      <StatusChangeModal {...statusModalProps} />
      <TicketModal {...ticketModalProps} />
      <OfferConfirmationModal {...offerConfirmatioModalProps} />
      <EcaUploadModal {...ecaUploadModalProps} />

      <Panel kind="primary">
        <PanelHead>{t('views.contracts.index.title')}</PanelHead>
      </Panel>

      <StatusTabs {...{ isCurrentStatus, changeStatus, statuses }} />

      <div className="content">
        <blockquote>
          {t(`views.contracts.index.states.${status}.description`)}
        </blockquote>
      </div>

      <Panel>
        <PanelBlock>
          <TableWithSearch
            {...tableProps}
          />
        </PanelBlock>
      </Panel>

      <Button onClick={() => openEcaUploadModal()}>
        {t(`views.contracts.index.ecaUpload.openModalButton`)}
      </Button>
    </div>
  )
}

ContractsIndex.propTypes = {
  actions: PropTypes.shape({
    onTicketSubmit:             PropTypes.func.isRequired,
    openOfferConfirmationModal: PropTypes.func.isRequired,
    openEcaUploadModal:         PropTypes.func.isRequired,
    openTicketModal:            PropTypes.func.isRequired,
    changeStatus:               PropTypes.func.isRequired,
    handleTableChange:          PropTypes.func.isRequired,
    isCurrentStatus:            PropTypes.func.isRequired,
    onStatusChange:             PropTypes.func.isRequired,
    onStatusModalSubmit:        PropTypes.func.isRequired,
    onOfferConfirmation:        PropTypes.func.isRequired,
    onEcaUploadSubmit:          PropTypes.func.isRequired,
    onEcaUploadReset:           PropTypes.func.isRequired,
    onCloseModal:               PropTypes.func.isRequired,
    handleTableQueryChange:     PropTypes.func.isRequired
  }),
  isStatusModalOpen:            PropTypes.bool.isRequired,
  isOfferConfirmationModalOpen: PropTypes.bool.isRequired,
  isEcaUploadModalOpen:         PropTypes.bool.isRequired,
  ecaUploadErrors:              PropTypes.array.isRequired,
  isTicketModalOpen:            PropTypes.bool.isRequired,
  ticketSalesId:                PropTypes.number,
  ticketsUsers:                 PropTypes.array.isRequired,
  ticketsCategories:            PropTypes.array.isRequired,
  allowedActions:               PropTypes.array.isRequired,
  status:                       PropTypes.string.isRequired,
  newState:                     PropTypes.string,
  statuses:                     PropTypes.array.isRequired,
  offer:                        PropTypes.object,
  externalOfferConfirmLoading:  PropTypes.bool,
  confirmationLinks:            PropTypes.array,
  tableData:                    PropTypes.object.isRequired
}

ContractsIndex.defaultProps = {
  newState: ''
}

export { ContractsIndex }
