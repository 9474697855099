import React from 'react'
import PropTypes from 'prop-types'

import { Signature } from 'web/app/common/signature'
import { Modal }     from 'web/app/common/modal'
import { t }         from 'web/locale'

const SignContractModal = ({
  advModal: {
    advModalClosable,
    advModalPath,
    advModalShown
  },
  directModalShown,
  onModalClose,
  onSignatureChange
}) => {
  const modalProps = {
    disableClose: !advModalClosable,
    isShown:      advModalShown,
    onClose:      onModalClose
  }

  return (
    <div>
      <Modal {...modalProps}>
        <img src={advModalPath} />
      </Modal>

      <Modal
        isShown={directModalShown}
        header={`${t('views.companies.offers.show.warning')}!`}
        onClose={onModalClose}
      >
        <div className="direct-debit-field">
          <p>{t('views.companies.offers.show.directDebitWarning')}</p>
          <Signature onSave={onSignatureChange('directDebitSign')} />
        </div>
      </Modal>
    </div>
  )
}

SignContractModal.propTypes = {
  advModal: PropTypes.shape({
    advModalClosable: PropTypes.bool.isRequired,
    advModalPath:     PropTypes.string.isRequired,
    advModalShown:    PropTypes.bool.isRequired
  }),
  directModalShown:  PropTypes.bool.isRequired,
  onModalClose:      PropTypes.func.isRequired,
  onSignatureChange: PropTypes.func.isRequired
}

export default SignContractModal
