import buildValidation from 'web/utility/validations'

const validation = ({ cee: values }) => {
  const { filled, number } = buildValidation(values)

  return {
    cee: {
      product: filled('product'),
      value: number('value'),
      year: filled('year')
    }
  }
}

export default validation
