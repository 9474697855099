import buildValidation from 'web/utility/validations'

export const validation = (manualFields) => {
  const nonCheckboxFields = manualFields.filter(({ options }) => (
    !options || (options && options.length !== 2)
  ))

  return (values) => {
    const { filled } = buildValidation(values)

    const manualFields = nonCheckboxFields.reduce((validation, { name }) => {
      validation[name] = filled(`manualFields.${name}`)
      return validation
    }, {})

    let meeting = {}
    if (values.newAppointment) {
      meeting = {
        contactId: filled('meeting.contactId'),
        userId: filled('meeting.userId'),
        datetime: filled('meeting.datetime'),
        duration: filled('meeting.duration')
      }
    }

    return { manualFields, meeting }
  }
}
