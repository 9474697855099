import buildValidation from 'web/utility/validations'

const validateFilesForm = (values = {}) => {
  const { filled } = buildValidation(values)

  return {
    file: filled('file')
  }
}

export { validateFilesForm }
