import domready from 'domready'

const findMenuLinks = () => document.querySelectorAll('ul.menu-list li a')
const isActiveLink = (link) => window.location.pathname === link.getAttribute('href')
const makeLinkActive = (link) => link.classList.add('is-active')

domready(() => {
  const links = findMenuLinks()
  const activeLink = Array.from(links).find(isActiveLink)
  if (activeLink) makeLinkActive(activeLink)
})
