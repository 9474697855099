import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SignaturePad from 'signature_pad'

import { Columns, Column } from 'web/app/common/layout'
import { Button }          from 'web/app/common/elements'
import { t }               from 'web/locale'

export default class SignContract extends Component {
  static propTypes = {
    onSave:   PropTypes.func,
    onChange: PropTypes.func
  }

  componentDidMount () {
    window.addEventListener('resize', this._resizeCanvas)
    const opts = {}
    if (this.props.onChange) opts.onEnd = this._onChange
    this.signaturePad = new SignaturePad(this.canvas, opts)
    window.qqq = this.signaturePad
    this._resizeCanvas()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this._resizeCanvas)
    this.signaturePad.off()
  }

  _resizeCanvas = () => {
    const { canvas, signaturePad } = this

    const ratio = Math.max(window.devicePixelRatio || 1, 1)
    canvas.width = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    canvas.getContext('2d').scale(ratio, ratio)
    signaturePad.clear()
  }

  _onSave = () => {
    const dataUrl = this.signaturePad.toDataURL()
    const isEmpty = this.signaturePad.isEmpty()

    this.props.onSave(dataUrl, !isEmpty)
  }

  _onChange = () => {
    const dataUrl = this.signaturePad.toDataURL()
    const isEmpty = this.signaturePad.isEmpty()

    this.props.onChange(dataUrl, !isEmpty)
  }

  _onClear = () => {
    const { onChange } = this.props
    this.signaturePad.clear()
    if (onChange) onChange(null, true)
  }

  render () {
    const { onSave } = this.props
    const saveButton = (
      <Column classNames="has-text-right">
        <Button onClick={this._onSave}>{t('views.offers.contractSign.sign')}</Button>
      </Column>
    )

    return (
      <div className="spad--wrapper">
        <div className="spad--body">
          <canvas className="spad--canvas" ref={(canvas) => { this.canvas = canvas }} />
        </div>
        <div className="spad--footer">
          <Columns classNames="is-flex">
            <Column classNames="has-text-left">
              <Button onClick={this._onClear}>Clear</Button>
            </Column>
            <Column classNames="content is-small">
              <p>
                Please sign above
              </p>
            </Column>
            { onSave && saveButton }
          </Columns>
        </div>
      </div>
    )
  }
}
