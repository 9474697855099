// TODO: do not depend on underscore
import { humanize } from 'underscore.string'
import { sortBy, sortedUniqBy, cloneDeep, snakeCase, camelCase, concat } from 'lodash'

import { t } from '../locale'

const prepareCallsStates = (callsStates) =>
  callsStates.map(
    (state) => ({ name: t(`attributes.call.status.${camelCase(state)}`), value: state })
  )

const prepareCallsSubStates = (state, callsSubStates) => {
  const subStates = callsSubStates[camelCase(state)]
  if (subStates === undefined) return undefined

  return subStates.map(
    (subState) => ({ name: t(`attributes.call.subStatus.${camelCase(subState)}`), value: subState })
  )
}

const prepareMeetingsDurations = (meetingsDurations) =>
  Object.keys(meetingsDurations)
    .reduce(
      (previous, current) => (
        concat(previous, [{ name: current, value: meetingsDurations[current] }])
      ), [])

const prepareDefaultValueMeetingsDurations = (meetingsDurations, defaultValue) => {
  meetingsDurations.splice(meetingsDurations.findIndex((d) => d.name === defaultValue.name), 1)
  meetingsDurations.unshift(defaultValue)

  return meetingsDurations
}

const prepareCompanyCall = ({ values, companyId }) => {
  const { contactId, note, callback, state, subState, meeting, extra } = values

  if (state === 'callback') {
    callback['companyId'] = companyId

    return ({
      call: {
        contactId,
        note,
        state
      },
      callback: {
        ...callback
      }
    })
  } else if (state === 'meeting') {
    meeting['contactId'] = contactId
    meeting['companyId'] = companyId

    return ({
      call: {
        contactId,
        note,
        state
      },
      meeting: {
        ...meeting
      }
    })
  } else if (state === 'lt_100') {
    return ({
      call: {
        contactId,
        note,
        state,
        subState,
        extra
      }
    })
  } else {
    return ({
      call: {
        contactId,
        note,
        state,
        extra
      }
    })
  }
}

const prepareOptions = (options) =>
  options.map((option) => ({ value: option, name: option }))

const prepareAreas = (areas) =>
  areas.map((area) => ({ value: area.id, text: area.name }))

const prepareCallAgentEmails = (callAgentEmails) =>
  callAgentEmails.map((callAgentEmail) => ({ value: callAgentEmail, name: callAgentEmail }))

const prepareCompanyCategories = (companyCategories) =>
  companyCategories.map((category) => ({ value: category.id, text: category.name }))

const prepareNaces = (naceList) =>
  naceList.map((nace) => ({ value: nace.code, text: nace.code }))

const prepareCompanyTypes = (companyTypes) =>
  companyTypes.map((type) => ({ value: type.id, text: type.name }))

const prepareCompanyStates = (companyStates) =>
  companyStates.map((state) => ({ value: state, text: state }))

const prepareContactKinds = () => {
  const translations = t(`common.contacts.kind`, { returnObjects: true })

  return Object.entries(translations).map((kv, _idx) => ({ text: kv[1], value: kv[0] }))
}

const prepareDays = (days) =>
  days.map((day) => ({ value: day, text: t(`common.day.${day}`) }))

const prepareDurations = (durations) =>
  durations.map((d) => ({ value: d, text: t(`common.duration.${d}`) }))

const prepareFixedFeePeriods = (ffPeriods) =>
  ffPeriods.map((period) => (
    { value: period, text: t(`attributes.fixedFeePeriod.${period}`) }
  ))

const prepareLanguages = (languages) =>
  languages.map((language) => ({ value: language.id, text: t(`common.language.${language.name}`) }))

const prepareMarketNames = (units) =>
  units.map((unit) => ({ value: unit.id, text: unit.name }))

const prepareMeters = (meters) =>
  meters.map((meter) => ({ value: meter.id, text: meter.name }))

const prepareMeetingStates = (meetingStates) =>
  meetingStates.map(
    (state) => ({ name: t(`attributes.meeting.states.${camelCase(state)}`), value: state })
  )

const preparePostalCodes = (postIndices) => {
  const collection = postIndices.map((pi) => ({ value: pi.postalCode, text: pi.postalCode }))
  return sortedUniqBy(sortBy(collection, 'value'), 'value')
}

const preparePriceModels = (priceModels, { universal = false } = {}) => {
  let models = priceModels.map((product) =>
    ({ value: product, text: t(`common.priceModel.${product}`) })
  )

  if (universal) {
    const universalPriceModel = { value: '', text: t('common.priceModel.universal') }
    models = [universalPriceModel, ...models]
  }

  return models
}

const prepareProducts = (products, { universal = false } = {}) => {
  let prods = products.map((product) => ({ value: product, text: t(`common.product.${product}`) }))

  if (universal) {
    const universalProduct = { value: '', text: t('common.product.universal') }
    prods = [universalProduct, ...prods]
  }

  return prods
}

const prepareProductTypes = (units) =>
  units.map((unit) => ({ value: unit.id, text: unit.name }))

const prepareReasons = (reasons) =>
  reasons.map((r) => (
    { value: r, text: humanize(t(`attributes.eanStat.reasons.${camelCase(r)}`)) }
  ))

const prepareSorting = (sorting) =>
  cloneDeep(sorting).map((el) => {
    el.id = el.id.split('.').map((s) => snakeCase(s)).join('.')
    return el
  })

const prepareStatuses = (statuses) =>
  statuses.concat(['all']).map((value) => {
    const text =  t(`attributes.user.statuses.${value}`)

    return ({
      value,
      text
    })
  })

const prepareLeadListStatuses = (statuses) =>
  statuses.concat(['all']).map((value) => {
    const text =  t(`attributes.leadList.statuses.${value}`)

    return ({
      value,
      text
    })
  })

const prepareSuppliers = (suppliers, { withEmpty = false } = {}) => {
  let sups = suppliers.map((sup) => ({ value: sup.id, text: sup.name }))

  if (withEmpty) {
    const empty = { value: '', text: 'No supplier' }
    sups = [empty, ...sups]
  }

  return sups
}

const prepareTariffGroups = (tariffGroups) =>
  tariffGroups.map((group) => ({ value: group.id, text: humanize(group.name) }))

const prepareTariffs = (tariffs) =>
  tariffs.map((t) => ({
    value: t.id,
    text: `${t.name} (${t.tariffGroup.name})`
  }))

const prepareTitles = () => {
  const translations = t(`common.title`, { returnObjects: true })

  return Object.entries(translations).map((kv, _idx) => ({ text: kv[1], value: kv[0] }))
}

const prepareUnits = (units) =>
  units.map((unit) => ({ value: unit.id, text: unit.name }))

const prepareSpokeWith = (opts) =>
  opts.map((opt) => ({ value: opt, text: t(`attributes.call.spokeWith.${camelCase(opt)}`) }))

const prepareUserFileTypes = () => {
  const translations = t(`attributes.company.document.userFileTypes`, { returnObjects: true })

  return Object.entries(translations).map((kv, _idx) => ({ text: kv[1], value: kv[0] }))
}

const prepareYearsOfferExternalPrices = (years) =>
  years.map((y) => ({ text: y, value: y }))

export {
  prepareAreas,
  prepareCallAgentEmails,
  prepareCompanyCategories,
  prepareCompanyTypes,
  prepareCompanyStates,
  prepareContactKinds,
  prepareDays,
  prepareDurations,
  prepareFixedFeePeriods,
  prepareLanguages,
  prepareMarketNames,
  prepareMeters,
  prepareMeetingStates,
  preparePostalCodes,
  preparePriceModels,
  prepareProducts,
  prepareProductTypes,
  prepareReasons,
  prepareSorting,
  prepareStatuses,
  prepareSuppliers,
  prepareTariffGroups,
  prepareTariffs,
  prepareTitles,
  prepareUnits,
  prepareCallsStates,
  prepareCallsSubStates,
  prepareMeetingsDurations,
  prepareDefaultValueMeetingsDurations,
  prepareCompanyCall,
  prepareOptions,
  prepareLeadListStatuses,
  prepareSpokeWith,
  prepareUserFileTypes,
  prepareYearsOfferExternalPrices,
  prepareNaces
}
