import React from 'react'
import PropTypes from 'prop-types'

const PanelBlock = ({ style, children, classNames = '' }) => {
  const className = `panel-block ${classNames}`

  return (
    <div className={className} style={style}>{children}</div>
  )
}

PanelBlock.propTypes = {
  classNames: PropTypes.string,
  children: PropTypes.any.isRequired,
  style:    PropTypes.object
}

export default PanelBlock
