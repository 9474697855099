import { get, post, destroy } from 'web/utility/http'

import { pricesPath } from '../../helpers'

const createCee = ({ values }) =>
  post(pricesPath('/'), { ...values })

const fetchCees = () =>
  get(pricesPath('/'))

const destroyCee = (id) =>
  destroy(pricesPath(`/${id}`))

export { createCee, fetchCees, destroyCee }
