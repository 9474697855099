import React from 'react'
import PropTypes from 'prop-types'
import { moment, dateTime, time } from 'web/locale'

const Event = ({
  contact: {
    name: contactName
  } = {},
  company: {
    address,
    postIndex: {
      city
    } = {}
  },
  datetime,
  duration,
  user: {
    fullName: userName
  },
  createdBy: {
    fullName: callAgentName
  },
  createdAt
}) => {
  const startAt = dateTime(datetime)
  const endAt = time(moment(datetime).add(duration, 'h'))
  return (
    <div style={{ fontSize: 12 }}>
      <p>{ contactName }</p>
      <br />
      <p>{ address }, { city }</p>
      <p>{ startAt } - { endAt }</p>
      <br />
      <p>Salesperson: { userName }</p>
      <p>Call agent: { callAgentName }</p>
      <p>Created at: { dateTime(createdAt) }</p>
    </div>
  )
}

export default Event

Event.propTypes = {
  contact: PropTypes.shape({
    name: PropTypes.string
  }),
  company: PropTypes.shape({
    address: PropTypes.string,
    postIndex: PropTypes.shape({
      city: PropTypes.string
    })
  }).isRequired,
  datetime: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  user: PropTypes.shape({
    fullName: PropTypes.string
  }).isRequired,
  createdBy: PropTypes.shape({
    fullName: PropTypes.string
  }).isRequired,
  createdAt: PropTypes.string.isRequired
}
