import buildValidation from 'web/utility/validations'
import { isCountry }   from 'web/utility/country'

export default (values) => {
  const { filled } = buildValidation(values)
  const validation = {
    contractSign:          filled('contractSign'),
    paraphSign:            filled('paraphSign'),
    sendResignationLetter: filled('sendResignationLetter')
  }
  if (isCountry('fr')) validation.stampFile = filled('stampFile')

  return validation
}
