import buildValidation from 'web/utility/validations'

const validation = (values) => {
  const { filled, number, compare, exist } = buildValidation(values)

  const endValidation = compare('volume.end', 'gt', 'volume.begin')

  return {
    volume: {
      begin: number('volume.begin'),
      end:   exist('volume.end') && endValidation
    },
    marginLetters: filled('marginLetters')
  }
}

export default validation
