import React from 'react'
import PropTypes from 'prop-types'
import { includes } from 'lodash'

import { DatatableWithSearch } from 'web/app/common/datatable'
import { NativeSelect } from 'web/app/common/form-elements'
import { t, dateTime, moment } from 'web/locale'
import { sortStates } from '../helpers'
import { Icon, Button } from 'web/app/common/elements'

/* eslint-disable react/prop-types */
const link = ({ key, href, children }) =>
  <a key={key} href={href}> {children} </a>

const columns = ({
  onStatusChange, allowedActions, openTicketModal, openOfferConfirmationModal
}) => {
  return [{
    Header: t('attributes.contract.id'),
    accessor: 'id',
    minWidth: 60
  }, {
    Header:   t('attributes.contract.offerId'),
    id: 'offerId',
    minWidth: 70,
    Cell: ({ row: { _original } }) => {
      const offerId = _original.offer.id
      return link({ href: `/offers/${offerId}`, children: offerId })
    }
  }, {
    Header: t('attributes.company._'),
    id: 'company.name',
    minWidth: 200,
    Cell: ({ row: { _original } }) => {
      return link({
        href: `/companies/${_original.company.id}`,
        children: _original.company.name
      })
    }
  }, {
    Header: t('attributes.supplier._'),
    id: 'supplier.name',
    minWidth: 200,
    Cell: ({ row: { _original } }) => {
      const { confirmationNeeded, offer } = _original
      let name = _original.supplier.aliasName
      let confirmButton = null

      if (_original.supplierPermissions.includes('read')) {
        name = link({ href: `/suppliers/${_original.supplier.id}`, children: name })
      }

      if (confirmationNeeded) {
        confirmButton = (
          <Button onClick={() => { openOfferConfirmationModal(offer) }} >
            { t('common.confirm') }
          </Button>
        )
      }

      return (
        <>
          <span> { name } </span>
          <span> { confirmButton } </span>
        </>
      )
    }
  }, {
    // TODO need to provide sales from back-end to replace hardcode
    Header: t('attributes.contract.sales'),
    minWidth: 200,
    sortable: false,
    Cell: ({ row: { _original } }) => {
      return (_original.offer.users.map((user) => {
        return link({
          key: user.id,
          href: `/users/${user.id}`,
          children: user.fullName
        })
      })
      )
    }
  }, {
    Header: t('attributes.contract.product'),
    id: 'product',
    minWidth: 60,
    Cell: ({ row: { _original } }) => t(`common.product.${_original.product || 'universal'}`)
  }, {
    Header: t('attributes.contract.state'),
    id: 'state',
    minWidth: 120,
    Cell: ({ row: { _original: { id, state, allowedStates } } }) => {
      if (includes(allowedActions, 'transition')) {
        return (
          <NativeSelect
            options={[state, ...sortStates(allowedStates)]}
            value={state}
            onChange={onStatusChange({ id, currentState: state })}
          />
        )
      } else {
        return (
          <span>{state}</span>
        )
      }
    }
  }, {
    Header: t('attributes.contract.stateDate'),
    accessor: 'stateChangedAt',
    minWidth: 120,
    id: 'stateChangedAt',
    Cell: ({ value }) => <span>{dateTime(value)}</span>
  }, {
    Header: t('attributes.contract.endAt'),
    accessor: 'endAt',
    minWidth: 120,
    id: 'endAt',
    Cell: ({ value }) => <span>{dateTime(value)}</span>
  }, {
    Header: t('attributes.contract.commissionPoints'),
    accessor: 'commissionPoints',
    minWidth: 100,
    sortable: false,
    Cell: ({ value }) => <div className="has-text-centered">{value}</div>
  }, {
    Header: t('attributes.contract.stateDuration'),
    id: 'stateDuration',
    minWidth: 120,
    sortable: false,
    Cell: ({ row: { _original } }) => <span>{moment(_original.stateChangedAt).fromNow()}</span>
  }, {
    sortable: false,
    minWidth: 30,
    Cell: ({ row: { _original } }) =>
      <Icon iconClass="icon-tags" onClick={() => openTicketModal({ row: _original })} />
  }]
}
/* eslint-enable react/prop-types */

const TableWithSearch = ({
  allowedActions,
  data,
  handleChange,
  handleTableQueryChange,
  loading,
  onStatusChange,
  pages,
  query,
  sorted,
  openOfferConfirmationModal,
  openTicketModal
}) => {
  const columnsProps = {
    onStatusChange,
    allowedActions,
    openOfferConfirmationModal,
    openTicketModal
  }

  return (
    <DatatableWithSearch
      input={{
        query,
        onChange: handleTableQueryChange
      }}
      manual
      columns={columns(columnsProps)}
      data={data}
      loading={loading}
      pages={pages}
      onFetchData={handleChange}
      defaultSorted={sorted}
    />
  )
}

TableWithSearch.propTypes = {
  allowedActions:             PropTypes.array.isRequired,
  data:                       PropTypes.array,
  handleChange:               PropTypes.func.isRequired,
  loading:                    PropTypes.bool.isRequired,
  onStatusChange:             PropTypes.func.isRequired,
  pages:                      PropTypes.number,
  query:                      PropTypes.string.isRequired,
  sorted:                     PropTypes.array.isRequired,
  handleTableQueryChange:     PropTypes.func.isRequired,
  openOfferConfirmationModal: PropTypes.func.isRequired,
  openTicketModal:            PropTypes.func.isRequired
}

export default TableWithSearch
