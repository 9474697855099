import React from 'react'
import PropTypes from 'prop-types'

import { humanize } from 'underscore.string'
import { t } from 'web/locale'

import { FormRow } from 'web/app/common/layout'
import {
  ControlButtons,
  Form,
  FileInputWithLabel as FileInput,
  InputWithLabel as Input,
  MultiselectWithLabel as MultiSelect,
  SelectWithLabel as Select
} from 'web/app/common/forms'
import {
  prepareLanguages,
  prepareMeters,
  preparePriceModels,
  prepareProducts
} from 'web/utility/prepareData'
import validations from '../validation'

const prepareProductTypes = (getValue, filteredProductTypes) => {
  let product      = getValue('product')
  let productTypes = filteredProductTypes(product).map((productType) => (
    { value: productType.id, text: productType.name }
  ))

  productTypes.push({ value: null, text: humanize(t('common.product.universal')) })

  return productTypes
}

const adjustPriceModels = (priceModels, isUniversalChosen) => {
  const data = preparePriceModels(priceModels, { universal: true })
  return isUniversalChosen ? data.slice(0, 1) : data
}

const TemplatesForm = ({
  dataOptions,
  formApi: { getValue },
  filteredProductTypes
}) => {
  const isUniversalChosen = !getValue('product')

  return (
    <>
      <FormRow>
        <Select
          fieldName="supplierContractTemplate.product"
          field="product"
          data={prepareProducts(dataOptions.products, { universal: true })}
          required
        />
        <MultiSelect
          fieldName="supplierContractTemplate.productTypes"
          field="productTypeIds"
          data={prepareProductTypes(getValue, filteredProductTypes)}
        />
        <FileInput
          fieldName="supplierContractTemplate.file"
          field="file"
          isWide
          required
        />
      </FormRow>

      <FormRow>
        <Select
          fieldName="supplierContractTemplate.language"
          field="languageId"
          data={prepareLanguages(dataOptions.languages)}
          required
        />
        <Select
          fieldName="supplierContractTemplate.priceModel"
          field="priceModel"
          data={adjustPriceModels(dataOptions.priceModels, isUniversalChosen)}
          disabled={isUniversalChosen}
          required
        />
        <MultiSelect
          fieldName="supplierContractTemplate.meters"
          field="meterIds"
          data={prepareMeters(dataOptions.meters)}
          required
        />
      </FormRow>

      <FormRow>
        <Input
          fieldName="supplierContractTemplate.minVolume"
          field="volumeBegin"
          type="number"
          required
        />
        <Input
          fieldName="supplierContractTemplate.maxVolume"
          field="volumeEnd"
          type="number"
        />
      </FormRow>

      <ControlButtons />
    </>
  )
}

TemplatesForm.propTypes = {
  dataOptions:          PropTypes.object.isRequired,
  filteredProductTypes: PropTypes.func.isRequired,
  formApi:              PropTypes.any
}

const onFormChange = ({ field, value, handleChange, values }) => {
  if (field === 'product' && !value) {
    if (values.priceModel) {
      handleChange('priceModel', undefined)
    }
  }
}

export default (props) => {
  // eslint-disable-next-line react/prop-types
  const { onTemplateCreate } = props

  return (
    <Form
      onSubmit={onTemplateCreate}
      validation={validations}
      onChange={onFormChange}
    >
      <TemplatesForm {...props} />
    </Form>
  )
}
