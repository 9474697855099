import React, { Component } from 'react'
import LeadListSettings from './component'
import PropTypes from 'prop-types'
import { t } from 'web/locale'
import { searchUsers, updateLeadList } from './api'
import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import { Columns, Column } from 'web/app/common/layout'

export default class LeadListSettingsContainer extends Component {
  static propTypes = {
    leadList: PropTypes.object.isRequired,
    companyLink: PropTypes.string.isRequired,
    permissions: PropTypes.object.isRequired
  }

  state = {
    callAgentsOptions: [],
    salesOptions: []
  }

  componentDidMount () {
    this.fetchUsers('call_agent', 'callAgentsOptions')
    this.fetchUsers('sales', 'salesOptions')
  }

  fetchUsers = (role, collectionOptionName) => {
    const { leadList: { organisation: { id: organisationId } } } = this.props
    const newState = {}

    searchUsers({
      organisationId: organisationId,
      role: role
    }).then((response) => {
      if (response.data.length > 0) {
        newState[collectionOptionName] = response.data

        this.setState(newState)
      }
    })
  }

  normalizeMultiselectValues = (values) => {
    values['leadList']['callAgents'] = values['leadList']['callAgents'].map((agent) => agent.id)
    values['leadList']['sales'] = values['leadList']['sales'].map((agent) => agent.id)

    return values
  }

  handleSubmit = (values, { setSubmitting, handleErrors }) => {
    updateLeadList(this.normalizeMultiselectValues(values)).then(({ success, data }) => {
      if (success) {
        window.location = data.url
      } else {
        setSubmitting(false)
        handleErrors(data)
      }
    })
  }

  render () {
    const { leadList, companyLink, permissions } = this.props
    const { callAgentsOptions, salesOptions } = this.state

    return (
      <Panel>
        <PanelHead>
          { t('views.leadLists.settings.title') }
        </PanelHead>
        <PanelBlock>
          <Columns>
            <Column>
              <LeadListSettings
                leadList={leadList}
                handleSubmit={this.handleSubmit}
                companyLink={companyLink}
                callAgentsOptions={callAgentsOptions}
                salesOptions={salesOptions}
                permissions={permissions}
              />
            </Column>
          </Columns>
        </PanelBlock>
      </Panel>
    )
  }
}
