import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

import { CeeForm, CeeTable } from './components'

@inject('store')
@observer
class SupplierCees extends Component {
  componentDidMount () {
    const { store } = this.props

    store.loadCees()
  }

  render () {
    const { supplier, products, store } = this.props
    const { cees, isLoading } = store

    return (
      <>
        <CeeForm
          supplier={supplier}
          products={products}
        />
        <CeeTable
          data={cees}
          onCeeDelete={store.deleteCee}
          isLoading={isLoading}
        />
      </>
    )
  }
}

SupplierCees.propTypes = {
  products: PropTypes.array.isRequired,
  supplier: PropTypes.object.isRequired,
  store: PropTypes.object
}

export default SupplierCees
