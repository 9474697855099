const pageMatchPattern = (page) => new RegExp(`/suppliers/\\d+/${page}$`, 'i')
const rootMatchPattern = new RegExp(`/suppliers/\\d+$`, 'i')

const isLinkActive = ({ page, pathname }) =>
  pageMatchPattern(page).test(pathname)

const isRootActive = ({ pathname }) =>
  rootMatchPattern.test(pathname)

export { isLinkActive, isRootActive }
