import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as mPropTypes } from 'mobx-react'

import { t } from 'web/locale'
import { Level, LevelInfo } from 'web/app/common/level'
import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import { calcSavings } from './helpers'
import Contract from './components/Contract'

const Contracts = ({ offer, contracts, goTo, sentRemoteLink, actions }) => {
  const savings = calcSavings(contracts)

  return (
    <div>
      <Panel>
        <PanelHead>{ t('views.contracts.show.titleSavings') }</PanelHead>
        <PanelBlock>
          <Level>
            <LevelInfo title={t('common.product.electricity')}>
              { savings.electricity || '-' }
            </LevelInfo>
            <LevelInfo title={t('common.product.gas')}>
              { savings.gas || '-' }
            </LevelInfo>
            <LevelInfo title={t('views.contracts.show.captions.total')}>
              { savings.total || '-' }
            </LevelInfo>
          </Level>
        </PanelBlock>
      </Panel>

      {contracts.map((contract) =>
        <Contract
          key={contract.id}
          offer={offer}
          contract={contract}
          sentRemoteLink={sentRemoteLink}
          goTo={goTo}
          actions={actions}
        />
      )}
    </div>
  )
}

Contracts.propTypes = {
  actions:        PropTypes.object.isRequired,
  contracts:      mPropTypes.observableArray.isRequired,
  sentRemoteLink: PropTypes.object,
  offer:          PropTypes.object,
  goTo:           PropTypes.func.isRequired
}

export default Contracts
