import { get, apiPath } from 'web/utility/http'
import Notify from 'web/utility/Notify'

const fetchContracts = (id) => ({ page, pageSize, sorting }) =>
  get(apiPath(`/companies/${id}/contracts`), {
    params: {
      page,
      pageSize,
      sorting
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

const fetchEans = (id) => ({ page, pageSize, sorting }) =>
  get(apiPath(`/companies/${id}/eans`), {
    params: {
      page,
      pageSize,
      sorting
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

const fetchOffers = (id) => ({ page, pageSize, sorting }) =>
  get(apiPath(`/companies/${id}/offers`), {
    params: {
      page,
      pageSize,
      sorting
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

export { fetchContracts, fetchEans, fetchOffers }
