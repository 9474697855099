import { post, get, apiPath } from 'web/utility/http'

const getUsers = ({ page, pageSize, query, status, sorting }) =>
  get(apiPath('/users'), {
    params: {
      page,
      pageSize,
      query,
      status,
      sorting
    }
  })

const postPretend = ({ id }) => post('/session/pretendent', { id })

export { getUsers, postPretend }
