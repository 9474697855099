import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Panel, PanelBlock } from 'web/app/common/panel'
import { ButtonGroup } from 'web/app/common/elements'
import { t } from 'web/locale'

const SupplierPrices = ({
  supplier: {
    id
  }
}) => {
  return (
    <div>
      <Panel>
        <PanelBlock>
          <ButtonGroup>
            <Link className="button" to={`/suppliers/${id}/prices/addons`}>
              {t('views.suppliers.show.adjustAddons')}
            </Link>
            <Link className="button" to={`/suppliers/${id}/prices/price_filters`}>
              {t('views.suppliers.show.adjustFilters')}
            </Link>
            <Link className="button" to={`/suppliers/${id}/prices/margins`}>
              {t('views.suppliers.show.adjustMargins')}
            </Link>
            <Link className="button" to={`/suppliers/${id}/prices/import_prices`}>
              {t('views.suppliers.show.import._')}
            </Link>
            <Link className="button" to={`/suppliers/${id}/prices/cees`}>
              {t('views.suppliers.show.import.cees')}
            </Link>
          </ButtonGroup>
        </PanelBlock>
      </Panel>
    </div>
  )
}

SupplierPrices.propTypes = {
  supplier: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
}

export default SupplierPrices
