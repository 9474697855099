import React from 'react'
import PropTypes from 'prop-types'

import TableCell from './TableCell'
import TableCellHeader from './TableCellHeader'

const TableRow = ({ children }) => (
  <tr>{children}</tr>
)

TableRow.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.objectOf(TableCell),
      PropTypes.objectOf(TableCellHeader),
      PropTypes.bool
    ]).isRequired
  )
}

export default TableRow
