import { apiPathWith, get, patch } from 'web/utility/http'
import { time } from 'web/locale'

const apiMailsPath = (path) =>
  apiPathWith(path).replace('/mails', '')

const fetchMails = ({ page, pageSize, sorting, query }) =>
  get(apiMailsPath('/mails'), {
    params: {
      page,
      pageSize,
      sorting,
      query
    }
  })

const onChangeState = ({ state, id }) =>
  patch(apiMailsPath(`/mails/update_contract_state`), {
    state,
    contractId: id
  })

const onCsvFormUpdate = (supplier) =>
  patch(apiMailsPath('/csv_delivery'), {
    supplier: {
      ...supplier,
      deliverCsvAt: time(new Date(supplier.deliverCsvAt))
    }
  })

const onContractFormUpdate = (supplier) =>
  patch(apiMailsPath('/contracts_delivery'), {
    supplier: {
      ...supplier,
      deliverContractsAt: time(new Date(supplier.deliverContractsAt))
    }
  })

export { fetchMails, onChangeState, onCsvFormUpdate, onContractFormUpdate }
