import React from 'react'
import PropTypes from 'prop-types'
import { Columns, Column } from 'web/app/common/layout'
import { IconLink } from 'web/app/common/elements'

const GeneralInfoHeading = ({ company }) => {
  return (
    <Columns>
      <Column>
        {`${company.name} (ID #${company.id}) [${company.state}]`}
      </Column>
      <Column>
        <div className="pull-right-table-buttons">
          {
            company.permissions.includes('update') &&
            <IconLink iconClass="icon-pencil" url={`/companies/${company.id}/edit`} />
          }
        </div>
      </Column>
    </Columns>
  )
}

GeneralInfoHeading.propTypes = {
  company: PropTypes.object.isRequired
}

export default GeneralInfoHeading
