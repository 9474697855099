import Appsignal from '@appsignal/javascript'
import { plugin } from '@appsignal/plugin-window-events'

const appsignal = new Appsignal({
  key: process.env.APPSIGNAL_FRONT_END_KEY,
  namespace: 'frontend',
  revision: process.env.APP_REVISION
})

const isProd = process.env.NODE_ENV === 'production'

appsignal.use(plugin({
  onerror: isProd,
  onunhandledrejection: isProd
}))

export default appsignal
