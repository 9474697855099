import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  prepareMeetingsDurations,
  prepareCallAgentEmails,
  prepareMeetingStates
} from 'web/utility/prepareData'
import Notify from 'web/utility/Notify'
import { t, dateTime } from 'web/locale'
import { updateMeeting } from './api'
import {
  Form,
  ControlButtons,
  CheckBoxWithLabel as CheckBox,
  SelectWithLabel as Select,
  TextAreaWithLabel as TextArea,
  DateTimePickerWithLabel as DateTime
} from 'web/app/common/forms'
import { InfoWithLabel as Info } from 'web/app/common/form-elements'
import { MeetingsCalendar } from 'web/app/calendars/Meetings'
import { camelCase } from 'lodash'

export default class ProcessMeeting extends Component {
  static propTypes = {
    meetingsDurations: PropTypes.object.isRequired,

    onMeetingUpdate: PropTypes.func,
    meeting: PropTypes.object
  }

  static defaultProps = {
    onMeetingUpdate: () => {}
  }

  state = {
    state: ''
  }

  _handleSubmit = (values, { setSubmitting, handleErrors }) => {
    const {
      onMeetingUpdate,
      meeting: { id }
    } = this.props
    const { state } = this.state

    updateMeeting({ id, state, values }).then(({ success, data }) => {
      if (success) {
        Notify.success(t('views.meetings.flash.update'))
        onMeetingUpdate()
      } else {
        handleErrors(data)
        setSubmitting(false)
        if (data && data.newCallback && data.newCallback.companyId) {
          Notify.error(data.newCallback.companyId)
        }
      }
    })
  }

  _onChangeState = (newState) => {
    this.setState({ state: newState.value })
  }

  render () {
    const { state } = this.state
    const { meeting, meetingsDurations } = this.props
    if (!meeting) return null

    const { permittedStates, callAgents, previousValues } = meeting
    const canTransition = permittedStates.length > 0

    const meetingsDurationsOptions = prepareMeetingsDurations(meetingsDurations)
    const formTarget = state === 'callback' ? 'newCallback' : 'newMeeting'
    const { company, contact, user } = meeting


    const previousValuesHTML = previousValues.map((previousVal) =>
      <div className="previous-values" key={previousVal.id}>
        <div className="columns">
          <div className="column is-2">{t('attributes.meeting.state')}: </div>
          <div className="column is-4">{t(`attributes.meeting.states.${camelCase(previousVal.state)}`)}</div>
          {previousVal.stateChangedAt && (
            <div className="column is-6 has-text-right">
              {dateTime(previousVal.stateChangedAt)}
            </div>
          )}
        </div>

        <div className="columns">
          <div className="column is-2">{t('attributes.meeting.note')}: </div>
          <div className="column is-10">{previousVal.note}</div>
        </div>
      </div>
    )

    return (
      <div className="process-meeting">
        <div className="columns">
          <div className="column is-2">{t('attributes.meeting.companyId')}: </div>
          <div className="column is-4">
            <a href={`/companies/${company && company.id}`}>{company && company.name}</a>
          </div>
          <div className="column is-2">{t('attributes.meeting.contactId')}: </div>
          <div className="column is-4">
            <a href={`/contacts/${contact && contact.id}`}>{contact && contact.name}</a>
          </div>
        </div>
        <div className="columns">
          <div className="column is-2">{t('attributes.meeting.address')}: </div>
          <div className="column is-4">{company.address}</div>
          <div className="column is-2">{t('attributes.meeting.date')}: </div>
          <div className="column is-4">{meeting.newDate}</div>
        </div>
        <div className="columns">
          <div className="column is-2">{t('attributes.meeting.createdById')}: </div>
          <div className="column is-4">{user && user.fullName}</div>
        </div>
        <div>
          <br />
          <Info name={t('attributes.meeting.state')}>
            {t(`attributes.meeting.states.${camelCase(meeting.state)}`)}
          </Info>
        </div>
        {meeting.note && (
          <div>
            <br />
            <Info name={t('attributes.meeting.note')}>
              {meeting.note}
            </Info>
          </div>
        )}
        {previousValues.length > 0 && (
          <div>
            <hr />
            <Info name={t('attributes.meeting.previousValues')}>
              {previousValuesHTML}
            </Info>
          </div>
        )}
        {canTransition && <hr />}
        {canTransition && (
          <Form
            onSubmit={this._handleSubmit}
            initialValues={{
              meeting: {
                callAgent: callAgents[0] && callAgents.length === 1 ? callAgents[0] : ''
              }
            }}
          >
            <>
              {/* stateSelect */}
              <Select
                field="state"
                label={t('attributes.meeting.state')}
                data={prepareMeetingStates(permittedStates)}
                onChange={this._onChangeState}
                textField="name"
                valueField="value"
                required
              />

              {/* meetingNote */}
              {['callback', 'cancelled', 'reschedule', 'not_interested',
                'bad_appointment', 'bad_appointment_yes', 'bad_appointment_no'].includes(state) && (
                <div>
                  <br />
                  <TextArea field="note" label={t('attributes.meeting.note')} />
                </div>
              )}

              {/* newMeetingDatetime */}
              {['invalid', 'reschedule', 'callback', 'follow_up', 'mandate'].includes(state) && (
                <div>
                  <br />
                  <DateTime
                    field={`${formTarget}.datetime`}
                    label={t('attributes.meeting.datetime')}
                    time
                  />
                </div>
              )}

              {/* newMeetingNote */}
              {['invalid', 'follow_up', 'mandate'].includes(state) && (
                <div>
                  <br />
                  <TextArea field={`${formTarget}.note`} label={t('attributes.meeting.note')} />
                </div>
              )}

              {/* newMeetingDurationSelect */}
              {['invalid', 'reschedule', 'callback', 'follow_up', 'mandate'].includes(state) && (
                <div>
                  <br />
                  <Select
                    field={`${formTarget}.duration`}
                    label={t('attributes.meeting.duration')}
                    data={meetingsDurationsOptions}
                    required
                    textField="name"
                    valueField="value"
                  />
                </div>
              )}

              {/* callAgentSelect */}
              {['callback', 'cancelled', 'reschedule', 'not_interested'].includes(state) && (
                <div>
                  <br />
                  <Select
                    field={`${formTarget}.callAgent`}
                    label={t('views.meetings.index.selectCallAgent')}
                    data={prepareCallAgentEmails(callAgents)}
                    required
                    textField="name"
                    valueField="value"
                  />
                </div>
              )}

              {/* skipNotificationCheckBox */}
              {['invalid', 'reschedule', 'follow_up', 'mandate'].includes(state) && (
                <div>
                  <br />
                  <CheckBox
                    field={`${formTarget}.notification`}
                    label={t('attributes.meeting.notification')}
                    defaultValue
                  />
                </div>
              )}

              {/* submitButton */}
              <br />
              <ControlButtons withoutReset />

              {/* calendar */}
              {['invalid', 'reschedule', 'follow_up', 'mandate'].includes(state) && (
                <div>
                  <br />
                  <MeetingsCalendar userIds={[user.id]} />
                </div>
              )}
            </>
          </Form>
        )}
      </div>
    )
  }
}
