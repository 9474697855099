import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router } from 'react-router-dom'
import { observer, Provider }  from 'mobx-react'
import DevTools                        from 'mobx-react-devtools'

import { env }         from 'web/utility/helpers'
import CompanyShowView from './component'
import Store           from './store'

@observer
class CompanyShowViewContainer extends Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    companyKeys: PropTypes.object.isRequired,
    callsStates: PropTypes.array.isRequired,
    callsSubStates: PropTypes.object.isRequired,
    callsContacts: PropTypes.array.isRequired,
    spokeWithOpts: PropTypes.array.isRequired,
    meetingsDurations: PropTypes.object.isRequired,
    sales: PropTypes.array.isRequired,
    callAgents: PropTypes.array.isRequired,
    ticketCategories: PropTypes.array.isRequired,
    companyContracts: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    permissions: PropTypes.shape({
      callbacks: PropTypes.array.isRequired,
      contracts: PropTypes.array.isRequired,
      eans: PropTypes.array.isRequired,
      meetings: PropTypes.array.isRequired,
      offers: PropTypes.array.isRequired,
      tickets: PropTypes.array.isRequired,
      calls: PropTypes.array.isRequired,
      creditScores: PropTypes.array.isRequired,
      userAssignments: PropTypes.array.isRequired
    }).isRequired
  }

  render () {
    const {
      company,
      companyKeys,
      callsStates,
      callsSubStates,
      callsContacts,
      spokeWithOpts,
      meetingsDurations,
      sales,
      callAgents,
      ticketCategories,
      companyContracts,
      permissions,
      users
    } = this.props

    const store = new Store({
      company,
      companyKeys,
      callsStates,
      callsSubStates,
      callsContacts,
      spokeWithOpts,
      meetingsDurations,
      sales,
      callAgents,
      ticketCategories,
      companyContracts,
      permissions,
      users
    })

    return (
      <div>
        <Provider store={store}>
          <Router>
            <CompanyShowView />
          </Router>
        </Provider>
        {env.dev && <DevTools />}
      </div>
    )
  }
}

export default CompanyShowViewContainer
