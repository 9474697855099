import React from 'react'
import PropTypes from 'prop-types'

import useField from './useField'
import { Control } from '../form-elements'
import { t } from 'web/locale'
import { toId, toInputName } from 'web/utility/helpers'

const CheckBoxWithLabel = ({
  field,

  disabled,
  label,
  onManualChange,
  checkedValue = true,
  uncheckedValue = false,
  defaultValue = false
}) => {
  const { getValue, handleChange } = useField(field, { defaultValue })

  const id = toId(field)
  label = label || t(`attributes.${field}`)
  const value = getValue(defaultValue)
  let checked

  if (value === checkedValue) {
    checked = true
  } else if (value === uncheckedValue) {
    checked = false
  } else {
    checked = value
  }

  return (
    <Control>
      <label className="checkbox">
        <input
          id={id}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          name={toInputName(field)}
          onChange={(e) => {
            const { checked } = e.target
            if (onManualChange) {
              onManualChange(checked, { handleChange })
            } else {
              handleChange(checked)
            }
          }}
        />
        {' '}
        {label}
      </label>
    </Control>
  )
}

CheckBoxWithLabel.propTypes = {
  field:       PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types

  disabled: PropTypes.bool,
  label: PropTypes.string,
  onManualChange: PropTypes.func,
  checkedValue: PropTypes.any,
  uncheckedValue: PropTypes.any,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
}

export default CheckBoxWithLabel
