import React, { Component } from 'react'
import { PropTypes as MobxPropTypes, inject } from 'mobx-react'

import {
  fetchContacts,
  fetchUserAssignments,
  fetchCreditScores,
  fetchFiles
} from './api'
import Info from './component'

@inject('store')
class InfoContainer extends Component {
  render () {
    const { company, generalInfo, permissions } = this.props.store

    return (
      <Info
        company={company}
        generalInfo={generalInfo}
        onUserAssignmentsTableChange={fetchUserAssignments(company.id)}
        onContactsTableChange={fetchContacts(company.id)}
        onCreditScoresTableChange={fetchCreditScores(company.id)}
        onFilesTableChange={fetchFiles(company.id)}
        permissions={permissions}
      />
    )
  }
}

InfoContainer.propTypes = {
  store: MobxPropTypes.observableObject.isRequired
}

export default InfoContainer
