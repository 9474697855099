import React from 'react'
import PropTypes from 'prop-types'

import Product from './components/Product'

const Parameters = ({ deleteEanStat, electricity, gas }) => {
  const elecProps = Object.assign(electricity, { deleteEanStat: deleteEanStat })
  const gasProps  = Object.assign(gas, { deleteEanStat: deleteEanStat })

  return (
    <div>
      <Product product="electricity" {...elecProps} />
      <Product product="gas" {...gasProps} />
    </div>
  )
}

const productShape = {
  stats: PropTypes.object,
  table: PropTypes.array,
  unit:  PropTypes.string.isRequired
}

Parameters.propTypes = {
  deleteEanStat: PropTypes.func.isRequired,
  electricity:   PropTypes.shape(productShape),
  gas:           PropTypes.shape(productShape)
}

export default Parameters
