import React from 'react'
import PropTypes from 'prop-types'

import { MeetingsCalendar } from 'web/app/calendars/Meetings'
import { MultiselectWithLabel as MultiSelect } from 'web/app/common/html_forms'
import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'

const MyTasks = ({ users, selectedUserIds, handleUserSelect, meetingsDurations }) => {
  const hasUsers = users.length > 0

  return (
    <Panel>
      <PanelHead>My tasks</PanelHead>
      <PanelBlock>
        {hasUsers && (
          <MultiSelect
            label="Users to display"
            name="users"
            data={users}
            handleChange={handleUserSelect}
            textField="fullName"
            valueField="id"
            values={selectedUserIds || []}
          />
        )}

        {hasUsers && <hr />}

        <MeetingsCalendar
          meetingsDurations={meetingsDurations}
          userIds={selectedUserIds}
          eventClickable
        />
      </PanelBlock>
    </Panel>
  )
}

MyTasks.propTypes = {
  users: PropTypes.array.isRequired,
  selectedUserIds: PropTypes.array.isRequired,
  handleUserSelect: PropTypes.func.isRequired,
  meetingsDurations: PropTypes.object.isRequired
}

export default MyTasks
