import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'
import { FormRow } from 'web/app/common/layout'
import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import {
  CheckBoxWithLabel as Checkbox,
  DateTimePickerWithLabel as DatePicker,
  SelectWithLabel as Select,
  TextWithLabel as Text
} from 'web/app/common/forms'

const MeetingForm = ({
  isNewAppointment,
  contacts,
  durations,
  sales
}) => (
  <Panel kind="light">
    <PanelHead>{t('views.offers.show.newAppointment')}</PanelHead>
    <PanelBlock>
      <Checkbox
        field="newAppointment"
        label={t('views.offers.show.isNewAppointment')}
      />
      {
        isNewAppointment &&
        <div id="new-appointment">
          <FormRow columns={2}>
            <Select
              field="meeting.contactId"
              textField="name"
              valueField="id"
              data={contacts}
              search
              required
            />
            <Select
              field="meeting.userId"
              textField="fullName"
              valueField="id"
              data={sales}
              search
              required
            />
          </FormRow>
          <FormRow columns={2}>
            <DatePicker
              field="meeting.datetime"
              required
            />
            <Select
              field="meeting.duration"
              data={durations}
              required
            />
          </FormRow>
          <Text field="meeting.note" />
        </div>
      }
    </PanelBlock>
  </Panel>
)

MeetingForm.propTypes = {
  isNewAppointment: PropTypes.bool.isRequired,
  contacts: PropTypes.array.isRequired,
  durations: PropTypes.array.isRequired,
  sales: PropTypes.array.isRequired
}

export default MeetingForm
