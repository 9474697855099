import { apiPath, get, post, patch } from 'web/utility/http'

const createTicketComment = ({ ticketId, values }) =>
  post(apiPath(`/tickets/${ticketId}/comments`), { ...values })

const fetchComments = ({ ticketId }) =>
  get(apiPath(`/tickets/${ticketId}/comments`))

const fetchTicketDocuments = ({ ticketId }) =>
  get(apiPath(`/tickets/${ticketId}/documents`))

const postFile = ({ ticketId, values, fileName }) =>
  post(apiPath(`/tickets/${ticketId}/documents`), { ...values, fileName })

const updateTicket = ({ id, ticket }) =>
  patch(apiPath(`/tickets/${id}`), { ticket })

const downloadFilePath = ({ ticketId, id }) =>
  apiPath(`/tickets/${ticketId}/documents/${id}/download`)

const postResolveAppointment = ({ ticketId, confirm }) =>
  post(apiPath(`/tickets/${ticketId}/resolveAppointment`), { confirm })

export {
  createTicketComment,
  downloadFilePath,
  fetchComments,
  fetchTicketDocuments,
  postFile,
  updateTicket,
  postResolveAppointment
}
