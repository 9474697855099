import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Datatable, SearchInput } from './'
import { prepareSorting } from '../../../utility/prepareData'
import { Column, Columns } from '../layout'

export default class IndexDatatable extends Component {
  static propTypes = {
    columns:              PropTypes.array.isRequired,
    defaultPageSize:      PropTypes.number,
    fetchData:            PropTypes.func.isRequired,
    onRowClick:           PropTypes.string,
    sorted:               PropTypes.array,
    withSearch:           PropTypes.bool,
    customFetchDataProps: PropTypes.object,
    children:             PropTypes.object
  }

  static defaultProps = {
    sorted: [{ id: 'id', desc: false }],
    tdShow:  true,
    defaultPageSize: 25,
    withSearch: false
  }

  constructor (props) {
    super(props)
    this._handleChange      = this._handleChange.bind(this)
    this._handleQueryChange = this._handleQueryChange.bind(this)
  }

  state = {
    data:     [],
    loading:  true,
    pages:    null,
    query:    '',
    page:     1,
    pageSize: this.props.defaultPageSize,
    sorted:   this.props.sorted
  }

  _getTdProps = (_state, rowInfo, column) => {
    const tdProps = { style: {} }
    const { onRowClick } = this.props
    const isButton = column && column.id === 'buttons'

    if (onRowClick && !isButton) {
      tdProps.style = { cursor: 'pointer' }

      tdProps.onClick = () => {
        const { id } = rowInfo.row || {}

        window.location = `${onRowClick}/${id}`
      }
    }

    return tdProps
  }

  _handleQueryChange (query) {
    this.setState({ query }, this._handleChange)
  }

  _decoratePages (pages, pageSize) {
    const dbLimit = 10000
    const itemsSize = pages * pageSize

    if (itemsSize >= dbLimit) return `${pages}+`

    return pages
  }

  _handleChange ({ page, pageSize, sorted } = this.state) {
    this.setState({ loading: true, page, pageSize, sorted })

    const customFetchDataProps = this.apiQueryGetParams()

    const { query } = this.state

    this.props.fetchData({
      page: page + 1,
      pageSize,
      query,
      sorting: prepareSorting(sorted),
      ...customFetchDataProps
    }).then(({ data, pages }) => {
      this.setState({ data, pages, loading: false })
    })
  }

  apiQueryGetParams () {
    return this.props.customFetchDataProps || {}
  }

  searchHeader () {
    return <SearchInput {...{ query: this.state.query, onChange: this._handleQueryChange }} />
  }

  headers () {
    return this.props.withSearch && <Columns>
      <Column classNames="is-4 is-offset-8">
        { this.searchHeader() }
      </Column>
    </Columns>
  }

  render () {
    const { data, loading, pages, sorted, pageSize } = this.state
    const getPaginationProps = () => ({
      renderTotalPagesCount: (pages) => this._decoratePages(pages, pageSize)
    })

    const tableProps = {
      manual: true,
      columns: this.props.columns,
      data: data,
      loading: loading,
      pages: pages,
      onFetchData: this._handleChange,
      defaultSorted: sorted,
      getTdProps: this._getTdProps,
      defaultPageSize: pageSize,
      getPaginationProps: getPaginationProps
    }

    return <div>
      { this.headers() || this.props.children }
      <Datatable {...tableProps} />
    </div>
  }
}
