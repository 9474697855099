import React               from 'react'
import { Link }            from 'react-router-dom'
import { ceil, sum, uniq } from 'lodash'

import { date } from 'web/locale'
import { Icon } from 'web/app/common/elements'

const sumArray = (array) => ceil(sum(array))

const supplierName = (supplier) => (supplier && supplier.name) || '-'

const sumVolume = ({ eanTariffs }) => {
  const volumes = eanTariffs.map((tariff) => tariff.annualVolume)
  return sumArray(volumes)
}

const sumPrice = ({ eanTariffs, fixedFee }) => {
  const volumePrices = eanTariffs.map((tariff) => tariff.annualVolume * tariff.price)
  return sumArray(volumePrices) + (fixedFee || 0)
}

export const generateStats = (eanStats, product, offerId) => {
  const path = {
    pathname: `/offers/${offerId}/parameters/new`,
    hash: product
  }
  return {
    amount:    eanStats.length,
    price:     sumArray(eanStats.map(sumPrice)),
    suppliers: uniq(eanStats.map((stat) => supplierName(stat.supplier))).join(', '),
    volume:    sumArray(eanStats.map(sumVolume)),
    button: (
      <Link className={`${product}-new-ean`} to={path}>
        <Icon iconClass="icon-plus" size="medium" link={false} />
      </Link>
    )
  }
}

export const generateTable = (eanStats, offerId) => {
  return eanStats.map((stat) => ({
    offerId:         offerId,
    eanStatId:       stat.id,
    id:              stat.ean.id,
    supplier:        supplierName(stat.supplier),
    volume:          sumVolume(stat),
    price:           sumPrice(stat),
    startOfDelivery: date(stat.deliverFrom),
    number:          stat.ean.number,
    address:         stat.ean.address,
    postalCode:      stat.ean.postIndex.postalCode,
    product:         stat.ean.product,
    actions:         ''
  }))
}
