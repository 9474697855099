import React from 'react'
import PropTypes from 'prop-types'
import { Document, Page } from 'react-pdf'

import { t } from 'web/locale'

const SignContractPdf = ({
  filePath,
  onDocumentLoad,
  numPages
}) => {
  return (
    <>
      <Document
        file={filePath}
        noData={t('views.offers.contractSign.noPdf')}
        onLoadSuccess={onDocumentLoad}
        error="Failed to load your Consumption data. Please contact your sales manger"
        loading="Loading..."
      >
        {
          Array.from(
            new Array(numPages),
            (_el, index) =>
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={1000}
              />
          )
        }
      </Document>
    </>
  )
}

SignContractPdf.propTypes = {
  filePath: PropTypes.string.isRequired,
  onDocumentLoad: PropTypes.func.isRequired,
  numPages: PropTypes.number
}

export default SignContractPdf
