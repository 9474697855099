import {
  assign,
  chain,
  isEmpty,
  forOwn,
  isArray,
  isPlainObject,
  isSet,
  isString,
  mapKeys,
  mapValues,
  trim
} from 'lodash'

import Notify from 'web/utility/Notify'
import { flatten } from 'web/utility/flatten'
import { t } from '../locale'

// Intended to handle JSON errors that come form back-end.
// Usually, we send errors in the format that may be mapped to the form fields.
//   from;
//     { "ean": { address: "must be filled" }, "ean_stat": { reason: ["is not valid"] } }
//   to form fields:
//     { "ean.address": "must be filled", "eanStat.reason": "is not valid" }
//
// We then take those errors and update form state to reflect them on the page.
// We also send errors which don't belong to any particular form field, like:
//   { "product_confirmity": ["product does not confirm"] }
// For such errors we will show a popup via Notify.
//
const setFormErrors = ({ errors, formApi }) => {
  // occures when response is 403 Forbidden
  if (isString(errors)) return Notify.error(errors)

  const schema = formApi.getFormState().touched
  const formFields = toFormFields(schema)
  const allErrors = mapToFormErrors(errors)
  const customErrors = []

  forOwn(allErrors, (value, key) => {
    if (formFields.includes(key)) {
      formApi.setError(key, value)
    } else {
      customErrors.push(value)
    }
  })

  if (customErrors.length > 0) Notify.error(customErrors.pop())
}

// Converts object to array of form fields
// from:
//   { ean: { address: 1, number: 2 }, eanStat: { reason: 1 } }
// to:
//   ["ean.address", "ean.number", "eanStat.reason"]
//
const toFormFields = (object, namespace) => {
  namespace = namespace || []

  return Object.keys(object).reduce((acc, key) => {
    const value = object[key]

    if (isPlainObject(value)) {
      const fields = toFormFields(value, namespace.concat([key]))
      return acc.concat(fields)
    } else {
      const field = namespace.slice(0)
      field.push(key)
      acc.push(field.join('.'))
      return acc
    }
  }, [])
}

// Converts json error object
// from;
//   { "ean": { address: "must be filled" }, "ean_stat": { reason: ["is not valid"] } }
// to:
//   { "ean.address": "must be filled", "eanStat.reason": "is not valid" }
//
const mapToFormErrors = (errors) => {
  if (isArray(errors)) return errors
  return chain(errors)
    .keys()
    .map((nesting) => mapKeys(errors[nesting], (_value, key) => `${nesting}.${key}`))
    .map((aryErrors) => mapValues(aryErrors, (err) => isArray(err) ? trim(err[0]) : trim(err)))
    .reduce((result, aryErrors) => {
      assign(result, aryErrors)
      return result
    }, {})
    .value()
}

const hasErrors = (errors) => !isEmpty(flatten(errors))

// TODO: fix the case when we have to make a field like "eanStat.eanTariffs[0].price"
//       currently, it will produce such name "eanStat[eanTariffs[0]][volume]"
const reduxFormName = (model) => {
  const [main, ...arr] = model.split('.')
  return arr.reduce((acc, el) => (acc += `[${el}]`), main)
}

const reactFormName = reduxFormName

const requiredString = (value) => {
  const isFull = value && value.length
  return isFull ? undefined : t('errors.filled?')
}

const requiredArray = (value) => {
  value = isSet(value) ? [...value] : value
  const isFull = value && value.length
  return isFull ? undefined : t('errors.filled?')
}

const requiredFloat = (value) => {
  const isFull = value && parseFloat(value)
  return isFull ? undefined : t('errors.filled?')
}

const validateEach = (array, method) => {
  array = isSet(array) ? [...array] : array

  const isntFull = !array || !array.length
  const error = t('errors.inclusion?')

  return isntFull ? error : array.map(method)
}

const noErrors = (args) => !hasErrors(args)

export {
  noErrors,
  hasErrors,
  reactFormName,
  reduxFormName,
  requiredArray,
  requiredFloat,
  requiredString,
  setFormErrors,
  validateEach,
  toFormFields
}
