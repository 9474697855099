import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const IconLink = ({ url, target, style, iconClass, size, download, color }) => {
  const classNames = cn({
    icon: true,
    [`is-${size}`]: size !== 'default' && size,
    [`is-${color}`]: color
  })

  const iconClassNames = cn({
    [iconClass]: true
  })

  return (
    <a className={classNames} href={url} target={target} style={style} download={download}>
      <i className={iconClassNames} />
    </a>
  )
}

IconLink.propTypes = {
  style:  PropTypes.object,
  target: PropTypes.string,
  url:    PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  download: PropTypes.string,
  color: PropTypes.string
}

IconLink.defaultProps = {
  target: '_self',
  size: 'small'
}

export default IconLink
