import React from 'react'
import { Columns, Column } from 'web/app/common/layout'
import {
  MultiselectWithLabel as MultiSelect,
  SelectWithLabel as Select,
  InputWithLabel as Input,
  DateTimePickerWithLabel as DateTime,
  Form
} from 'web/app/common/forms'
import PropTypes from 'prop-types'
import { ButtonGroup, ButtonLink } from 'web/app/common/elements'

export default function LeadListShowView ({
  handleSubmit,
  leadList,
  companyLink,
  permissions: {
    openList
  }
}) {
  return (
    <Form
      initialValues={{ leadList: leadList }}
      onSubmit={handleSubmit}
    >
      <Columns>
        <Column classNames="is-one-quarter">
          <Input
            field="leadList.name"
            label="List name"
            disabled
            required
          />
        </Column>
      </Columns>
      <Columns>
        <Column classNames="is-one-quarter">
          <Select
            field="leadList.area"
            label="Area"
            data={[]}
            textField="name"
            valueField="id"
            disabled
          />
        </Column>
        <Column classNames="is-one-quarter">
          <MultiSelect
            field="leadList.cities"
            label="City"
            disabled
          />
        </Column>
        <Column classNames="is-one-quarter">
          <MultiSelect
            field="leadList.postalCodes"
            label="ZIP"
            disabled
          />
        </Column>
        <Column classNames="is-one-quarter">
          <MultiSelect
            field="leadList.counties"
            label="County"
            disabled
          />
        </Column>
      </Columns>
      <Columns>
        <Column classNames="is-one-quarter">
          <MultiSelect
            field="leadList.naces"
            label="NACE"
            disabled
          />
        </Column>
        <Column classNames="is-one-quarter">
          <MultiSelect
            field="leadList.categories"
            label="Category"
            disabled
          />
        </Column>
        <Column classNames="is-one-quarter">
          <MultiSelect
            field="leadList.states"
            label="State"
            disabled
          />
        </Column>
        <Column classNames="is-one-quarter">
          <Columns>
            <Column classNames="is-half">
              <DateTime
                field="leadList.stateFrom"
                label="State from"
                required
                disabled
              />
            </Column>
            <Column classNames="is-half">
              <DateTime
                field="leadList.stateTill"
                label="State till"
                required
                disabled
              />
            </Column>
          </Columns>
        </Column>
      </Columns>
      <Columns>
        <Column classNames="is-one-quarter">
          <DateTime
            field="leadList.stateChangedAt"
            label="Last call state"
            required
            disabled
          />
        </Column>
      </Columns>
      <Columns>
        <Column classNames="is-one-quarter">
          <Select
            field="leadList.organisation"
            label="Organization"
            data={[]}
            textField="name"
            valueField="id"
            disabled
          />
        </Column>
        <Column classNames="is-one-quarter">
          <MultiSelect
            field="leadList.callAgents"
            label="Call agent"
            textField="fullName"
            valueField="id"
            disabled
          />
        </Column>
        <Column classNames="is-one-quarter">
          <MultiSelect
            field="leadList.sales"
            label="Sales"
            textField="fullName"
            valueField="id"
            disabled
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <ButtonGroup>
            <ButtonLink href="/lead_lists">Back to overview</ButtonLink>
            { openList && <ButtonLink href={companyLink}>Open list</ButtonLink> }
          </ButtonGroup>
        </Column>
      </Columns>
    </Form>
  )
}

LeadListShowView.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  leadList: PropTypes.object.isRequired,
  companyLink: PropTypes.string.isRequired,
  permissions: PropTypes.shape({
    openList: PropTypes.bool.isRequired
  })
}
