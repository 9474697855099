import moment from 'moment'
import i18next from 'i18next'
import { reject, isUndefined, compact } from 'lodash'

import locales from '../../../../config/locales/locales.json'

const localeMatch   = document.cookie.match(/locale=(..)-(..)|locale=(..)/)
const [, lng, region] = reject(localeMatch, isUndefined)

const fullLang = compact([lng, region]).join('-')

const translations = Object.keys(locales).reduce((acc, language) => {
  acc[language] = { translation: locales[language] }
  return acc
}, {})

i18next.init({
  pluralSeparator: '-',
  fallbackLng: 'en',
  interpolation: {
    prefix: '%{',
    suffix: '}'
  },
  resources: {
    ...translations
  }
})

i18next.on('languageChanged', () => moment.locale(fullLang))

i18next.changeLanguage(lng)

const t = i18next.t.bind(i18next)

const time = (value) => value ? moment(value).format('LT') : ''
const date = (value) => value ? moment(value).format('L') : ''
const dateTime = (value) => value ? `${date(value)} ${time(value)}` : ''
const calendar = (value) => value ? moment(value).calendar() : ''

const daysDiff = (begin, end) => {
  if (!begin || !end) return ''
  return moment(end).diff(begin, 'days')
}

export { t, time, date, dateTime, i18next, moment, daysDiff, calendar }
