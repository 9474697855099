import { get } from 'web/utility/http'

const fetchData = ({ page, pageSize, sorting, query }) =>
  get('/suppliers', {
    params: {
      page,
      pageSize,
      sorting,
      query
    }
  })

export { fetchData }
