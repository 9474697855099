import React from 'react'
import PropTypes from 'prop-types'
import { isArray, times } from 'lodash'

import Columns from './Columns'
import Column from './Column'

const AttributeRow = ({ columns, children }) => {
  const childrenArray = isArray(children) ? children : [children]
  const elements = times(columns, (i) =>
    <Column key={i}>{ childrenArray[i] }</Column> || null
  )

  return (
    <Columns>
      {times(columns, (i) => elements[i])}
    </Columns>
  )
}

AttributeRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.element
  ]).isRequired,
  columns: PropTypes.number.isRequired
}

AttributeRow.defaultProps = {
  columns: 3
}

export default AttributeRow
