import React from 'react'
import PropTypes from 'prop-types'

import { IndexDatatable } from 'web/app/common/datatable'
import { t, dateTime } from 'web/locale'

/* eslint-disable react/prop-types */
const columns =
[
  {
    accessor: 'id',
    sortable: false,
    minWidth: 75,
    Header: t('attributes.call.id')
  }, {
    id: 'callbacks.datetime',
    accessor: ({ datetime: d }) => dateTime(d),
    minWidth: 180,
    Header: t('attributes.callback.datetime')
  }, {
    accessor: 'contactName',
    sortable: false,
    minWidth: 180,
    Header: t('attributes.call.contactName')
  }, {
    id: 'callbacks.state',
    accessor: 'state',
    minWidth: 180,
    Header: t('attributes.call.status._')
  }, {
    accessor: 'note',
    minWidth: 180,
    sortable: false,
    Header: t('attributes.call.note'),
    Cell: ({ value }) => <div dangerouslySetInnerHTML={{ __html: value }} />
  }
]
/* eslint-enable react/prop-types */

const CallbacksTable = ({ onChange }) => {
  return (
    <div id="calls-table">
      <IndexDatatable
        fetchData={onChange}
        columns={columns}
        sorted={[{ id: 'callbacks.datetime', desc: true }]}
      />
    </div>
  )
}

CallbacksTable.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default CallbacksTable
