import React from 'react'
import PropTypes from 'prop-types'

import { ButtonGroup, Button } from 'web/app/common/elements'
import { t } from 'web/locale'

const UniversalButtons = ({ universal, onFinalize, onSendResignationLetter }) => (
  <ButtonGroup>
    {
      universal &&
      <Button onClick={onFinalize(null)}>{t('views.offers.show.links.finalizeAll')}</Button>
    }
    {
      <Button onClick={onSendResignationLetter}>
        {t('views.offers.show.links.sendResignationLetter')}
      </Button>
    }
    <span />
  </ButtonGroup>
)

UniversalButtons.propTypes = {
  onFinalize:              PropTypes.func.isRequired,
  onSendResignationLetter: PropTypes.func.isRequired,
  universal:               PropTypes.bool.isRequired
}

export default UniversalButtons
