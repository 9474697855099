import React from 'react'

import { t } from 'web/locale'
import {
  ControlButtons,
  DateTimePickerWithLabel as DateTime
} from 'web/app/common/forms'

const StatusChangeForm = () => {
  return (
    <>
      <DateTime
        field="deliveryStopAt"
        label={t('attributes.contract.deliveryStopAt')}
        required
      />
      {/* We add that much <br /> for padding - otherwise datepicker wont feet the modal body */}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <ControlButtons />
    </>
  )
}

export default StatusChangeForm
