import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const Tab = ({ children, isActive }) => {
  const className = cn({ 'is-active': isActive })

  return (
    <li className={className}>
      {children}
    </li>
  )
}

Tab.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string
  ]).isRequired,
  isActive: PropTypes.bool
}

Tab.defaultProps = {
  isActive: false
}

export default Tab
