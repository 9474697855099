import React from 'react'
import PropTypes from 'prop-types'

const Subtitle = ({ size, children }) => {
  const className = `subtitle is-${size}`
  switch (size) {
    case 1:
      return (<h1 className={className}>{children}</h1>)
    case 2:
      return (<h2 className={className}>{children}</h2>)
    case 3:
      return (<h3 className={className}>{children}</h3>)
    case 4:
      return (<h4 className={className}>{children}</h4>)
    case 5:
      return (<h5 className={className}>{children}</h5>)
    case 6:
      return (<h6 className={className}>{children}</h6>)
    default:
      return (<h5 className="subtitle">{children}</h5>)
  }
}

Subtitle.propTypes = {
  size: PropTypes.number.isRequired,
  children: PropTypes.any
}

export default Subtitle
