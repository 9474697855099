import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'
import { Datatable } from 'web/app/common/datatable'

const mainTableColumns = [{
  header: 'Product',
  accessor: 'product'
}, {
  header: t('attributes.meter._'),
  accessor: 'meterName'
}, {
  header: t('attributes.tariffGroup._'),
  accessor: 'tariffGroupName'
}, {
  header: t('attributes.tariff._'),
  accessor: 'tariffName'
}, {
  header: 'Price',
  id: 'totalPrice',
  accessor: (obj) => parseFloat(obj.totalPrice).toFixed(2)
}]

const PricesTable = ({ prices }) =>
  <Datatable
    columns={mainTableColumns}
    data={prices}
    showPagination={false}
  />

PricesTable.propTypes = {
  prices: PropTypes.array.isRequired
}

export default PricesTable
