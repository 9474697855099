import React from 'react'
import { t } from '../../../../locale'
import { RowLink } from '../../../common/datatable'
import { BooleanRow } from '../../../common/table'

const can = (row, action) =>
  row.permissions.includes(action)

/* eslint-disable react/prop-types */
const dnoColumns = [
  { Header: t('attributes.dno.id'), id: 'dnos.id', accessor: 'id' },
  { Header: t('attributes.dno.name'), id: 'dnos.name', accessor: 'name' },
  { Header: t('attributes.dno.address'), id: 'dnos.address', accessor: 'address' },
  { Header: t('attributes.dno.zip'), id: 'post_index.postal_code', accessor: 'postalCode' },
  { Header: t('attributes.dno.city'), id: 'post_index.city', accessor: 'city' },
  {
    Header: t('attributes.dno.electricity'),
    accessor: 'hasElectricityPostIndices',
    sortable: false,
    Cell: ({ value }) =>
      <BooleanRow value={value} />
  },
  {
    Header: t('attributes.dno.gas'),
    accessor: 'hasGasPostIndices',
    sortable: false,
    Cell: ({ value }) =>
      <BooleanRow value={value} />
  },
  {
    id:       'buttons',
    minWidth: 35,
    sortable: false,
    Cell: ({ row: { _original } }) => {
      return (
        <span>
          { can(_original, 'read') && <RowLink type="show" url={`/dnos/${_original.id}`} /> }
          {
            can(_original, 'update') &&
              <RowLink type="edit" url={`/dnos/${_original.id}/edit`} />
          }
        </span>
      )
    }
  }
]
/* eslint-enable react/prop-types */

export { dnoColumns }
