import React from 'react'
import PropTypes from 'prop-types'
import { camelCase } from 'lodash'

import { Tabs, Tab } from 'web/app/common/layout'
import { t } from 'web/locale'

const renderTab = (isCurrentStatus, changeStatus) => {
  return (status, i) => {
    const state = camelCase(status)

    return (
      <Tab isActive={isCurrentStatus(status)} key={[status, i]}>
        <a onClick={changeStatus(status)}>{t(`views.contracts.index.states.${state}.name`)}</a>
      </Tab>
    )
  }
}

const StatusTabs = ({
  changeStatus,
  isCurrentStatus,
  statuses
}) => {
  return (
    <Tabs>
      { statuses.map(renderTab(isCurrentStatus, changeStatus)) }
    </Tabs>
  )
}

StatusTabs.propTypes = {
  changeStatus:    PropTypes.func.isRequired,
  isCurrentStatus: PropTypes.func.isRequired,
  statuses:        PropTypes.array.isRequired
}

export default StatusTabs
