import buildValidation from 'web/utility/validations'

const validateCommentForm = (values = {}) => {
  const { filled } = buildValidation(values)

  return {
    comment: filled('comment')
  }
}

const validateStateChangeForm = (values = {}) => {
  const { filled } = buildValidation(values)

  if (['done', 'in_progress'].includes(values.state)) {
    return { state: filled('state') }
  } else {
    return {
      comment: filled('comment'),
      state: filled('state')
    }
  }
}

export { validateCommentForm, validateStateChangeForm }
