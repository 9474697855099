import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject } from 'mobx-react'

import { Form } from 'web/app/common/forms'
import { LoadingOverlay } from 'web/app/common/helpers'
import { Column, Columns } from 'web/app/common/layout'
import { Button } from 'web/app/common/elements'

import Notify from 'web/utility/Notify'
import { isCountry } from 'web/utility/country'
import SignDnoConsumption from './component'
import { signDnoConsumption } from '../api'
import { post } from '../../../../utility/http'
import { papp } from '../../../../utility/helpers'

@inject('store')
class SignDnoConsumptionContainer extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired
  }

  state = {
    isLoading: false,
    signature: null
  }

  // Handlers
  // ---------------------------------------------------------------
  _onSignatureChange = (value, notEmpty) => {
    if (notEmpty) this.setState({ signature: value })
  }

  _onSubmit = (values, { handleErrors, setSubmitting, resetForm }) => {
    const { id, consumptionDataId } = this.props.match.params
    const params = { signatureFile: this.state.signature }
    if (isCountry('fr')) params.stampFile = values.stamp

    this.setState({ isLoading: true }, () => {
      signDnoConsumption({ id: consumptionDataId, params }).then(({ success, data }) => {
        if (success) {
          this.props.store.offer.setConsumptionData(data)
          this.props.history.push(`/offers/${id}/proposals`)
          resetForm()
        } else {
          this.setState({ isLoading: false }, () => {
            handleErrors(data)
          })
        }
        setSubmitting(false)
      })
    })
  }

  _onSendRemoteLink = async ({ offerId, sendPublicSignaturePath }) => {
    const { success } = await post(sendPublicSignaturePath)

    if (success) {
      Notify.success('SENT')
      this.props.history.push(`/offers/${offerId}/proposals`)
    }
  }

  // Render
  // ---------------------------------------------------------------
  render () {
    const { isLoading } = this.state
    const { store, match } = this.props
    const { consumptionDataId } = match.params
    const offerId = store.offer.id
    const consumption = store.offer.getConsumptionDataById(consumptionDataId)
    const { signedAt, downloadPath, sendPublicSignaturePath } = consumption
    const canSignConsumption = !signedAt

    const withStamp = false

    return (
      <div>
        { isLoading && <LoadingOverlay /> }

        {
          canSignConsumption &&
            <Columns>
              <Column classNames="is-offset-3 is-6">
                <Button isWide onClick={papp(this._onSendRemoteLink, { offerId, sendPublicSignaturePath })}>
                  Sign remotely
                </Button>
              </Column>
            </Columns>
        }

        <Form onSubmit={this._onSubmit}>
          <SignDnoConsumption
            onSignatureChange={this._onSignatureChange}
            withStamp={withStamp}
            downloadPath={downloadPath}
          />
        </Form>
      </div>
    )
  }
}

export default SignDnoConsumptionContainer
