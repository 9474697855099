import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'
import { ModalButton } from 'web/app/common/modal'
import { FormRow } from 'web/app/common/layout'
import {
  FileInputWithLabel as FileInput,
  ControlButtons
} from 'web/app/common/forms'

const UserFileUploadForm = ({ getFileName }) => {
  return (
    <ModalButton
      header="Upload file"
      buttonText={t('common.button.upload')}
    >
      <>
        <FormRow>
          <FileInput
            getFileName={getFileName}
            name="userFile"
            field="userFile"
            isWide
            required
          />
        </FormRow>
        <ControlButtons />
      </>
    </ModalButton>
  )
}

UserFileUploadForm.propTypes = {
  getFileName: PropTypes.func
}

export default UserFileUploadForm
