import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  createTemplate,
  deleteTemplate,
  downloadTemplatePath,
  fetchTemplates
} from './api'
import Notify from 'web/utility/Notify'
import SupplierContractTemplates from './component'
import { isParsePdfError, normalizeParsePdfError } from './helpers'

export default class SupplierContactTemplatesContainer extends Component {
  static propTypes = {
    productTypes: PropTypes.array.isRequired
  }

  state = {
    templates:      [],
    tableIsLoading: false
  }

  componentDidMount () {
    this._fetchData()
  }

  _fetchData () {
    this.setState({ tableIsLoading: true })

    fetchTemplates().then(({ success, data }) => {
      this.setState({ tableIsLoading: false })

      if (success) {
        this.setState({ templates: data, tableIsLoading: false })
      } else {
        if (data.message) {
          Notify.apiError(data)
        }
      }
    })
  }

  _onTemplateCreate = (values, { handleErrors, setSubmitting, resetForm }) => {
    this.setState({ tableIsLoading: true })

    createTemplate({ values })
      .then(({ success, data }) => {
        this.setState({ tableIsLoading: false })

        if (success) {
          const templates = data ? [...this.state.templates, data] : this.state.templates

          this.setState({ templates })
          resetForm()
        } else {
          if (data && data.message) {
            Notify.apiError(data)
          } else if (isParsePdfError({ errors: data })) {
            Notify.error(normalizeParsePdfError({ errors: data }))
          } else {
            handleErrors(data.supplierContractTemplate)
          }
        }

        setSubmitting(false)
      })
  }

  _onTemplateDelete = (id) => {
    this.setState({ tableIsLoading: true })

    deleteTemplate({ id })
      .then(({ success, data }) => {
        this.setState({ tableIsLoading: false })
        if (success) {
          this.setState({
            templates: this.state.templates.filter((obj) => obj.id !== id)
          })
        } else {
          Notify.apiError(data)
        }
      })
  }

  _filteredProductTypes = (product) => {
    if (product === undefined || product === null || product.value === null) {
      return []
    }

    return this.props.productTypes.filter((productType) => productType.product === product)
  }

  render () {
    const dataOptions = { ...this.props }

    return (
      <SupplierContractTemplates
        dataOptions={dataOptions}
        onTemplateCreate={this._onTemplateCreate}
        onTemplateDelete={this._onTemplateDelete}
        downloadTemplatePath={downloadTemplatePath}
        filteredProductTypes={this._filteredProductTypes}
        templates={this.state.templates}
        tableIsLoading={this.state.tableIsLoading}
      />
    )
  }
}
