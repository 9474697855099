import { apiPath, get, patch } from 'web/utility/http'

const searchUsers = ({ organisationId, role, query }) => {
  return (
    get(apiPath(`/lead_lists/users/search`), {
      params: {
        organisationId,
        role,
        query
      }
    })
  )
}

const updateLeadList = (values) => {
  const { leadList: { id } } = values

  return (
    patch(apiPath(`/lead_lists/${id}`),
      values
    )
  )
}

export { searchUsers, updateLeadList }
