import React from 'react'
import PropTypes from 'prop-types'

import { prepareDays } from 'web/utility/prepareData'
import { FormRow } from 'web/app/common/layout'
import { validationCsvForm } from '../validation'
import {
  ControlButtons,
  Form,
  MultiselectWithLabel as MultiSelect,
  DateTimePickerWithLabel as DateTime
} from 'web/app/common/forms'

const CsvMailerForm = ({
  canUpdate,
  days,
  supplier
}) => {
  return (
    <>
      <FormRow>
        <MultiSelect
          field="supplier.csvEmails"
          data={supplier.csvEmails}
          disabled={!canUpdate}
          created
          required
        />

        <MultiSelect
          field="supplier.csvDays"
          data={prepareDays(days)}
          disabled={!canUpdate}
        />

        <DateTime
          field="supplier.deliverCsvAt"
          calendar={false}
          time
          disabled={!canUpdate}
          required
        />
      </FormRow>

      { canUpdate && <ControlButtons withoutReset /> }
    </>
  )
}

CsvMailerForm.propTypes = {
  canUpdate:       PropTypes.bool.isRequired,
  days:            PropTypes.array.isRequired,
  supplier:        PropTypes.object.isRequired
}

export default (props) => {
  // eslint-disable-next-line react/prop-types
  const { onCsvFormUpdate, supplier } = props

  return (
    <Form
      initialValues={{ supplier }}
      validation={validationCsvForm}
      onSubmit={onCsvFormUpdate}
    >
      <CsvMailerForm {...props} />
    </Form>
  )
}
