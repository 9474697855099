import React, { Component } from 'react'
import { merge } from 'lodash'

import { Form } from 'web/app/common/forms'
import { t, date } from 'web/locale'
import { papp } from 'web/utility/helpers'
import { pathWith, currentPath } from 'web/utility/http'
import Notify from 'web/utility/Notify'
import { IndexDatatable } from 'web/app/common/datatable'
import { IconLink } from 'web/app/common/elements'
import { fetchUserFiles, uploadUserFile } from './api'
import UserFileUploadForm  from './components/UserFileUpload'

const columns = [
  {
    Header: t('attributes.userFile.id'),
    accessor: 'id',
    minWidth: 30
  }, {
    Header: t('attributes.userFile.name'),
    accessor: 'name'
  }, {
    Header: t('attributes.userFile.uploadedBy'),
    accessor: 'uploadedBy.fullName',
    sortable: false
  }, {
    Header: t('attributes.userFile.createdAt'),
    accessor: 'createdAt',
    sortable: false,
    Cell: ({ row: { _original } }) => {
      return (date(_original))
    }
  }, {
    className: 'has-text-centered',
    sortable: false,
    minWidth: 25,
    Cell: ({ row: { _original } }) => { // eslint-disable-line react/prop-types
      return (
        <IconLink
          iconClass="icon-download"
          target="_blank"
          url={pathWith(_original.downloadPath)}
          download={_original.name}
        />
      )
    }
  }
]

export default class UserFilesTable extends Component {
  _resolve = ({ success, data, error }) => {
    if (success) {
      return data
    } else {
      Notify.apiError(error)
      return []
    }
  }

  _onFormSubmit = (values, _state, _props, instance) => {
    const userFileData = merge(values, { fileName: this.state.fileName })

    uploadUserFile({ values: userFileData }).then(({ success, data, error }) => {
      if (success) {
        window.location.reload()
      } else {
        instance.setAllTouched()
        instance.setState({ errors: data })
        Notify.apiError(error)
      }
    })
  }

  _setFileName = (name) => {
    this.setState({ fileName: name })
  }

  render () {
    const { canUpload } = this.props // eslint-disable-line react/prop-types

    return (
      <div>
        <IndexDatatable
          columns={columns}
          fetchData={papp(fetchUserFiles, this._resolve)}
          onClickPath={currentPath()}
          defaultPageSize={10}
        />
        {
          canUpload &&
          <span>
            <br />
            <Form
              onSubmit={this._onFormSubmit}
            >
              <UserFileUploadForm
                getFileName={this._setFileName}
              />
            </Form>
          </span>
        }
      </div>
    )
  }
}
