import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const Icon = ({ iconClass, size, color, onClick, link }) => {
  const Tag = link ? 'a' : 'span'
  const classNames = cn('icon', {
    [`is-${size}`]: size,
    [color]: color,
    'is-clickable': onClick
  })

  return (
    <Tag className={classNames} onClick={onClick}>
      <i className={iconClass} />
    </Tag>
  )
}

Icon.propTypes = {
  iconClass: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  onClick: PropTypes.func,
  link: PropTypes.bool
}

Icon.defaultProps = {
  size: 'small',
  link: true
}

export default Icon
