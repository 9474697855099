import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router } from 'react-router-dom'
import { observer, Provider } from 'mobx-react'
import DevTools from 'mobx-react-devtools'

import { env } from 'web/utility/helpers'
import Store from './store'
import ShowOffer from './component'

@observer
class ShowOfferContainer extends Component {
  static propTypes = {
    offer:  PropTypes.object.isRequired,
    common: PropTypes.object.isRequired
  }

  render () {
    const store = new Store(this.props.offer, this.props.common)

    return (
      <div>
        <Provider store={store}>
          <Router>
            <ShowOffer />
          </Router>
        </Provider>
        {env.dev && <DevTools />}
      </div>
    )
  }
}

export default ShowOfferContainer
