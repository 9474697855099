import { get, post, patch, destroy, apiPath, toFormData } from 'web/utility/http'
import Notify from 'web/utility/Notify'

export const searchEan = (number, product) =>
  get('/eans/search', { params: { number: number, product: product } })

export const searchIdem = (id) =>
  get(`/api/v1/companies/${id}`)

// EAN STATS
// ---------------------------------------------
export const fetchEanStats = (id) =>
  get(`/api/v1/offers/${id}/stats`)

export const fetchEanStat = (eanId, eanStatId) =>
  get(`/eans/${eanId}/stat`, { params: { eanStatId } })

export const createEanStat = (values) =>
  post('/api/v1/eans/stats', values)

export const deleteEanStat = ({ eanStatId, offerId }) =>
  destroy(`/api/v1/offers/${offerId}/ean_stats/${eanStatId}`)

export const fetchExternalPrices = (values) =>
  post(`/api/v1/offers/${values.offerId}/ean_stats/fetch_prices`, values)

export const fetchEanStatsCollection = (offerId, contractId) => ({ page, pageSize, sorting }) =>
  get(apiPath(`/offers/${offerId}/ean_stats`), {
    params: {
      contractId,
      page,
      pageSize,
      sorting
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

// OFFER
// ---------------------------------------------
export const sendOffer = (id) =>
  post(`/api/v1/offers/${id}/send`)

export const sendResignationLetter = (id) =>
  post(`/api/v1/offers/${id}/resignation_letter/send`)

export const sendOfferPdf = (id) =>
  post(`/api/v1/offers/${id}/proposals/send_email`)

export const finalizeOffer = ({ id, product }) =>
  post(`/api/v1/offers/${id}/finalize`, { product })

export const copyOffer = ({ id }) =>
  post(`/api/v1/offers/${id}/copy`)

export const createDnoConsumption = ({ id, params }) =>
  post(`/api/v1/offers/${id}/consumption_data`, params)

export const signDnoConsumption = ({ id, params }) =>
  post(`/api/v1/consumption_data/${id}/sign`, params)

export const sendDnoConsumption = ({ id }) =>
  post(`/api/v1/consumption_data/${id}/send_signature_link`)

export const fetchConsumptionDataManualFields = () =>
  get('/api/v1/consumption_data/manual_fields')

// FILTERS
// ---------------------------------------------
export const fetchFilters = ({ id, product }) =>
  get(`/api/v1/offers/${id}/filters`, { params: { product } })

export const updateFilters = ({ id, product, ...filters }) =>
  patch(`/api/v1/offers/${id}/filters`, { product, ...filters })

// PROPOSALS
// ---------------------------------------------
export const analyzeProposals = ({ id, product }) =>
  get(`/api/v1/offers/${id}/proposals/analyze`, { params: { product } })

export const fetchProposals = ({ id, product }) =>
  get(`/api/v1/offers/${id}/proposals`, { params: { product } })

export const fetchHiddenProposals = ({ id, product }) =>
  get(`/api/v1/offers/${id}/proposals/hidden`, { params: { product } })

export const selectProposal = ({ offerId, proposalId }) =>
  patch(`/api/v1/offers/${offerId}/proposals/${proposalId}`, { chosen: true })

export const saveProposal = ({ id, filters }) =>
  post(`/api/v1/offers/${id}/proposals`, { ...filters })

export const deleteProposal = ({ offerId, proposalId }) =>
  destroy(`/api/v1/offers/${offerId}/proposals/${proposalId}`)

// MANDATES
// ---------------------------------------------
export const createMandate = ({ id, values }) =>
  post(`/api/v1/offers/${id}/mandates`, values)

export const deleteMandate = ({ id, product, mandates }) =>
  destroy(`/api/v1/offers/${id}/mandates`, {
    params: {
      product,
      mandates
    }
  })

export const findMargin = (values) =>
  get(`/suppliers/${values.supplierId}/margins/search`, { params: values })

// CONTRACTS
// -------------------------------------------
export const fetchContracts = (offerId) =>
  get(`/api/v1/offers/${offerId}/contracts`)

export const sendContract = (contract) =>
  post(`/companies/${contract.companyId}/contracts/${contract.hex}/send`)

export const sendSignatureLink = ({ id }) =>
  post(`/api/v1/contracts/${id}/send_signature_link`)

export const loadManualData = ({ id }) =>
  get(`/contracts/${id}/manual_data`)

export const saveManualData = ({ id, data }) =>
  patch(`/contracts/${id}/manual_data`, { data })

export const signContract = ({
  hex,
  companyId,
  sendResignationLetter,
  contractSign:    signature,
  directDebitSign: directDebitSignature,
  paraphSign:      paraphSignature,
  stampFile
}) => {
  return post(
    `/companies/${companyId}/contracts/${hex}/sign`,
    { signature, stampFile, sendResignationLetter, directDebitSignature, paraphSignature }
  )
}

export const uploadContract = ({ contract: { companyId, hex }, values: { contract } }) =>
  post(`/api/v1/companies/${companyId}/contracts/${hex}/upload`, toFormData(contract))

export const reuploadContract = ({ contract: { companyId, hex }, values }) =>
  post(`/api/v1/companies/${companyId}/contracts/${hex}/reupload`, toFormData(values))

// COMPANY
// -------------------------------------------
export const fetchCompanyContacts = (companyId) =>
  get(`/api/v1/companies/${companyId}/contacts`)

export const fetchCompanySales = (companyId) =>
  get(`/api/v1/companies/${companyId}/sales`)

export const fetchMeetingDurations = () =>
  get(`/api/v1/meetings/durations`)

export const importEans = (id, fileName, fileData) =>
  post(
    `/offers/${id}/import_eans`,
    {
      file_name: fileName,
      file: fileData
    }
  )

// sentRemoteLink
// -------------------------------------------
export const archiveSentRemoteLink = (linkId) =>
  destroy(apiPath(`/sent_remote_links/${linkId}`))
