import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { IconLink, Icon } from '../elements'

const TableLink = ({ type, url, onClick }) => {
  const icon = cn({
    'icon-download': type === 'download',
    'icon-eye':      type === 'show',
    'icon-pencil':   type === 'edit',
    'icon-trash':    type === 'delete',
    'icon-cog-alt':  type === 'settings'
  })

  if (type === 'delete') {
    return <Icon
      iconClass="icon-trash"
      onClick={onClick}
    />
  } else {
    return <IconLink iconClass={icon} url={url} style={{ marginRight: '5px' }} />
  }
}

TableLink.propTypes = {
  type:    PropTypes.string.isRequired,
  url:     PropTypes.string,
  onClick: PropTypes.func
}

export default TableLink
