import React from 'react'
import PropTypes from 'prop-types'
import { uniqWith, isEqual } from 'lodash'

import { Datatable } from 'web/app/common/datatable'
import OkIcon        from './OkIcon'
import CancelIcon    from './CancelIcon'
import { t }         from 'web/locale'

const generateColumns = ({ createFilter, deleteFilter, marginLetters }) => [
  {
    Header: t('common.volume._'),
    columns: [
      {
        Header:   t('common.range.begin'),
        id:       'begin',
        style:    { textAlign: 'center' },
        accessor: (obj) => parseFloat(obj.begin)
      },
      {
        Header:   t('common.range.end'),
        id:       'end',
        style:    { textAlign: 'center' },
        accessor: (obj) => parseFloat(obj.end) || '...'
      }
    ]
  },
  {
    Header: 'Margins',
    columns: [
      ...marginLetters.map((letter) => (
        {
          Header:   letter,
          accessor: letter,
          sortable: false,
          style:    { textAlign: 'center' },
          minWidth: 50,
          // eslint-disable-next-line react/prop-types
          Cell:   ({ row: { _original: { begin, end } }, value: id }) => {
            if (id) {
              return <OkIcon func={deleteFilter} value={id} type="volume" />
            } else {
              return (
                <CancelIcon
                  func={createFilter}
                  value={{ volume: { begin, end }, marginLetter: letter }}
                  type="volume"
                />
              )
            }
          }
        }
      ))
    ]
  }
]

const prepareData = ({ data }) =>
  uniqWith(data.map((d) => d.volume), isEqual).map((volume) => {
    const { begin, end } = volume
    const filteredData = data.filter(({ volume: v }) => v.begin === begin && v.end === end)

    return filteredData.reduce((acc, filter) => {
      acc[filter.marginLetter] = filter.id
      return acc
    }, { begin, end })
  })

const VolumeFilters = ({
  createFilter,
  data,
  deleteFilter,
  isLoading,
  marginLetters
}) => {
  return <Datatable
    columns={generateColumns({ createFilter, deleteFilter, marginLetters })}
    data={prepareData({ data, marginLetters })}
    loading={isLoading}
    showPagination={false}
  />
}

VolumeFilters.propTypes = {
  createFilter:  PropTypes.func.isRequired,
  data:          PropTypes.array.isRequired,
  deleteFilter:  PropTypes.func.isRequired,
  isLoading:     PropTypes.bool.isRequired,
  marginLetters: PropTypes.array.isRequired
}

export default VolumeFilters
