import React from 'react'

import { fetchPostIndices } from './api'
import { withFieldSearch } from 'web/app/common/forms/HOC'
import Select from 'web/app/common/forms/SelectWithLabel'

const SelectPostIndex = (props) =>
  <Select
    textField="shortForm"
    valueField="id"
    {...props}
  />

const SearchPostIndex = withFieldSearch(
  SelectPostIndex,
  fetchPostIndices
)

export default SearchPostIndex
