import React from 'react'
import PropTypes from 'prop-types'
import CallForm from 'web/app/common/info_tables/CallsForm'
import { Form } from 'web/app/common/forms'
import { validateCallsForm } from 'web/app/common/validations/validateCallsForm'
import { Modal } from 'web/app/common/modal'

const CallStates = ({
  modalProps,
  initialValues,
  handleCallSubmit,
  callsStates,
  callsSubStates,
  callsContacts,
  meetingsDurations,
  sales,
  callAgents,
  companyId,
  spokeWithOpts,
  leadListId
}) => {
  return (
    <Modal {...modalProps}>
      <Form
        onSubmit={handleCallSubmit}
        initialValues={initialValues}
        validation={validateCallsForm}
      >
        <CallForm
          callsStates={callsStates}
          callsSubStates={callsSubStates}
          callsContacts={callsContacts}
          spokeWithOpts={spokeWithOpts}
          meetingsDurations={meetingsDurations}
          sales={sales}
          callAgents={callAgents}
          companyId={companyId}
          afterAddContactRedirectUrl={encodeURI(`/lead_lists/${leadListId}/company`)}
        />
      </Form>
    </Modal>
  )
}

CallStates.propTypes = {
  modalProps: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  handleCallSubmit: PropTypes.func.isRequired,
  callsContacts: PropTypes.array.isRequired,
  callsStates: PropTypes.array.isRequired,
  callsSubStates: PropTypes.object.isRequired,
  spokeWithOpts: PropTypes.array.isRequired,
  sales: PropTypes.array.isRequired,
  callAgents: PropTypes.array.isRequired,
  meetingsDurations: PropTypes.object.isRequired,
  companyId: PropTypes.number.isRequired,
  leadListId: PropTypes.number.isRequired
}

export default CallStates
