import buildValidation from 'web/utility/validations'

const marginValidations = (values) => {
  const { filled, number, compare, exist } = buildValidation(values)
  const endValidation = compare('volume.end', 'gt', 'volume.begin')

  let validObj = {
    type:        filled('type'),
    letter:      filled('letter'),
    priceModel:  filled('priceModel'),
    product:     filled('product'),
    salesMargin: number('salesMargin'),
    passive:     number('passive'),
    volume: {
      from: number('volume.begin'),
      to:   exist('volume.end') && endValidation

    }
  }

  if (values.type === 'fix') {
    validObj.totalMargin = number('totalMargin')
  }

  return validObj
}

const ratioValidations = (values) => {
  const { number } = buildValidation(values)

  return {
    salesCoefficient:  number('salesCoefficient'),
    brokerCoefficient: number('brokerCoefficient')
  }
}

export { marginValidations, ratioValidations }
