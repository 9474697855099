import React from 'react'
import PropTypes from 'prop-types'

const Overlay = ({ children }) => (
  <div className="is-overlay" style={{ zIndex: 10 }}>{children}</div>
)

Overlay.propTypes = {
  children: PropTypes.any
}

export default Overlay
