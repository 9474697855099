import React from 'react'
import PropTypes from 'prop-types'

const Counters = ({ filteredCompanies }) => {
  return (
    <>
      <div className="counter-box counter1">
        <div className="counter-label">
          Time spent for this company
        </div>
        <div className="counter">
          57:26
        </div>
      </div>
      <div className="counter-box counter2">
        <div className="counter-label">
          Pause time Total:
        </div>
        <div className="counter">
          10:10
        </div>
      </div>
      <div className="counter-box counter3">
        <div className="counter-label">
          Time spent on a call
        </div>
        <div className="counter">
          57:26
        </div>
      </div>
      <div className="counter-box counter4">
        <div className="counter-label">
          Time to qualify
        </div>
        <div className="counter">
          57
        </div>
      </div>
      <div className="counter-box counter5">
        <div className="counter-label">
          Total filtered companies:
        </div>
        <div className="counter">
          {filteredCompanies}
        </div>
      </div>
      <div className="counter-box counter6">
        <div className="counter-label">
          Total called companies:
        </div>
        <div className="counter">
          57
        </div>
      </div>
      <div className="counter-box counter7">
        <div className="counter-label">
          Total left to call companies
        </div>
        <div className="counter">
          57:26
        </div>
      </div>
      <div className="counter-box counter8">
        <div className="counter-label">
          Total meetings booked:
        </div>
        <div className="counter is-green">
          57
        </div>
      </div>
    </>
  )
}

Counters.propTypes = {
  filteredCompanies: PropTypes.number.isRequired
}

export default Counters
