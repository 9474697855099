import Notify from 'web/utility/Notify'
import { t } from 'web/locale'

export function readAsDataURL (fileOrBlob, onSuccess, onError) {
  const reader = new FileReader() // eslint-disable-line no-undef

  reader.onload = (event) => {
    if (event.target.error) {
      if (onError) {
        onError(event)
      } else {
        Notify.error(t('erros.fileUnreadable'))
      }
    } else {
      onSuccess(event.target.result)
    }
  }

  reader.readAsDataURL(fileOrBlob)
}
