import { observable, computed, action } from 'mobx'

class Contract {
  constructor (json) {
    Object.assign(this, json)
  }

  can (rule) {
    return this.permissions.includes(rule)
  }

  cannot (rule) {
    return !this.can(rule)
  }

  @computed get uploadPath () {
    return `/companies/${this.companyId}/contracts/${this.hex}/upload`
  }

  @computed get manualDataPath () {
    return `/offers/${this.offerId}/contracts/${this.id}/manual_data`
  }
}

class Offer {
  @observable permissions = []

  constructor (json) {
    this.consumptionData = []
    Object.assign(this, json)
  }

  can (rule) {
    return this.permissions.includes(rule)
  }

  @computed get canReadPriceModels () {
    return this.can('read_price_models')
  }

  @computed get canFinalizeGas () {
    return this.can('finalize_gas') || this.can('finalize_universal')
  }

  @computed get canFinalizeElectricity () {
    return this.can('finalize_electricity') || this.can('finalize_universal')
  }

  @computed get canFinalize () {
    return this.canFinalizeGas || this.canFinalizeElectricity
  }

  @computed get canRequestExternalPrices () {
    return this.can('request_external_prices') && this.can('finalize_electricity')
  }

  @computed get finalize () {
    return {
      gas:         this.canFinalizeGas,
      electricity: this.canFinalizeElectricity,
      universal:   this.canFinalizeGas && this.canFinalizeElectricity
    }
  }

  getConsumptionData (product) {
    return this.consumptionData.find((data) => data.product === product)
  }

  getConsumptionDataById (id) {
    // double equal here for purpose, so that we can omit casting integer to string and vice-versa
    // eslint-disable-next-line eqeqeq
    return this.consumptionData.find((data) => data.id == id)
  }

  @action addConsumptionData (object) {
    this.consumptionData.push(object)
  }

  @action setConsumptionData (object) {
    const current = this.getConsumptionData(object.product)
    return Object.assign(current, object)
  }

  @action setAbilityRequestExternalPrices () {
    this.permissions.push('request_external_prices')
  }
}

class SentRemoteLink {
  @observable permissions = []

  constructor (json) {
    this.consumptionData = []
    Object.assign(this, json)
  }

  can (rule) {
    return this.permissions.includes(rule)
  }
}

class Store {
  // Observable
  // ---------------------------------------------------------------
  @observable proposals
  @observable offer
  @observable eanStats = []
  @observable eanStatsLoaded = false
  @observable contracts = []
  @observable contractsLoaded = false
  @observable sentRemoteLink = null

  // Static attributes
  products = ['gas', 'electricity']
  durations = [0, 1, 2, 3, 4, 5]
  fixedFeePeriods = [1, 12]
  marginLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'K']
  priceModels = ['fix', 'flex']

  // Dynamic attributes
  marketNames = []
  meters = []
  tariffs = []
  tariffGroups = []
  units = []
  productTypes = []
  postIndices = []
  reasons = []
  suppliers = []

  constructor (offerJson, common) {
    const { company, contact, sales, ...offer } = offerJson
    const { contracts, ...other } = common
    this.company = company
    this.contact = contact
    this.sales   = sales
    this.offer   = new Offer(offer)
    if (contracts && contracts.length > 0) this.setContracts(contracts)
    Object.assign(this, other)
  }

  // Computed
  // ---------------------------------------------------------------
  @computed get eans () {
    return this.eanStats.map((es) => es.ean)
  }
  @computed get gasStats () {
    return this.eanStats.filter(({ ean }) => ean.product === 'gas')
  }
  @computed get electricityStats () {
    return this.eanStats.filter(({ ean }) => ean.product === 'electricity')
  }
  @computed get hasContracts () {
    return this.contracts.length > 0
  }

  // Actions
  // ---------------------------------------------------------------
  @action.bound deleteEanStat (statId) {
    this.eanStats = this.eanStats.filter(({ id }) => statId !== id)
  }
  @action setContracts (jsonArray) {
    const contracts = jsonArray.map((json) => {
      return new Contract(json)
    })
    this.contracts = contracts
    this.contractsLoaded = true
  }

  @action setSentRemoteLink (sentRemoteLink) {
    this.sentRemoteLink = new SentRemoteLink(sentRemoteLink)
  }

  @action archiveSentRemoteLink () {
    this.sentRemoteLink = null
  }

  @action updateExternalRequestContractDurations (updatedDurations) {
    this.externalRequestContractDurations = updatedDurations
  }

  // Plain methods
  // ---------------------------------------------------------------
  contractById (id) {
    return this.contracts.find((c) => c.id == id) // eslint-disable-line eqeqeq
  }
}

export default Store
