import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ContractPdf from './ContractPdf'
import SignForm from './SignForm'

import { readAsDataURL } from 'web/utility/file'
import { isCountry, currentCountry } from 'web/utility/country'
import { post } from 'web/utility/http'
import { t } from '../../../locale'

const STAMP_MIME_TYPES = ['image/jpeg', 'image/jpg', 'image/png']

const RemoteSignatureContainer = ({
  contract: {
    publicDownloadPath,
    withDirectDebit
  },
  contactName
}) => {
  const [success, setSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [numPages, setNumPages] = useState(null)
  const [signature, setSignature] = useState(null)
  const [paraphSignature, setParaphSignature] = useState(null)
  const [directDebitSignature, setDirectDebitSignature] = useState(null)
  const [stampFile, setStampFile] = useState(null)
  const [stampFilename, setStampFilename] = useState(null)

  // simple validation

  let canSubmit = !!signature && !!paraphSignature && !isLoading

  if (isCountry('fr')) canSubmit = canSubmit && !!stampFile
  if (withDirectDebit) canSubmit = canSubmit && !!directDebitSignature

  // handlers

  const onDocumentLoad = ({ numPages }) => {
    setNumPages(numPages)
  }

  const onStampChange = (e) => {
    const file = e.target.files[0]
    if (!file) return

    if (!STAMP_MIME_TYPES.includes(file.type)) return

    setStampFilename(file.name)
    readAsDataURL(file, (encoded) => setStampFile(encoded))
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    const params = { signature, paraphSignature, directDebitSignature, stampFile }
    const { success } = await post(window.location, params)
    setSuccess(success)
  }

  // rendering

  if (success) {
    const __html = t(`views.contracts.signature.${currentCountry}.success`, {
      contact_name: contactName
    })
    const style = { fontSize: 20 }

    return <div className="notification" style={style} dangerouslySetInnerHTML={{ __html }} />
  }

  return (
    <div>
      <ContractPdf
        filePath={publicDownloadPath}
        numPages={numPages}
        onDocumentLoad={onDocumentLoad}
      />

      {numPages && <SignForm
        canSubmit={canSubmit}
        isStampShown={isCountry('fr')}
        isDirectDebitShown={withDirectDebit}
        onSignatureChange={setSignature}
        onParaphSignatureChange={setParaphSignature}
        onDirectDebitSignature={setDirectDebitSignature}
        onStampChange={onStampChange}
        stampFilename={stampFilename}
        onSubmit={handleSubmit}
      />}
    </div>
  )
}

RemoteSignatureContainer.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.number.isRequired,
    publicDownloadPath: PropTypes.string.isRequired,
    withDirectDebit: PropTypes.bool.isRequired
  }).isRequired,
  contactName: PropTypes.string.isRequired
}

export default RemoteSignatureContainer
