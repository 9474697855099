import { apiPath, get } from 'web/utility/http'
import Notify from 'web/utility/Notify'

export const fetchData = ({ page, pageSize, sorting, query, since }) =>
  get(apiPath(`/callbacks`), {
    params: {
      page,
      pageSize,
      sorting,
      query,
      since
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })
