import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Form } from 'web/app/common/forms'
import { Modal } from 'web/app/common/modal'
import MailModal from './component'

import { t } from 'web/locale'
import { createMail } from './api'
import validation from './validation'

// Designed to be controlled by parent container (show or hide modal).
// It is just much easier to have a conditional rendering, than
// manage and synchronize showed/hidden state between components.
// So, in the parent render() you should have something like:
//
// <div>
//   <SomeTable {...tableProps} />
//   { showModal && <MailModalContainer {...modalProps} /> }
// </div>
//
export default class MailModalContainer extends Component {
  static propTypes = {
    dataId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  }

  _handleSubmit = (values, { setSubmitting, handleErrors, resetForm }) => {
    const { dataId, onSubmit } = this.props
    const params = this._normalizeParams(values)

    createMail(dataId, params).then(({ success, data }) => {
      if (success) {
        onSubmit()
        resetForm()
      } else {
        handleErrors(data)
      }

      setSubmitting(false)
    })
  }

  _normalizeParams = (values) => {
    const mapValue = (obj) => obj.value
    const mail = Object.assign({}, values.mail)
    const { cc, bcc } = mail
    if (cc) mail.cc = cc.map(mapValue)
    if (bcc) mail.bcc = bcc.map(mapValue)
    return Object.assign({}, values, { mail })
  }

  _getFormApi = (formApi) => {
    this.formApi = formApi
  }

  render () {
    const header = t(`views.consumptionData.index.mail.title`)
    const initialValues = { attachFile: true }

    return (
      <Modal header={header} onClose={this.props.onClose} isShown>
        <Form
          initialValues={initialValues}
          getApi={this._getFormApi}
          validation={validation}
          onSubmit={this._handleSubmit}
        >
          <MailModal />
        </Form>
      </Modal>
    )
  }
}
