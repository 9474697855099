import React, { Component }        from 'react'
import PropTypes                   from 'prop-types'
import { t }                       from 'web/locale'
import { updateTicketState }       from '../api'
import { validateStateChangeForm }     from 'web/app/tickets/validations'
import { ChangeTicketState }       from 'web/app/tickets/ChangeTicketState'

export default class TicketStateChangeModal extends Component {
  static propTypes = {
    handleCloseModal: PropTypes.func.isRequired,
    isModalVisible:   PropTypes.bool.isRequired,
    targetTicketId:   PropTypes.number,
    newState:         PropTypes.string.isRequired
  }

  _handleSubmit = (values, { setSubmitting, handleErrors, resetForm }) => {
    const { targetTicketId } = this.props

    updateTicketState({
      id: targetTicketId,
      values
    }).then(({ success, data }) => {
      if (success) {
        resetForm()
        this.props.handleCloseModal()
        window.location.reload()
      } else {
        handleErrors(data)
        setSubmitting(false)
      }
    })
  }

  render () {
    const { isModalVisible, newState } = this.props

    const modalProps = {
      header:  t(`views.tickets.index.updateState.title`),
      isShown: isModalVisible,
      onClose: this.props.handleCloseModal
    }
    const initialValues = { state: newState }

    return (
      <div>
        <ChangeTicketState
          state={newState}
          modalProps={modalProps}
          handleSubmit={this._handleSubmit}
          validateStateChangeForm={validateStateChangeForm}
          initialValues={initialValues}
        />
      </div>
    )
  }
}

TicketStateChangeModal.propTypes = {
  handleCloseModal:  PropTypes.func.isRequired,
  isModalVisible:    PropTypes.bool.isRequired,
  targetTicketId:    PropTypes.number,
  newState:          PropTypes.string.isRequired
}
