import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'
import { FormRow } from 'web/app/common/layout'
import { Label, Control } from 'web/app/common/form-elements'
import validation from '../validation'
import {
  Form,
  ControlButtons,
  SelectWithLabel as Select,
  InputWithLabel as Input
} from 'web/app/common/forms'
import {
  prepareProductTypes
} from 'web/utility/prepareData'

const UnitForm = ({
  productTypes,
  units,
  viewConversion,
  formApi: {
    values
  }
}) => {
  const { unit } = values

  return (
    <>
      <FormRow columns={4}>
        <Select
          field="unit.name"
          data={units}
          required
        />

        <Input
          field="unit.coefficient"
          type="number"
          required
        />

        <Select
          field="unit.productTypeId"
          data={prepareProductTypes(productTypes)}
          required
        />

        <Control>
          <Label>
            {t('attributes.unit.conversion')}
          </Label>

          <span>{viewConversion(unit)}</span>
        </Control>
      </FormRow>

      <ControlButtons />
    </>
  )
}

UnitForm.propTypes = {
  productTypes:   PropTypes.array.isRequired,
  units:          PropTypes.array.isRequired,
  viewConversion: PropTypes.func.isRequired,
  formApi:        PropTypes.any
}

export default (props) => {
  // eslint-disable-next-line react/prop-types
  const { onUnitCreate } = props

  return (
    <Form
      onSubmit={onUnitCreate}
      validation={validation}
    >
      <UnitForm {...props} />
    </Form>
  )
}
