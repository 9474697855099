import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { t }                       from 'web/locale'
import { FormRow }                 from 'web/app/common/layout'
import { SearchKva }               from 'web/app/eans/eanStats/eanTariffs/SearchKva'
import { PanelBlock }              from 'web/app/common/panel'
import { InputWithLabel as Input } from 'web/app/common/forms'

export default class Prices extends Component {
  static propTypes = {
    shouldApplyKVA:   PropTypes.bool.isRequired,
    availableKva:     PropTypes.array.isRequired,
    tariffs:          PropTypes.array.isRequired,
    onKvaValueChange: PropTypes.func
  }

  _renderPriceRow = (tariff, i) => {
    const { shouldApplyKVA, availableKva, onKvaValueChange } = this.props
    const isMainKvaField = (i) => (i === 0)
    const onChange = () => {
      return isMainKvaField(i) ? onKvaValueChange : undefined
    }

    const priceLabel  = t('views.eans.common.label.price', { tariff_name: tariff.name })
    const volumeLabel = t('views.eans.common.label.volume', { tariff_name: tariff.name })
    const kvaLabel    = t('views.eans.common.label.kva', { tariff_name: tariff.name })

    return (
      <FormRow key={tariff.id}>
        <Input
          field={`eanStat.eanTariffs[${i}].price`}
          label={priceLabel}
          type="number"
          required
        />
        <Input
          field={`eanStat.eanTariffs[${i}].volume`}
          label={volumeLabel}
          type="number"
          required
        />
        {
          shouldApplyKVA &&
            <SearchKva
              field={`eanStat.eanTariffs[${i}].kva`}
              label={kvaLabel}
              defaultData={availableKva}
              onChange={onChange()}
              required
            />
        }
      </FormRow>
    )
  }

  render () {
    return (
      <PanelBlock>
        { this.props.tariffs.map(this._renderPriceRow) }
      </PanelBlock>
    )
  }
}
