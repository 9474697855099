import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'web/locale'
import {
  ControlButtons,
  TextAreaWithLabel as TextArea,
  SelectWithLabel as Select
} from 'web/app/common/forms'

const SupplierMailModal = ({ suppliersToSelect }) => (
  <div>
    <Select
      textField="name"
      valueField="id"
      field="mail.supplierId"
      label={t('views.tickets.show.supplierMail.supplier')}
      data={suppliersToSelect}
      required
    />
    <TextArea
      label={t('views.tickets.show.supplierMail.body')}
      field="mail.body"
      required
    />
    <ControlButtons withoutReset />
  </div>
)

SupplierMailModal.propTypes = {
  suppliersToSelect: PropTypes.array.isRequired
}

export default SupplierMailModal
