import React from 'react'
import PropTypes from 'prop-types'

import { IndexDatatable, RowLink } from 'web/app/common/datatable'
import { t, dateTime } from 'web/locale'

/* eslint-disable react/prop-types */
const columns =
  [
    {
      id: 'contracts.id',
      accessor: 'id',
      minWidth: 50,
      Header: t('attributes.contract.id')
    },
    {
      id: 'contracts.offerId',
      accessor: 'offer.id',
      maxWidth: 120,
      Header: t('attributes.contract.offerId')
    },
    {
      accessor: 'supplier.name',
      minWidth: 120,
      Header: t('attributes.supplier.name')
    },
    {
      id: 'contracts.state',
      accessor: 'state',
      minWidth: 120,
      Header: t('attributes.contract.state')
    },
    {
      id: 'contracts.product',
      accessor: 'product',
      minWidth: 120,
      Header: t('attributes.contract.product')
    },
    {
      id: 'contracts.stateChangedAt',
      accessor: ({ stateChangedAt }) => dateTime(stateChangedAt),
      minWidth: 120,
      Header: t('attributes.contract.stateDate')
    },
    {
      id: 'contracts.endAt',
      accessor: 'endAt',
      minWidth: 120,
      Header: t('attributes.contract.endAt'),
      Cell: ({ value }) => <span>{dateTime(value)}</span>
    },
    {
      id:       'buttons',
      minWidth: 25,
      sortable: false,
      Cell: ({ original }) => { // eslint-disable-line react/prop-types
        return (
          <span>
            {
              <RowLink type="show" url={`/offers/${original.offer.id}`} />
            }
          </span>
        )
      }
    }
  ]
/* eslint-enable react/prop-types */

const ContractsTable = ({ onChange }) => {
  return (
    <div id="contracts-table">
      <IndexDatatable
        fetchData={onChange}
        columns={columns}
        sorted={[{ id: 'contracts.id', desc: false }]}
      />
    </div>
  )
}

ContractsTable.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default ContractsTable
