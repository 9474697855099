import { http }   from '../../../utility/http'
import Notify from '../../../utility/Notify'

const fetchData = ({ page, pageSize, sorting, query }) =>
  http
    .get('/api/v1/companies', {
      params: {
        page,
        pageSize,
        sorting,
        query
      }
    })
    .then((response) => response.data)
    .catch((error) => {
      Notify.apiError(error)
      return false
    })

export { fetchData }
