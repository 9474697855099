import React from 'react'
import PropTypes from 'prop-types'
import { capitalize, findIndex } from 'lodash'

import { Datatable } from 'web/app/common/datatable'
import { Icon }      from 'web/app/common/elements'
import { t }         from 'web/locale'

import CommissionPoints from './CommissionPoints'

const generateColumns = ({
  onProposalToggle,
  onMandateToggle,
  checkedMandates,
  data,
  canDeleteMandates,
  isMandate
}) => [{
  /* eslint-disable react/prop-types */
  Header: t(`views.offers.show.hiddenProposalHeaders.delete`),
  accessor: 'delete',
  Cell: ({ original }) => {
    const {
      mandateId,
      productType: { id: productTypeId },
      margin: { letter: marginLetter },
      supplier: { id: supplierId }
    } = original
    if (!canDeleteMandates) return null

    const checked = findIndex(checkedMandates, (m) => (m.mandateId === mandateId)) > -1

    return (
      <input
        type="checkbox"
        checked={checked}
        onClick={() => { onMandateToggle({ mandateId, productTypeId, marginLetter, supplierId }) }}
      />
    )
  }
}, {
  Header: t(`views.offers.show.hiddenProposalHeaders.${isMandate ? 'displayMandate' : 'display'}`),
  accessor: 'id',
  Cell: ({ original }) => {
    const {
      id,
      product,
      priceModel,
      duration,
      productType: { id: productTypeId },
      supplier: { id: supplierId },
      margin: { id: marginId, letter: marginLetter }
    } = original
    if (id) {
      return (
        <Icon iconClass="icon-ok" size="" color="is-success" onClick={onProposalToggle({ id })} />
      )
    } else {
      const func = onProposalToggle({
        product, priceModel, duration, supplierId, marginId, productTypeId, marginLetter
      })
      return (
        <Icon
          iconClass="icon-cancel"
          size=""
          color="is-danger"
          onClick={func}
        />
      )
    }
  }
}, {
  Header: t('attributes.supplier._'),
  accessor: 'supplier.name'
}, {
  Header: t('attributes.offer.proposal.priceModel'),
  accessor: 'priceModel'
}, {
  Header: t('attributes.offer.proposal.productType'),
  accessor: 'productType.name'
}, {
  Header: t('attributes.offer.proposal.margin'),
  accessor: 'margin.letter',
  Cell: ({ original, value }) => {
    const text = original.margin.type === 'flex' ? value : `${value} (fix)`
    return <span>{text}</span>
  }
}, {
  Header: t('attributes.offer.proposal.passive'),
  accessor: 'margin.passive'
}, {
  Header: t('attributes.offer.proposal.commissionPoints'),
  accessor: 'commissionPoints',
  Cell: ({ original }) => {
    return <CommissionPoints {...{ original, data }} />
  }
}, {
  Header: capitalize(t('common.month-plural')),
  accessor: 'months'
}, {
  Header: t('attributes.offer.proposal.annualSavings'),
  accessor: 'annualSavings'
}]
/* eslint-enable react/prop-types */

const ProposalsTable = ({
  data,
  onProposalToggle,
  onMandateToggle,
  checkedMandates,
  loading,
  canDeleteMandates,
  isMandate
}) =>
  <Datatable
    className="proposal-table"
    columns={
      generateColumns({
        onProposalToggle,
        onMandateToggle,
        checkedMandates,
        data,
        canDeleteMandates,
        isMandate })
    }
    data={data}
    defaultSorting={[{ id: 'totalSavings', desc: false }]}
    loading={loading}
    showPagination={false}
    pageSize={data.length}
  />

ProposalsTable.propTypes = {
  data:              PropTypes.array.isRequired,
  loading:           PropTypes.bool.isRequired,
  isMandate:         PropTypes.bool.isRequired,
  onProposalToggle:  PropTypes.func.isRequired,
  onMandateToggle:   PropTypes.func.isRequired,
  canDeleteMandates: PropTypes.func.isRequired,
  checkedMandates:   PropTypes.array.isRequired
}

export default ProposalsTable
