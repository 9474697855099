import { observable, computed } from 'mobx'
import { camelCase } from 'lodash'

import { t } from 'web/locale'

export default class CompanyOverviewStore {
  constructor ({
    company,
    companyKeys,
    callsStates,
    callsSubStates,
    callsContacts,
    meetingsDurations,
    sales,
    callAgents,
    permissions,
    leadList
  }) {
    this.company = company
    this.companyKeys = companyKeys
    this.callsStates = callsStates
    this.callsSubStates = callsSubStates
    this.callsContacts = callsContacts
    this.meetingsDurations = meetingsDurations
    this.sales = sales
    this.callAgents = callAgents
    this.permissions = permissions
    this.leadList = leadList
  }

  @observable company = {}
  @observable companyKeys = {}
  @observable leadList = {}

  @computed
  get generalInfo () {
    const { company, postIndex } = this.companyKeys

    const companyArray = company.map(
      (column) => {
        return ({
          key: t(`attributes.company.${camelCase(column)}`),
          value: this.company[column]
        })
      }
    )

    const postIndexArray = postIndex.map(
      (column) => {
        return ({
          key: t(`attributes.postIndex.${camelCase(column)}`),
          value: this.company.postIndex ? this.company.postIndex[camelCase(column)] : null
        })
      }
    )

    return companyArray.concat(postIndexArray)
  }
}
