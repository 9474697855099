import { get, apiPath } from 'web/utility/http'
import Notify from 'web/utility/Notify'

const fetchData = (id) => ({ page, pageSize, sorting }) =>
  get(apiPath(`/contacts/${id}/calls`), {
    params: {
      page,
      pageSize,
      sorting
    }
  })
    .then((response) => response.data)
    .catch((error) => { Notify.alert(error); return false })

export { fetchData }
