import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import useField from './useField'
import { t } from 'web/locale'
import { toId, toInputName } from 'web/utility/helpers'
import { Control, Label } from '../form-elements'
import Error from './Error'

const TextAreaInputWithLabel = ({
  field,
  className = '',
  label = undefined,
  required = false,
  withLabel = true,
  placeholder = undefined
}) => {
  const { getValue, getError, getTouched, handleTouched, handleChange } = useField(field)

  const id = toId(field)
  label = label || t(`attributes.${field}`)
  const value = getValue('')
  const error = getError()
  const touched = getTouched()

  const iconClass = cn('icon is-small', {
    'icon-ok': !error,
    'icon-asterisk is-danger': !!error
  })

  return (
    <Control classNames={`right-asterisk ${className}`}>
      { withLabel && <Label htmlFor={id}>{label}</Label> }
      <Control
        hasIcon={required}
      >
        <textarea
          className="textarea"
          id={id}
          placeholder={`${placeholder || label}...`}
          value={value}
          onChange={(event) => handleChange(event.target.value)}
          onBlur={() => handleTouched(true)}
          name={toInputName(field)}
        />
        {required && <i className={iconClass} />}
      </Control>
      <Error touched={touched}>{error}</Error>
    </Control>
  )
}

TextAreaInputWithLabel.propTypes = {
  field: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  withLabel: PropTypes.bool,
  placeholder: PropTypes.string
}

export default TextAreaInputWithLabel
