import React from 'react'
import PropTypes from 'prop-types'

import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import { ControlButtons }               from 'web/app/common/forms'

import UserFields     from './components/UserFields'
import CompanyFields  from './components/CompanyFields'
import ContactFields  from './components/ContactFields'
import PasswordFields from './components/PasswordFields'

export default function UserForm ({
  action,
  companyData,
  companyPostIndex,
  contactData,
  handleCompanySearch,
  handlers,
  hide: {
    userFields
  },
  permissions,
  searchingCompany,
  title,
  userData
}) {
  return (
    <Panel>
      <PanelHead>{title}</PanelHead>
      <>
        <UserFields
          data={userData}
          permissions={permissions}
          hide={userFields}
          handlers={handlers}
        />
        <PasswordFields />

        {
          action === 'new' &&
          <CompanyFields
            searchingCompany={searchingCompany}
            companyPostIndex={companyPostIndex}
            handleCompanySearch={handleCompanySearch}
            data={companyData}
          />
        }

        {
          action === 'new' &&
          <ContactFields data={contactData} />
        }

        <PanelBlock>
          <ControlButtons />
        </PanelBlock>
      </>
    </Panel>
  )
}

UserForm.propTypes = {
  action:              PropTypes.string.isRequired,
  companyData:         PropTypes.object.isRequired,
  companyPostIndex:    PropTypes.object,
  contactData:         PropTypes.object.isRequired,
  handleCompanySearch: PropTypes.func.isRequired,
  handlers:            PropTypes.object.isRequired,
  hide:                PropTypes.shape({
    userFields:          PropTypes.object.isRequired
  }),
  permissions:         PropTypes.array.isRequired,
  searchingCompany:    PropTypes.bool.isRequired,
  title:               PropTypes.string.isRequired,
  userData:            PropTypes.object.isRequired
}
