import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'
import {
  SelectWithLabel as Select,
  TextWithLabel as TextInput,
  DateTimePickerWithLabel as DateTime,
  CheckBoxWithLabel as CheckBox,
  MultiselectWithLabel as Multiselect,
  ControlButtons,
  Form
} from 'web/app/common/forms'

const daysSelect = [...Array(7)].map((_, i) => {
  const dayIdx = (i + 1) % 7
  return { text: t(`common.day.${dayIdx}`), value: dayIdx }
})

const CalendarBlockForm = ({
  onSubmit,
  users
}) => {
  const isRecurringLabel = t('attributes.calendarBlock.recurring') + ' (date will be ignored)'

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        isRecurring: false
      }}
    >
      {({ formApi: { values, errors } }) =>
        <>
          <Select
            field="blockForId"
            label={t('attributes.calendarBlock.blockFor')}
            textField="fullName"
            valueField="id"
            data={users}
            search
          />

          <DateTime
            field="startAt"
            label={t('attributes.calendarBlock.startAt')}
            time
          />

          <DateTime
            field="endAt"
            label={t('attributes.calendarBlock.endAt')}
            error={errors.endsLaterThenStarts}
            time
          />

          <CheckBox
            field="isRecurring"
            label={isRecurringLabel}
          />

          {
            values.isRecurring &&
            <Multiselect
              field="days"
              data={daysSelect}
              error={errors.requireDays}
              noLabel
              placeholder="Please select days..."
            />
          }

          <TextInput
            field="note"
            label={t('attributes.calendarBlock.note')}
          />

          <ControlButtons />
        </>
      }
    </Form>
  )
}

CalendarBlockForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired
}

export default CalendarBlockForm
