import { useEffect } from 'react'
import { isUndefined } from 'lodash'

import { papp } from 'web/utility/helpers'
import { useFormContext } from './Form'

const useField = (field, { defaultValue = undefined } = {}) => {
  const { getValue, getError, getTouched, handleTouched, handleChange } = useFormContext()

  useEffect(() => {
    const value = getValue(field)
    handleChange(field, isUndefined(value) ? defaultValue : value)
  }, [])

  return {
    getValue: papp(getValue, field),
    getError: papp(getError, field),
    getTouched: papp(getTouched, field),
    handleTouched: papp(handleTouched, field),
    handleChange: papp(handleChange, field)
  }
}

export default useField
