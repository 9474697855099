import React from 'react'
import PropTypes from 'prop-types'

import { PanelBlock } from 'web/app/common/panel'
import { FormRow }    from 'web/app/common/layout'
import { t }          from 'web/locale'
import {
  InputWithLabel as Input,
  SelectWithLabel as Select
} from 'web/app/common/forms'

const ContactFields = ({ data: { titles, kinds } }) =>
  <PanelBlock>
    <h3 className="title is-3">{t('views.users.new.contactInfo')}</h3>
    <FormRow>
      <Select field="contact.title" data={titles} required />
      <Select field="contact.kind" data={kinds} required />
      <Input field="contact.jobTitle" required />
    </FormRow>
  </PanelBlock>

ContactFields.propTypes = {
  data: PropTypes.shape({
    kinds: PropTypes.array.isRequired
  })
}

export default ContactFields
