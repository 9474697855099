import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import Notify               from 'web/utility/Notify'
import { TicketsIndex }     from './component'
import { prepareSorting }   from 'web/utility/prepareData'
import { fetchData }        from './api'

export default class TicketsTableContainer extends Component {
  static propTypes = {
    tabs: PropTypes.array.isRequired
  }

  state = {
    currentTab: this.props.tabs[0],
    isModalVisible: false,
    newState: '',
    targetTicketId: null,
    transitionDataId: null,
    tableData: {
      data:     [],
      loading:  true,
      page:     1,
      pages:    1,
      pageSize: 25,
      query:    '',
      sorted:  [{ id: 'id', desc: false }]
    }
  }

  _isCurrentTab = (tab) => {
    return this.state.currentTab === tab
  }

  _handleTableChange = (state = this.state.tableData) => {
    const { currentTab, tableData } = this.state
    const { page, pageSize, sorted } = state
    const { tableData: { query } }    = this.state

    this.setState({ tableData: { ...tableData, page, pageSize, sorted, loading: true } })
    fetchData({
      page: page + 1,
      pageSize,
      query,
      sorting: prepareSorting(sorted),
      state: currentTab
    }).then(({ success, response, error }) => {
      const { tableData } = this.state

      if (success) {
        const { data, pages } = response.data

        this.setState({ tableData: { ...tableData, data, pages, loading: false } })
      } else {
        this.setState({ tableData: { ...tableData, loading: false } })
        Notify.apiError(error)
      }
    })
  }

  _handleTableQueryChange = (query) => {
    const { tableData } = this.state

    this.setState({ tableData: { ...tableData, query } }, this._handleTableChange)
  }

  _handleTabChange = (tab) => {
    if (tab === this.state.currentTab) return

    this.setState({ currentTab: tab }, this._handleTableChange)
  }

  _handleStateChange = (ticketId, currentState) => {
    return (e) => {
      const newState = e.target.value

      if (newState === currentState) return

      this.setState({ isModalVisible: true, targetTicketId: ticketId, newState })
    }
  }

  _handleCloseModal = () => {
    this.setState({ isModalVisible: false, targetTicketId: null, newState: '' })
  }

  render () {
    const {
      tableData,
      isModalVisible,
      newState,
      targetTicketId
    } = this.state

    const actions = {
      handleTableChange:      this._handleTableChange,
      handleTableQueryChange: this._handleTableQueryChange,
      handleTabChange:        this._handleTabChange,
      handleStateChange:      this._handleStateChange,
      handleCloseModal:       this._handleCloseModal
    }
    const { tabs } = this.props

    return (
      <div>
        <TicketsIndex
          actions={actions}
          tableData={tableData}
          isCurrentTab={this._isCurrentTab}
          isModalVisible={isModalVisible}
          newState={newState}
          targetTicketId={targetTicketId}
          tabs={tabs}
        />
      </div>
    )
  }
}
