import React from 'react'
import PropTypes from 'prop-types'

import { Datatable } from 'web/app/common/datatable'
import { t } from 'web/locale'

const generateColumns = ({ tariffGroups }) => [{
  Header: t('attributes.meter._'),
  accessor: 'name'
}, {
  Header: t('attributes.price.fixedFee'),
  accessor: 'fixedFee'
},
...tariffGroups.map((tg) => {
  return {
    Header: tg.name,
    columns: tg.tariffs.map((t) => {
      return {
        Header: t.name,
        id: 'prices',
        accessor: '',
        Cell: ({ original }) => {
          const price = original.prices.find((p) => p.tariff.id === t.id)
          const toDisplay = (price && price.totalPrice.toFixed(2)) || '-'

          return toDisplay
        }
      }
    })
  }
})
]

const ProposalDetailsTable = ({ margin, meters, tariffGroups }) =>
  <Datatable
    columns={generateColumns({ tariffGroups, meters, margin })}
    data={meters}
    showPagination={false}
  />

ProposalDetailsTable.propTypes = {
  margin:       PropTypes.object.isRequired,
  meters:       PropTypes.array.isRequired,
  tariffGroups: PropTypes.array.isRequired
}

export default ProposalDetailsTable
