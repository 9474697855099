import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { debounce, isPlainObject, isArray, isEqual } from 'lodash'

const withFieldSearch = (WrappedComponent, fetchData) => {
  return class extends Component {
    static propTypes = {
      initialData: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
      ]),
      preload: PropTypes.bool.isRequired
    }

    static defaultProps = {
      preload: false
    }

    constructor (props) {
      super(props)
      this._prepareData = this._prepareData.bind(this)

      this.state = {
        query: '',
        data: this._prepareData(this.props.initialData),
        loading: false
      }
    }

    componentDidMount () {
      if (this.props.preload) this._fetchDataImmediately()
    }

    componentWillReceiveProps (nextProps) {
      const current = this.props.initialData
      const next    = nextProps.initialData

      if (!isEqual(current, next)) {
        this.setState({ data: this._prepareData(next) })
      }
    }

    _prepareData (data) {
      if (isPlainObject(data)) {
        return [data]
      } else if (isArray(data)) {
        return data
      } else {
        return []
      }
    }

    _fetchDataImmediately = () => {
      const { query } = this.state
      this.setState({ loading: true })
      fetchData(query).then(this._handleFetchData)
    }
    _fetchData = debounce(this._fetchDataImmediately, 250)

    _handleFetchData = ({ success, data }) => {
      if (success) {
        this.setState({ data, loading: false })
      } else {
        this.setState({ data: [], loading: false })
      }
    }

    _handleSearch = (query, _value) => {
      if (query) {
        this.setState({ query }, this._fetchData)
      }
    }

    render () {
      return (
        <WrappedComponent
          data={this.state.data}
          handleSearch={this._handleSearch}
          search
          preload={this.props.preload}
          busy={this.state.loading}
          {...this.props}
        />
      )
    }
  }
}

export default withFieldSearch
