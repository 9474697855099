import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { uniq } from 'lodash'

import { t, date } from 'web/locale'
import { DatatableWithSearch } from 'web/app/common/datatable'
import { PanelBlock } from 'web/app/common/panel'
import { NativeSelect } from 'web/app/common/form-elements'
import { fetchMails, onChangeState } from '../api'
import { prepareSorting } from 'web/utility/prepareData'
import Notify from 'web/utility/Notify'

const columns = (onChange, states) => {
  return [
    { Header: t('attributes.contract.id'), accessor: 'id' },
    { Header: t('attributes.contract.company'), accessor: 'company.name', sortable: false },
    { Header: t('attributes.contract.product'), accessor: 'product' },
    {
      Header: t('attributes.mail.createdAt'),
      className: 'mail-createdAt',
      id: 'createdAt',
      accessor: ({ mail: { createdAt } }) => date(createdAt)
    },
    {
      Header: t('views.suppliers.mails.changeState'),
      className: 'has-text-centered',
      sortable: false,
      Cell: ({ original: { id, state } }) => { // eslint-disable-line react/prop-types
        let objStates = states.slice()
        objStates.unshift(state)
        objStates = uniq(objStates)

        return (
          <NativeSelect
            value={state}
            onChange={(e) => onChange(e.target.value, id)}
            options={objStates}
          />
        )
      }

    }
  ]
}

export default class MailTable extends Component {
  static propTypes = {
    states:         PropTypes.array.isRequired
  }

  state = {
    data:     [],
    loading:  true,
    pages:    null,
    query:    '',
    page:     1,
    pageSize: 25,
    sorted:  [{ id: 'id', desc: false }]
  }

  _handleQueryChange = (query) => {
    this.setState({ query }, this._handleChange)
  }

  _handleChange = ({ page, pageSize, sorted } = this.state) => {
    this.setState({ loading: true, page, pageSize, sorted })

    const { query } = this.state

    fetchMails({
      page: (page + 1) || 1,
      pageSize,
      query,
      sorting: prepareSorting(sorted)
    }).then(({ success, data }) => {
      this.setState({ loading: false })

      if (success) {
        this.setState({ data: data.data, pages: data.pages })
      } else {
        Notify.apiError(data)
      }
    })
  }

  _handleChangeState = (value, id) => {
    this.setState({ loading: true })

    onChangeState({
      state: value,
      id
    }).then(({ data, success }) => {
      this.setState({ loading: false })
      if (success) {
        this._handleChange()
      } else if (data.contractId) {
        Notify.error(data.contractId)
      }
    })
  }

  render () {
    const { data, loading, pages, query, sorted } = this.state
    const { states } = this.props

    return (
      <PanelBlock>
        <DatatableWithSearch
          input={{
            query,
            onChange: this._handleQueryChange
          }}
          manual
          columns={columns(this._handleChangeState, states)}
          data={data}
          loading={loading}
          pages={pages}
          onFetchData={this._handleChange}
          defaultSorted={sorted}
        />
      </PanelBlock>
    )
  }
}
