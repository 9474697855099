import http from 'axios'
import { isObject, isUndefined, isNull } from 'lodash'

const ok = (response, { data } = {}) => {
  return {
    success:  true,
    failure:  false,
    response: response,
    error:    undefined,
    data:     data || response.data
  }
}

const fail = (error, { data } = {}) => {
  // NOTE: this logging were added due to specific
  // behavour of axios: https://www.npmjs.com/package/axios#handling-errors
  console.error(error)

  return {
    success:  false,
    failure:  true,
    response: error.response,
    error:    error,
    data:     data || (error.response && error.response.data)
  }
}

const get = (path, opts = {}) =>
  http
    .get(path, opts)
    .then(ok)
    .catch(fail)

const post = (path, opts = {}) =>
  http
    .post(path, opts)
    .then(ok)
    .catch(fail)

const patch = (path, opts = {}) =>
  http
    .patch(path, opts)
    .then(ok)
    .catch(fail)

const destroy = (path, opts = {}) =>
  http
    .delete(path, opts)
    .then(ok)
    .catch(fail)

const currentPath = () => {
  const path = window.location.pathname
  return path.endsWith('/') ? path.slice(0, -1) : path
}

const pathWith = (string) => {
  return `${currentPath()}${string}`
}

const apiPathWith = (string) => {
  return apiPath(pathWith(string))
}

const apiPath = (string) => {
  return `/api/v1${string}`
}

// Primarely used for file uploads.
// As a bonus, it will automatically set 'Content-Type' header
const toFormData = (params) => {
  const fd = new FormData() // eslint-disable-line no-undef

  Object
    .keys(params)
    .forEach((key) => {
      const value = params[key]
      if ((!isObject(value) || !isNull(value.value)) && !isUndefined(value)) {
        fd.append(key, params[key])
      }
    })

  return fd
}

export {
  http,
  currentPath,
  pathWith,
  apiPath,
  apiPathWith,
  toFormData,
  ok,
  fail,
  get,
  post,
  patch,
  destroy
}
