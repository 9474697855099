import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import { toJS } from 'mobx'

import {
  CallsTable,
  CallsHeading,
  CallbacksTable,
  MeetingsTable,
  TicketsHeading,
  TicketsTable
} from './components'
import { Panel, PanelBlock, PanelHead } from 'web/app/common/panel'
import { t } from 'web/locale'

const Communication = ({
  onCallsTableChange,
  onCallbacksTableChange,
  onTicketsTableChange,
  onMeetingsTableChange,
  company,
  callsStates,
  callsSubStates,
  callsContacts,
  spokeWithOpts,
  meetingsDurations,
  sales,
  callAgents,
  ticketCategories,
  companyContracts,
  permissions,
  users,
  postCalls,
  validateCallsForm,
  afterAddContactRedirectUrl
}) => (
  <div>
    {permissions.calls.includes('read_all') && (
      <Panel>
        <PanelHead>
          <CallsHeading
            title={t('common.panelHeaders.calls')}
            buttonTitle={t('common.button.add')}
            companyId={company.id}
            callsStates={toJS(callsStates)}
            callsSubStates={toJS(callsSubStates)}
            callsContacts={toJS(callsContacts)}
            spokeWithOpts={toJS(spokeWithOpts)}
            meetingsDurations={toJS(meetingsDurations)}
            sales={toJS(sales)}
            callAgents={toJS(callAgents)}
            validateCallsForm={validateCallsForm}
            postCalls={postCalls}
            afterAddContactRedirectUrl={afterAddContactRedirectUrl}
          />
        </PanelHead>
        <PanelBlock>
          <CallsTable onChange={onCallsTableChange} />
        </PanelBlock>
      </Panel>
    )}

    {permissions.callbacks.includes('read_all') && (
      <Panel>
        <PanelHead>{t('common.panelHeaders.callbacks')}</PanelHead>
        <PanelBlock>
          <CallbacksTable onChange={onCallbacksTableChange} />
        </PanelBlock>
      </Panel>
    )}

    {permissions.meetings.includes('read_all') && (
      <Panel>
        <PanelHead>{t('views.companies.show.meetings.title')}</PanelHead>
        <PanelBlock>
          <MeetingsTable onChange={onMeetingsTableChange} />
        </PanelBlock>
      </Panel>
    )}

    {permissions.tickets.includes('read_all') && (
      <Panel>
        <PanelHead>
          <TicketsHeading
            title={t('views.companies.show.tickets.title')}
            buttonTitle={t('common.button.add')}
            companyId={company.id}
            users={toJS(users)}
            ticketCategories={toJS(ticketCategories)}
            companyContracts={toJS(companyContracts)}
          />
        </PanelHead>
        <PanelBlock>
          <TicketsTable
            onChange={onTicketsTableChange}
            companyId={company.id}
            users={toJS(users)}
            ticketCategories={toJS(ticketCategories)}
            companyContracts={toJS(companyContracts)}
          />
        </PanelBlock>
      </Panel>
    )}
  </div>
)

Communication.propTypes = {
  callsContacts: MobxPropTypes.observableArray.isRequired,
  spokeWithOpts: MobxPropTypes.observableArray.isRequired,
  callsStates: MobxPropTypes.observableArray.isRequired,
  callsSubStates: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  companyContracts: MobxPropTypes.observableArray.isRequired,
  sales: PropTypes.array.isRequired,
  callAgents: PropTypes.array.isRequired,
  meetingsDurations: PropTypes.object.isRequired,
  onCallbacksTableChange: PropTypes.func.isRequired,
  onCallsTableChange: PropTypes.func.isRequired,
  onMeetingsTableChange: PropTypes.func.isRequired,
  onTicketsTableChange: PropTypes.func.isRequired,
  ticketCategories: MobxPropTypes.observableArray.isRequired,
  permissions: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  postCalls: PropTypes.func.isRequired,
  validateCallsForm: PropTypes.func.isRequired,
  afterAddContactRedirectUrl: PropTypes.string.isRequired
}

export default Communication
