import React from 'react'
import PropTypes from 'prop-types'

import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import { FormRow } from 'web/app/common/layout'
import { SearchPostIndex } from 'web/app/postIndices/SearchPostIndex'
import {
  ControlButtons,
  InputWithLabel as Input,
  SelectWithLabel as Select,
  TextWithLabel as Text,
  MultiselectWithLabel
} from 'web/app/common/forms'

export default function CompanyForm ({
  companyCategories,
  companyTypes,
  naceList,
  initialPostIndex,
  languages,
  title,
  uinAndVatRequired,
  uinLabel,
  vatLabel,
  handleSearchNaceCodes
}) {
  return (
    <Panel>
      <PanelHead>{title}</PanelHead>
      <PanelBlock>
        <FormRow columns={2} >
          <Input field="company.name" required />
          <Input field="company.shortName" />
        </FormRow>

        <FormRow columns={2} >
          <Input field="company.uin" label={uinLabel} required />
          { uinAndVatRequired && <Input field="company.vat" label={vatLabel} required /> }
        </FormRow>

        <FormRow columns={2} >
          <Input field="company.website" />
          <Input field="company.email" required />
        </FormRow>

        <FormRow columns={2} >
          <Input field="company.tel" required />
          <Input field="company.mobile" />
        </FormRow>

        <FormRow columns={2} >
          <Input field="company.address" required />
          <SearchPostIndex field="company.postIndexId" initialData={initialPostIndex} required />
        </FormRow>

        <FormRow columns={2} >
          <Input field="company.fax" />
          <Input field="company.sector" />
        </FormRow>

        <FormRow columns={2} >
          <Select field="company.languageId" data={languages} required />
          <Select field="company.juridicalSituationCode" data={[]} disabled />
        </FormRow>

        <FormRow columns={2} >
          <Select field="company.companyTypeId" data={companyTypes} required />
          <Select field="company.companyCategoryId" data={companyCategories} required />
        </FormRow>

        <FormRow columns={2} >
          <MultiselectWithLabel
            field="company.nace"
            data={naceList}
            onSearch={(searchTerm) => handleSearchNaceCodes({ searchTerm })}
          />
          <Input field="company.iban" defaultValue="no iban" required />
        </FormRow>

        <FormRow columns={1} >
          <Text field="company.note" />
        </FormRow>
      </PanelBlock>

      <PanelBlock>
        <ControlButtons />
      </PanelBlock>
    </Panel>
  )
}

CompanyForm.propTypes = {
  companyCategories:     PropTypes.array.isRequired,
  companyTypes:          PropTypes.array.isRequired,
  initialPostIndex:      PropTypes.object,
  languages:             PropTypes.array.isRequired,
  title:                 PropTypes.string.isRequired,
  uinAndVatRequired:     PropTypes.bool.isRequired,
  uinLabel:              PropTypes.string.isRequired,
  vatLabel:              PropTypes.string.isRequired,
  naceList:              PropTypes.array.isRequired,
  handleSearchNaceCodes: PropTypes.func.isRequired
}
