import React from 'react'
import PropTypes from 'prop-types'

import { Panel, PanelBlock } from 'web/app/common/panel'
import SelectPriceFilterType from './components/SelectPriceFilterType'
import MeterFilters          from './components/MeterFilters'
import DurationFilters       from './components/DurationFilters'
import VolumeFilters         from './components/VolumeFilters'
import VolumeFiltersForm     from './components/VolumeFiltersForm'

const SupplierFilters = ({
  data,
  durations,
  isLoading,
  marginLetters,
  meters,
  onCreateFilter,
  onDeleteFilter,
  onFilterTypeChange,
  onCreateVolumeFilters,
  priceModel,
  priceModels,
  product,
  products,
  shouldApplyVolume
}) => {
  let volumeComponents = null
  if (shouldApplyVolume) {
    volumeComponents = (
      <div>
        <VolumeFiltersForm
          isLoading={isLoading}
          marginLetters={marginLetters}
          onCreateVolumeFilters={onCreateVolumeFilters}
        />
        <br />
        <VolumeFilters
          createFilter={onCreateFilter}
          data={data.volumePriceFilters}
          deleteFilter={onDeleteFilter}
          isLoading={isLoading}
          marginLetters={marginLetters}
        />
      </div>
    )
  }

  return (
    <Panel>
      <PanelBlock>
        <SelectPriceFilterType
          onFilterTypeChange={onFilterTypeChange}
          priceModel={priceModel}
          priceModels={priceModels}
          product={product}
          products={products}
        />

        <MeterFilters
          createFilter={onCreateFilter}
          data={data.meterPriceFilters}
          deleteFilter={onDeleteFilter}
          isLoading={isLoading}
          meters={meters}
        />
        <br />

        <DurationFilters
          createFilter={onCreateFilter}
          data={data.durationPriceFilters}
          deleteFilter={onDeleteFilter}
          durations={durations}
          isLoading={isLoading}
        />
        <br />

        {volumeComponents}
      </PanelBlock>
    </Panel>
  )
}

SupplierFilters.propTypes = {
  data: PropTypes.shape({
    meterPriceFilters:    PropTypes.array.isRequired,
    durationPriceFilters: PropTypes.array.isRequired,
    volumePriceFilters:   PropTypes.array.isRequired
  }).isRequired,
  durations:             PropTypes.array.isRequired,
  isLoading:             PropTypes.bool.isRequired,
  marginLetters:         PropTypes.array.isRequired,
  meters:                PropTypes.array.isRequired,
  onCreateFilter:        PropTypes.func.isRequired,
  onCreateVolumeFilters: PropTypes.func.isRequired,
  onDeleteFilter:        PropTypes.func.isRequired,
  onFilterTypeChange:    PropTypes.func.isRequired,
  priceModel:            PropTypes.string.isRequired,
  priceModels:           PropTypes.array.isRequired,
  product:               PropTypes.string.isRequired,
  products:              PropTypes.array.isRequired,
  shouldApplyVolume:     PropTypes.bool.isRequired
}

export default SupplierFilters
