import buildValidation from 'web/utility/validations'

export default (values) => {
  const { filled, editorFilled } = buildValidation(values)
  const validation = {
    mail: {
      supplierId: filled('mail.supplierId'),
      body: editorFilled('mail.body')
    }
  }

  return validation
}
