import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'web/locale'
import { updateLeadListStatus } from '../actions'
import { validateStatusChangeForm } from '../validations'
import { ChangeLeadListStatus } from 'web/app/leadLists/ChangeLeadListStatus'

export default class EditStatusModal extends Component {
  static propTypes = {
    handleCloseModal: PropTypes.func.isRequired,
    isModalVisible: PropTypes.bool.isRequired,
    targetLeadListId: PropTypes.number,
    newStatus: PropTypes.string.isRequired
  }

  _handleSubmit = (values, { setSubmitting, handleErrors, resetForm }) => {
    const { targetLeadListId } = this.props

    updateLeadListStatus({
      id: targetLeadListId,
      values
    }).then(({ success, data }) => {
      if (success) {
        resetForm()
        this.props.handleCloseModal()
        window.location.reload()
      } else {
        handleErrors(data)
        setSubmitting(false)
      }
    })
  }

  render () {
    const { isModalVisible, newStatus } = this.props

    const modalProps = {
      header:  t(`views.leadLists.index.updateStatus.title`),
      isShown: isModalVisible,
      onClose: this.props.handleCloseModal
    }
    const initialValues = { status: newStatus }

    return (
      <div>
        <ChangeLeadListStatus
          status={newStatus}
          modalProps={modalProps}
          handleSubmit={this._handleSubmit}
          validateStatusChangeForm={validateStatusChangeForm}
          initialValues={initialValues}
        />
      </div>
    )
  }
}
