import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { countryEanName } from 'web/utility/country'
import { InfoWithLabel }  from 'web/app/common/form-elements'
import { Button, Icon }   from 'web/app/common/elements'
import { t, daysDiff }    from 'web/locale'
import { PanelBlock }     from 'web/app/common/panel'
import { FormRow }        from 'web/app/common/layout'
import {
  InputWithLabel as Input,
  SelectWithLabel as Select,
  CheckBoxWithLabel as CheckBox,
  DateTimePickerWithLabel as DateTime
} from 'web/app/common/forms'

export default class EanStats extends Component {
  static propTypes = {
    canEditEanStatField: PropTypes.bool.isRequired,
    hasPriceRate:        PropTypes.bool.isRequired,
    getValue:            PropTypes.func.isRequired,
    prefillStartDate:    PropTypes.func.isRequired,
    fixedFeePeriods:     PropTypes.array.isRequired,
    tariffGroups:        PropTypes.array.isRequired,
    suppliers:           PropTypes.array.isRequired,
    reasons:             PropTypes.array.isRequired,
    units:               PropTypes.array.isRequired,
    onTariffGroupChange:  PropTypes.func.isRequired
  }

  render () {
    const {
      suppliers, reasons, fixedFeePeriods, units,
      hasPriceRate, tariffGroups, getValue, prefillStartDate,
      onTariffGroupChange, canEditEanStatField
    } = this.props

    const invoicingTo     = getValue('eanStat.invoicingTo')
    const invoicingFrom   = getValue('eanStat.invoicingFrom')

    const invoicingPeriod = daysDiff(invoicingFrom, invoicingTo)
    const meter           = getValue('ean.meterId')
    const hammerButton = (
      <Button onClick={prefillStartDate} disabled={!canEditEanStatField} >
        <Icon iconClass="icon-hammer" />
      </Button>
    )

    return (
      <PanelBlock>
        <h5 className="title is-5">{t('views.eans.new.stats', { ean_name: countryEanName() })}</h5>

        <FormRow>
          <Select
            field="eanStat.supplierId"
            data={suppliers}
            search
            sort
            disabled={!canEditEanStatField}
          />
        </FormRow>

        <FormRow>
          <Select field="eanStat.reason" data={reasons} required />
          <DateTime
            field="eanStat.deliverFrom"
            button={hammerButton}
            required
            disabled={!canEditEanStatField}
          />
        </FormRow>

        <FormRow>
          <DateTime
            field="eanStat.invoicingFrom"
            time={false}
            format="DD/MM/YYYY"
            required
            disabled={!canEditEanStatField}
          />
          <DateTime
            field="eanStat.invoicingTo"
            time={false}
            format="DD/MM/YYYY"
            required
            disabled={!canEditEanStatField}
          />
          <InfoWithLabel name={t('attributes.eanStat.invoicingPeriod')}>
            {invoicingPeriod}
          </InfoWithLabel>
        </FormRow>

        <FormRow>
          <CheckBox
            field="helper.priceRate"
            label={t('views.eans.common.priceRate')}
            disabled
          />
          <CheckBox field="eanStat.greenEnergy" disabled={!canEditEanStatField} />
          <CheckBox field="eanStat.solarPanels" disabled={!canEditEanStatField} />
        </FormRow>

        {
          hasPriceRate &&
          <div>
            <FormRow>
              <Input field="eanStat.fixedFee" type="number" required />
              <Select
                field="eanStat.fixedFeePeriod"
                data={fixedFeePeriods}
                required
              />
            </FormRow>

            <Select
              wrapperClassNames={'display-none'}
              field="eanStat.priceModel"
              data={['fix']}
              value="fix"
              required
            />
            <FormRow>
              <Select
                field="eanStat.tariffGroupId"
                textField="name"
                valueField="id"
                data={tariffGroups}
                onChange={onTariffGroupChange}
                required
                disabled={!meter}
              />
              <Select
                field="eanStat.unitId"
                data={units}
                textField="name"
                valueField="id"
                required
              />
            </FormRow>
          </div>
        }
      </PanelBlock>
    )
  }
}
