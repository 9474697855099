import React, { Component } from 'react'
import LeadListForm from './component'
import {
  createLeadList,
  searchUsers,
  searchPostIndex,
  loadFormData,
  filteredCompaniesCounter
} from './api'
import Notify from 'web/utility/Notify'
import { Columns, Column } from 'web/app/common/layout'
import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import { Form } from 'web/app/common/forms'
import { t } from 'web/locale'
import PropTypes from 'prop-types'
import { set, isEmpty, cloneDeep } from 'lodash'
import { diff } from 'deep-diff'

export default class LeadListFormContainer extends Component {
  static propTypes = {
    formData: PropTypes.object.isRequired
  }

  state = {
    citiesOptions: [],
    zipOptions: [],
    countyOptions: [],
    salesOptions: [],
    nacesOptions: [],
    callAgentsOptions: [],
    categoryOptions: [],
    callAgentsSelectingDisabled: true,
    salesSelectingDisabled: true,
    postIndexFieldsDisabled: true,
    totalCompaniesFiltered: null,
    lastParamsFormFiltering: null
  }

  componentDidMount () {
    loadFormData().then((response) => {
      this.setState({
        nacesOptions: response.data.naces,
        categoryOptions: response.data.categories
      })
    })
  }

  _afterHookTriggers = (values) => {
    if (isEmpty(values) || isEmpty(values['leadList'])) return

    const previousValues = this.state.lastParamsFormFiltering
    const difference = previousValues && diff(previousValues['leadList'], values['leadList'])

    if (difference && (difference[0]['path'][0] === 'name')) {
      this.setState({ lastParamsFormFiltering: values })
      return
    }

    const stateChangedAt = values['leadList']['stateChangedAt']
    if (stateChangedAt === '' || stateChangedAt === null) {
      values['leadList']['stateChangedAt'] = undefined
    }

    if (values['leadList']['stateFrom'] === '' || values['leadList']['stateFrom'] === null) {
      values['leadList']['stateFrom'] = undefined
    }

    if (values['leadList']['stateTill'] === '' || values['leadList']['stateTill'] === null) {
      values['leadList']['stateTill'] = undefined
    }

    if (JSON.stringify(values) !== JSON.stringify(previousValues)) {
      filteredCompaniesCounter({ values }).then(({ success, data }) => {
        if (success) {
          this.setState({ totalCompaniesFiltered: data['filteredCompanies'] })
          this.setState({ lastParamsFormFiltering: values })
        }
      })
    }
  }

  _handleSubmit = (values, { setSubmitting, handleErrors }) => {
    createLeadList({ values }).then(({ success, data }) => {
      if (success) {
        window.location = data.url
        Notify.success(t('views.leadLists.new.success'))
      } else {
        data.message ? Notify.error(data.message) : handleErrors(data)
        setSubmitting(false)
      }
    })
  }

  _handleChangeArea = ({ setValues, vals }) => {
    const params = { fields: ['city', 'postal_code', 'county'], area: vals.id }

    setValues((prevValues) => {
      const newValues = cloneDeep(prevValues)
      set(newValues, 'leadList.cities', undefined)
      set(newValues, 'leadList.postalCodes', undefined)
      set(newValues, 'leadList.counties', undefined)
      return newValues
    })

    if (vals) {
      searchPostIndex(params).then((response) => {
        this.setState({
          citiesOptions: response.data.city,
          zipOptions: response.data.postalCode,
          countyOptions: response.data.county,
          postIndexFieldsDisabled: false
        })
      })
    }
  }

  _handleSearchCities = ({ getValue, searchTerm }) => {
    if (searchTerm.length >= 3 || searchTerm === '') {
      const area = getValue('leadList.areaId')
      const counties = getValue('leadList.counties')
      const postalCodes = getValue('leadList.postalCodes')
      const params = { area, counties, fields: ['city'], query: searchTerm, postalCodes }

      searchPostIndex(params).then((response) => {
        this.setState({ citiesOptions: response.data.city })
      })
    }
  }

  _handleSearchZips = ({ getValue, searchTerm }) => {
    if (searchTerm.length >= 1 || searchTerm === '') {
      const area = getValue('leadList.areaId')
      const counties = getValue('leadList.counties')
      const params = { area, counties, fields: ['postal_code'], query: searchTerm }

      searchPostIndex(params).then((response) => {
        this.setState({ zipOptions: response.data.postalCode })
      })
    }
  }

  _handleSearchCounties = ({ getValue, searchTerm }) => {
    const area = getValue('leadList.areaId')

    searchPostIndex({ area, fields: ['county'], query: searchTerm.toString() }).then((response) => {
      this.setState({ countyOptions: response.data.county })
    })
  }

  _handleChangeCounties = ({ getValue, vals }) => {
    const area = getValue('leadList.areaId')

    const params = { area, counties: vals, fields: ['city', 'postal_code'] }

    if (vals) {
      searchPostIndex(params).then((response) => {
        this.setState({
          citiesOptions: response.data.city,
          zipOptions: response.data.postalCode,
          postIndexFieldsDisabled: false
        })
      })
    }
  }

  _handleChangeZips = ({ getValue, vals }) => {
    const area = getValue('leadList.areaId')
    const counties = getValue('leadList.counties')

    const params = { area, counties, postalCodes: vals, fields: ['city'] }

    if (vals) {
      searchPostIndex(params).then((response) => {
        this.setState({
          citiesOptions: response.data.city,
          postIndexFieldsDisabled: false
        })
      })
    }
  }

  _handleChangeOrganisation = (vals) => {
    searchUsers({
      organisationId: vals.id,
      role: 'call_agent'
    }).then((response) => {
      if (response.data.length > 0) {
        this.setState({ callAgentsOptions: response.data })
        this.setState({ callAgentsSelectingDisabled: false })
      } else {
        this.setState({ callAgentsOptions: [] })
        this.setState({ salesSelectingDisabled: false })
      }
    })

    searchUsers({
      organisationId: vals.id,
      role: 'sales'
    }).then((response) => {
      if (response.data.length > 0) {
        this.setState({ salesOptions: response.data })
        this.setState({ salesSelectingDisabled: false })
      } else {
        this.setState({ salesOptions: [] })
        this.setState({ salesSelectingDisabled: true })
      }
    })
  }

  render () {
    const { formData } = this.props
    const {
      citiesOptions,
      zipOptions,
      countyOptions,
      salesOptions,
      nacesOptions,
      callAgentsOptions,
      categoryOptions,
      callAgentsSelectingDisabled,
      salesSelectingDisabled,
      postIndexFieldsDisabled,
      totalCompaniesFiltered
    } = this.state
    const selectsOptions = {
      citiesOptions,
      zipOptions,
      countyOptions,
      salesOptions,
      nacesOptions,
      callAgentsOptions,
      categoryOptions,
      callAgentsSelectingDisabled,
      salesSelectingDisabled,
      postIndexFieldsDisabled
    }
    const actions = {
      handleChangeArea:     this._handleChangeArea,
      handleSearchCities:   this._handleSearchCities,
      handleSearchZips:     this._handleSearchZips,
      handleSearchCounties: this._handleSearchCounties,
      handleSearchNaces:    this._handleSearchNaces,
      handleChangeCities:   this._handleChangeCities,
      handleChangeZips:     this._handleChangeZips,
      handleChangeCounties: this._handleChangeCounties,
      handleChangeNaces:    this._handleChangeNaces,
      handleChangeCategories: this._handleChangeCategories,
      handleChangeOrganisation: this._handleChangeOrganisation
    }

    return (
      <Panel>
        <PanelHead>
          Create new lead list
        </PanelHead>
        <PanelBlock>
          <Columns>
            <Column classNames="is-three-quarters">
              <Form
                onSubmit={this._handleSubmit}
                afterHookTriggers={this._afterHookTriggers}
              >
                <LeadListForm
                  formData={formData}
                  actions={actions}
                  selectsOptions={selectsOptions}
                  totalCompaniesFiltered={totalCompaniesFiltered}
                />
              </Form>
            </Column>
            <Column>
              <Columns>
                <Column />
                <Column classNames="notification is-info companies-counter">
                  <Columns>
                    <Column>
                      Total filtered companies:
                    </Column>
                  </Columns>
                  <Columns>
                    <Column classNames="counter">
                      { totalCompaniesFiltered }
                    </Column>
                  </Columns>
                </Column>
              </Columns>
            </Column>
          </Columns>
        </PanelBlock>
      </Panel>
    )
  }
}
