import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const LevelItem = ({ children, hasTextCentered }) => {
  const classNames = cn('level-item', { 'has-text-centered': hasTextCentered })
  return <div className={classNames}>{children}</div>
}

LevelItem.propTypes = {
  children: PropTypes.any.isRequired,
  hasTextCentered: PropTypes.bool
}

LevelItem.defaultProps = {
  hasTextCentered: false
}

export default LevelItem
