import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'web/locale'
import {
  ControlButtons,
  Form,
  TextAreaWithLabel as TextArea
} from 'web/app/common/forms'
import { Modal } from 'web/app/common/modal'

const ChangeTicketState = ({
  state,
  modalProps,
  handleSubmit,
  validateStateChangeForm,
  initialValues
}) => (
  <div>
    <Modal {...modalProps} >
      <Form
        initialValues={initialValues || {}}
        onSubmit={handleSubmit}
        validation={validateStateChangeForm}
      >
        {() =>
          <>
            {
              !['done', 'in_progress'].includes(state) && <TextArea
                field="comment"
                placeholder={t(`views.tickets.index.updateState.title`)}
                withLabel={false}
                required
              />
            }
            <input name="state" field="state" type="hidden" />
            <ControlButtons withoutReset />
          </>
        }
      </Form>
    </Modal>
  </div>
)

ChangeTicketState.propTypes = {
  state: PropTypes.string.isRequired,
  modalProps: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  validateStateChangeForm: PropTypes.func.isRequired,
  initialValues: PropTypes.object
}

export default ChangeTicketState
