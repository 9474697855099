import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'
import {
  ControlButtons,
  Form,
  InputWithLabel as Input,
  DateTimePickerWithLabel as DateTime,
  MultiselectWithLabel as MultiSelect,
  SelectWithLabel as Select,
  FileInputWithLabel as FileInput,
  TextAreaWithLabel as TextArea
} from 'web/app/common/forms'
import { Modal } from 'web/app/common/modal'

const ModalTicketForm = ({
  modalProps,
  handleSubmit,
  initialValues,
  validateForm,
  ticketsUsers,
  bccEmails,
  ticketsCategories,
  companyContracts,
  getFileName
}) => (
  <Modal {...modalProps}>
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validation={validateForm}
    >
      {() =>
        <>
          <Select
            field="ticket.createdForId"
            label={t('attributes.ticket.createdForId')}
            textField="fullName"
            valueField="id"
            search
            data={ticketsUsers}
            required
          />
          <MultiSelect
            field="ticket.bccEmails"
            label={t('attributes.ticket.bccEmails')}
            data={bccEmails || []}
          />
          <Select
            field="ticket.category"
            label={t('attributes.ticket.category')}
            data={ticketsCategories}
            textField="humanized"
            valueField="default"
            required
          />
          <DateTime
            field="ticket.deadline"
            label={t('attributes.ticket.deadline')}
            time={false}
            required
          />
          <Input
            field="ticket.description"
            label={t('attributes.ticket.description')}
            required
          />
          <TextArea
            field={'ticket.note'}
            label={t('attributes.ticket.note')}
          />
          <MultiSelect
            textField="supplierName"
            valueField="id"
            field="ticket.contractsIds"
            label={t('attributes.ticket.contracts')}
            data={companyContracts}
          />
          <FileInput
            getFileName={getFileName}
            field="ticket.file"
            label={t('attributes.ticket.attachment')}
          />
          <ControlButtons />
        </>
      }
    </Form>
  </Modal>
)

ModalTicketForm.propTypes = {
  modalProps: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  ticketsUsers: PropTypes.array.isRequired,
  bccEmails: PropTypes.array,
  ticketsCategories: PropTypes.array.isRequired,
  companyContracts: PropTypes.array.isRequired,
  getFileName: PropTypes.func
}

export default ModalTicketForm
