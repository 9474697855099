import React from 'react'
import PropTypes from 'prop-types'

import { Datatable } from 'web/app/common/datatable'
import { Icon } from 'web/app/common/elements'
import { t } from 'web/locale'

const columns = (onMarginDelete) => {
  return [
    {
      Header: t('attributes.margin.type'),
      accessor: 'type'
    },
    {
      Header: t('attributes.margin.letter'),
      accessor: 'letter'
    },
    {
      Header: t('attributes.margin.product'),
      accessor: 'product'
    },
    {
      Header: t('attributes.margin.priceModel'),
      accessor: 'priceModel'
    },
    {
      Header: t('common.range.begin'),
      id: 'volumeFrom',
      accessor: (obj) => obj.volume.begin
    },
    {
      Header: t('common.range.end'),
      id: 'volumeTo',
      accessor: (obj) => obj.volume.end
    },
    {
      Header: t('attributes.margin.salesMargin'),
      accessor: 'salesMargin'
    },
    {
      header: t('attributes.margin.passive'),
      Hccessor: 'passive'
    },
    {
      Header: t('attributes.margin.bruttoBroker'),
      accessor: 'bruttoBroker'
    },
    {
      Header: t('attributes.margin.nettoBroker'),
      accessor: 'nettoBroker'
    },
    {
      Header: t('attributes.margin.total'),
      accessor: 'totalMargin'
    },
    {
      className: 'has-text-centered',
      sortable: false,
      minWidth: 50,
      Cell: ({ row: { _original } }) => { // eslint-disable-line
        return (
          <Icon iconClass="icon-trash" onClick={() => onMarginDelete(_original.id)} />
        )
      }
    }
  ]
}

const MarginsTable = ({ data, isLoading, onMarginDelete }) => {
  return (
    <Datatable
      loading={isLoading}
      columns={columns(onMarginDelete)}
      data={data}
      defaultPageSize={100}
      showPagination={false}
    />
  )
}

MarginsTable.propTypes = {
  data:           PropTypes.array.isRequired,
  isLoading:      PropTypes.bool.isRequired,
  onMarginDelete: PropTypes.func.isRequired
}

export default MarginsTable
