import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'
import { isCountry } from 'web/utility/country'

import { Button, ButtonLink, ButtonGroup } from 'web/app/common/elements'
import { PanelBlock } from 'web/app/common/panel'
import { papp } from '../../../../../utility/helpers'

const Controls = ({
  offer,
  product,
  onFinalize,
  onSendOfferPdf,
  goTo
}) => {
  const consumption = offer.getConsumptionData(product)
  const canCreateConsumption = offer.can(`create_${product}_consumption_data`) && !consumption
  const canSignConsumption = consumption && !consumption.signedAt
  const canDownloadConsumption = consumption && consumption.signedAt

  return (
    <PanelBlock>
      <ButtonGroup>
        <Button className="finalize-proposal" onClick={onFinalize(product)}>
          {t('views.offers.show.links.finalize')}
        </Button>
        {
          !isCountry('fr') &&
          <ButtonLink
            className="download-proposal"
            href={`/offers/${offer.id}/proposals/download`}
            download
          >
            {t('views.offers.show.links.offerPdf')}
          </ButtonLink>
        }
        {
          !isCountry('fr') &&
          <Button className="send-proposal" onClick={onSendOfferPdf}>
            {t('views.offers.show.links.sendOfferPdf')}
          </Button>
        }
        {
          canCreateConsumption &&
          <Button
            className="sign-dno-consumption"
            onClick={() => goTo(`/offers/${offer.id}/consumption_data/${product}`)}
          >
            {t('views.offers.show.links.signDnoConsumption')}
          </Button>
        }
        {
          canSignConsumption &&
          <Button
            className="sign-dno-consumption"
            onClick={() => goTo(
              `/offers/${offer.id}/consumption_data/${consumption.id}/sign`
            )}
          >
            {t('views.offers.show.links.signDnoConsumption')}
          </Button>
        }
        {
          canDownloadConsumption &&
          <ButtonLink
            className="download-dno-consumption"
            href={consumption.downloadPath}
            download
          >
            {t('views.offers.show.links.downloadDnoConsumption')}
          </ButtonLink>
        }
      </ButtonGroup>
    </PanelBlock>
  )
}

Controls.propTypes = {
  offer: PropTypes.object.isRequired,
  product: PropTypes.string.isRequired,
  onFinalize: PropTypes.func.isRequired,
  onSendOfferPdf: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired
}

export default Controls
