import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'

import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import { Tabs, Tab } from 'web/app/common/layout'
import { TableWithSearch } from './components'

const renderTab = (isCurrentTab, onTabChange) =>
  (tab) =>
    <Tab isActive={isCurrentTab(tab)} key={tab}>
      <a onClick={() => onTabChange(tab)}>{t(`views.consumptionData.index.tabs.${tab}`)}</a>
    </Tab>

const ConsumptionDataTable = ({
  onChange,
  onSearch,
  onMailClick,
  onStateChange,
  onTabChange,
  isCurrentTab,
  tabs,
  tableData
}) => {
  const tableProps = {
    ...tableData,
    onChange,
    onSearch,
    onMailClick,
    onStateChange,
    isCurrentTab
  }

  return (
    <div>
      <Panel kind="primary">
        <PanelHead>{t('views.consumptionData.index.title')}</PanelHead>
      </Panel>

      <Tabs>
        { tabs.map(renderTab(isCurrentTab, onTabChange)) }
      </Tabs>

      <Panel>
        <PanelBlock>
          <TableWithSearch {...tableProps} />
        </PanelBlock>
      </Panel>
    </div>
  )
}

ConsumptionDataTable.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onMailClick: PropTypes.func.isRequired,
  onStateChange: PropTypes.func.isRequired,
  onTabChange: PropTypes.func.isRequired,
  isCurrentTab: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired,
  tableData: PropTypes.object.isRequired
}

export { ConsumptionDataTable }
