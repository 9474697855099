import alertify              from 'alertify.js'
import domready              from 'domready'
import { isArray, isString } from 'lodash'

domready(() => {
  alertify.parent(document.body)
})

class Notify {
  static alert (message) {
    return alertify.alert(message)
  }

  static confirm (message, ok, cancel) {
    return alertify.confirm(message, ok, cancel)
  }

  static prompt (message, ok, cancel) {
    return alertify.prompt(message, ok, cancel)
  }

  // deprecated
  static errorAlert (error) {
    const { data } = error.response

    let msg
    if (isArray(data)) {
      msg = data.join('<br />')
    } else if (data.message) {
      msg = data.message
    } else {
      msg = isString(data) ? data : error
    }

    return alertify.alert(msg)
  }

  static apiError (error) {
    const data = (error.response.data || {})

    let msg
    if (isArray(data)) {
      msg = data.join('<br />')
      return alertify.alert(msg)
    } else if (data.message) {
      return alertify.error(data.message)
    } else if (isString(data)) {
      return alertify.alert(data)
    } else {
      return alertify.error(error)
    }
  }

  static success (message) {
    return alertify.success(message)
  }

  static error (message) {
    return alertify.error(message)
  }

  static log (message) {
    return alertify.log(message)
  }

  static clearLogs () {
    return alertify.clearLogs()
  }
}

export default Notify
