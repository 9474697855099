import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const Input = ({
  id,
  value,
  defaultValue,
  handleChange,
  handleKeyPress,
  type,
  placeholder,
  isExpanded,
  name,
  handleBlur
}) => {
  if (value && !handleChange) {
    throw new Error('No handler provided to Input')
  } else if (!value && value !== '' && handleChange) {
    throw new Error('No value provided to Input')
  }

  const classNames = cn('input', { 'is-expanded': isExpanded })

  return (
    <input
      id={id}
      className={classNames}
      name={name}
      value={value}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      defaultValue={defaultValue}
      type={type}
      placeholder={placeholder}
      onBlur={handleBlur}
    />
  )
}

Input.propTypes = {
  id:             PropTypes.string,
  value:          PropTypes.string,
  defaultValue:   PropTypes.string,
  handleChange:   PropTypes.func,
  handleKeyPress: PropTypes.func,
  type:           PropTypes.string.isRequired,
  placeholder:    PropTypes.string.isRequired,
  isExpanded:     PropTypes.bool.isRequired,
  name:           PropTypes.string,
  handleBlur:     PropTypes.func
}

Input.defaultProps = {
  id: '',
  type: 'text',
  placeholder: '',
  isExpanded: false
}

export default Input
