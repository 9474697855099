import { apiPathWith, toFormData, get, post, destroy } from 'web/utility/http'

const prepareParams = (values) => {
  const params = toFormData(values)
  params.append('file', values.file)
  return params
}

const apiTemplatesPath = (path) =>
  apiPathWith(path).replace('/contract_templates', '')

const downloadTemplatePath = (id) =>
  apiTemplatesPath(`/contract_templates/${id}/download`)

const fetchTemplates = () =>
  get(apiTemplatesPath('/contract_templates'))

const deleteTemplate = ({ id }) =>
  destroy(apiTemplatesPath(`/contract_templates/${id}`))

const createTemplate = ({ values }) =>
  post(apiTemplatesPath('/contract_templates'), prepareParams(values))

export { fetchTemplates, createTemplate, deleteTemplate, downloadTemplatePath }
