import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'
import { papp } from 'web/utility/helpers'
import { isUinAndVatRequired, uinName } from 'web/utility/country'
import {
  prepareCompanyCategories,
  prepareCompanyTypes,
  prepareLanguages,
  prepareNaces
} from 'web/utility/prepareData'
import CompanyForm from './component'
import validation from './validation'
import { createCompany, updateCompany, searchNaceCodes } from './api'
import { Form } from 'web/app/common/forms'

class CompanyFormContainer extends Component {
  static propTypes = {
    company:           PropTypes.object,
    companyCategories: PropTypes.array.isRequired,
    companyTypes:      PropTypes.array.isRequired,
    languages:         PropTypes.array.isRequired,
    naceList:          PropTypes.array.isRequired
  }

  constructor (props) {
    const { naceList } = props

    super(props)

    this.state = { naceList: naceList }
  }

  action = this.props.company ? 'edit' : 'new'

  _prepareComponentData = () => {
    const { company, companyCategories, companyTypes, languages } = this.props
    const { handleSearchNaceCodes } = this
    const { naceList } = this.state

    return {
      companyCategories: prepareCompanyCategories(companyCategories),
      companyTypes:      prepareCompanyTypes(companyTypes),
      initialPostIndex:  company && company.postIndex,
      languages:         prepareLanguages(languages),
      title:             t(`views.companies.${this.action}.title`),
      uinAndVatRequired: isUinAndVatRequired(),
      uinLabel:          uinName(),
      vatLabel:          t('countries.vat'),
      naceList:          prepareNaces(naceList),
      handleSearchNaceCodes
    }
  }

  handleSearchNaceCodes = ({ searchTerm }) => {
    if (searchTerm.length >= 3 || searchTerm === '') {
      searchNaceCodes({ query: searchTerm }).then((response) => {
        this.setState({ naceList: response.data })
      })
    }
  }

  _handleSubmit = (values, { setSubmitting, handleErrors }) => {
    const action = this.props.company ? 'edit' : 'new'
    const fn = (action === 'new') ? createCompany : papp(updateCompany, this.props.company.id)

    fn(values).then(({ success, data }) => {
      if (success) {
        window.location = data.url
      } else {
        setSubmitting(false)
        handleErrors(data)
      }
    })
  }

  render () {
    const { company } = this.props
    const componentProps = this._prepareComponentData()

    return (
      <Form
        onSubmit={this._handleSubmit}
        initialValues={{ company }}
        validation={validation}
      >
        <CompanyForm {...componentProps} />
      </Form>
    )
  }
}

export default CompanyFormContainer
