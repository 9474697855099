import React from 'react'
import PropTypes from 'prop-types'
import { DateTimePicker as Picker } from 'react-widgets'

export default function DateTimePicker ({ name, value, calendar, time }) {
  const defaultValue = value ? new Date(value) : null

  return (
    <Picker
      name={name}
      defaultValue={defaultValue}
      date={calendar}
      time={time}
      format={'YYYY-MM-DD'}
    />
  )
}

DateTimePicker.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  calendar: PropTypes.bool.isRequired,
  time: PropTypes.bool.isRequired
}

DateTimePicker.defaultProps = {
  calendar: true,
  time: true
}
