import { apiPath, get, patch } from 'web/utility/http'

const fetchData = ({ page, pageSize, query, sorting, state }) =>
  get(apiPath('/tickets'), {
    params: {
      page,
      pageSize,
      sorting,
      query,
      state
    }
  })

const updateTicketState = ({ id, values }) =>
  patch(apiPath(`/tickets/${id}/update_state`), {
    ticket: { state: values.state },
    comment: values.comment
  })

export { fetchData, updateTicketState }
