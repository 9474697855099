import React from 'react'
import PropTypes from 'prop-types'

import { Control } from '../form-elements'

const ButtonGroup = ({ children }) => (
  <Control isGrouped>
    {
      [...children].map((el, i) =>
        el ? <Control key={i}>{el}</Control> : null
      )
    }
  </Control>
)

ButtonGroup.propTypes = {
  children: PropTypes.array.isRequired
}

export default ButtonGroup
