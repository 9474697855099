import { patch, apiPath }   from '../../../utility/http'

const updateDnoPostIndices = ({ id, postalCodes, product }) =>
  patch(apiPath(`/dnos/${id}/post_indices`), {
    params: {
      postalCodes,
      product
    }
  })

export { updateDnoPostIndices }
