import { difference, includes, without } from 'lodash'

// This function Receives states array and
// removes 'archived' state from it and
// puts it to the end of array.
const sortStates = (states) => {
  if (includes(states, 'archived')) {
    let newStates = states.slice(0)

    newStates = without(newStates, 'archived')
    newStates.push('archived')

    return newStates
  } else {
    return states
  }
}

// This function prepares status to post it to back end. Receives
// current status from state.
const prepareStatusToSend = ({ currentStatus, stateAggregators }) =>
  stateAggregators[currentStatus] || [currentStatus]

// This function get order states and State Argegator from back end and
// properly sorts it for saving into component state.
const prepareStatuses = ({ order, states, stateAggregators }) => {
  const all = states.concat(Object.keys(stateAggregators))

  let ordered = []
  order.forEach((current) => {
    let idx = all.indexOf(current)
    if (idx || idx === 0) ordered.push(all[idx])
  })

  const unordered = difference(ordered, all)
  ordered.concat(unordered)

  return ordered
}

// This function gets array  of Users collection,
// consits of { id, firstName,lastName } and copies it
// to collection of { id, name } , where name is
// concatenated firstName and lastName
const prepareTicketsUsers = ({ ticketsUsers }) =>
  ticketsUsers.map((user) => {
    const { id, firstName, lastName } = user

    return ({
      id,
      name: `${firstName} ${lastName}`
    })
  })

export { prepareStatusToSend, prepareStatuses, sortStates, prepareTicketsUsers }
