import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { remove, cloneDeep, findIndex, omit } from 'lodash'

import Notify         from 'web/utility/Notify'
import HiddenProposal from './component'
import {
  prepareDurations,
  preparePriceModels
} from 'web/utility/prepareData'
import {
  analyzeProposals,
  fetchHiddenProposals,
  fetchFilters,
  updateFilters,
  deleteMandate,
  deleteProposal,
  saveProposal
} from '../api'

@inject('store')
@observer
class HiddenProposalContainer extends Component {
  static propTypes = {
    store: PropTypes.shape({
      offer:         PropTypes.object.isRequired,
      durations:     PropTypes.array.isRequired,
      marginLetters: PropTypes.array.isRequired,
      priceModels:   PropTypes.array.isRequired,
      productTypes:  PropTypes.array.isRequired
    }).isRequired,
    match: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props)
    this._onFilterChange   = this._onFilterChange.bind(this)
    this._onProposalToggle = this._onProposalToggle.bind(this)
  }

  state = {
    analysis: {},
    filters: {
      durations:     [],
      marginLetters: [],
      priceModels:   [],
      productTypes:  []
    },
    loading:          false,
    loadingFilters:   false,
    isModalShown:     false,
    proposals:        [],
    checkedMandates: []
  }

  componentDidMount () {
    this._fetchData()
    this._fetchFilters()
  }

  _fetchFilters () {
    this.setState({ loadingFilters: true })
    const { params: { product } } = this.props.match
    const { offer: { id } } = this.props.store

    fetchFilters({ id, product }).then(({ success, data }) => {
      if (success) {
        this.setState({ filters: data, loadingFilters: false })
      } else {
        Notify.error(data)
        this.setState({ loadingFilters: false })
      }
    })
  }

  _fetchData () {
    this.setState({ loading: true })
    const { params: { product } } = this.props.match
    const { offer: { id } } = this.props.store

    fetchHiddenProposals({ id, product }).then(({ success, data }) => {
      if (success) {
        this.setState({ proposals: data, loading: false })
      } else {
        Notify.error(data)
        this.setState({ loading: false })
      }
    })
  }

  _onFilterChange (type, value) {
    return () => {
      this.setState({ loadingFilters: true })
      const { params: { product } } = this.props.match
      const { offer: { id } } = this.props.store
      const { filters: newFilters } = this.state
      let filter = newFilters[type]
      filter = filter.includes(value) ? remove(filter, (e) => e !== value) : [...filter, value]
      newFilters[type] = filter

      updateFilters({ id, product, ...newFilters }).then(({ success }) => {
        if (success) {
          this.setState({ filters: newFilters, loadingFilters: false }, this._fetchData)
        } else {
          this.setState({ loadingFilters: false })
        }
      })
    }
  }

  _handleDeleteProposal = ({ offerId, proposalId, proposals }) => {
    deleteProposal({ offerId, proposalId }).then(({ success }) => {
      if (success) {
        const proposal = proposals.find((p) => p.id === proposalId)

        proposal.id = undefined
        proposal.shown = false

        this.setState({ proposals })
      }
    })
  }

  _handleSaveProposal = ({ success, data, error, proposals, filters }) => {
    const {
      product, priceModel, duration, supplierId,
      marginId, productTypeId, marginLetter
    } = filters

    if (success) {
      const proposal = proposals.find((p) =>
        p.product === product &&
        p.priceModel === priceModel &&
        p.duration === duration &&
        p.supplier.id === supplierId &&
        p.productType.id === productTypeId &&
        p.margin.letter === marginLetter &&
        p.margin.id === marginId
      )

      proposal.id = data.id
      proposal.shown = true

      this.setState({ proposals })
    } else {
      Notify.apiError(error)
    }
  }

  _onProposalToggle ({
    id, product, priceModel, duration, supplierId, marginId, productTypeId, marginLetter
  }) {
    const { offer: { id: offerId } } = this.props.store
    const filters = {
      product, priceModel, duration, supplierId, marginId, productTypeId, marginLetter
    }

    return () => {
      const proposals = cloneDeep(this.state.proposals)

      if (id) {
        this._handleDeleteProposal({ offerId, proposalId: id, proposals })
      } else {
        saveProposal({
          id: offerId,
          filters: filters
        }).then(({ success, data, error }) => {
          this._handleSaveProposal({ success, data, error, proposals, filters })
        })
      }
    }
  }

  _onAnalyzeClick = (product, _event) => {
    const { offer } = this.props.store
    analyzeProposals({ product, id: offer.id }).then(({ success, data }) => {
      if (success) {
        this.setState({ isModalShown: true, analysis: data })
      } else {
        Notify.error(data)
      }
    })
  }

  _onDeleteMandateClick = (product, _event) => {
    const { offer } = this.props.store
    const { checkedMandates } = this.state
    const mandates = checkedMandates.map((m) => (omit(m, 'mandateId')))

    deleteMandate({ product, id: offer.id, mandates }).then(({ success, data, error }) => {
      if (success) {
        offer.permissions = data.offer.permissions
        this.setState({ checkedMandates: [] })
        this._fetchData()
      } else {
        Notify.apiError(error)
      }
    })
  }

  _onModalClose = () => {
    this.setState({ isModalShown: false })
  }

  _onMandateToggle = (mandateAttrs) => {
    const { checkedMandates } = this.state
    const { mandateId } = mandateAttrs
    const present = findIndex(checkedMandates, (m) => (m.mandateId === mandateId)) > -1

    if (!present) {
      this.setState({
        checkedMandates: checkedMandates.concat(mandateAttrs)
      })
    } else {
      this.setState({
        checkedMandates: remove(checkedMandates, (m) => (m.mandateId !== mandateId))
      })
    }
  }

  render () {
    const {
      store: { offer, durations, marginLetters, priceModels, productTypes },
      match: { params: { product } }
    } = this.props
    const {
      filters, proposals, loading, loadingFilters,
      isModalShown, analysis, checkedMandates
    } = this.state
    const modal = {
      message: analysis.message,
      isShown: isModalShown,
      onClose: this._onModalClose
    }
    const isMandate = !!proposals.find((p) => p.mandate)

    return (
      <HiddenProposal
        data={proposals}
        durations={prepareDurations(durations)}
        filters={filters}
        loading={loading}
        isMandate={isMandate}
        loadingFilters={loadingFilters}
        marginLetters={marginLetters}
        modal={modal}
        offer={offer}
        onAnalyzeClick={this._onAnalyzeClick}
        onDeleteMandateClick={this._onDeleteMandateClick}
        onFilterChange={this._onFilterChange}
        onProposalToggle={this._onProposalToggle}
        priceModels={preparePriceModels(priceModels)}
        product={product}
        productTypes={productTypes.filter((pt) => pt.product === product)}
        onMandateToggle={this._onMandateToggle}
        checkedMandates={checkedMandates}
      />
    )
  }
}

export default HiddenProposalContainer
