import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Form } from 'web/app/common/forms'
import { t } from 'web/locale'
import ContractUploadForm from './component'
import validation from './validation'
import { uploadContract, reuploadContract } from '../api'

export default class ContractContainer extends Component {
  static propTypes = {
    contract: PropTypes.object.isRequired,
    flag: PropTypes.string.isRequired
  }

  _submitFile = (flag, contract, values) => {
    const func = flag === 'upload' ? uploadContract : reuploadContract

    return func({ contract, values })
  }

  _handleSubmit = (values, { handleErrors, setSubmitting }) => {
    const { contract, flag } = this.props

    this._submitFile(flag, contract, values).then(({ success, data }) => {
      if (success) {
        window.location.reload()
      } else {
        setSubmitting(false)
        handleErrors(data)
      }
    })
  }

  render () {
    const text = t(`common.button.${this.props.flag}`)

    return (
      <Form onSubmit={this._handleSubmit} validation={validation}>
        <ContractUploadForm text={text} />
      </Form>
    )
  }
}
