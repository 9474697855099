import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { fetchData } from './actions'
import { Datatable } from 'web/app/common/datatable'
import { leadListsTableColumns } from './components/leadListsTableColumns'
import { Columns, Column } from 'web/app/common/layout'
import StatusSelect from './components/StatusSelect'
import SearchInput from 'web/app/common/datatable/SearchInput'
import { prepareSorting } from 'web/utility/prepareData'
import EditStatusModal from './components/EditStatusModal'

class LeadListsOverview extends Component {
  static propTypes = {
    leadListStatuses: PropTypes.array.isRequired
  }

  state = {
    data:               [],
    loading:            true,
    pages:              null,
    query:              '',
    status:             'active',
    page:               1,
    pageSize:           25,
    sorted:             [{ id: 'leadLists.id', desc: false }],
    statusModalVisible: false,
    targetLeadListId:   null,
    newStatus:          ''
  }

  _handleSelectStatusChange = (selectedField) => {
    this.setState({ status: selectedField.value }, this._handleChange)
  }

  _handleQueryChange = (query) => {
    this.setState({ query }, this._handleChange)
  }

  _handleChange = ({ page, pageSize, sorted } = this.state) => {
    this.setState({ loading: true, pageSize, sorted, page })

    const { query, status } = this.state
    const queryParamStatus = status === 'all' ? '' : status

    fetchData({
      page: page + 1,
      pageSize,
      query,
      status: queryParamStatus,
      sorting: prepareSorting(sorted)
    }).then((response) => {
      const { pages, data } = response

      this.setState({ data, pages, loading: false })
    })
  }

  editStatusModalProps = ({ _handleCloseModalStatus, state } = this) => (
    {
      handleCloseModal: _handleCloseModalStatus,
      isModalVisible: state.statusModalVisible,
      targetLeadListId: state.targetLeadListId,
      newStatus: state.newStatus
    }
  )

  _handleStatusChange = (leadListId, currentStatus) => {
    return (e) => {
      const newStatus = e.target.value

      if (newStatus === currentStatus) return

      this.setState({
        statusModalVisible: true,
        targetLeadListId: leadListId,
        newStatus: newStatus
      })
    }
  }

  _handleCloseModalStatus = () => {
    this.setState({
      statusModalVisible: false,
      targetLeadListId: null,
      newStatus: ''
    })
  }

  render () {
    const { query, data, loading, pages, sorted, status } = this.state
    const { leadListStatuses } = this.props
    const {
      _handleSelectStatusChange,
      _handleQueryChange,
      _handleChange,
      _handleStatusChange,
      editStatusModalProps
    } = this

    return (
      <div>
        <EditStatusModal {...editStatusModalProps()} />
        <Columns>
          <Column classNames="is-2 is-offset-6">
            <StatusSelect
              selectionValue={status}
              leadListStatuses={leadListStatuses}
              onChange={_handleSelectStatusChange}
            />
          </Column>
          <Column classNames="is-4">
            <SearchInput
              query={query}
              onChange={_handleQueryChange}
            />
          </Column>
        </Columns>

        <Datatable
          manual
          columns={leadListsTableColumns(_handleStatusChange)}
          data={data}
          loading={loading}
          pages={pages}
          defaultSorted={sorted}
          onFetchData={_handleChange}
          onClickPath="/leadLists"
        />
      </div>
    )
  }
}

export default LeadListsOverview
