import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'
import Columns from './Columns'
import Column from './Column'

const Attribute = ({ title, value, titleInline }) => {
  const tTitle = titleInline === undefined ? t(`attributes.${title}`) : titleInline

  return (
    <Columns classNames="is-mobile">
      <Column classNames="is-one-third has-text-right">{tTitle}</Column>
      <Column classNames="is-two-thirds">{value}</Column>
    </Columns>
  )
}

Attribute.propTypes = {
  title: PropTypes.string,
  titleInline: PropTypes.string,
  value: PropTypes.any

}

export default Attribute
