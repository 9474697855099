import { camelCase, lowerFirst, isArray } from 'lodash'

const _pipe = (f, g) => (...args) => g(f(...args))

export const pipe = (...fns) => fns.reduce(_pipe)

export const papp = (fn, ...args) => fn.bind(null, ...args)

const _nodeEnv = process.env.NODE_ENV

export const env = {
  dev:   _nodeEnv === 'development',
  prodn: _nodeEnv === 'production',
  test:  _nodeEnv === 'test'
}

// used to normalize html id attributes
export const toId = (el) => {
  el = isArray(el) ? el.join('.') : el
  return lowerFirst(camelCase(el))
}

export const toInputName = (el) => {
  const [main, ...arr] = isArray(el) ? el : el.split('.')
  return arr.reduce((acc, el) => (acc += `[${el}]`), main)
}
