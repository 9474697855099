import React from 'react'
import PropTypes from 'prop-types'
import { isArray, times, compact } from 'lodash'

import { Columns, Column } from './'

const FormRow = ({ children, columns, className }) => {
  const childrenArray = isArray(children) ? compact(children) : [children]
  const renderColumn = (i) => <Column key={i}>{childrenArray[i]}</Column>
  const elements = times(columns, renderColumn)

  return (
    <Columns classNames={className}>
      {times(columns, (i) => elements[i])}
    </Columns>
  )
}

FormRow.defaultProps = {
  columns: 3
}

FormRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.element
  ]).isRequired,
  columns:   PropTypes.number.isRequired,
  className: PropTypes.string
}

export default FormRow
