import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { CSSTransition } from 'react-transition-group'

export default class Modal extends Component {
  static propTypes = {
    header: PropTypes.any,
    children: PropTypes.element.isRequired,
    footer: PropTypes.any,

    height: PropTypes.string,
    isShown: PropTypes.bool.isRequired,
    isWide: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    withCloseButton: PropTypes.bool,
    disableBackgroundClick: PropTypes.bool,
    disableClose: PropTypes.bool
  }

  static defaultProps = {
    isShown: false,
    isWide: false,
    disableBackgroundClick: false,
    withCloseButton: true,
    disableClose: false
  }

  componentDidUpdate () {
    if (this.props.isShown) {
      document.documentElement.style.cssText = 'overflow: hidden !important'
    } else {
      document.documentElement.style.overflow = null
    }
  }

  componentWillUnmount () {
    document.documentElement.style.overflow = null
  }

  _renderBackground () {
    const { disableBackgroundClick, disableClose, onClose } = this.props

    return (
      <div
        className="modal-background"
        onClick={disableBackgroundClick || disableClose ? null : onClose}
      />
    )
  }

  _renderBody () {
    const isPanelModal = this._isPanelModal()
    const { children } = this.props

    if (!isPanelModal) return (<div className="modal-content">{children}</div>)

    const { header, withCloseButton, disableClose, onClose, height } = this.props
    const style = { height }

    return (
      <div style={style} className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{header}</p>
          {
            withCloseButton &&
            !disableClose &&
            <button className="delete" onClick={onClose} />
          }
        </header>
        <section className="modal-card-body">{children}</section>
        <footer className="modal-card-foot">{this.props.footer}</footer>
      </div>
    )
  }

  _renderCloseButton () {
    const { withCloseButton, disableClose, onClose } = this.props

    if (disableClose) return null
    if (!withCloseButton && this._isPanelModal()) return null

    return <button className="modal-close" onClick={onClose} />
  }

  _isPanelModal () {
    const { header, footer } = this.props
    return !!header || !!footer
  }

  render () {
    const { isWide, isShown } = this.props
    const modalClassNames = cn('modal is-active', { 'is-wide': isWide })

    return (
      <CSSTransition
        classNames="modal"
        timeout={{ enter: 300, exit: 200 }}
        in={isShown}
        mountOnEnter
        unmountOnExit
      >
        <div className={modalClassNames}>
          {this._renderBackground()}
          {this._renderBody()}
          {this._renderCloseButton()}
        </div>
      </CSSTransition>
    )
  }
}
