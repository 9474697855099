import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Notify from 'web/utility/Notify'

import SupplierPricesImport from './component'
import { importPrices, rollbackImport } from './api'
import { prepareUnits } from 'web/utility/prepareData'
import { t } from 'web/locale'

export default class SupplierPricesImportContainer extends Component {
  static propTypes = {
    router:                   PropTypes.any,
    lastImport:               PropTypes.string.isRequired,
    supplierGasUnits:         PropTypes.array.isRequired,
    supplierElectricityUnits: PropTypes.array.isRequired
  }

  _onSubmit = (values, { handleErrors, setSubmitting, resetForm }) => {
    importPrices(values)
      .then(({ success, data, error }) => {
        if (success) {
          Notify.success(t('views.suppliers.show.import.success'))
          resetForm()
        } else {
          if (data.message) {
            Notify.apiError(error)
          } else {
            handleErrors(data)
          }
        }
        setSubmitting(false)
      })
  }

  _onRollbackImport = () => {
    Notify.confirm(
      t('views.suppliers.show.import.rollback.confirmation'),
      () => this._handleRollbackImport()
    )
  }

  _handleRollbackImport = () => {
    rollbackImport()
      .then(({ success, data, error }) => {
        if (success) {
          Notify.success(t('views.suppliers.show.import.rollback.success'))
        } else {
          if (data.message) {
            Notify.apiError(error)
          }
        }
      })
  }

  render () {
    const { lastImport, supplierGasUnits, supplierElectricityUnits } = this.props
    const minDate = lastImport.length > 0 ? moment(lastImport).add(1, 'day').toDate() : null

    const props = {
      minDate,
      supplierGasUnits: prepareUnits(supplierGasUnits),
      supplierElectricityUnits: prepareUnits(supplierElectricityUnits),
      onSubmit: this._onSubmit,
      onRollbackImport: this._onRollbackImport
    }

    return (
      <SupplierPricesImport {...props} />
    )
  }
}
