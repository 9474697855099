import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { startsWith, sortBy, toLower } from 'lodash'
import { DropdownList } from 'react-widgets'

import { Control, Label } from '../form-elements'

const LIMIT = 50

export default class SelectWithLabel extends Component {
  static propTypes = {
    data:         PropTypes.array.isRequired,
    disabled:     PropTypes.bool,
    handleChange: PropTypes.func,
    isRequired:   PropTypes.bool,
    label:        PropTypes.string.isRequired,
    name:         PropTypes.string,
    textField:    PropTypes.string,
    valueField:   PropTypes.string,
    value:        PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])
  }

  static defaultProps = {
    disabled:   false,
    isRequired: false,
    value:      '',
    textField:  'text',
    valueField: 'value'
  }

  state = { search: '', value: this.props.value || '' }

  _handleSearch = (value) => {
    this.setState({ search: value })
  }

  _handleChange = (data) => {
    const { handleChange, valueField } = this.props
    const value = data[valueField]

    if (handleChange) {
      handleChange(value)
    } else {
      this.setState({ value })
    }
  }

  _filterData = () => {
    const { data, textField }   = this.props
    const { search } = this.state

    if (search && search !== '') {
      return data.filter((el) => startsWith(toLower(el[textField]), toLower(search)))
    } else {
      return data
    }
  }

  _dataToShow = () => {
    const { value } = this.state
    const { valueField } = this.props
    const filtered = this._filterData()
    const limited = sortBy(filtered, [valueField]).slice(0, LIMIT)
    const findSelf = (collection) => collection.find(({ id }) => id === value)
    const includesSelected = findSelf(limited)

    if (!includesSelected) {
      const self = findSelf(filtered)
      return [self, ...limited]
    }
    return limited
  }

  render () {
    const { disabled, isRequired, label, name, textField, valueField, handleChange } = this.props
    let { value } = this.state
    const classNames = cn({ 'rw-required': isRequired && !value })

    if (handleChange && (value !== this.props.value)) {
      value = this.props.value
    }

    return (
      <Control>
        <Label>{label}</Label>
        <Control>
          <input name={name} type="hidden" value={value} />
          <DropdownList
            disabled={disabled}
            placeholder={label}
            onChange={this._handleChange}
            value={value}
            data={this._dataToShow()}
            className={classNames}
            filter="startsWith"
            onSearch={this._handleSearch}
            textField={textField}
            valueField={valueField}
          />
        </Control>
      </Control>
    )
  }
}
