import React from 'react'
import PropTypes from 'prop-types'

import { ModalButton } from 'web/app/common/modal'
import { t } from 'web/locale'
import { FormRow } from 'web/app/common/layout'
import {
  FileInputWithLabel as FileInput,
  DateTimePickerWithLabel as DateInput,
  ControlButtons,
  CheckBoxWithLabel
} from 'web/app/common/forms'

const ContractUploadForm = ({ text }) => {
  return (
    <ModalButton
      buttonText={text}
      header={t('views.contracts.show.titleUpload')}
    >
      <>
        <FormRow>
          <FileInput
            field="contract.contractFile"
            isWide
            required
          />
          <DateInput
            field="contract.signedAt"
            time={false}
            max={new Date()}
            required
          />
        </FormRow>
        <FormRow>
          <CheckBoxWithLabel
            field="contract.sendResignationLetter"
          />
        </FormRow>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <ControlButtons />
      </>
    </ModalButton>
  )
}

ContractUploadForm.propTypes = {
  text: PropTypes.string.isRequired
}

export default ContractUploadForm
