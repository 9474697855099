import ReactAutoMount from './utility/ReactAutoMount'

// New structure
import { CompaniesTable } from './app/companies/CompaniesTable'
import { CompanyForm } from './app/companies/CompanyForm'
import { ConsumptionDataTable } from './app/consumptionData/ConsumptionDataTable'
import { ContactsTable } from './app/contacts/ContactsTable'
import { ContactCallsTable } from './app/contacts/ContactCallsTable'
import { ContactTasksTable } from './app/contacts/ContactTasksTable'
import { ContractsIndex } from './app/contracts/ContractsIndex'
import { RemoteSignature } from './app/contracts/RemoteSignature'
import { DnosTable } from './app/dnos/DnosTable'
import { DnoPostIndices } from './app/dnos/DnoPostIndices'
import { EansTable } from './app/eans/EansTable'
import { TicketShow } from './app/tickets/TicketShow'
import { TicketsIndex } from './app/tickets/TicketsIndex'
import { MultiSelectAreasContainer } from './app/areas/MultiSelectAreas'
import { CalendarBlocks } from './app/dashboard/CalendarBlocks'
import { MyTasks } from './app/dashboard/MyTasks'
import { CallbacksTable } from './app/dashboard/CallbacksTable'
import { OfferForm } from './app/offers/OfferForm'
import { RemoteDnoSignature } from './app/offers/RemoteDnoSignature'
import { OrganisationsTable } from './app/organisations/OrganisationsTable'
import { ProcessMeetingModal } from './app/meetings/ProcessMeetingModal'
import { SelectPostIndexContainer } from './app/postIndices/SelectPostIndex'
import { SettingPricesForm } from './app/settings/SettingPricesForm'
import { SentRemoteLinksTable } from './app/settings/SentRemoteLinksTable'
import { ShowSupplier } from './app/suppliers/ShowSupplier'
import { SuppliersTable } from './app/suppliers/SuppliersTable'
import { MeetingsOverviewIndex } from './app/meetings/MeetingsOverviewIndex'
import { UserEventsTable } from './app/users/UserEventsTable'
import { UserFilesTable } from './app/users/UserFilesTable'
import { UserForm } from './app/users/UserForm'
import { UsersTable } from './app/users/UsersTable'
import { DateTimePicker, MultiselectWithLabel, SelectWithLabel } from './app/common/html_forms'
import { LeadListForm } from './app/leadLists/LeadListForm'
import { LeadListsOverview } from './app/leadLists/LeadListsOverview'
import { LeadListOverviewCompany } from './app/leadLists/OverviewCompany'
import { LeadListShowView } from './app/leadLists/LeadListShowView'
import { LeadListSettings } from './app/leadLists/LeadListSettings'

// Views
import { CompanyShowView } from './app/companies/CompanyShowView'
import { ShowOfferView } from './app/offers/ShowOfferView'

ReactAutoMount.registerComponents({
  CompaniesTable,
  CompanyForm,
  CompanyShowView,
  ConsumptionDataTable,
  ContactsTable,
  ContactCallsTable,
  ContactTasksTable,
  ContractsIndex,
  RemoteSignature,
  DateTimePicker,
  DnosTable,
  DnoPostIndices,
  EansTable,
  TicketShow,
  TicketsIndex,
  MultiSelectAreasContainer,
  MultiselectWithLabel,
  CalendarBlocks,
  MyTasks,
  CallbacksTable,
  OfferForm,
  RemoteDnoSignature,
  OrganisationsTable,
  ProcessMeetingModal,
  SelectPostIndexContainer,
  SelectWithLabel,
  SettingPricesForm,
  SentRemoteLinksTable,
  ShowOfferView,
  ShowSupplier,
  SuppliersTable,
  MeetingsOverviewIndex,
  UserEventsTable,
  UserFilesTable,
  UserForm,
  UsersTable,
  LeadListForm,
  LeadListsOverview,
  LeadListOverviewCompany,
  LeadListSettings,
  LeadListShowView
})

ReactAutoMount.setup()
