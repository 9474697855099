import React from 'react'
import PropTypes from 'prop-types'

import { Datatable } from 'web/app/common/datatable'
import { t }         from 'web/locale'

const generateHeaders = ({ tariffGroups }) => [{
  Header: t('attributes.price.supplier'),
  accessor: 'supplierName'
}, {
  Header: t('attributes.price.priceModel'),
  accessor: 'priceModel'
}, {
  Header: t('attributes.price.meter'),
  accessor: 'meter'
}, {
  Header: t('attributes.price.fixedFee'),
  accessor: 'fixedFee'
},
...tariffGroups.map((tg) => {
  return {
    Header: tg.name,
    columns: tg.tariffs.map((t) => {
      return {
        Header: t.name,
        accessor: `tariffs.${tg.id}.${t.id}`
      }
    })
  }
})
]

const generateData = ({ tariffGroups, eanStats }) =>
  eanStats.map((stat) => ({
    supplierName: (stat.supplier && stat.supplier.name) || '-',
    priceModel:   stat.priceModel,
    meter:        stat.ean.meter.name,
    fixedFee:     stat.fixedFee,
    tariffs: tariffGroups.reduce((acc, tg) => {
      acc[tg.id] = acc[tg.id] || {}
      tg.tariffs.map((t) => {
        const tariff = stat.eanTariffs.find((statTariff) => statTariff.tariffId === t.id)
        acc[tg.id][t.id] = (tariff && tariff.price) || '-'
      })
      return acc
    }, {})
  }))

const OldTariffsTable = ({ product, tariffGroups, eanStats }) => {
  return <Datatable
    columns={generateHeaders({ product, tariffGroups })}
    data={generateData({ product, tariffGroups, eanStats })}
    showPagination={false}
  />
}

OldTariffsTable.propTypes = {
  product:      PropTypes.string.isRequired,
  tariffGroups: PropTypes.array.isRequired,
  eanStats:     PropTypes.array.isRequired
}

export default OldTariffsTable
