import React from 'react'
import { RowLink } from '../../../common/datatable'
import { NativeSelect } from 'web/app/common/form-elements'

const fetchAgentFullNames = (leadList, agentType) => (
  leadList[agentType].map(({ fullName }) => fullName).join(', ')
)

const can = (leadList, action) => {
  return leadList.permissions.includes(action)
}

/* eslint-disable react/prop-types */
const leadListsTableColumns = (handleStatusChange) => [
  { Header: 'Id', id: 'leadLists.id', accessor: 'id', maxWidth: 60 },
  { Header: 'Name', id: 'leadLists.name', accessor: 'name' },
  { Header: 'Area', id: 'area.name', sortable: false, accessor: 'areaName' },
  { Header: 'Organisation', id: 'organisation.name', accessor: 'organisationName' },
  { Header: 'Call Agents',
    id: 'leadLists.callAgents',
    sortable: false,
    accessor: (leadList) => fetchAgentFullNames(leadList, 'callAgents')
  }, {
    Header: 'Sales Agent',
    id: 'leadLists.salesAgent',
    sortable: false,
    accessor: (leadList) => fetchAgentFullNames(leadList, 'sales')
  }, {
    Header: 'State',
    id: 'leadLists.status',
    accessor: 'status',
    Cell: ({ row: { _original } }) => {
      const { current, statuses } = _original.status

      return (
        can(_original, 'update_status') && <NativeSelect
          placeholder={current}
          options={[current, ...statuses]}
          value={current}
          onChange={handleStatusChange(_original.id, current)}
        />
      )
    }
  }, {
    id:       'buttons',
    minWidth: 35,
    sortable: false,
    Cell: ({ row: { _original } }) => {
      return (
        <span>
          <RowLink type="show" url={`/lead_lists/${_original.id}`} />
          <RowLink type="edit" url={`/lead_lists/${_original.id}/company`} />
          <RowLink type="settings" url={`/lead_lists/${_original.id}/settings`} />
        </span>
      )
    }
  }
]
/* eslint-enable react/prop-types */

export { leadListsTableColumns }
