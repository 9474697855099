import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { pick, defaults } from 'lodash'

import { Form } from 'web/app/common/forms'
import UserForm from './component'
import validate from './validation'
import Notify   from 'web/utility/Notify'
import { papp } from 'web/utility/helpers'
import { t }    from 'web/locale'
import {
  prepareTitles,
  prepareContactKinds
} from 'web/utility/prepareData'

import {
  saveUser,
  updateUser,
  searchCompany
} from './api'

export default class UserFormContainer extends Component {
  static propTypes = {
    user:             PropTypes.object,
    roles:            PropTypes.array.isRequired,
    statuses:         PropTypes.array.isRequired,
    countries:        PropTypes.array.isRequired,
    languages:        PropTypes.array.isRequired,
    permissions:      PropTypes.array.isRequired,
    companyTypes:     PropTypes.array.isRequired,
    referencesFrom:   PropTypes.array.isRequired,
    offerCategories:  PropTypes.array.isRequired,
    cooperationForms: PropTypes.array.isRequired
  }

  static defaultProps = {
    companyTypes: []
  }

  constructor (props) {
    super(props)

    const user = Object.assign({}, this.props.user)

    this.state = {
      searchingCompany: false,
      companyPostIndex: null,
      hide: {
        userFields: {
          workingHours: user.cooperationForm !== 'payroll'
        }
      },
      defaultValues: {
        user: user,
        company: {},
        contact: {}
      },
      noErrors: false
    }
  }

  action = this.props.user ? 'edit' : 'new'

  _title = () => {
    const { user } = this.props
    const name     = user ? user.fullName : null

    return t(`views.users.${this.action}.title`, { name: name })
  }

  _userData = () => ({
    roles:               this.props.roles,
    statuses:            this.props.statuses,
    countries:           this.props.countries,
    languages:           this.props.languages,
    references:          this.props.referencesFrom,
    offerCategories:     this.props.offerCategories,
    cooperationForms:    this.props.cooperationForms,
    initialPostIndex:    this.props.user && this.props.user.postIndex,
    initialOrganisation: this.props.user && this.props.user.organisation
  })

  _companyData = () => ({
    types:      this.props.companyTypes,
    categories: this.props.offerCategories
  })

  _contactData = () => ({
    titles: prepareTitles(),
    kinds:  prepareContactKinds()
  })

  _handleCoopFormSelect = (value) => {
    this.setState({
      hide: {
        userFields: defaults({ workingHours: value !== 'payroll' }, this.state.hide.userFields)
      }
    })
  }

  _handleCompanySearch = () => {
    const { getValue, handleChange: setValue } = this.formApi
    const uin = getValue('company.uin')
    if (!uin) return
    this.setState({ searchingCompany: true })

    searchCompany(uin).then(({ success, data, error }) => {
      if (success) {
        const company = pick(data, [
          'uin', 'name', 'address', 'tel', 'mobile', 'email', 'iban', 'bic', 'companyCategoryId',
          'companyTypeId', 'fax', 'website', 'postIndexId', 'postIndex'
        ])

        this.setState({ companyPostIndex: company.postIndex }, () => {
          setValue('company', company)
        })
      } else {
        Notify.apiError(error)
        setValue('company', {})
        setValue('company.uin', uin)
      }
      this.setState({ searchingCompany: false })
    })
  }

  _handleSubmit = (values, { handleErrors, setSubmitting }) => {
    const { user } = this.props
    const func = user ? papp(updateUser, user.id) : saveUser

    func(values).then(({ success, data }) => {
      if (success) {
        window.location = data.redirect
      } else {
        setSubmitting(false)
        handleErrors(data)
      }
    })
  }

  _getFormApi = (formApi) => {
    this.formApi = formApi
  }

  render () {
    const { defaultValues, searchingCompany, hide, companyPostIndex } = this.state
    const { permissions } = this.props
    const handlers = {
      cooperationForm: this._handleCoopFormSelect
    }

    return (
      <div>
        <Form
          initialValues={defaultValues}
          getApi={this._getFormApi}
          onSubmit={this._handleSubmit}
          validation={papp(validate, this.action, permissions)}
        >
          <UserForm
            action={this.action}
            hide={hide}
            handlers={handlers}
            companyData={this._companyData()}
            companyPostIndex={companyPostIndex}
            contactData={this._contactData()}
            handleCompanySearch={this._handleCompanySearch}
            permissions={permissions}
            searchingCompany={searchingCompany}
            title={this._title()}
            userData={this._userData()}
          />
        </Form>
      </div>
    )
  }
}
