import { isArray, cloneDeep } from 'lodash'
import buildValidation from '../../../utility/validations'

// This method allows one attribute 'to' from volumes can be null
// and it means this will be range -> from...Infinityy
const allowCountErrors = (array, count, key) => {
  if (!isArray(array)) return array

  let index = 0
  const filtered = cloneDeep(array).map((hash) => {
    if (hash[key]) {
      hash[key] = undefined
      index++
    }
    return hash
  })

  if (index > count) return array
  return filtered
}

export default (values) => {
  const { filled, filledEach } = buildValidation(values)

  const result = {
    electricityMarketNames: filled('electricityMarketNames'),
    electricityProductTypes: filled('electricityProductTypes'),
    gasMarketNames: filled('gasMarketNames'),
    gasProductTypes: filled('gasProductTypes'),
    meters: filled('meters'),
    tariffGroups: filledEach('tariffGroups', 'name', 'tariffs', 'product')
  }
  result.volumes = allowCountErrors(result.volumes, 1, 'to')
  return result
}
