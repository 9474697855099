import React from 'react'
import PropTypes from 'prop-types'

import { NativeSelect } from 'web/app/common/form-elements'
import { t }            from 'web/locale'

const options = [
  { text: t('common.priceModel._'), value: 'priceModel' },
  { text: t('attributes.meter._'), value: 'meter' },
  { text: t('common.duration._'), value: 'duration' },
  { text: t('attributes.tariff._'), value: 'tariff' },
  { text: t('common.volume._'), value: 'volume' }
]

const SelectAddonType = ({ addonType, onAddonTypeChange, isLoading }) => {
  return (
    <NativeSelect
      value={addonType}
      onChange={onAddonTypeChange}
      options={options}
      pretty={false}
      isLoading={isLoading}
    />
  )
}

SelectAddonType.propTypes = {
  addonType:         PropTypes.string.isRequired,
  isLoading:         PropTypes.bool.isRequired,
  onAddonTypeChange: PropTypes.func.isRequired
}

export default SelectAddonType
