import buildValidation from 'web/utility/validations'
import { shouldApplyKVA } from 'web/utility/country'
import { merge } from 'lodash'

export default (values, tariffs) => {
  const { exist, filled } = buildValidation(values)

  let eanTariffs = null
  if (tariffs && tariffs.length > 0) {
    eanTariffs = tariffs.reduce((acc, _tariff, i) => {
      let price = `eanStat.eanTariffs.${i}.price`
      let volume = `eanStat.eanTariffs.${i}.volume`
      let kva = `eanStat.eanTariffs.${i}.kva`

      acc.push({
        price: filled(price),
        volume: filled(volume),
        kva: (values.ean && shouldApplyKVA(values.ean.product)) ? filled(kva) : null
      })

      return acc
    }, [])
  }

  const eanStatWithPriceRate = {
    unitId:         filled('eanStat.unitId'),
    fixedFee:       filled('eanStat.fixedFee'),
    fixedFeePeriod: filled('eanStat.fixedFeePeriod'),
    priceModel:     filled('eanStat.priceModel'),
    tariffGroupId:  filled('eanStat.tariffGroupId'),
    eanTariffs:     eanTariffs
  }

  const validation = {
    ean: {
      number:      filled('ean.number'),
      product:     filled('ean.product'),
      meterId:     filled('ean.meterId'),
      address:     filled('ean.address'),
      postIndexId: filled('ean.postIndexId')
    },
    eanStat: {
      deliverFrom:   filled('eanStat.deliverFrom'),
      reason:        filled('eanStat.reason'),
      invoicingFrom: filled('eanStat.invoicingFrom'),
      invoicingTo:   filled('eanStat.invoicingTo')
    }
  }

  const hasPriceRate = !!exist('helper.priceRate')
  if (hasPriceRate) {
    validation.eanStat = merge(validation.eanStat, eanStatWithPriceRate)
  }

  return validation
}
