import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Form } from 'web/app/common/forms'
import TicketForm              from './TicketForm'
import { validateTicketsForm } from '../validations'
import { Modal }               from 'web/app/common/modal'
import { t }                   from 'web/locale'

export default class TicketModal extends Component {
  static propTypes = {
    isOpen:            PropTypes.bool.isRequired,
    newState:          PropTypes.string,
    onCloseModal:      PropTypes.func.isRequired,
    onSubmit:          PropTypes.func.isRequired,
    ticketSalesId:     PropTypes.number,
    ticketsCategories: PropTypes.array.isRequired,
    ticketsUsers:      PropTypes.array.isRequired
  }

  render () {
    const { isOpen, ticketsCategories, ticketsUsers, ticketSalesId } = this.props

    const initialValues = {
      ticket: { createdForId: ticketSalesId }
    }

    const modalProps = {
      header:  t('views.contracts.index.label.newTicket'),
      isShown: isOpen,
      onClose: this.props.onCloseModal
    }

    return (
      <div className="TicketModal">
        <Modal {...modalProps}>
          <Form
            onSubmit={this.props.onSubmit}
            initialValues={initialValues}
            validation={validateTicketsForm}
          >
            <TicketForm
              ticketsCategories={ticketsCategories}
              ticketsUsers={ticketsUsers}
            />
          </Form>
        </Modal>
      </div>
    )
  }
}
