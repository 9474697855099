import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  SelectWithLabel as Select
} from 'web/app/common/forms'
import { arraySearch } from './helpers'

class SearchKva extends Component {
  state = {
    defaultData: this.props.defaultData,
    data: this.props.defaultData,
    query: null
  }

  _searchKva = (query, value) => {
    const { defaultData } = this.state

    if (!query && !value) {
      this.setState({ query, data: defaultData })
    } else if (query || !value) {
      this.setState({ query, data: arraySearch({ defaultData, query }) })
    }
  }

  render () {
    return (
      <Select
        field="search"
        data={this.state.data}
        handleSearch={this._searchKva}
        {...this.props}
      />
    )
  }
}

SearchKva.propTypes = {
  defaultData: PropTypes.array.isRequired
}

export default SearchKva
