import React from 'react'

import { fetchOrganisation } from './api'
import { withFieldSearch } from 'web/app/common/forms/HOC'
import {
  SelectWithLabel as Select
} from 'web/app/common/forms'

const fetchData = (query) => {
  return fetchOrganisation({ query, limit: 150 })
}

const SelectOrganisation = (props) =>
  <Select
    limit={150}
    textField="name"
    valueField="id"
    {...props}
    data={props.data.data || props.data} // eslint-disable-line react/prop-types
  />

const SearchOrganisation = withFieldSearch(
  SelectOrganisation,
  fetchData
)

export default SearchOrganisation
