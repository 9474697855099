import { observable, action } from 'mobx'
import { fetchCees, createCee, destroyCee } from './api'

export default class SupplierCeesStore {
  constructor ({
    cees
  }) {
    this.cees = cees
  }

  @observable cees = []
  @observable isLoading = false

  @action loadCees = () => {
    this.isLoading = true

    return fetchCees().then(action((response) => {
      if (response.failure) return response

      this.cees = response.data

      return response
    })).finally(action(() => { this.isLoading = false }))
  }

  @action createCee = ({ values }) => {
    this.isLoading = true

    return createCee({ values }).then(action((response) => {
      if (response.failure) return response

      this.cees.unshift(response.data)

      return response
    })).finally(action(() => { this.isLoading = false }))
  }

  @action deleteCee = (ceeId) => {
    this.isLoading = true

    return destroyCee(ceeId).then(action((response) => {
      if (response.failure) return response

      const deletedCee = this.cees.find((cee) => (cee.id === ceeId))
      const ceeIdx = this.cees.indexOf(deletedCee)
      this.cees.splice(ceeIdx, 1)

      return response
    })).finally(action(() => { this.isLoading = false }))
  }
}
