import React from 'react'
import PropTypes from 'prop-types'

import Tab from './Tab'

const Tabs = ({ children }) => (
  <div className="tabs is-toggle is-fullwidth">
    <ul>
      {children}
    </ul>
  </div>
)

Tabs.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.objectOf(Tab)
    ])
  )
}

export default Tabs
