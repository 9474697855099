import { pathWith, get, destroy, post } from 'web/utility/http'

const unitsPath = (path) =>
  pathWith(path).replace('/settings', '')

const fetchUnits = () =>
  get(unitsPath('/units'))

const deleteUnit = ({ id }) =>
  destroy(unitsPath(`/units/${id}`))

const createUnit = ({ values }) =>
  post(unitsPath('/units'), values)

export { fetchUnits, deleteUnit, createUnit }
