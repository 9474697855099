import '../stylesheets/app.scss'
import './config'
import './utility/ErrorReporter'
import './utility/ReactWidgetsConfig'

import './plain'
import './render'

require.context('./../images', true, /.(jpg|png|gif|ico)$/)

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept()
}
