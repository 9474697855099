import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'web/app/common/elements'

const CancelIcon = ({ func, type, value }) =>
  <Icon iconClass="icon-cancel" size="" color="is-danger" onClick={func(type, value)} />

CancelIcon.propTypes = {
  func:  PropTypes.func.isRequired,
  type:  PropTypes.string.isRequired,
  value: PropTypes.any.isRequired
}

export default CancelIcon
