import React from 'react'
import PropTypes from 'prop-types'
import { DropdownList } from 'react-widgets'

import { prepareLeadListStatuses } from 'web/utility/prepareData'

const StatusSelect = ({ leadListStatuses, onChange, selectionValue }) => (
  <DropdownList
    data={prepareLeadListStatuses(leadListStatuses)}
    value={selectionValue}
    valueField="value"
    textField="text"
    onChange={onChange}
  />
)

StatusSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  leadListStatuses: PropTypes.array.isRequired,
  selectionValue: PropTypes.string.isRequired
}

export default StatusSelect
