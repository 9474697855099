import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes, observer } from 'mobx-react'

import { t } from 'web/locale'
import { Subtitle } from 'web/app/common/elements'
import { Columns, Column, Attribute } from 'web/app/common/layout'

const GeneralInfo = ({ company, generalInfo }) => {
  return (
    <div>
      <Subtitle size={4}>
        { t('views.companies.show.generalInfo.title') }
      </Subtitle>
      <Columns>
        <Column>
          {
            generalInfo
              .slice(0, 5)
              .map(
                (col, index) => <Attribute key={index} titleInline={col.key} value={col.value} />
              )
          }
        </Column>
        <Column>
          {
            generalInfo
              .slice(5, 10)
              .map(
                (col, index) => <Attribute key={index} titleInline={col.key} value={col.value} />
              )
          }
        </Column>
        <Column>
          {
            generalInfo
              .slice(10, 15)
              .map(
                (col, index) => <Attribute key={index} titleInline={col.key} value={col.value} />
              )
          }
        </Column>
      </Columns>
      <Subtitle size={4}>
        { t('attributes.company.note') }
      </Subtitle>
      <p dangerouslySetInnerHTML={{ __html: company.note }} />
    </div>
  )
}

GeneralInfo.propTypes = {
  company: MobxPropTypes.observableObject.isRequired,
  generalInfo: PropTypes.array.isRequired
}

export default observer(GeneralInfo)
