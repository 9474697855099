import { apiPath, post, get } from 'web/utility/http'

const createLeadList = ({ values }) =>
  post(apiPath(`/lead_lists`), {
    ...values
  })

const searchUsers = ({ organisationId, role, query }) => {
  return (
    get(apiPath(`/lead_lists/users/search`), {
      params: {
        organisationId,
        role,
        query
      }
    })
  )
}

const searchPostIndex = ({ fields, query, area, counties, postalCodes }) => {
  return (
    get(apiPath(`/lead_lists/post_indices/search`), {
      params: {
        area,
        counties,
        postalCodes,
        fields,
        query
      }
    })
  )
}

const loadFormData = () => {
  return (
    get(apiPath(`/lead_lists/new`))
  )
}

const filteredCompaniesCounter = ({ values }) => {
  return (
    get(apiPath(`/lead_lists/counters/filtered_companies`), {
      params: {
        ...values
      }
    })
  )
}

export { createLeadList, searchUsers, searchPostIndex, loadFormData, filteredCompaniesCounter }
