import React from 'react'
import LeadListsOverview from './component'
import PropTypes from 'prop-types'

const LeadListsOverviewContainer = ({ leadListStatuses }) => (
  <LeadListsOverview leadListStatuses={leadListStatuses} />
)

LeadListsOverviewContainer.propTypes = {
  leadListStatuses: PropTypes.array.isRequired
}

export default LeadListsOverviewContainer
