import { t, dateTime } from 'web/locale'

const tasksTableColumns =
[
  {
    id: 'tasks.id',
    accessor: 'id',
    minWidth: 30,
    Header: t('attributes.task.id')
  },
  {
    id: 'tasks.created_at',
    accessor: ({ createdAt }) => dateTime(createdAt),
    minWidth: 30,
    Header: t('attributes.task.date')
  },
  {
    id: 'tasks.state',
    accessor: 'state',
    minWidth: 30,
    Header: t('attributes.task.status._')
  },
  {
    id: 'tasks.note',
    accessor: 'note',
    minWidth: 100,
    Header: t('attributes.task.note')
  },
  {
    id: 'user.first_name',
    accessor: 'userName',
    minWidth: 30,
    Header: t('attributes.task.userName')
  }
]

export { tasksTableColumns }
