import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Notify from 'web/utility/Notify'
import { prepareSorting } from 'web/utility/prepareData'
import { fetchData, updateConsumptionData } from './api'
import { t } from 'web/locale'

import { ConsumptionDataTable } from './component'
import { MailModal } from './MailModal'
import { NoteModal } from './components'

export default class ConsumptionDataTableContainer extends Component {
  static propTypes = {
    states: PropTypes.array.isRequired
  }

  constructor (props) {
    super(props)

    this.state = {
      currentTab: props.states[0],
      isMailModalOpen: false,
      isNoteModalOpen: false,
      transitionDataId: null,
      tableData: {
        data: [],
        loading: true,
        page: 1,
        pages: 1,
        pageSize: 25,
        query: '',
        sorted: [{ id: 'consumptionData.id', desc: false }]
      }
    }
  }

  // Table related
  // --------------------------------------------------------------------
  _isCurrentTab = (tab) => {
    return this.state.currentTab === tab
  }

  _handleTabChange = (tab) => {
    this.setState({ currentTab: tab }, this._handleTableChange)
  }

  _handleSearch = (query) => {
    const { tableData } = this.state
    this.setState({ tableData: { ...tableData, query } }, this._handleTableChange)
  }

  _handleTableChange = (state = this.state.tableData) => {
    const { currentTab, tableData } = this.state
    const { page, pageSize, sorted } = state
    const {
      tableData: { query }
    } = this.state

    this.setState({ tableData: { ...tableData, loading: true, page, pageSize, sorted } })

    fetchData({
      pageSize,
      query,
      page: page + 1,
      sorting: prepareSorting(sorted),
      states: [currentTab]
    }).then(({ success, data, error }) => {
      if (success) {
        const { tableData } = this.state
        this.setState({ tableData: { ...tableData, ...data, loading: false } })
      } else {
        Notify.apiError(error)
      }
    })
  }

  _handleStateChange = ({ id, current }) => {
    return (e) => {
      const state = e.target.value
      if (state === current) return
      if (state === 'denied') {
        this.setState({ isNoteModalOpen: true, transitionDataId: id, transitionDataState: state })
      } else {
        this._updateConsumptionData(id, { state })
      }
    }
  }

  // Modals handlers
  // --------------------------------------------------------------------
  _handleMailClick = (dataId) => {
    const { isMailModalOpen } = this.state
    this.setState({ isMailModalOpen: !isMailModalOpen, transitionDataId: dataId })
  }

  _handleMailModalClose = () => {
    this.setState({ isMailModalOpen: false })
  }

  _handleMailSubmit = () => {
    this.setState({ isMailModalOpen: false }, () => {
      Notify.success(t('views.consumptionData.index.mail.successfullyScheduled'))
    })
  }

  _handleNoteModalClose = () => {
    this.setState({ isNoteModalOpen: false })
  }

  _handleNoteSubmit = (values, { setSubmitting, resetForm }) => {
    this.setState({ isNoteModalOpen: false }, () => {
      const { transitionDataId, transitionDataState } = this.state

      this._updateConsumptionData(
        transitionDataId,
        {
          state: transitionDataState,
          note: values.note
        },
        {
          setSubmitting,
          resetForm
        }
      )
    })
  }

  // API wrappers
  // --------------------------------------------------------------------
  _updateConsumptionData = (
    id,
    attrs,
    { setSubmitting, resetForm } = { setSubmitting: () => {}, resetForm: () => {} }
  ) => {
    const params = { consumptionData: attrs }

    updateConsumptionData({ id, params }).then(({ success, error }) => {
      if (success) {
        this._handleTableChange()
        resetForm()
      } else {
        Notify.apiError(error)
      }
      setSubmitting(false)
    })
  }

  // Render
  // --------------------------------------------------------------------
  render () {
    const { isMailModalOpen, isNoteModalOpen, transitionDataId, tableData } = this.state
    const { states } = this.props

    return (
      <div>
        <ConsumptionDataTable
          onChange={this._handleTableChange}
          onSearch={this._handleSearch}
          onTabChange={this._handleTabChange}
          onMailClick={this._handleMailClick}
          onStateChange={this._handleStateChange}
          isCurrentTab={this._isCurrentTab}
          tabs={states}
          tableData={tableData}
        />
        {isMailModalOpen && (
          <MailModal
            dataId={transitionDataId}
            onClose={this._handleMailModalClose}
            onSubmit={this._handleMailSubmit}
          />
        )}
        {isNoteModalOpen && (
          <NoteModal onClose={this._handleNoteModalClose} onSubmit={this._handleNoteSubmit} />
        )}
      </div>
    )
  }
}
