import buildValidation from 'web/utility/validations'

const validateStatusChangeForm = (values = {}) => {
  const { filled } = buildValidation(values)

  if (['active', 'inactive'].includes(values.status)) {
    return { status: filled('status') }
  }
}

export { validateStatusChangeForm }
