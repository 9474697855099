import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { t, date } from 'web/locale'
import { fetchData } from './api'
import Notify from 'web/utility/Notify'
import { IndexDatatable, RowLink } from 'web/app/common/datatable'

const editColumn = (canShowAll, canUpdateAll) => ({
  id:       'buttons',
  minWidth: 50,
  sortable: false,
  Cell: ({ row: { _original } }) => { // eslint-disable-line react/prop-types
    return (
      <span>
        {
          canShowAll &&
            <RowLink type="show" url={`/suppliers/${_original.id}`} />
        }
        {
          canUpdateAll &&
            <RowLink type="edit" url={`/suppliers/${_original.id}/edit`} />
        }
      </span>
    )
  }
})

const columns = (canShowAll, canUpdateAll) => {
  let array = [{
    Header: t('attributes.supplier.id'),
    accessor: 'id',
    minWidth: 30
  }, {
    Header:   t('attributes.supplier.name'),
    accessor: 'name',
    minWidth: 250
  }, {
    Header: t('attributes.supplier.lastImport'),
    id:     'priceFiles.createdAt',
    Cell: ({ row: { _original } }) => { // eslint-disable-line react/prop-types
      const lastImport = _original.priceFiles[0] || {}

      return (
        <span>
          {date(lastImport.createdAt)}
        </span>
      )
    }
  }, {
    Header: t('attributes.supplier.electricityEomFixDate'),
    accessor: 'electricityEomFixDate',
    minWidth: 120,
    Cell: ({ value }) => <span>{date(value)}</span> // eslint-disable-line react/prop-types
  }, {
    Header: t('attributes.supplier.gasEomFixDate'),
    accessor: 'gasEomFixDate',
    minWidth: 120,
    Cell: ({ value }) => <span>{date(value)}</span> // eslint-disable-line react/prop-types
  }, {
    Header: t('attributes.supplier.electricityEomFlexDate'),
    accessor: 'electricityEomFlexDate',
    minWidth: 120,
    Cell: ({ value }) => <span>{date(value)}</span> // eslint-disable-line react/prop-types
  }, {
    Header: t('attributes.supplier.gasEomFlexDate'),
    accessor: 'gasEomFlexDate',
    minWidth: 120,
    Cell: ({ value }) => <span>{date(value)}</span> // eslint-disable-line react/prop-types
  }]

  if (canShowAll || canUpdateAll) array.push(editColumn(canShowAll, canUpdateAll))

  return array
}

export default class SuppliersTable extends Component {
  static propTypes = {
    canShowAll:   PropTypes.bool.isRequired,
    canUpdateAll: PropTypes.bool.isRequired
  }

  static defaultProps = {
    canShowAll:   false,
    canUpdateAll: false
  }

  _fetchSuppliers = (tableArgs) =>
    fetchData(tableArgs).then(({ success, data, error }) => {
      if (success) {
        return data
      } else {
        Notify.apiError(error)
        return false
      }
    })

  render () {
    const { canShowAll, canUpdateAll } = this.props
    return (
      <IndexDatatable
        withSearch
        columns={columns(canShowAll, canUpdateAll)}
        fetchData={this._fetchSuppliers}
      />
    )
  }
}
