import { post, patch, currentPath } from 'web/utility/http'

const offerCreatePath = () =>
  currentPath().split('/').slice(0, -1).join('/')

const createOffer = (values) =>
  post(offerCreatePath(), values)

const updateOffer = (values) =>
  patch(`/offers/${values.offer.id}`, values)

export { createOffer, updateOffer }
