import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const ButtonLink = ({
  children, onClick, href, target, kind, isWide, download, disabled, className
}) => {
  const classNames = cn('button', className, {
    [`is-${kind}`]:   kind,
    [`is-fullwidth`]: isWide,
    [`is-disabled`]:  disabled
  })

  return (
    <a
      className={classNames}
      target={target}
      onClick={onClick}
      href={href}
      download={download}
    >
      {children}
    </a>
  )
}

ButtonLink.propTypes = {
  children:  PropTypes.any.isRequired,
  disabled:  PropTypes.bool,
  href:      PropTypes.string,
  className: PropTypes.string,
  isWide:    PropTypes.bool,
  kind:      PropTypes.string,
  onClick:   PropTypes.func,
  target:    PropTypes.string,
  download:  PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ])
}

ButtonLink.defaultProps = {
  disabled: false,
  download: false,
  href:     '#',
  isWide:   false,
  kind:     '',
  target:   '_self'
}

export default ButtonLink
