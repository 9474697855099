import React from 'react'
import PropTypes from 'prop-types'

import { Datatable } from 'web/app/common/datatable'
import { Icon, IconLink, ButtonGroup } from 'web/app/common/elements'
import { humanize } from 'underscore.string'
import { t, date } from 'web/locale'

const columns = (deleteTemplate, downloadTemplatePath) => {
  return [
    {
      Header: humanize(t('attributes.supplierContractTemplate.createdAt')),
      id: 'createdAt',
      accessor: (obj) => date(obj.createdAt)
    },
    {
      Header: humanize(t('attributes.supplierContractTemplate.product')),
      id: 'product',
      accessor: (obj) => t(`common.product.${obj.product || 'universal'}`)
    },
    {
      Header: humanize(t('attributes.supplierContractTemplate.productTypes')),
      id: 'productTypes',
      accessor: (objs) => objs.productTypes.map((obj) => obj.name).join(', ') || humanize(t('common.product.universal')) // eslint-disable-line max-len
    },
    {
      Header: humanize(t('attributes.supplierContractTemplate.language')),
      id: 'language',
      accessor: (objs) => objs.language.name
    },
    {
      Header: humanize(t('attributes.supplierContractTemplate.priceModel')),
      id: 'priceModel',
      accessor: (obj) => t(`common.priceModel.${obj.priceModel || 'universal'}`)
    },
    {
      Header: humanize(t('attributes.supplierContractTemplate.meters')),
      id: 'meters',
      accessor: (objs) => objs.meters.map((obj) => obj.name).join(', ')
    },
    {
      Header: humanize(t('attributes.supplierContractTemplate.volume')),
      id: 'volume',
      accessor: (obj) => `${obj.volume.begin} - ${obj.volume.end || t('common.range.infinity')}`
    },
    {
      className: 'has-text-centered',
      sortable: false,
      Cell: ({ row: { _original } }) => { // eslint-disable-line
        return (
          <ButtonGroup>
            <IconLink
              iconClass="icon-download"
              target="_blank"
              url={downloadTemplatePath(_original.id)}
              download={_original.fileName}
            />
            <Icon
              iconClass="icon-trash"
              onClick={() => deleteTemplate(_original.id)}
            />
          </ButtonGroup>
        )
      }
    }
  ]
}

const TemplatesTable = ({ data, onTemplateDelete, downloadTemplatePath, isLoading }) => {
  return (
    <Datatable
      loading={isLoading}
      columns={columns(onTemplateDelete, downloadTemplatePath)}
      data={data}
      showPagination={false}
    />
  )
}

TemplatesTable.propTypes = {
  data:                 PropTypes.array.isRequired,
  isLoading:            PropTypes.bool.isRequired,
  onTemplateDelete:     PropTypes.func.isRequired,
  downloadTemplatePath: PropTypes.func.isRequired
}

export default TemplatesTable
