export function initAccordion () {
  var acc = document.getElementsByClassName('accordion')
  var i

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener('click', function () {
      var prevState = this.classList.contains('active')

      this.classList.toggle('active')
      var panel = this.nextElementSibling

      if (prevState) {
        panel.style.display = 'none'
      } else {
        panel.style.display = 'block'
      }
    })
  }
}
