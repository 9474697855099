import React, { Component }  from 'react'
import PropTypes             from 'prop-types'
import { Form }              from 'web/app/common/forms'
import { Columns, Column }   from 'web/app/common/layout'
import { FilesForm }         from './'
import { validateFilesForm } from '../validations'
import { Modal }             from 'web/app/common/modal'
import { Button }            from 'web/app/common/elements'
import { t }                 from 'web/locale'
import { postFile }          from '../api'

const can = (ticket, action) =>
  ticket.permissions.includes(action)

class FilesHeading extends Component {
  state = {
    isOpen: false,
    fileName: ''
  }

  _handleSubmit = (values, { setSubmitting, handleErrors, resetForm }) => {
    const ticketId = this.props.ticket.id
    const { fileName } = this.state

    postFile({ ticketId, values, fileName })
      .then(({ success, data }) => {
        const { handleFilesTableChange } = this.props

        if (success) {
          resetForm()
          this._handleClose()
          handleFilesTableChange()
        } else {
          handleErrors(data)
          setSubmitting(false)
        }
      })
  }

  _handleClose = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  _setFileName = (fileName) => {
    this.setState({ fileName: fileName })
  }

  render () {
    const { ticket } = this.props
    const { buttonTitle, title } = this.props
    const { isOpen } = this.state
    const modalProps = {
      header: t('views.tickets.show.filesForm.title'),
      isShown: isOpen,
      onClose: this._handleClose
    }

    return (
      <Columns>
        <Column>
          { title }
        </Column>
        <Column>
          <Modal {...modalProps}>
            <Form
              onSubmit={this._handleSubmit}
              validation={validateFilesForm}
            >
              <FilesForm getFileName={this._setFileName} />
            </Form>
          </Modal>
          {
            can(ticket, 'create_documents') && <div className="pull-right-table-buttons">
              <Button onClick={this._handleClose}>
                { buttonTitle }
              </Button>
            </div>
          }
        </Column>
      </Columns>
    )
  }
}

FilesHeading.propTypes = {
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  ticket: PropTypes.object.isRequired,
  handleFilesTableChange: PropTypes.func.isRequired
}

export default FilesHeading
