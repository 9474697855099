import React from 'react'
import PropTypes from 'prop-types'

import { FormRow }      from 'web/app/common/layout'
import { NativeSelect } from 'web/app/common/form-elements'

const SelectPriceFilterType = ({
  onFilterTypeChange,
  priceModel,
  priceModels,
  product,
  products
}) => (
  <FormRow columns={2}>
    <NativeSelect options={products} value={product} onChange={onFilterTypeChange('product')} />
    <NativeSelect
      options={priceModels}
      value={priceModel}
      onChange={onFilterTypeChange('priceModel')}
    />
  </FormRow>
)

SelectPriceFilterType.propTypes = {
  onFilterTypeChange: PropTypes.func.isRequired,
  priceModel:         PropTypes.string.isRequired,
  priceModels:        PropTypes.array.isRequired,
  product:            PropTypes.string.isRequired,
  products:           PropTypes.array.isRequired
}

export default SelectPriceFilterType
