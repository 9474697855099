import React from 'react'
import PropTypes from 'prop-types'

import { Button } from 'web/app/common/elements'
import { Columns, Column } from 'web/app/common/layout'
import { t } from 'web/locale'
import { Signature } from 'web/app/common/signature'

const SignDnoConsumptionForm = ({
  canSubmit,
  onSignatureChange,
  onSubmit
}) => {
  return (
    <div style={{ marginTop: '4rem' }}>
      <Columns>
        <Column classNames="is-6 is-offset-3 signature-field">
          <p>{t('views.offers.contractSign.signature')} *</p>
          <Signature onChange={onSignatureChange} />
        </Column>
      </Columns>

      <Columns>
        <Column classNames="is-6 is-offset-3">
          <Button
            type="submit"
            kind="primary"
            isWide
            disabled={!canSubmit}
            onClick={onSubmit}
          >
            {t('views.offers.contractSign.submit')}
          </Button>
        </Column>
      </Columns>
    </div>
  )
}

SignDnoConsumptionForm.propTypes = {
  canSubmit: PropTypes.bool.isRequired,
  onSignatureChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default SignDnoConsumptionForm
