import React from 'react'
import PropTypes from 'prop-types'

import { IndexDatatable, RowLink } from 'web/app/common/datatable'
import { t } from 'web/locale'

const ActionsIcons = ({ id }) => // eslint-disable-line react/prop-types
  <div>
    <RowLink url={`/contacts/${id}`} type="show" />
    <RowLink url={`/contacts/${id}/edit`} type="edit" />
  </div>

const columns = [
  {
    id: 'contacts.id',
    accessor: 'id',
    minWidth: 55,
    Header: t('attributes.contact.id')
  },
  {
    id: 'contacts.first_name',
    accessor: 'firstName',
    minWidth: 50,
    Header: t('attributes.contact.firstName')
  },
  {
    id: 'contacts.last_name',
    accessor: 'lastName',
    minWidth: 50,
    Header: t('attributes.contact.lastName')
  },
  {
    id: 'contacts.mobile',
    accessor: 'mobile',
    minWidth: 50,
    Header: t('attributes.contact.mobile')
  },
  {
    accessor: 'tel',
    sortable: false,
    minWidth: 50,
    Header: t('attributes.contact.tel')
  },
  {
    id: 'contacts.email',
    accessor: 'email',
    minWidth: 70,
    Header: t('attributes.contact.email')
  },
  {
    accessor: 'id',
    header: t('common.actions'),
    sortable: false,
    minWidth: 70,
    Cell: ({ row: { id } }) => <ActionsIcons id={id} /> // eslint-disable-line react/prop-types
  }
]

const ContactsTable = ({ onChange }) => {
  return (
    <IndexDatatable
      fetchData={onChange}
      columns={columns}
      sorted={[{ id: 'contacts.id', desc: false }]}
    />
  )
}

ContactsTable.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default ContactsTable
