import React from 'react'
import PropTypes from 'prop-types'

import { Level, LevelInfo } from 'web/app/common/level'
import { t } from 'web/locale'
import { countryEanName } from 'web/utility/country'

const Stats = ({ suppliers, volume, price, amount, button, finalize, unit }) => (
  <Level>
    <LevelInfo title={t('common.navigation.suppliers')}>{suppliers || '-'}</LevelInfo>
    <LevelInfo title={`${unit} / ${t('common.year')}`}>{volume || '-'}</LevelInfo>
    <LevelInfo title={t('views.offers.stats.currencyYear')}>{price || '-'}</LevelInfo>
    <LevelInfo title={countryEanName(true)}>{amount || '-'}</LevelInfo>
    { finalize &&
      <LevelInfo title={t('views.offers.stats.add', { ean_name: countryEanName() })}>
        {button || '-'}
      </LevelInfo>
    }
  </Level>
)

Stats.propTypes = {
  amount: PropTypes.number,
  button: PropTypes.element.isRequired,
  finalize: PropTypes.bool.isRequired,
  price: PropTypes.number,
  suppliers: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]),
  volume: PropTypes.number,
  unit: PropTypes.string.isRequired
}

export default Stats
