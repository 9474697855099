import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { prepareSorting } from 'web/utility/prepareData'
import { UserColumns, StatusSelect } from './components'
import Datatable from 'web/app/common/datatable/Datatable'
import { Columns, Column } from 'web/app/common/layout'
import SearchInput from 'web/app/common/datatable/SearchInput'
import Notify from 'web/utility/Notify'
import { getUsers, postPretend } from './api'

export default class UserTable extends Component {
  static propTypes = {
    canPretendAll:  PropTypes.bool.isRequired,
    canShowAll:     PropTypes.bool.isRequired,
    userStatuses:   PropTypes.array.isRequired
  }

  state = {
    data:     [],
    loading:  true,
    pages:    null,
    query:    '',
    status:   'active',
    page:     1,
    pageSize: 25,
    sorted: [{ id: 'users.id', desc: false }]
  }

  _handleStatusChange = (selectedField) => {
    this.setState({ status: selectedField.value }, this._handleChange)
  }

  _handleQueryChange = (query) => {
    this.setState({ query }, this._handleChange)
  }

  _handleLoginLinkClick = (e, id) => {
    e.preventDefault()

    postPretend({ id })
      .then(({ success, data, error }) => {
        if (success) {
          window.location = data.redirect
        } else {
          Notify.apiError(error)
        }
      })
  }

  _handleChange = ({ page, pageSize, sorted } = this.state) => {
    this.setState({ loading: true, page, pageSize, sorted })

    const { query, status } = this.state

    getUsers({
      page: page + 1,
      pageSize,
      query,
      status: status === 'all' ? '' : status,
      sorting: prepareSorting(sorted)
    }).then(({ success, response, error }) => {
      if (success) {
        const { pages, data } = response.data
        this.setState({ data: data, pages, loading: false })
      } else {
        Notify.apiError(error)
      }
    })
  }
  // eslint-disable-next-line no-unused-vars
  _getTdProps = (state, rowInfo, column) => {
    const tdProps = { style: {} }
    const { canShowAll } = this.props
    const isButton = column && column.id === 'buttons'

    if (canShowAll && !isButton) {
      tdProps.style = { cursor: 'pointer' }

      tdProps.onClick = () => {
        const { id } = rowInfo.row || {}

        window.location = `${'/users'}/${id}`
      }
    }

    return tdProps
  }

  render () {
    const { data, loading, pages, query, sorted, status } = this.state
    const { canPretendAll, userStatuses } = this.props
    const { _handleChange, _handleLoginLinkClick, _getTdProps } = this

    return (
      <div>
        <Columns>
          <Column classNames="is-2 is-offset-6">
            <StatusSelect
              selectionValue={status}
              userStatuses={userStatuses}
              onChange={this._handleStatusChange}
            />
          </Column>
          <Column classNames="is-4">
            <SearchInput
              query={query}
              onChange={this._handleQueryChange}
            />
          </Column>
        </Columns>

        <Datatable
          manual
          columns={UserColumns({
            canPretendAll,
            handleLinkClick: _handleLoginLinkClick
          })}
          data={data}
          loading={loading}
          pages={pages}
          defaultSorted={sorted}
          onFetchData={_handleChange}
          getTdProps={_getTdProps}
        />
      </div>
    )
  }
}
