import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Form } from 'web/app/common/forms'
import OfferForm from './component'
import validate  from './validation'

import { createOffer, updateOffer }     from './api'
import { prepareData, prepareBackPath } from './helpers'

export default class OfferFormContainer extends Component {
  static propTypes = {
    action:         PropTypes.string.isRequired,
    contact:        PropTypes.object.isRequired,
    contacts:       PropTypes.array.isRequired,
    offer:          PropTypes.object.isRequired
  }

  state = {
    contactId: null
  }

  _handleSubmit = (values, { handleErrors, setSubmitting }) => {
    let actionFunc
    const { action } = this.props

    if (action === 'new') actionFunc = createOffer
    if (action === 'edit') actionFunc = updateOffer

    actionFunc(values).then(({ success, data }) => {
      if (success) {
        window.location = data.redirect
      } else {
        setSubmitting(false)
        handleErrors(data)
      }
    })
  }

  _setupContact = ({ value: contactId, handleChanges }) => {
    const { contacts } = this.props

    // eslint-disable-next-line
    const contact   = contacts.find((contact) => contact.id == contactId) || {}
    const currentId = contact.id || null
    const offer     = { languageId: contact.languageId }

    this.setState({ contactId: currentId }, () => {
      handleChanges({ contact, offer })
    })
  }

  _findContactId = (newContactId, currContact) => {
    return newContactId || (currContact && currContact.id)
  }

  render () {
    const { contact, offer } = this.props
    const { contactId } = this.state
    const backPath = prepareBackPath()
    const data = prepareData(this.props)

    return (
      <div>
        <Form
          id="offer-form"
          onSubmit={this._handleSubmit}
          initialValues={contact && { contact, offer }}
          validation={validate}
        >
          <OfferForm
            data={data}
            backPath={backPath}
            offer={offer}
            setupContact={this._setupContact}
            contactTitle={this._contactTitle}
            contact={contact}
            contactId={this._findContactId(contactId, contact)}
          />
        </Form>
      </div>
    )
  }
}
