import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'

import {
  ContactsTable,
  UserAssignmentsTable,
  CreditScoresTable,
  FilesTable,
  GeneralInfo,
  ContactsHeading,
  UserAssigmentsHeading,
  CreditScoresHeading,
  FilesHeading,
  GeneralInfoHeading
} from './components'
import { Panel, PanelBlock, PanelHead } from 'web/app/common/panel'
import { t } from 'web/locale'

const Info = ({
  company,
  generalInfo,
  onFilesTableChange,
  onUserAssignmentsTableChange,
  onContactsTableChange,
  onCreditScoresTableChange,
  permissions
}) => (
  <div>
    <Panel kind={'primary'}>
      <PanelHead>
        <GeneralInfoHeading company={company} />
      </PanelHead>
      <PanelBlock>
        <GeneralInfo company={company} generalInfo={generalInfo} />
      </PanelBlock>
    </Panel>

    <Panel>
      <PanelHead>
        <ContactsHeading
          title={t('common.panelHeaders.contacts')}
          buttonTitle={t('common.button.add')}
          companyId={company.id}
        />
      </PanelHead>
      <PanelBlock>
        <ContactsTable onChange={onContactsTableChange} />
      </PanelBlock>
    </Panel>

    {permissions.userAssignments.includes('read_all') && (
      <Panel>
        <PanelHead>
          <UserAssigmentsHeading
            title={t('views.companies.show.userAssignments.title')}
            buttonTitle={t('common.button.add')}
            companyId={company.id}
          />
        </PanelHead>
        <PanelBlock>
          <UserAssignmentsTable onChange={onUserAssignmentsTableChange} companyId={company.id} />
        </PanelBlock>
      </Panel>
    )}

    {permissions.creditScores.includes('read_all') && (
      <Panel>
        <PanelHead>
          <CreditScoresHeading
            title={t('common.panelHeaders.creditScores')}
            buttonTitle={t('common.button.add')}
            companyId={company.id}
          />
        </PanelHead>
        <PanelBlock>
          <CreditScoresTable onChange={onCreditScoresTableChange} />
        </PanelBlock>
      </Panel>
    )}

    <Panel>
      <PanelHead>
        <FilesHeading
          onFilesTableChange={onFilesTableChange}
          title={t('views.companies.show.files.title')}
          buttonTitle={t('common.button.add')}
          companyId={company.id}
        />
      </PanelHead>
      <PanelBlock>
        <FilesTable
          onChange={onFilesTableChange}
          permissions={permissions.documents}
        />
      </PanelBlock>
    </Panel>
  </div>
)

Info.propTypes = {
  onContactsTableChange: PropTypes.func.isRequired,
  onUserAssignmentsTableChange: PropTypes.func.isRequired,
  onCreditScoresTableChange: PropTypes.func.isRequired,
  onFilesTableChange: PropTypes.func.isRequired,
  company: MobxPropTypes.observableObject.isRequired,
  generalInfo: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired
}

export default Info
