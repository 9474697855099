import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { IndexDatatable, RowLink } from 'web/app/common/datatable'

import { t, dateTime } from 'web/locale'
import { destroyCalendarBlock } from './api'

const columns = (ref) => [{
  id: 'calendarBlocks.id',
  accessor: 'id',
  minWidth: 50,
  Header: t('attributes.calendarBlock.id')
}, {
  id: 'blockFor.firstName',
  accessor: 'blockFor.fullName',
  minWidth: 160,
  Header: t('attributes.calendarBlock.blockFor')
}, {
  id: 'createdBy.firstName',
  accessor: 'createdBy.fullName',
  minWidth: 140,
  Header: t('attributes.calendarBlock.createdBy')
}, {
  id: 'calendarBlocks.startAt',
  accessor: ({ startAt }) => dateTime(startAt),
  minWidth: 140,
  Header: t('attributes.calendarBlock.startAt')
}, {
  id: 'calendarBlocks.endAt',
  accessor: ({ endAt }) => dateTime(endAt),
  minWidth: 140,
  Header: t('attributes.calendarBlock.endAt')
}, {
  accessor: 'days',
  minWidth: 140,
  sortable: false,
  Header: t('attributes.calendarBlock.recurring')
}, {
  accessor: 'note',
  minWidth: 100,
  sortable: false,
  Header: t('attributes.calendarBlock.note'),
  Cell: ({ value }) => <div dangerouslySetInnerHTML={{ __html: value }} /> // eslint-disable-line
}, {
  id: 'links',
  sortable: false,
  Cell: ({ original: { id, permissions } }) => {
    if (permissions.includes('destroy')) {
      const onClick = () => {
        destroyCalendarBlock(id).then(() => {
          ref.current._handleChange()
        })
      }
      return <RowLink type="delete" onClick={onClick} />
    } else {
      return null
    }
  }
}]

const ContactsIndexTable = forwardRef(({ fetchData }, ref) => {
  return <IndexDatatable
    ref={ref}
    withSearch
    columns={columns(ref)}
    fetchData={fetchData}
    sorted={[{ id: 'calendarBlocks.id', desc: true }]}
  />
})

ContactsIndexTable.propTypes = {
  fetchData: PropTypes.func.isRequired
}

export default ContactsIndexTable
