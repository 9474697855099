import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'web/app/common/modal'
import { t } from 'web/locale'
import { ProcessMeeting } from 'web/app/meetings/ProcessMeeting'

export default class ProcessMeetingModal extends Component {
  static propTypes = {
    meeting: PropTypes.object.isRequired,
    meetingsDurations: PropTypes.object.isRequired
  }

  _handleCloseModal = () => {
    window.location.reload()
  }

  _onMeetingUpdate = () => {
    window.location.reload()
  }

  render () {
    const { meeting, meetingsDurations } = this.props
    const modalProps = {
      header: t(`views.meetings.index.processMeeting`),
      isShown: true,
      onClose: this._handleCloseModal
    }

    return (
      <div>
        <Modal {...modalProps}>
          <ProcessMeeting
            meetingsDurations={meetingsDurations}
            meeting={meeting}
            onMeetingUpdate={this._onMeetingUpdate}
          />
        </Modal>
      </div>
    )
  }
}
