import React from 'react'
import PropTypes from 'prop-types'

const Table = ({ children }) => (
  <table className="table is-bordered is-striped">
    {children}
  </table>
)

Table.propTypes = {
  children: PropTypes.any.isRequired
}

export default Table
