import { http, apiPath, destroy } from 'web/utility/http'
import Notify from 'web/utility/Notify'

const fetchData = ({ page, pageSize, sorting }) =>
  http
    .get(apiPath('/sent_remote_links'), {
      params: {
        page,
        pageSize,
        sorting
      }
    })
    .then((response) => response.data)
    .catch((error) => {
      Notify.apiError(error)
      return false
    })

const deactivateSentRemoteLink = ({ id }) =>
  destroy(apiPath(`/sent_remote_links/${id}`))

export { fetchData, deactivateSentRemoteLink }
