import React from 'react'

import { PanelBlock } from 'web/app/common/panel'
import { FormRow } from 'web/app/common/layout'
import { InputWithLabel as Input } from 'web/app/common/forms'
import { t } from 'web/locale'

const PasswordFields = () =>
  <PanelBlock>
    <h3 className="title is-3">{t('views.users.new.passwordInfo')}</h3>
    <FormRow cols="2">
      <Input field="user.password" type="password" required />
      <Input field="user.passwordConfirmation" type="password" required />
    </FormRow>
  </PanelBlock>

export default PasswordFields
