import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Form } from 'web/app/common/forms'
import EcaUploadForm from './EcaUploadForm'
import { validateEcaUploadForm } from '../validations'
import { Modal } from 'web/app/common/modal'
import { t } from 'web/locale'

export default class EcaUploadModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    ecaUploadErrors: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired
  }

  _handleClose = () => {
    this.props.onCloseModal()
  }

  render () {
    const { isOpen, ecaUploadErrors, onSubmit, onReset } = this.props

    const modalProps = {
      header:  t('views.contracts.index.ecaUpload.label'),
      isShown: isOpen,
      onClose: this._handleClose
    }

    return (
      <Modal {...modalProps}>
        <Form
          onSubmit={onSubmit}
          validation={validateEcaUploadForm}
        >
          <EcaUploadForm
            ecaUploadErrors={ecaUploadErrors}
            onReset={onReset}
          />
        </Form>
      </Modal>
    )
  }
}
