import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import tippy from 'tippy.js'
import ReactDOMServer from 'react-dom/server'

import { t, moment } from 'web/locale'
import { Modal } from 'web/app/common/modal'
import { Calendar } from 'web/app/common/calendar'
import { ProcessMeeting } from 'web/app/meetings/ProcessMeeting'
import Event from './Event'

import { formatMeeting, formatCalendarBlock } from './helpers'
import { fetchMeetings, fetchCalendarBlocks } from './api'

const renderEvent = (props) => {
  const { el, event: { title, extendedProps, rendering } } = props
  let html

  if (rendering === 'background') {
    html = `
      ${title}
      ${title && '<br />'}
      ${extendedProps.blockFor.fullName}
    `
  } else {
    html = ReactDOMServer.renderToStaticMarkup(Event(extendedProps))
  }

  tippy(el, {
    theme: 'light',
    content: html.trim()
  })
}

export default class CalendarMeetingsContainer extends Component {
  static propTypes = {
    eventClickable: PropTypes.bool,
    fetchAll: PropTypes.bool,
    userIds: PropTypes.array,
    meetingsDurations: PropTypes.object // required if eventClickable is true
  }

  static defaultProps = {
    eventClickable: false,
    meetingsDurations: {},
    fetchAll: false,
    userIds: undefined
  }

  state = {
    meeting: undefined
  }

  componentDidUpdate (prevProps) {
    if (this.props.userIds !== prevProps.userIds) {
      this.refetch()
    }
  }

  calendarRef = createRef()

  sourceFactory = (api) => {
    return ({ start, end }, successCb, failureCb) => {
      const { userIds, fetchAll } = this.props

      // BUG: start and end dates are based on Sunday being first day of the week for some reason
      // adding 1 day manually so week starts on Monday
      const from = moment(start).add(1, 'd').toDate()
      const until = moment(end).add(1, 'd').toDate()

      api({ from, until, userIds, fetchAll }).then(({ success, data, error }) => {
        if (success) {
          successCb(data)
        } else {
          failureCb(error)
        }
      })
    }
  }

  handleEventClick = ({ event }) => {
    if (event.rendering === 'background') return

    const { extendedProps: meeting } = event
    this.setState({ meeting })
  }

  handleMeetingUpdate = () => {
    this.handleModalClose()
    this.refetch()
  }

  handleModalClose = () => {
    this.setState({ meeting: undefined })
  }

  refetch () {
    const calendarApi = this.calendarRef.current.getApi()
    calendarApi.refetchEvents()
  }

  meetingSource = this.sourceFactory(fetchMeetings)
  calendarBlockSource = this.sourceFactory(fetchCalendarBlocks)

  render () {
    const { meeting } = this.state
    const { meetingsDurations } = this.props

    return (
      <>
        <Calendar
          ref={this.calendarRef}
          eventSources={[
            {
              events: this.meetingSource,
              eventDataTransform: formatMeeting
            },
            {
              events: this.calendarBlockSource,
              eventDataTransform: formatCalendarBlock
            }
          ]}
          eventRender={renderEvent}
          getApi={this.getApi}
          eventClick={(this.props.eventClickable && this.handleEventClick) || undefined}
        />
        <Modal
          header={t(`views.meetings.index.processMeeting`)}
          isShown={!!meeting}
          onClose={this.handleModalClose}
        >
          <ProcessMeeting
            meetingsDurations={meetingsDurations}
            meeting={meeting}
            onMeetingUpdate={this.handleMeetingUpdate}
          />
        </Modal>
      </>
    )
  }
}
