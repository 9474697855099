import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Columns, Column } from 'web/app/common/layout'
import { validateTicketForm } from 'web/app/tickets/ProcessTicket/validations'
import { ProcessTicket } from 'web/app/tickets/ProcessTicket'
import { Button } from 'web/app/common/elements'
import { t } from 'web/locale'
import { postTickets } from '../api'

class TicketsHeading extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    buttonTitle: PropTypes.string.isRequired,
    companyId: PropTypes.number.isRequired,
    users: PropTypes.array.isRequired,
    ticketCategories: PropTypes.array.isRequired,
    companyContracts: PropTypes.array.isRequired
  }

  state = {
    ticket: {
      createdForId: null,
      bccEmails: [],
      category: null,
      deadline: null,
      note: null,
      file: null,
      fileName: null,
      contractsIds: []
    },
    isOpen: false
  }

  _handleSubmit = ({ ticket }, { setSubmitting, handleErrors, resetForm }) => {
    const { companyId } = this.props

    if (ticket.file) {
      ticket.fileName = this.state.ticket.fileName
    }

    postTickets({ id: companyId, ticket })
      .then(({ success, data }) => {
        if (success) {
          resetForm()
          this._toogleModal()
          window.location.reload()
        } else {
          setSubmitting(false)
          handleErrors(data)
        }
      })
  }

  _setFileName = (name) => {
    this.setState({ ticket: { fileName: name } })
  }

  _resetState = () => {
    this.setState({ file: '' })
  }

  _toogleModal = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render () {
    const {
      buttonTitle,
      title,
      users,
      ticketCategories,
      companyContracts
    } = this.props
    const { contact, state, note, isOpen } = this.state
    const initialValues = { contact, state, note }
    const modalProps = {
      header: t('views.companies.show.tickets.add'),
      isShown: isOpen,
      onClose: this._toogleModal
    }

    return (
      <Columns>
        <Column>
          { title }
        </Column>
        <Column>
          <ProcessTicket
            modalProps={modalProps}
            handleSubmit={this._handleSubmit}
            initialValues={initialValues}
            validateForm={validateTicketForm}
            ticketsUsers={users}
            bccEmails={this.state.ticket.bccEmails}
            ticketsCategories={ticketCategories}
            companyContracts={companyContracts}
            getFileName={this._setFileName}
          />
          <div className="pull-right-table-buttons">
            <Button onClick={this._toogleModal}>
              { buttonTitle }
            </Button>
          </div>
        </Column>
      </Columns>
    )
  }
}

export default TicketsHeading
