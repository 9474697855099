import React from 'react'
import PropTypes from 'prop-types'

import { Panel, PanelBlock, PanelHead } from 'web/app/common/panel'
import { t } from 'web/locale'

import MailTable from './components/MailTable'
import CsvMailerForm from './components/CsvMailerForm'
import ContractMailerForm from './components/ContractMailerForm'

const SupplierMails = ({
  canUpdate,
  days,
  onContractFormUpdate,
  onCsvFormUpdate,
  states,
  supplier
}) => {
  return (
    <div>
      <Panel kind="light">
        <PanelHead>{t('views.suppliers.show.mailerCsv')}</PanelHead>

        <PanelBlock>
          <CsvMailerForm
            supplier={supplier}
            days={days}
            onCsvFormUpdate={onCsvFormUpdate}
            canUpdate={canUpdate}
          />
        </PanelBlock>
      </Panel>

      <Panel kind="light">
        <PanelHead>{t('views.suppliers.show.mailerContract')}</PanelHead>

        <PanelBlock>
          <ContractMailerForm
            supplier={supplier}
            days={days}
            onContractFormUpdate={onContractFormUpdate}
            canUpdate={canUpdate}
          />
        </PanelBlock>
      </Panel>

      <Panel>
        <PanelBlock>
          <MailTable states={states} />
        </PanelBlock>
      </Panel>
    </div>
  )
}

SupplierMails.propTypes = {
  canUpdate:            PropTypes.bool.isRequired,
  days:                 PropTypes.array.isRequired,
  onContractFormUpdate: PropTypes.func.isRequired,
  onCsvFormUpdate:      PropTypes.func.isRequired,
  states:               PropTypes.array.isRequired,
  supplier:             PropTypes.object.isRequired
}

export default SupplierMails
