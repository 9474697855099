import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { t }              from 'web/locale'
import { Control, Input } from '../form-elements'

export default class SearchInput extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    query: PropTypes.string.isRequired
  }

  static defaultProps = {
    placeholder: t('common.searchPrompt')
  }

  constructor (props) {
    super(props)
    this._handleChange     = this._handleChange.bind(this)
    this._handleBlur       = this._handleBlur.bind(this)
    this._handleEnterPress = this._handleEnterPress.bind(this)
  }

  state = {
    value: this.props.query
  }

  _handleChange (e) {
    this.setState({ value: e.target.value })
  }

  _handleBlur () {
    this.props.onChange(this.state.value)
  }

  _handleEnterPress (e) {
    // if (e.key === 'Enter') this.props.onChange(this.state.value)
    if (e.key === 'Enter') e.target.blur()
  }

  render () {
    const { placeholder } = this.props
    return (
      <Control hasIcon hasAddons>
        <Input
          value={this.state.value}
          handleChange={this._handleChange}
          handleBlur={this._handleBlur}
          handleKeyPress={this._handleEnterPress}
          placeholder={placeholder}
          isExpanded
        />
        <span className="icon is-small">
          <i className="fa icon-search" />
        </span>
        <a className="button is-primary">
          {t('common.button.search')}
        </a>
      </Control>
    )
  }
}
