import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'
import { Datatable } from 'web/app/common/datatable'
import { Icon } from 'web/app/common/elements'

const columns = (deleteUnit, viewConversion) => {
  return [
    { Header: t('attributes.unit.name'), accessor: 'name' },
    { Header: t('attributes.unit.coefficient'), accessor: 'coefficient' },
    { Header: t('attributes.unit.productTypeId'), accessor: 'productType.name' },
    {
      Header: t('attributes.unit.conversion'),
      className: 'has-text-centered',
      sortable: false,
      Cell: (obj) => <span>{viewConversion(obj.row._original)}</span>
    },
    {
      Header: t('common.button.delete'),
      className: 'has-text-centered',
      sortable: false,
      Cell: ({ row: { _original: { id } } }) => // eslint-disable-line
        <Icon iconClass="icon-trash" onClick={() => deleteUnit(id)} />
    }
  ]
}

const UnitTable = ({ data, onUnitDelete, tableIsLoading, viewConversion }) => {
  return (
    <Datatable
      loading={tableIsLoading}
      columns={columns(onUnitDelete, viewConversion)}
      data={data}
      showPagination={false}
    />
  )
}

UnitTable.propTypes = {
  data:           PropTypes.array.isRequired,
  onUnitDelete:   PropTypes.func.isRequired,
  tableIsLoading: PropTypes.bool.isRequired,
  viewConversion: PropTypes.func.isRequired
}

export default UnitTable
