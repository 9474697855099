import React from 'react'
import PropTypes from 'prop-types'
import { chain } from 'lodash'

const CommissionPoints = ({
  original: { commissionPoints, margin: { type } }, data: proposals
}) => {
  const colorValue = chain(proposals)
    .filter((proposal) => type === proposal.margin.type)
    .map((proposal) => proposal.commissionPoints)
    .max()
    .thru((maxPoints) => Math.round(commissionPoints / maxPoints * 100))
    .value()

  return (
    <div
      className="has-text-centered"
      style={{
        backgroundColor: `hsl(${colorValue}, 71%, 48%)`,
        color: 'hsl(0, 0%, 100%)'
      }}
    >
      {commissionPoints}
    </div>
  )
}

CommissionPoints.propTypes = {
  data: PropTypes.array.isRequired,
  original: PropTypes.shape({
    commissionPoints: PropTypes.number.isRequired,
    margin: PropTypes.shape({
      type: PropTypes.string.isRequired
    })
  })
}

export default CommissionPoints
