import React from 'react'
import PropTypes from 'prop-types'

import { IndexDatatable, RowLink } from 'web/app/common/datatable'
import { t } from 'web/locale'
import { destroyCompanyDocument, downloadFilePath } from '../api'
import Notify from 'web/utility/Notify'

const can = (permissions, action) =>
  permissions.includes(action)

const deleteCompanyDocument = ({ companyId, id }) => {
  destroyCompanyDocument({ companyId, id }).then(({ success, error }) => {
    if (success) {
      window.location.reload()
    } else {
      Notify.apiError(error)
    }
  })
}

const ActionsIcons = ({ id, companyId, permissions }) => { // eslint-disable-line react/prop-types
  if (can(permissions, 'destroy')) {
    return (<div>
      <RowLink type="delete" onClick={() => deleteCompanyDocument({ companyId, id })} />
    </div>
    )
  } else {
    return null
  }
}

const columns = (permissions) => ([
  {
    accessor: 'id',
    sortable: false,
    minWidth: 50,
    Header: t('attributes.company.document.id')
  },
  {
    sortable: false,
    minWidth: 50,
    Header: t('attributes.company.document.filename'),
    Cell: ({ row: { _original: { fileName, id, companyId } } }) => // eslint-disable-line react/prop-types, max-len
      <a
        target="_blank"
        download={fileName}
        href={downloadFilePath({ companyId, id })}
      >
        {fileName}
      </a>
  },
  {
    accessor: 'userFileType',
    minWidth: 50,
    Header: t('attributes.company.document.userFileType')
  },
  {
    accessor: 'uploadedBy',
    sortable: false,
    minWidth: 50,
    Header: t('attributes.company.document.uploadedBy')
  },
  {
    accessor: 'uploadedOn',
    sortable: false,
    minWidth: 50,
    Header: t('attributes.company.document.uploadedOn')
  },
  {
    sortable: false,
    minWidth: 20,
    Header: t('common.actions'),
    Cell: ({ original: { id, companyId } }) => // eslint-disable-line react/prop-types
      <ActionsIcons id={id} companyId={companyId} permissions={permissions} />
  }
])

const FilesTable = ({ onChange, permissions }) => {
  return (
    <IndexDatatable
      fetchData={onChange}
      columns={columns(permissions)}
      sorted={[{ id: 'files.id', desc: false }]}
    />
  )
}

FilesTable.propTypes = {
  onChange: PropTypes.func.isRequired,
  permissions: PropTypes.array.isRequired
}

export default FilesTable
