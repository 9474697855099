import React from 'react'
import PropTypes from 'prop-types'

import { FormRow } from 'web/app/common/layout'
import {
  ControlButtons,
  Form,
  InputWithLabel as Input,
  SelectWithLabel as Select
} from 'web/app/common/forms'
import {
  preparePriceModels,
  prepareDurations,
  prepareMeters,
  prepareTariffs,
  prepareProducts
} from 'web/utility/prepareData'
import validations from '../validation'
import { t } from 'web/locale'

const prepareForm = ({ addonType, dataOptions }) => {
  return {
    priceModel: {
      label: t('common.priceModel._'),
      field: 'priceModel',
      data: dataOptions.priceModels,
      prepare: preparePriceModels
    },
    duration: {
      label: t('common.duration._'),
      field: 'duration',
      data: dataOptions.durations,
      prepare: prepareDurations
    },
    meter: {
      label: t('attributes.meter._'),
      field: 'meterId',
      data: dataOptions.meters,
      prepare: prepareMeters
    },
    tariff: {
      label: t('attributes.tariff._'),
      field: 'tariffId',
      data: dataOptions.tariffs,
      prepare: prepareTariffs
    },
    volume: {
      label: {
        begin: t('common.range.begin'),
        end: t('common.range.end')
      },
      field: {
        begin: 'range.begin',
        end: 'range.end'
      }
    }
  }[addonType]
}

const AddonsForm = ({
  addonType,
  dataOptions
}) => {
  const { label, field, data, prepare } = prepareForm({ addonType, dataOptions })

  const addonTypeInput = (addonType === 'volume')
    ? <FormRow columns={2}>
      <Input label={label.begin} field={field.begin} required />
      <Input label={label.end} field={field.end} required />
    </FormRow>
    : <Select label={label} field={field} data={prepare(data)} required />

  return (
    <>
      <FormRow>
        <Select
          label={t('common.product._')}
          field="product"
          data={prepareProducts(dataOptions.products)}
          required
        />
        {addonTypeInput}
        <Input
          label={t('attributes.addon._')}
          field="addon"
          type="number"
          required
        />
      </FormRow>

      <ControlButtons />
    </>
  )
}

AddonsForm.propTypes = {
  addonType: PropTypes.string.isRequired,
  dataOptions: PropTypes.object.isRequired
}

export default (props) => {
  // eslint-disable-next-line react/prop-types
  const { onAddonCreate, addonType } = props

  return (
    <Form
      onSubmit={onAddonCreate}
      validation={validations(addonType)}
    >
      <AddonsForm {...props} />
    </Form>
  )
}
