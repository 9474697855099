import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Modal from './Modal'
import { Button } from '../elements'

export default class ModalButton extends Component {
  static propTypes = {
    buttonText: PropTypes.string.isRequired,
    children:   PropTypes.object.isRequired,
    header:     PropTypes.string.isRequired
  }

  constructor (props) {
    super(props)
    this._toggleModal = this._toggleModal.bind(this)
  }

  state = {
    isOpen: false
  }

  _toggleModal (override) {
    return () => {
      const { isOpen } = this.state
      let newState     = !isOpen

      if (override === 'close') newState = false
      if (override === 'open') newState = true

      this.setState({ isOpen: newState })
    }
  }

  render () {
    const { isOpen } = this.state
    const { buttonText, children, header } = this.props

    return (
      <div>
        <Modal
          isShown={isOpen}
          onClose={this._toggleModal('close')}
          header={header}
        >
          {children}
        </Modal>
        <Button onClick={this._toggleModal()}>
          {buttonText}
        </Button>
      </div>
    )
  }
}
