import React from 'react'
import PropTypes from 'prop-types'

import { t }                 from 'web/locale'
import { Modal }             from 'web/app/common/modal'
import { Panel, PanelBlock } from 'web/app/common/panel'
import { LoadingOverlay }    from 'web/app/common/helpers'
import Filters               from './components/Filters'
import ProposalsTable        from './components/ProposalsTable'
import Buttons               from './components/Buttons'

const HiddenProposal = ({
  data,
  durations,
  filters,
  loading,
  loadingFilters,
  marginLetters,
  modal: { message, isShown, onClose },
  offer,
  onAnalyzeClick,
  onDeleteMandateClick,
  onFilterChange,
  onProposalToggle,
  priceModels,
  product,
  productTypes,
  onMandateToggle,
  checkedMandates,
  isMandate
}) =>
  <Panel>
    {/* display analysis results */}
    <Modal header={t('views.offers.show.analysisResults')} isShown={isShown} onClose={onClose}>
      <div className="content">
        <p>{message}</p>
      </div>
    </Modal>

    <PanelBlock style={{ position: 'relative' }}>
      {loadingFilters && <LoadingOverlay />}
      <Filters
        durations={durations}
        filters={filters}
        loadingFilters={loadingFilters}
        marginLetters={marginLetters}
        onFilterChange={onFilterChange}
        priceModels={priceModels}
        productTypes={productTypes}
        product={product}
        offer={offer}
      />
    </PanelBlock>

    <PanelBlock>
      <ProposalsTable
        data={data}
        canDeleteMandates={offer.can(`destroy_${product}_mandates`)}
        loading={loading}
        isMandate={isMandate}
        checkedMandates={checkedMandates}
        onProposalToggle={onProposalToggle}
        onMandateToggle={onMandateToggle}
      />
    </PanelBlock>

    <Buttons
      offer={offer}
      product={product}
      onAnalyzeClick={onAnalyzeClick}
      onDeleteMandateClick={onDeleteMandateClick}
    />
  </Panel>

HiddenProposal.propTypes = {
  data:                 PropTypes.array.isRequired,
  durations:            PropTypes.array.isRequired,
  filters:              PropTypes.object.isRequired,
  loading:              PropTypes.bool.isRequired,
  isMandate:            PropTypes.bool.isRequired,
  loadingFilters:       PropTypes.bool.isRequired,
  marginLetters:        PropTypes.array.isRequired,
  offer:                PropTypes.object.isRequired,
  onAnalyzeClick:       PropTypes.func.isRequired,
  onDeleteMandateClick: PropTypes.func.isRequired,
  onFilterChange:       PropTypes.func.isRequired,
  onProposalToggle:     PropTypes.func.isRequired,
  priceModels:          PropTypes.array.isRequired,
  product:              PropTypes.string.isRequired,
  productTypes:         PropTypes.array.isRequired,
  onMandateToggle:      PropTypes.func.isRequired,
  checkedMandates:      PropTypes.array.isRequired,
  modal:                PropTypes.shape({
    message:            PropTypes.string,
    isShown:            PropTypes.bool.isRequired,
    onClose:            PropTypes.func.isRequired
  })
}

export default HiddenProposal
