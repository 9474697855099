import { get, apiPath } from 'web/utility/http'

export const fetchOrganisation = ({ query, limit }) => {
  return get(apiPath('/organisations'), {
    params: {
      page: 1,
      pageSize: limit,
      sorting: [{ id: 'organisations.id', desc: 'false' }],
      query
    }
  })
}
