import React from 'react'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'

const TextInput = ({
  handleChange,
  value
}) => {
  if (value && !handleChange) {
    throw new Error('No handler provided to TextInput')
  } else if (!value && value !== '' && handleChange) {
    throw new Error('No value provided to TextInput')
  }

  return (
    <ReactQuill
      value={value}
      onChange={handleChange}
    />
  )
}

TextInput.propTypes = {
  handleChange: PropTypes.func,
  value:        PropTypes.string
}

export default TextInput
