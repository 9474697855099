import { t } from 'web/locale'

const countryEanName = (plural = false) =>
  t(`countries.${plural ? 'eans' : 'ean'}`)

const currentCountry = ServerConfig.country // eslint-disable-line no-undef

const isCountry = (value) => currentCountry === value

const isUinAndVatRequired = () => isCountry('fr')

const shouldApplyKVA = (product) => isCountry('fr') && product === 'electricity'

const uinName = () => {
  switch (currentCountry) {
    case 'fr':
      return t('countries.siren')
    case 'be':
      return t('countries.vat')
    default:
      return 'UIN'
  }
}

export {
  countryEanName,
  currentCountry,
  isCountry,
  isUinAndVatRequired,
  shouldApplyKVA,
  uinName
}
