import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toNumber } from 'lodash'

import { t } from 'web/locale'
import { FormRow } from 'web/app/common/layout'
import { InfoWithLabel } from 'web/app/common/form-elements'
import { InputWithLabel as Input } from 'web/app/common/forms'

export default class Fees extends Component {
  static propTypes = {
    setValue: PropTypes.func.isRequired,
    getValue: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this._calculateFixedFee = this._calculateFixedFee.bind(this)
  }

  componentWillUpdate (_nextProps, _nextState) {
    this._calculateFixedFee()
  }

  _calculateFixedFee () {
    const { getValue, setValue } = this.props
    const brokerFixedFee   = toNumber(getValue('mandate.brokerFixedFee'))
    const supplierFixedFee = toNumber(getValue('mandate.supplierFixedFee'))
    const currentFixedFee  = toNumber(getValue('mandate.fixedFee'))
    const newFixedFee      = brokerFixedFee + supplierFixedFee

    if (currentFixedFee !== newFixedFee) {
      setValue('mandate.fixedFee', newFixedFee)
    }
  }

  render () {
    const { getValue } = this.props
    const fixedFee = getValue('mandate.fixedFee')

    return (
      <FormRow columns={3}>
        <Input field="mandate.brokerFixedFee" type="number" required />
        <Input field="mandate.supplierFixedFee" type="number" required />
        <InfoWithLabel name={t('attributes.mandate.fixedFee')}>
          {fixedFee}
        </InfoWithLabel>
      </FormRow>
    )
  }
}
