import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Notify from 'web/utility/Notify'

import { EansTable } from './component'
import { confirmDeleteMessage } from './helpers'
import { fetchData, fetchEanDeleteInfo, deleteEan } from './api'
import { prepareSorting } from '../../../utility/prepareData'

export default class EansTableContainer extends Component {
  static propTypes = {
    states: PropTypes.array.isRequired
  }

  constructor (props) {
    super(props)
    this._changeStatus           = this._changeStatus.bind(this)
    this._handleEanDelete        = this._handleEanDelete.bind(this)
    this._handleDeleteRequest    = this._handleDeleteRequest.bind(this)
    this._handleTableChange      = this._handleTableChange.bind(this)
    this._handleTableQueryChange = this._handleTableQueryChange.bind(this)
    this._isCurrentStatus        = this._isCurrentStatus.bind(this)

    this.state = {
      currentStatus: props.states[0],
      statuses: props.states,
      tableData: {
        data:     [],
        loading:  true,
        page:     1,
        pages:    1,
        pageSize: 25,
        query:    '',
        sorted:   [{ id: 'eans.id', desc: false }]
      }
    }
  }

  _changeStatus (status) {
    return () => {
      this.setState({ currentStatus: status }, this._handleTableChange)
    }
  }

  _isCurrentStatus (status) {
    return this.state.currentStatus === status
  }

  _handleTableQueryChange (query) {
    const { tableData } = this.state
    this.setState({ tableData: { ...tableData, query } }, this._handleTableChange)
  }

  _handleTableChange (state = this.state.tableData) {
    const { currentStatus, tableData } = this.state
    const { page, pageSize, sorted }  = state
    const { tableData: { query } }     = this.state

    this.setState({ tableData: { ...tableData, loading: true, page, pageSize, sorted } })

    fetchData({
      pageSize,
      query,
      page: page + 1,
      sorting: prepareSorting(sorted),
      states: [currentStatus]
    }).then(({ success, data, error }) => {
      if (success) {
        const { tableData } = this.state
        this.setState({ tableData: { ...tableData, ...data, loading: false } })
      } else {
        Notify.error(error)
      }
    })
  }

  _handleEanDelete (eanId, event) {
    event.preventDefault()

    fetchEanDeleteInfo(eanId).then(({ success, error, data }) => {
      if (success) {
        Notify.confirm(confirmDeleteMessage(data), () => this._handleDeleteRequest(eanId))
      } else {
        Notify.error(error)
      }
    })
  }

  _handleDeleteRequest (eanId) {
    deleteEan(eanId).then(({ success, error }) => {
      if (success) {
        this._handleTableChange()
      } else {
        Notify.error(error)
      }
    })
  }

  render () {
    const { statuses, tableData } = this.state

    const actions = {
      handleEanDelete:        this._handleEanDelete,
      changeStatus:           this._changeStatus,
      handleTableChange:      this._handleTableChange,
      isCurrentStatus:        this._isCurrentStatus,
      handleTableQueryChange: this._handleTableQueryChange
    }

    return (
      <EansTable
        actions={actions}
        statuses={statuses}
        tableData={tableData}
      />
    )
  }
}
