import React, { Component } from 'react'

import { t }         from '../../../locale'
import { fetchData } from './actions'
import { IndexDatatable, RowLink } from '../../common/datatable'

const can = (row, action) =>
  row.permissions.includes(action)

const columns = [
  { Header: t('attributes.company.vat'), id: 'companies.uin', accessor: 'uin' },
  { Header: t('attributes.company.name'), id: 'companies.name', accessor: 'name' },
  { Header: t('attributes.company.address'), id: 'companies.address', accessor: 'address' },
  { Header: t('attributes.postIndex.postalCode'), accessor: 'postIndex.postalCode' },
  { Header: t('attributes.postIndex.city'), accessor: 'postIndex.city' },
  { Header: t('attributes.company.email'), id: 'companies.email', accessor: 'email' },
  { Header: t('attributes.company.tel'), id: 'companies.tel', accessor: 'tel' },
  { Header: t('attributes.company.state'), id: 'companies.state', accessor: 'state' },
  {
    id:       'buttons',
    minWidth: 25,
    sortable: false,
    Cell: ({ row }) => { // eslint-disable-line react/prop-types
      const { _original } = row
      return (
        <span>
          {
            can(_original, 'read') &&
              <RowLink type="show" url={`/companies/${_original.id}`} />
          }
          {
            can(_original, 'edit') &&
            <RowLink type="edit" url={`/companies/${_original.id}/edit`} />
          }
        </span>
      )
    }
  }
]

export default class CompaniesTable extends Component {
  render () {
    return (
      <IndexDatatable
        withSearch
        columns={columns}
        fetchData={fetchData}
        sorted={[{ id: 'companies.uin', desc: false }]}
      />
    )
  }
}
