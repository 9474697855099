import React            from 'react'
import PropTypes        from 'prop-types'
import { t }            from 'web/locale'
import { NativeSelect } from 'web/app/common/form-elements'
import {
  DatatableWithSearch,
  RowLink
} from 'web/app/common/datatable'
import { isEmpty } from 'lodash'

const columns = (showUpdateState, showPreviousNote, handleStateChange, handleRenderMeetingModal) => [
  {
    Header: t('attributes.meeting.id'),
    accessor: 'id',
    minWidth: 30,
    sortable: true,
    id: 'id'
  }, {
    Header:   t('attributes.meeting.company'),
    accessor: 'company.name',
    sortable: true,
    id: 'company.name'
  }, {
    Header:   t('attributes.meeting.salesAgent'),
    accessor: 'user.fullName',
    sortable: true,
    id: 'user.name'
  }, {
    Header:   t('attributes.meeting.date'),
    accessor: 'date',
    sortable: false,
    id: 'date'
  }, {
    Header:   t('attributes.meeting.newDate'),
    accessor: 'newDate',
    sortable: false,
    id: 'newDate'
  }, {
    Header:   t('attributes.meeting.countdown'),
    accessor: 'countdown',
    sortable: false,
    Cell: ({ original }) => { // eslint-disable-line react/prop-types
      const { content, countdownColor } = original.countdown

      return (
        <div className={`${countdownColor}`} >
          {content}
        </div>
      )
    }
  }, {
    Header:   t('attributes.meeting.state'),
    accessor: 'state',
    sortable: false,
    show: showUpdateState,
    Cell: ({ original }) => { // eslint-disable-line react/prop-types
      const { permittedStates, state } = original

      return (
        <NativeSelect
          placeholder={state}
          options={[state, ...permittedStates]}
          value={state}
          onChange={handleStateChange(original, state)}
        />
      )
    }
  }, {
    Header:   t('attributes.meeting.note'),
    accessor: 'note',
    sortable: false,
    id: 'note'
  }, {
    Header: t('attributes.meeting.previousValues'),
    accessor: (meeting) => {
      const lastValues = meeting.previousValues[0]
      if (isEmpty(lastValues)) return ''

      return lastValues.note
    },
    sortable: false,
    show: showPreviousNote,
    id: 'previousNote'
  }, {
    sortable: false,
    minWidth: 42,
    Cell: ({ original }) => { // eslint-disable-line react/prop-types
      return (
        <span onClick={() => handleRenderMeetingModal(original)}>
          <RowLink type="show" url={'#'} />
        </span>
      )
    }
  }
]

const TableWithSearch = ({
  data,
  handleChange,
  handleTableQueryChange,
  handleStateChange,
  handleRenderMeetingModal,
  loading,
  pages,
  pageSize,
  query,
  sorted,
  currentTab
}) => {
  const showUpdateState = ['callback', 'follow_up'].includes(currentTab)
  const showPreviousNote = ['bad_appointment_yes', 'bad_appointment_no'].includes(currentTab)

  return (
    <DatatableWithSearch
      input={{
        query,
        onChange: handleTableQueryChange
      }}
      manual
      columns={columns(showUpdateState, showPreviousNote, handleStateChange, handleRenderMeetingModal)}
      data={data}
      loading={loading}
      pages={pages}
      defaultPageSize={pageSize}
      onFetchData={handleChange}
      defaultSorted={sorted}
    />
  )
}

TableWithSearch.propTypes = {
  data:                     PropTypes.array,
  handleChange:             PropTypes.func.isRequired,
  handleTableQueryChange:   PropTypes.func.isRequired,
  handleStateChange:        PropTypes.func.isRequired,
  handleRenderMeetingModal: PropTypes.func.isRequired,
  loading:                  PropTypes.bool.isRequired,
  pages:                    PropTypes.number,
  pageSize:                 PropTypes.number,
  query:                    PropTypes.string.isRequired,
  sorted:                   PropTypes.array.isRequired,
  currentTab:               PropTypes.string.isRequired
}

export default TableWithSearch
