import React from 'react'
import PropTypes from 'prop-types'
import { isArray } from 'lodash'

const errorsList = (errors) => (
  <ul>
    {errors.map((error, i) => <li key={i}>- {error}</li>)}
  </ul>
)

export default function Error ({ children: error, touched }) {
  if (!error || !touched) return null

  return (
    <span className="help is-danger">
      {isArray(error) ? errorsList(error) : error}
    </span>
  )
}

Error.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  touched: PropTypes.bool
}
