import React from 'react'
import PropTypes from 'prop-types'
import { humanize } from 'underscore.string'
import { t } from 'web/locale'
import { Panel, PanelHead, PanelBlock } from '../../../../common/panel'
import Stats from './Stats'
import EansTable from './EansTable'

const Product = ({ product, stats, table, finalize, unit, deleteEanStat }) => {
  return (
    <Panel kind="light">
      <PanelHead>{humanize(t(`common.product.${product}`))}</PanelHead>
      <PanelBlock>
        <Stats unit={unit} finalize={finalize} {...stats} />
        <EansTable unit={unit} finalize={finalize} table={table} deleteEanStat={deleteEanStat} />
      </PanelBlock>
    </Panel>
  )
}

Product.propTypes = {
  finalize:      PropTypes.bool.isRequired,
  product:       PropTypes.string.isRequired,
  stats:         PropTypes.object,
  table:         PropTypes.array,
  unit:          PropTypes.string.isRequired,
  deleteEanStat: PropTypes.func.isRequired
}

export default Product
