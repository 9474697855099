import { useState, useEffect } from 'react'
import { set, get, cloneDeep, isUndefined } from 'lodash'

import { hasErrors, toFormFields } from 'web/utility/forms'

const useForm = ({
  onSubmit,
  preSubmit,
  initialValues = {},
  validation = () => true,
  onChange = undefined,
  afterHookTriggers = undefined
}) => {
  const clonedValues = cloneDeep(initialValues)
  const [values, setValues] = useState(clonedValues)
  const [errors, setErrors] = useState({})
  const [touched, setTouched] = useState({})
  const [allTouched, setAllTouched] = useState(false)
  const [pristine, setPristine] = useState(true)
  const [dirty, setDirty] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const handleChange = (field, value) => {
    setValues((prevValues) => {
      const newValues = cloneDeep(prevValues)
      set(newValues, field, value)
      return newValues
    })
    handleTouched(field)
    if (onChange) onChange({ field, value, values, handleChange, getValue })
  }

  const handleChanges = (newValues) => {
    toFormFields(newValues).forEach((field) => {
      handleChange(field, get(newValues, field))
    })
  }

  useEffect(() => {
    if (allTouched) validate()
    if (afterHookTriggers) afterHookTriggers(values)
  }, [values])

  const handleErrors = (newErrors) => {
    setErrors(newErrors)
    setPristine(false)
    setDirty(true)
    setInvalid(true)
  }

  const handleTouched = (field, value = true) => {
    setTouched((prevTouched) => {
      const newTouched = { ...prevTouched }
      set(newTouched, field, value)
      return newTouched
    })
    setPristine(false)
    setDirty(true)
  }

  const validate = () => {
    const errors = validation(values)
    const withErrors = hasErrors(errors)
    setErrors(withErrors ? errors : {})
    setInvalid(withErrors)
    return !withErrors
  }

  const handleSubmit = (event) => {
    if (event && event.preventDefault) event.preventDefault()
    setPristine(false)
    setDirty(true)
    setAllTouched(true)

    if (!validate()) return

    if (onSubmit) {
      const valuesToSend = preSubmit ? preSubmit(values) : values
      setSubmitting(true)
      onSubmit(valuesToSend, { handleErrors, setSubmitting, resetForm, getValue })
    } else {
      console.error('No onSubmit callback provided')
    }
  }

  const resetForm = (callback) => {
    setValues(clonedValues)
    setPristine(true)
    setDirty(false)
    setAllTouched(false)
    setInvalid(false)
    setTouched({})
    setErrors({})

    if (callback) callback()
  }

  const getValue = (field, defaultValue = undefined) => {
    const value = get(values, field)

    if (isUndefined(value)) {
      set(values, field, defaultValue)

      return defaultValue
    }

    return value
  }

  const getError = (field) => {
    return get(errors, field)
  }

  const getErrors = (...fields) => {
    return fields.map((f) => getError(f)).filter((e) => e !== undefined)
  }

  const getTouched = (field) => {
    return invalid || get(touched, field) || false
  }

  return {
    values,
    errors,
    touched,
    pristine,
    dirty,
    invalid,
    submitting,

    handleChange,
    handleChanges,
    handleTouched,
    handleSubmit,

    getValue,
    getError,
    getErrors,
    getTouched,

    validate,
    resetForm,

    setValues
  }
}

export default useForm
