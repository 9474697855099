import React from 'react'
import PropTypes from 'prop-types'

import { IndexDatatable } from 'web/app/common/datatable'
import { t } from 'web/locale'

const columns =
[
  {
    id: 'eans.id',
    accessor: 'id',
    minWidth: 55,
    Header: t('attributes.ean.id')
  }, {
    id: 'eans.number',
    accessor: 'number',
    minWidth: 180,
    Header: t('attributes.ean.number')
  }, {
    id: 'eans.state',
    accessor: 'state',
    minWidth: 180,
    Header: t('attributes.ean.state')
  }, {
    id: 'eans.product',
    accessor: 'product',
    minWidth: 180,
    Header: t('attributes.ean.product')
  }, {
    id: 'eans.address',
    accessor: 'address',
    minWidth: 180,
    Header: t('attributes.ean.address')
  }, {
    id: 'post_index.postal_code',
    accessor: 'postIndex.postalCode',
    minWidth: 180,
    Header: t('attributes.postIndex.postalCode')
  }
]
/* eslint-enable react/prop-types */

const EansTable = ({ onChange }) => {
  return (
    <div id="eans-table">
      <IndexDatatable
        fetchData={onChange}
        columns={columns}
        sorted={[{ id: 'eans.id', desc: false }]}
      />
    </div>
  )
}

EansTable.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default EansTable
