import React from 'react'
import PropTypes from 'prop-types'

import Label from './Label'
import Control from './Control'

const InfoWithLabel = ({ children, name }) => (
  <Control>
    <Label>
      {name}
    </Label>
    <Control>
      <span style={{ lineHeight: '2.143rem' }}>{children}</span>
    </Control>
  </Control>
)

InfoWithLabel.propTypes = {
  children: PropTypes.any,
  name:     PropTypes.string
}

export default InfoWithLabel
