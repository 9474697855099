import React from 'react'
import PropTypes from 'prop-types'

const TableCell = ({ children }) => (
  <td>{children}</td>
)

TableCell.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node
  ])
}

export default TableCell
