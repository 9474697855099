import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Form } from 'web/app/common/forms'
import StatusChangeForm            from './StatusChangeForm'
import { validateStatusModalForm } from '../validations'
import { Modal }                   from 'web/app/common/modal'
import { t }                       from 'web/locale'

export default class StatusChangeModal extends Component {
  static propTypes = {
    isOpen:       PropTypes.bool.isRequired,
    newState:     PropTypes.string,
    onCloseModal: PropTypes.func.isRequired,
    onSubmit:     PropTypes.func.isRequired
  }

  _handleClose = () => {
    this.props.onCloseModal()
  }

  render () {
    const { isOpen } = this.props

    const modalProps = {
      header:  t(`views.contracts.index.label.needDelivery`),
      isShown: isOpen,
      onClose: this._handleClose
    }

    return (
      <Modal {...modalProps}>
        <Form
          onSubmit={this.props.onSubmit}
          validation={validateStatusModalForm}
        >
          <StatusChangeForm />
        </Form>
      </Modal>
    )
  }
}
