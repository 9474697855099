import React                            from 'react'
import PropTypes                        from 'prop-types'
import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import { t }                            from 'web/locale'
import { Tabs, Tab }                    from 'web/app/common/layout'
import {
  TableWithSearch,
  StateChangeModal,
  MeetingModal
} from './components'

const renderTab = (isCurrentTab, onTabChange) =>
  (tab) =>
    <Tab isActive={isCurrentTab(tab.value)} key={tab.value}>
      <a onClick={() => onTabChange(tab.value)}>{tab.label}</a>
    </Tab>

const MeetingsOverviewIndex = ({
  actions: {
    handleTableChange,
    handleTableQueryChange,
    handleTabChange,
    handleCloseModal,
    handleStateChange,
    handleRenderMeetingModal
  },
  tableData,
  tabs,
  isCurrentTab,
  isModalVisible,
  isMeetingModalVisible,
  targetMeeting,
  newState,
  currentTab,
  meetingsDurations
}) => {
  const tableProps = {
    ...tableData,
    handleChange: handleTableChange,
    handleTableQueryChange,
    handleStateChange,
    handleRenderMeetingModal,
    currentTab
  }
  const stateModalProps = {
    handleTableChange,
    handleCloseModal,
    isModalVisible,
    targetMeeting,
    newState,
    meetingsDurations
  }
  const meetingModalProps = {
    isMeetingModalVisible,
    handleCloseModal,
    handleTableChange,
    targetMeeting,
    meetingsDurations
  }

  return (
    <div>
      <StateChangeModal {...stateModalProps} />
      <MeetingModal {...meetingModalProps} />

      <Panel kind="primary">
        <PanelHead>{t('views.meetings.index.title')}</PanelHead>
      </Panel>

      <Tabs>
        { tabs.map(renderTab(isCurrentTab, handleTabChange)) }
      </Tabs>

      <Panel>
        <PanelBlock>
          <TableWithSearch {...tableProps} />
        </PanelBlock>
      </Panel>
    </div>
  )
}

MeetingsOverviewIndex.propTypes = {
  actions: PropTypes.shape({
    handleTableChange:        PropTypes.func.isRequired,
    handleTableQueryChange:   PropTypes.func.isRequired,
    handleTabChange:          PropTypes.func.isRequired,
    handleCloseModal:         PropTypes.func.isRequired,
    handleStateChange:        PropTypes.func.isRequired,
    handleRenderMeetingModal: PropTypes.func.isRequired
  }),
  tableData:             PropTypes.object.isRequired,
  tabs:                  PropTypes.array.isRequired,
  isCurrentTab:          PropTypes.func.isRequired,
  isModalVisible:        PropTypes.bool.isRequired,
  isMeetingModalVisible: PropTypes.bool.isRequired,
  newState:              PropTypes.string.isRequired,
  targetMeeting:         PropTypes.object,
  meetingsDurations:     PropTypes.object.isRequired,
  currentTab:            PropTypes.string.isRequired
}

export { MeetingsOverviewIndex }
