import buildValidation from 'web/utility/validations'

const validateTicketForm = (values = {}) => {
  const { filled } = buildValidation(values)
  return ({
    ticket: {
      description: filled('ticket.description'),
      createdForId: filled('ticket.createdForId'),
      category: filled('ticket.category'),
      deadline: filled('ticket.deadline')
    }
  })
}

export { validateTicketForm }
