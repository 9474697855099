import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { isFunction } from 'lodash'

import useForm from './useForm'

const FormContext = createContext()
const useFormContext = () => useContext(FormContext)

const Form = ({
  children,
  getApi = undefined,
  id = undefined,
  initialValues = {},
  onChange = undefined,
  onSubmit,
  preSubmit = undefined,
  validation = () => true,
  afterHookTriggers = undefined,
  ...rest
}) => {
  const formApi = useForm({
    initialValues,
    onChange,
    onSubmit,
    preSubmit,
    validation,
    afterHookTriggers
  })

  if (process.env.NODE_ENV === 'development') {
    console.debug('FORM API UPDATE', formApi)
  }

  if (getApi) getApi(formApi)

  return (
    <FormContext.Provider value={formApi}>
      <form id={id} onSubmit={formApi.handleSubmit}>
        {
          isFunction(children)
            ? children({ ...rest, formApi })
            : React.Children.map(children, (child) =>
              React.cloneElement(child, { ...rest, formApi })
            )
        }
      </form>
    </FormContext.Provider>
  )
}

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element).isRequired
  ]).isRequired,
  getApi: PropTypes.func,
  id: PropTypes.string,
  initialValues: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  preSubmit: PropTypes.func,
  validation: PropTypes.func,
  afterHookTriggers: PropTypes.func
}

export {
  FormContext,
  useFormContext
}

export default Form
