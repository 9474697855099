import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { fetchUnits, deleteUnit, createUnit } from './api'
import SupplierSettings from './component'
import Notify from 'web/utility/Notify'

export default class SupplierSettingsContainer extends Component {
  static propTypes = {
    baseGasUnit:     PropTypes.object.isRequired,
    gasProductTypes: PropTypes.array.isRequired,
    globalGasUnits:  PropTypes.array.isRequired
  }

  static defaultProps = {
    baseGasUnit:     {},
    gasProductTypes: [],
    globalGasUnits:  []
  }

  state = {
    tableIsLoading: false,
    unitsTable:     []
  }

  componentDidMount () {
    this._loadUnits()
  }

  _loadUnits () {
    this.setState({ tableIsLoading: true })

    fetchUnits()
      .then(({ success, data }) => {
        this.setState({ tableIsLoading: false })

        if (success) {
          this.setState({ unitsTable: data })
        } else {
          Notify.apiError(data)
        }
      })
  }

  _prepareUnits = (units) => {
    return units.filter((unit) => parseFloat(unit.coefficient) !== 1).map((unit) => unit.name)
  }

  _onUnitCreate = (values, { setSubmitting, handleErrors, resetForm }) => {
    this.setState({ tableIsLoading: true })

    if (values.unit) {
      values.unit.product = 'gas'
    }

    createUnit({ values })
      .then(({ success, data }) => {
        this.setState({ tableIsLoading: false })

        if (success) {
          const unitsTable = data ? [...this.state.unitsTable, data] : this.state.unitsTable
          this.setState({ unitsTable })
          resetForm()
        } else {
          data.message ? Notify.apiError(data) : handleErrors(data)
        }
        setSubmitting(false)
      })
  }

  _onUnitDelete = (id) => {
    this.setState({ tableIsLoading: true })

    deleteUnit({ id })
      .then(({ success, data }) => {
        this.setState({ tableIsLoading: false })

        if (success) {
          this.setState({
            unitsTable: this.state.unitsTable.filter((obj) => obj.id !== parseInt(id))
          })
        } else {
          Notify.alert(data)
        }
      })
  }

  _viewConversion = (obj) => {
    const { baseGasUnit, globalGasUnits } = this.props

    if (obj && (obj.name || obj.id) && obj.coefficient) {
      const name = obj.name ? obj.name : globalGasUnits.find((unit) => unit.id === obj.id).name
      return `1 ${baseGasUnit.name} = ${obj.coefficient} ${name}`
    }
  }

  render () {
    const { gasProductTypes, globalGasUnits } = this.props
    return (
      <SupplierSettings
        onUnitCreate={this._onUnitCreate}
        onUnitDelete={this._onUnitDelete}
        productTypes={gasProductTypes}
        tableIsLoading={this.state.tableIsLoading}
        unitsForm={this._prepareUnits(globalGasUnits)}
        unitsTable={this.state.unitsTable}
        viewConversion={this._viewConversion}
      />
    )
  }
}
