import React from 'react'
import PropTypes from 'prop-types'

import { Columns, Column }                 from 'web/app/common/layout'
import { FileInputWithLabel as FileInput } from 'web/app/common/forms'

const StampUpload = ({ onStampUpload }) => {
  const _onStampUpload = (e) => {
    const file = e.target.files[0]
    onStampUpload(file)
  }

  return (
    <>
      <Columns>
        <Column classNames="is-6 is-offset-3 stamp-field">
          <FileInput
            name="contract[stampFile]"
            field="stampFile"
            isWide
            required
            onChange={_onStampUpload}
          />
        </Column>
      </Columns>
      <br />
    </>
  )
}

StampUpload.propTypes = {
  onStampUpload: PropTypes.func.isRequired
}

export default StampUpload
