import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import useField from './useField'
import { t } from 'web/locale'
import { toId, toInputName } from 'web/utility/helpers'
import { Control, Label } from 'web/app/common/form-elements'
import Error from './Error'

const handleChangeWithFormat = (event, handleChange, format) => {
  return handleChange(format(event.target.value))
}

const Input = ({
  field,

  button = undefined,
  defaultValue = undefined,
  disabled = false,
  fieldName = undefined,
  label = undefined,
  min = 0,
  required = false,
  type = 'text',
  formatInput = (val) => (val)
}) => {
  const {
    getValue, getError, getTouched, handleTouched, handleChange
  } = useField(field, { defaultValue })

  const id = toId(field)
  label = label || (fieldName && t(`attributes.${fieldName}`)) || t(`attributes.${field}`)
  const value = getValue('')
  const error = getError()
  const touched = getTouched()

  const inputClass = cn('input', {
    'is-danger': error && touched,
    'is-expanded': true
  })
  const iconClass = cn('icon is-small', {
    'icon-ok': !error,
    'icon-asterisk is-danger': !!error
  })

  return (
    <Control>
      <Label htmlFor={id}>{label}</Label>
      <Control
        hasIcon={required}
        hasIconLeft={button && required}
        hasIconRight={required && !button}
        hasAddons={!!button}
      >
        <input
          id={id}
          placeholder={`${label}...`}
          className={inputClass}
          value={value}
          onChange={(event) => handleChangeWithFormat(event, handleChange, formatInput)}
          onBlur={() => handleTouched(true)}
          type={type}
          name={toInputName(field)}
          disabled={disabled}
          step="any"
          min={min}
        />
        {required && <i className={iconClass} />}
        {button}
      </Control>
      <Error touched={touched}>{error}</Error>
    </Control>
  )
}

Input.propTypes = {
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,

  button: PropTypes.element,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  fieldName: PropTypes.string,
  label: PropTypes.string,
  min: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  formatInput: PropTypes.func
}

export default Input
