import { apiPath, get, patch } from 'web/utility/http'

const fetchData = ({ page, pageSize, query, sorting, state }) =>
  get(apiPath('/meetings'), {
    params: {
      page,
      pageSize,
      sorting,
      query,
      state
    }
  })

const updateMeeting = ({ id, state, values }) =>
  patch(apiPath(`/meetings/${id}`), {
    state,
    ...values
  })

export { fetchData, updateMeeting }
