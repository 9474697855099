import React from 'react'
import PropTypes from 'prop-types'

const LevelRight = ({ children }) => (
  <div className="level-right">{children}</div>
)

LevelRight.propTypes = {
  children: PropTypes.any.isRequired
}

export default LevelRight
