import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { IndexDatatable } from 'web/app/common/datatable'
import { fetchData } from './api'
import { callsTableColumns } from './components/callsTableColumns'

export default class ContactCallsTable extends Component {
  static propTypes = {
    contactId: PropTypes.number.isRequired
  }

  render () {
    const { contactId } = this.props

    return (
      <IndexDatatable
        columns={callsTableColumns}
        fetchData={fetchData(contactId)}
        sorted={[{ id: 'calls.id', desc: false }]}
      />
    )
  }
}
