import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { fetchPrices } from './api'
import Notify from 'web/utility/Notify'
import SupplierPricesList from './component'
import { prepareMeters, prepareDurations } from 'web/utility/prepareData'

export default class SupplierPricesListContainer extends Component {
  static propTypes = {
    // addons:       PropTypes.array.isRequired,
    durations:    PropTypes.array.isRequired,
    meters:       PropTypes.array.isRequired,
    priceModels:  PropTypes.array.isRequired,
    products:     PropTypes.array.isRequired,
    supplier:     PropTypes.object.isRequired
    // tariffGroups: PropTypes.array.isRequired,
    // children:     PropTypes.any
  }

  static defaultProps = {
    addons: []
  }

  state = {
    data:       [],
    duration:   this.props.durations[2],
    meterId:    this.props.meters[0].id,
    priceModel: this.props.priceModels[0],
    product:    this.props.products[0],
    isLoading:  false
  }

  componentDidMount () {
    this._loadPrices()
  }

  _loadPrices = () => {
    this.setState({ isLoading: true })
    const { supplier } = this.props
    const { product, priceModel, meterId, duration } = this.state

    fetchPrices({ product, priceModel, meterId, duration, supplierId: supplier.id })
      .then(({ success, data, error }) => {
        if (success) {
          this.setState({ data, isLoading: false })
        } else {
          Notify.apiError(error)
        }
      })
  }

  _onChange = (key) => {
    return (event) => {
      const { value } = event.target
      const v = (key === 'duration' || key === 'meterId') ? Number(value) : value
      this.setState({ [key]: v }, this._loadPrices)
    }
  }

  render () {
    const { meters, durations, products, priceModels } = this.props
    const { duration, meterId, priceModel, product, data, isLoading } = this.state

    const tableParams = { duration, meterId, priceModel, product }
    const tableOptions = {
      priceModels,
      products,
      durations: prepareDurations(durations),
      meters: prepareMeters(meters),
      onChange: this._onChange
    }

    return (
      <SupplierPricesList
        data={data}
        tableOptions={tableOptions}
        tableParams={tableParams}
        isLoading={isLoading}
      />
    )
  }
}
