import React from 'react'
import PropTypes from 'prop-types'

import { FormRow } from 'web/app/common/layout'
import {
  ControlButtons,
  Form,
  InputWithLabel as Input
} from 'web/app/common/forms'
import { ratioValidations } from '../validation'

const MarginsRatiosForm = () =>
  <>
    <FormRow>
      <Input
        fieldName="margin.ratio.brokerCoefficient"
        field="brokerCoefficient"
        type="number"
        step={0.1}
        required
      />
      <Input
        fieldName="margin.ratio.salesCoefficient"
        field="salesCoefficient"
        type="number"
        step={0.1}
        required
      />
    </FormRow>
    <ControlButtons />
  </>

const MarginsRatiosFormContainer = ({
  onRatioUpdate,
  dataOptions: {
    marginRatio: {
      brokerCoefficient,
      salesCoefficient
    }
  }
}) =>
  <Form
    onSubmit={onRatioUpdate}
    validation={ratioValidations}
    initialValues={{ brokerCoefficient, salesCoefficient }}
  >
    <MarginsRatiosForm />
  </Form>

export default MarginsRatiosFormContainer

MarginsRatiosFormContainer.propTypes = {
  onRatioUpdate: PropTypes.func.isRequired,
  dataOptions: PropTypes.shape({
    marginRatio: PropTypes.shape({
      brokerCoefficient: PropTypes.number.isRequired,
      salesCoefficient: PropTypes.number.isRequired
    })
  })
}
