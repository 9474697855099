import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Columns, Column } from '../../common/layout'
import { MultiselectWithLabel } from '../../common/html_forms'
import { prepareAreas } from 'web/utility/prepareData'
import { t } from 'web/locale'

export default class MultiSelectAreas extends Component {
  static propTypes = {
    areas: PropTypes.array,
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.array
  }

  render () {
    const { areas, handleChange, values } = this.props

    return (
      <Columns>
        <Column>
          <MultiselectWithLabel
            data={prepareAreas(areas)}
            label={t(`attributes.supplier.areas`)}
            handleChange={handleChange}
            name="supplier[area_ids]"
            values={values}
            onChange={handleChange}
          />
        </Column>
      </Columns>
    )
  }
}
