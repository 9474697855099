import React from 'react'
import PropTypes from 'prop-types'

import { Panel, PanelHead, PanelBlock } from 'web/app/common/panel'
import { FormRow } from 'web/app/common/layout'
import { NativeSelect } from 'web/app/common/form-elements'
import { t } from 'web/locale'

import {
  ControlButtons,
  InputWithLabel as Input,
  SelectWithLabel as Select
} from 'web/app/common/forms'

const OfferForm = ({
  formApi: {
    handleChanges
  },
  backPath,
  contactId,
  setupContact,
  data: {
    title, contacts, titles, offerLanguages,
    jobTitles, kinds, languages
  }
}) => (
  <Panel>
    <PanelHead>{title}</PanelHead>
    {/* Contact selection section */}
    <PanelBlock>
      <h3 className="title is-3">{t('views.offers.new.contact')}</h3>
      <FormRow>
        <NativeSelect
          className="contacts"
          onChange={(e) => setupContact({ handleChanges, value: e.target.value })}
          options={contacts}
          value={contactId}
        />
      </FormRow>
    </PanelBlock>
    {/* Contact section */}
    <PanelBlock>
      <FormRow>
        <Input
          field="contact.firstName"
          required
        />
        <Input
          field="contact.lastName"
          required
        />
        <Select
          data={titles}
          field="contact.title"
          required
        />
      </FormRow>
      <FormRow>
        <Select
          field="contact.jobTitle"
          data={jobTitles}
          required
        />
        <Select
          field="contact.kind"
          data={kinds}
          required
        />
        <Select
          field="contact.languageId"
          data={languages}
          required
        />
      </FormRow>
      <FormRow>
        <Input
          field="contact.email"
          required
        />
        <Input
          field="contact.tel"
          required
        />
        <Input field="contact.mobile" />
      </FormRow>
    </PanelBlock>

    {/* Contract section */}
    <PanelBlock>
      <h3 className="title is-3">{t('views.offers.new.contract')}</h3>
      <FormRow>
        <Select
          field="offer.languageId"
          data={offerLanguages}
          required
        />
      </FormRow>
    </PanelBlock>

    <PanelBlock>
      <ControlButtons
        onCancel={() => (window.location = backPath)}
        onReset={() => setupContact({ value: null, handleChanges })}
      />
    </PanelBlock>
  </Panel>
)

OfferForm.propTypes = {
  formApi: PropTypes.shape({
    handleChanges: PropTypes.func.isRequired
  }),
  backPath:            PropTypes.string.isRequired,
  contactId:           PropTypes.number,
  setupContact:        PropTypes.func.isRequired,
  data: PropTypes.shape({
    contacts:       PropTypes.array.isRequired,
    jobTitles:      PropTypes.array.isRequired,
    kinds:          PropTypes.array.isRequired,
    languages:      PropTypes.array.isRequired,
    offerLanguages: PropTypes.array.isRequired,
    title:          PropTypes.string.isRequired,
    titles:         PropTypes.array.isRequired
  })
}

export default OfferForm
