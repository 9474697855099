import React from 'react'
import PropTypes from 'prop-types'

import LevelItem from './LevelItem'

const LevelInfo = ({ title, children, size }) => (
  <LevelItem hasTextCentered>
    <div>
      <p className="heading">{title}</p>
      <p className={`title is-${size || 3}`}>{children}</p>
    </div>
  </LevelItem>
)

LevelInfo.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string
  ]).isRequired,
  title: PropTypes.string.isRequired,
  size: PropTypes.string
}

export default LevelInfo
