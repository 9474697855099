import React from 'react'
import PropTypes from 'prop-types'

import {
  MultiselectWithLabel,
  Form
} from 'web/app/common/forms'
import { Modal } from 'web/app/common/modal'
import { Button } from 'web/app/common/elements'
import { t } from 'web/locale'

import { prepareYearsOfferExternalPrices } from 'web/utility/prepareData'

const NAME = 'EXTERNAL_PRICE_REQUEST_MODAL'

const ExternalPriceRequestModal =
  ({ modalProps, externalRequestContractDurations, onSubmit, validation }) => {
    return (
      <Modal {...modalProps}>
        <Form
          onSubmit={onSubmit}
          initialValues={[]}
          validation={validation}
        >
          <MultiselectWithLabel
            fieldName="externalPrice.years"
            field="years"
            data={prepareYearsOfferExternalPrices(externalRequestContractDurations)}
            required
          />

          <Button type="submit">
            { t('common.submit') }
          </Button>
        </Form>
      </Modal>
    )
  }

ExternalPriceRequestModal.propTypes = {
  modalProps: PropTypes.shape({
    header: PropTypes.string.isRequired,
    isShown: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    height: PropTypes.string.isRequired
  }),
  externalRequestContractDurations: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validation: PropTypes.func.isRequired
}

export { ExternalPriceRequestModal, NAME }
