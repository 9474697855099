import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'web/locale'
import {
  ControlButtons,
  FileInputWithLabel
} from 'web/app/common/forms'
import { isEmpty } from 'lodash'

const errorMessages = (errors) => {
  if (isEmpty(errors)) return (<> </>)

  return (
    <>
      <b> { t('views.contracts.index.ecaUpload.errors') } </b>

      { errors.map((error, idx) => (<p key={idx}> { error } </p>)) }
    </>
  )
}

const EcaUploadForm = ({ ecaUploadErrors, onReset }) => {
  return (
    <>
      <p> { t('views.contracts.index.ecaUpload.notice') } </p>
      <br />

      <FileInputWithLabel
        field="contractsImportFile"
        label={t('attributes.contract.importFile')}
        required
      />
      <FileInputWithLabel
        field="contractsParaphFile"
        label={t('attributes.contract.paraphFile')}
        required
      />
      <FileInputWithLabel
        field="contractsSignatureFile"
        label={t('attributes.contract.signatureFile')}
        required
      />

      { errorMessages(ecaUploadErrors) }

      <ControlButtons
        onReset={onReset}
      />
    </>
  )
}

EcaUploadForm.propTypes = {
  ecaUploadErrors: PropTypes.array.isRequired,
  onReset: PropTypes.func.isRequired
}

export default EcaUploadForm
