import { get, post, patch, destroy } from 'web/utility/http'
import { pricesPath } from '../../helpers'

const marginRatioPath = (path) =>
  pricesPath(path).replace('/margins', '')

const fetchMargins = () =>
  get(pricesPath('/'))

const createMargin = (values) =>
  post(pricesPath('/'), values)

const updateRatio = (values) =>
  patch(marginRatioPath('/margin_ratios'), values)

const deleteMargin = (id, values) =>
  destroy(pricesPath(`/${id}`), { data: values })

export { fetchMargins, deleteMargin, createMargin, updateRatio }
