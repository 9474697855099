import React from 'react'
import PropTypes from 'prop-types'
import { DateTimePicker as Picker } from 'react-widgets'
import cn from 'classnames'
import { isString } from 'lodash'

import useField from './useField'
import { moment, t } from 'web/locale'
import { Control, Label } from 'web/app/common/form-elements'
import { toId, toInputName } from 'web/utility/helpers'
import Error from './Error'

const momentFormats = [
  moment.ISO_8601,
  moment.HTML5_FMT.DATE,
  moment.HTML5_FMT.DATETIME_LOCAL_SECONDS
]

const DateTimePickerWithLabel = ({
  field,

  button = undefined,
  calendar = true,
  disabled = false,
  error = undefined,
  format = 'DD/MM/YYYY',
  label = undefined,
  max = undefined,
  min = new Date(1900, 0, 1),
  name = undefined,
  required = false,
  time = false,
  defaultValue = undefined,
  withDefaultValue = false,
  availableTimes = undefined,
  views = undefined
}) => {
  const {
    getValue, getError, getTouched, handleChange
  } = useField(field, { defaultValue: undefined })

  if (calendar && time) {
    format = `${format}, HH:mm`
  } else if (!calendar && time) {
    format = 'HH:mm'
  }

  const onChange = (value) => {
    handleChange(value)
  }

  const handleFormat = (value) => {
    const formattedValue = value ? moment(value).format(format) : value

    return formattedValue
  }

  const handleParse = (value) => {
    const formats = [format, ...momentFormats]
    // if its date without time, use UTC format
    const normalized = time ? moment(value, formats) : moment.utc(value, formats)

    return normalized.isValid() ? normalized.toDate() : undefined
  }

  const id = toId(field)
  label = label || t(`attributes.${field}`)
  const formValue = withDefaultValue ? getValue(defaultValue) : getValue()
  const value = isString(formValue) ? handleChange(handleParse(formValue)) : formValue
  error = error || getError()
  const touched = getTouched()

  const classNames = cn({
    'rw-required': required && !!error,
    'rw-required-outline': required && !!error && touched
  })

  return (
    <Control>
      <Label htmlFor={field}>{label}</Label>
      <Control hasAddons={!!button} >
        <Picker
          editing={false}
          id={id}
          date={calendar}
          disabled={disabled}
          className={classNames}
          name={name || toInputName(field)}
          onChange={onChange}
          onBlur={(e) => onChange(e.target.value)}
          placeholder={format}
          min={min}
          max={max}
          time={time}
          format={handleFormat}
          value={value}
          parse={handleParse}
          defaultValue={defaultValue}
          availableTimes={availableTimes}
          views={views}
        />
        {button}
      </Control>
      <Error touched={touched}>{error}</Error>
    </Control>
  )
}

DateTimePickerWithLabel.propTypes = {
  field: PropTypes.string.isRequired,

  button:           PropTypes.any,
  calendar:         PropTypes.bool,
  disabled:         PropTypes.bool,
  error:            PropTypes.string,
  format:           PropTypes.string,
  label:            PropTypes.string,
  max:              PropTypes.instanceOf(Date),
  min:              PropTypes.instanceOf(Date),
  name:             PropTypes.string,
  required:         PropTypes.bool,
  time:             PropTypes.bool,
  withDefaultValue: PropTypes.bool,
  defaultValue:     PropTypes.instanceOf(Date),
  availableTimes:   PropTypes.shape({
    hours: PropTypes.arrayOf(PropTypes.number),
    includeLastStep: PropTypes.bool
  }),
  views:            PropTypes.array
}

export default DateTimePickerWithLabel
