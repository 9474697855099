import React from 'react'
import PropTypes from 'prop-types'
import { Document, Page } from 'react-pdf'

import { Button } from 'web/app/common/elements'
import { t }      from 'web/locale'

const SignContractPdf = ({
  filePath,
  onDocumentLoad,
  onPageChange,
  pdfState
}) => {
  return (
    <div>
      <Document
        file={filePath}
        noData={t('views.offers.contractSign.noPdf')}
        onLoadSuccess={onDocumentLoad}
        pageIndex={pdfState.index}
      >
        <Page
          pageIndex={pdfState.index}
        />
      </Document>

      <div className="content is-small">
        <p>
          Page {pdfState.number} of {pdfState.total}
        </p>

        {
          pdfState.total !== 0 &&
          <div>
            <Button
              onClick={() => onPageChange(-1)}
              disabled={pdfState.index === 0}
            >
              {t('views.offers.contractSign.prev')}
            </Button>
            {' '}
            <Button
              onClick={() => onPageChange(1)}
              disabled={pdfState.number === pdfState.total}
            >
              {t('views.offers.contractSign.next')}
            </Button>
          </div>
        }
      </div>
    </div>
  )
}

SignContractPdf.propTypes = {
  filePath:       PropTypes.string.isRequired,
  onDocumentLoad: PropTypes.func.isRequired,
  onPageChange:   PropTypes.func.isRequired,
  pdfState:       PropTypes.object.isRequired
}

export default SignContractPdf
