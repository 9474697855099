import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Panel, PanelHead } from 'web/app/common/panel'
import { Tabs, Tab }        from 'web/app/common/layout'
import { t }                from 'web/locale'
import { isLinkActive, isRootActive } from './helpers'

const ShowSupplier = ({
  supplier: { id, name },
  canUpdate,
  location: { pathname }
}) => {
  return (
    <div className="suppliers-show">
      <Panel>
        <PanelHead>
          {name} (#{id})
        </PanelHead>
      </Panel>
      <Tabs>
        <Tab isActive={isRootActive({ pathname })}>
          <Link to={`/suppliers/${id}`}>
            {t('views.suppliers.show.info')}
          </Link>
        </Tab>
        { canUpdate &&
          <Tab isActive={pathname.includes('prices')}>
            <Link to={`/suppliers/${id}/prices`}>
              {t('views.suppliers.show.prices')}
            </Link>
          </Tab>
        }
        { canUpdate &&
          <Tab isActive={isLinkActive({ page: 'contract_templates', pathname })}>
            <Link to={`/suppliers/${id}/contract_templates`}>
              {t('views.suppliers.show.templates')}
            </Link>
          </Tab>
        }
        <Tab isActive={isLinkActive({ page: 'mails', pathname })}>
          <Link to={`/suppliers/${id}/mails`}>
            {t('views.suppliers.show.mails')}
          </Link>
        </Tab>
        { canUpdate &&
          <Tab isActive={isLinkActive({ page: 'settings', pathname })}>
            <Link to={`/suppliers/${id}/settings`}>
              {t('views.suppliers.show.settings')}
            </Link>
          </Tab>
        }
      </Tabs>
    </div>
  )
}

ShowSupplier.propTypes = {
  canUpdate: PropTypes.bool.isRequired,
  supplier: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
}

export default ShowSupplier
