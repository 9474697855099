import React from 'react'
import PropTypes from 'prop-types'
import { DropdownList } from 'react-widgets'

import { prepareStatuses } from 'web/utility/prepareData'

const StatusSelect = (props) => {
  const { userStatuses, onChange, selectionValue } = props

  return (
    <DropdownList
      data={prepareStatuses(userStatuses)}
      value={selectionValue}
      valueField="value"
      textField="text"
      onChange={onChange}
    />
  )
}

StatusSelect.propTypes = {
  onChange:       PropTypes.func.isRequired,
  userStatuses:   PropTypes.array.isRequired,
  selectionValue: PropTypes.string.isRequired
}

export default StatusSelect
