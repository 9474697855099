import React, { Component } from 'react'
import Notify from 'web/utility/Notify'

import { t }         from 'web/locale'
import { fetchData, deactivateSentRemoteLink } from './actions'
import { IndexDatatable, RowLink } from 'web/app/common/datatable'

const _deactivateSentRemoteLink = ({ id }) => {
  deactivateSentRemoteLink({ id }).then(({ success, error }) => {
    if (success) {
      window.location.reload()
    } else {
      Notify.apiError(error)
    }
  })
}

const _onClick = ({ id }) => {
  Notify.confirm(t('common.areYouSure'), () => _deactivateSentRemoteLink({ id }))
}

/* eslint-disable react/prop-types */
const columns = [
  { Header: t('attributes.sentRemoteLink.id'), id: 'sentRemoteLinks.id', accessor: 'id' },
  { Header: t('attributes.sentRemoteLink.kind'), id: 'sentRemoteLinks.kind', accessor: 'kind' },
  {
    Header: t('attributes.sentRemoteLink.offerId'),
    id: 'sentRemoteLinks.offer.id',
    accessor: 'offer.id',
    sortable: false,
    Cell: ({ original: { offer: { id }, kind } }) => {
      const contractModel = t('attributes.sentRemoteLink.kind.models.contract')

      if (kind === contractModel) return (<a href={`/offers/${id}/contracts`}>{id}</a>)

      return id
    }
  },
  {
    Header: t('attributes.sentRemoteLink.companyId'),
    id: 'sentRemoteLinks.offer.company.id',
    accessor: 'offer.company.id',
    sortable: false,
    Cell: ({ value }) => {
      return (<a href={`/companies/${value}/info`}>{value}</a>)
    }
  },
  {
    Header: t('attributes.sentRemoteLink.companyName'),
    id: 'sentRemoteLinks.offer.company.name',
    sortable: false,
    accessor: 'offer.company.name'
  },
  {
    Header: t('attributes.sentRemoteLink.createdAt'),
    id: 'sentRemoteLinks.createdAt',
    accessor: 'createdAt'
  },
  {
    id:       'buttons',
    minWidth: 25,
    sortable: false,
    Cell: ({ original: { id } }) => {
      return (
        <span>
          {
            <RowLink type="delete" onClick={() => _onClick({ id })} />
          }
        </span>
      )
    }
  }
]
/* eslint-enable react/prop-types */

export default class SentRemoteLinksTable extends Component {
  render () {
    return (
      <IndexDatatable
        columns={columns}
        fetchData={fetchData}
        sorted={[{ id: 'sentRemoteLinks.id', desc: false }]}
      />
    )
  }
}
